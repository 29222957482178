import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs } from './material';

const TabsToggle = withStyles(() => ({
  root: {
    padding: '2px',
    backgroundColor: '#EEEEEE',
    minHeight: '34px',
    borderRadius: '4px',
  },
  indicator: {
    backgroundColor: '#FF7F29',
    height: '34px',
    borderRadius: '3px',
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tabs {...props} />);

TabsToggle.defaultProps = {
  classes: {},
};

TabsToggle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
};

export default TabsToggle;
