class GtagEvent {
  constructor({
    event,
    eventCategory,
    eventAction,
    eventLabel,
    user,
    page,
    products
  }) {
    this.event = event || null;
    this.eventCategory = eventCategory || null;
    this.eventAction = eventAction || null;
    this.eventLabel = eventLabel || null;
    this.user = user || null;
    this.page = page || null;
    this.products = products || null;
  }
}

export default GtagEvent;