import { values, sortBy } from 'lodash';
import { convert, toArray, filterDisabled } from './utils';
import property from './property';

const mappings = {
  property,
  properties: (properties, lists, type) => {
    return {
      data: properties.data.map((p) => property[type](p, lists)),
      count: properties.statistics.count,
    };
  },
  regions: (regions) => {
    const codes = { omsk: '55', kazan: '16' };
    const other = values(regions)
      .filter((region) => [codes.omsk, codes.kazan].indexOf(region.id) === -1)
      .filter((region) => !!region.enabled);

    // Омск и Казань сверху. Все остальные по алфавиту
    return [regions[codes.omsk], regions[codes.kazan], ...sortBy(other, 'region_name')].map(
      mappings.region
    );
  },
  region: (region) => {
    return {
      id: Number(region.id),
      code: Number(region.id),
      name: region.region_name,
      capitalId: Number(region.capital_id),
      capitalName: region.name,
      domain: region.domain_long,
      coordinates: [region.lat, region.lon],
    };
  },
  detectedRegion: (region) => {
    return {
      code: Number(region.auto),
      name: region.name_ru,
    };
  },
  floorPositions: (items) => {
    return items.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  },
  lists: (lists) => {
    return {
      realty: {
        sales: values(lists.realty).map((item) => {
          return { id: Number(item.id), shortName: item.name_short2, name: item.name };
        }),
        longtermRents: values(lists.rentTypes).map((item) => {
          return { id: Number(item.id), shortName: item.name_short2, name: item.name };
        }),
        dailyRents: values(lists.dailyRentTypes).map((item) => {
          return { id: Number(item.id), shortName: item.name_short2, name: item.name };
        }),
        businessSales: values(lists.businessSaleRubrics).map((item) => {
          return { id: Number(item.id), shortName: item.name_short, name: item.name };
        }),
        businessRents: values(lists.businessRentRubrics).map((item) => {
          return { id: Number(item.id), shortName: item.name_short, name: item.name };
        }),
        lands: values(lists.landRubrics).map((item) => {
          return { id: Number(item.id), shortName: item.name_short, name: item.name };
        }),
      },
      building: values(lists.building).map((item) => {
        return { id: item.id, name: item.name_short };
      }),
      door: convert(lists.door),
      floorCover: convert(lists.floorCover),
      buildingMaterial: convert(lists.material).filter(filterDisabled),
      furniture: toArray(lists.furniture),
      parking: convert(lists.parking),
      balcony: convert(lists.balcony),
      material: convert(lists.material).filter(filterDisabled),
      layout: convert(lists.planning),
      condition: {
        rents: convert(lists.rentRenovation)
          .filter(filterDisabled)
          .sort((a, b) => a.rank > b.rank),
        sales: convert(lists.saleRenovation)
          .filter(filterDisabled)
          .sort((a, b) => a.rank > b.rank),
      },
      windowMaterial: convert(lists.windowType),
      viewFromWindow: convert(lists.windowView),
      sanitary: convert(lists.sanitary),
      searchPeriod: {
        rents: toArray(lists.rentSearchPeriod).sort((a, b) => a.id > b.id),
        sales: toArray(lists.saleSearchPeriod).sort((a, b) => a.id > b.id),
      },
      directionsList: toArray(lists.DirectionsList),
      heating: toArray(lists.heating),
      rentLease: toArray(lists.rentLease),
    };
  },
};

export default mappings;
