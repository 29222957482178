import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '../ui/material';
import { DialogStyles } from './DialogStyles';

function ModalWithStyles(props) {
  const { classes, children } = props;
  return (
    <Dialog className={classes.paper} {...props}>
      {children}
    </Dialog>
  );
}

ModalWithStyles.defaultProps = {
  classes: {},
};

ModalWithStyles.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

export default withStyles(DialogStyles)(ModalWithStyles);
