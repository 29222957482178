import { connect } from 'react-redux';
import ContactsView from '../components/ContactsView';

function mapStateToProps(state) {
  return {
    object: state.object,
    lists: state.lists,
    dictionaries: state.dictionaries,
    bids: state.bids,
    selectedRegion: state.selectedRegion,
  };
}

export default connect(mapStateToProps)(ContactsView);
