import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import lodash from 'lodash';
import ym from 'react-yandex-metrika';
import qs from 'query-string';
import RLink from '../../components/RLink';
import FilterIcon from '../../components/ui/assets/filter_icon.svg';
import ListView from '../../components/ListView';
import PropertyList from '../../components/PropertyList';
import {
  loadProperties,
  loadGarageProperties,
  loadMoreObjects,
  loadMoreGarageObjects,
  setCurrentPageType,
  setSortingSection,
  setScrollPosition,
  updateFilterType,
} from '../../actions';
import { pluralize, convertFilter } from '../../utils';
import styles from '../assets/ResultsPage.css';
import {
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
  lists as listsFromPropTypes,
} from '../../lib/PropTypeValues';

import {
  gtagPushEventNewPage,
  getCianDealType,
  getCianObjectType,
  getExtraCategory,
  getCustomUrlCian,
  getCustomUrlCianForApi2
} from '../../classes/analytics/gtagUtils';
import GtagBuilder from '../../classes/analytics/gtagEventBuilder';
import GtagBuilderV2 from '../../classes/analytics/gtagEventBuilderV2';

class _ResultsPage extends React.Component {
  constructor(...args) {
    super(...args);
    const { location } = this.props;
    this.state = {
      page: 1,
      loadList: false,
      loading: false,
      counter: true,
      additionalHeight: 0,
    };
    this.handleSendGA = this.handleSendGA.bind(this);
    this.handleSendClickAnnouncementGA = this.handleSendClickAnnouncementGA.bind(this);
  }

  componentDidMount() {
    const {
      location,
      selectedRegion,
      selectedSorting,
      dispatch,
      route,
      properties,
      selectedLocality,
      dictionaries,
      lists,
      history,
    } = this.props;

    const query = qs.parse(location.search);

    query.page = 1;

    history.replace({
      search: qs.stringify(query),
    });

    const filter = {
      region_fias: selectedRegion.code,
      pageSize: 10,
      ...query,
    };

    if (selectedSorting) {
      filter.sort = selectedSorting;
    }

    setTimeout(() => this.setState({ loadList: true }), 0);

    dispatch(setCurrentPageType('results'));
    dispatch(setSortingSection(route.path));

    if (properties.invalidated) {
      if (route.path === 'garages') {
        dispatch(
          loadGarageProperties(
            convertFilter(filter, selectedLocality),
            'sale/garages',
            dictionaries
          )
        ).then(() => {
          try {
            this.handleSendGA('v2', 1);
          } catch (e) {
            console.error('Ошибка в handleSendGA', e);
          }}
        );
      } else {
        dispatch(loadProperties({ ...filter }, route.path, lists)).then(() => {
          try {
            this.handleSendGA('v1', 1);
          } catch (e) {
            console.error('Ошибка в handleSendGA', e);
          }}
        );
      }
    }

    const landsIds = [101, 103, 104];

    const filterType = {
      sales: { dealType: 'sale', realtyType: 'apartments' },
      lands: { dealType: 'sale', realtyType: 'lands' },
      saleBusiness: { dealType: 'sale', realtyType: 'commercials' },
      garages: { dealType: 'sale', realtyType: 'garages' },

      rents: { dealType: 'rent', realtyType: 'apartments' },
      rentBusiness: { dealType: 'rent', realtyType: 'commercials' },
    };

    let currentFilterType = filterType[route.path];

    if (
      route.path === 'rents' &&
      query.rooms &&
      landsIds.some((r) => query.rooms == r) &&
      query.period == 1
    ) {
      currentFilterType = { dealType: 'rent', realtyType: 'apartments' /* 'lands' */ };
    }
    if (
      route.path === 'rents' &&
      ((query.rooms && !landsIds.some((r) => query.rooms.includes(r))) || !query.rooms) &&
      query.period == 2
    ) {
      currentFilterType = { dealType: 'rentDaily', realtyType: 'apartments' };
    }
    if (
      route.path === 'rents' &&
      query.rooms &&
      landsIds.some((r) => query.rooms == r) &&
      query.period == 2
    ) {
      currentFilterType = { dealType: 'rentDaily', realtyType: 'apartments' /* 'lands' */ };
    }

    dispatch(updateFilterType(currentFilterType.dealType, currentFilterType.realtyType));
    window.onscroll = this.handleChangeScroll.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      route,
      selectedLocality,
      dictionaries,
      lists,
      properties,
      rentsFilter,
      dispatch,
    } = this.props;
    const query = qs.parse(location.search);
    const prevQuery = qs.parse(prevProps.location.search);
    if (prevQuery.sort !== query.sort) {
      const filter = {
        region_fias: this.props.selectedRegion.code,
        pageSize: 10,
        ...query,
      };
      if (route.path === 'garages') {
        dispatch(
          loadGarageProperties(
            convertFilter(filter, selectedLocality),
            'sale/garages',
            dictionaries
          )
        );
      } else {
        dispatch(loadProperties({ ...filter }, route.path, lists));
      }
    }

    const hasProducts = properties.loaded && prevProps.properties.items !== properties.items;
    const isRentDaily = route.path === 'rents' && Number(rentsFilter.period) === 2;

    if (!isRentDaily && hasProducts) {
      let products = [];
      const moreProducts = [];
      const sliceProducts = [];

      prevProps.properties.items.map((item) => products.push(item.id.toString()));
      properties.items.map((item) => moreProducts.push(item.id.toString()));

      products = products ? lodash.difference(moreProducts, products) : moreProducts;
      products.map((item) => sliceProducts.push({ id: item }));

      window.vkRetargeting('view_search', route.path, {
        products: sliceProducts,
      });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.onscroll = null;

    dispatch(setSortingSection(''));
  }

  handleChangeScroll() {
    const { dispatch } = this.props;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    if (this.t) {
      clearTimeout(this.t);
    }
    this.t = setTimeout(() => dispatch(setScrollPosition(scroll)), 100);
  }

  handleSendGA(apiVersion = 'v1', initialPage = 0) {
    const {
      user,
      selectedViewType,
      currentFilterType,
      selectedSorting,
      selectedRegion,
      properties
    } = this.props;
    const { page } = this.state;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const cianGtagEvent = GtagBuilderClass
      .setEvent('newpage')
      .setEventCategory('page')
      .setEventAction('newpage')
      .setEventLabel('open')
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        sortType: selectedSorting ? selectedSorting : 'default',
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: Number(properties.count),
        pageNumber: initialPage || Number(page),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts(properties.items)
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleSendClickAnnouncementGA(apiVersion = 'v1', announcement) {
    const {
      user,
      selectedViewType,
      currentFilterType,
      selectedSorting,
      selectedRegion,
      properties
    } = this.props;
    const { page } = this.state;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('Listing')
      .setEventAction('to_card')
      .setEventLabel('from_listing')
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        sortType: selectedSorting ? selectedSorting : 'default',
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: Number(properties.count),
        pageNumber: Number(page),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleEventAddFavoriteGA(apiVersion = 'v1', announcement) {
    const {
      user,
      selectedViewType,
      currentFilterType,
      selectedSorting,
      selectedRegion,
      properties
    } = this.props;
    const { page } = this.state;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('favorite')
      .setEventAction(selectedViewType === 'photo' ? 'add_from_list': 'add_from_table')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(announcement) : getCustomUrlCianForApi2(announcement))
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        sortType: selectedSorting ? selectedSorting : 'default',
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: Number(properties.count),
        pageNumber: Number(page),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleEventRemoveFavoriteGA(apiVersion = 'v1', announcement) {
    const {
      user,
      selectedViewType,
      currentFilterType,
      selectedSorting,
      selectedRegion,
      properties
    } = this.props;
    const { page } = this.state;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('favorite')
      .setEventAction(selectedViewType === 'photo' ? 'remove_from_list': 'remove_from_table')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(announcement) : getCustomUrlCianForApi2(announcement))
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        sortType: selectedSorting ? selectedSorting : 'default',
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: Number(properties.count),
        pageNumber: Number(page),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleClickLoadMore = () => {
    const {
      location,
      history,
      route,
      selectedLocality,
      dictionaries,
      dispatch,
      lists,
    } = this.props;
    const { page } = this.state;
    const nextPage = page + 1;
    const query = qs.parse(location.search);
    delete query.page;

    const filter = {
      region_fias: this.props.selectedRegion.code,
      pageSize: 10,
      page: nextPage,
      ...query,
    };

    this.setState({
      loading: true,
      page: nextPage,
    });

    history.replace({
      pathname: location.pathname,
      search: qs.stringify({ ...query, page: nextPage }),
    });

    if (route.path === 'garages') {
      dispatch(
        loadMoreGarageObjects(convertFilter(filter, selectedLocality), 'sale/garages', dictionaries)
      ).then(() => {
        this.setState({ loading: false });
        try {
          this.handleSendGA('v2');
        } catch (e) {
          console.error('Ошибка в handleSendGA', e);
        }
      });
    } else {
      dispatch(loadMoreObjects(filter, route.path, lists)).then(() => {
        this.setState({ loading: false })
        try {
          this.handleSendGA();
        } catch (e) {
          console.error('Ошибка в handleSendGA', e);
        }}
      );
    }
  };

  handleScroll = (e) => {
    const { counter } = this.state;
    if (counter && e.scrollOffset > 10) {
      this.setState({
        counter: false,
        additionalHeight: 34,
      });
    }

    if (!counter && e.scrollOffset < 10) {
      this.setState({
        counter: true,
        additionalHeight: 0,
      });
    }
  };

  getArea(object) {
    const { route } = this.props;
    switch (route.path) {
      case 'saleBusiness':
        return object.square;
      case 'rentBusiness':
        return object.area;
      case 'garages':
        return object.area;
      default:
        return Math.floor(object.sqTotal || object.sqLiving);
    }
  }

  getIsBusiness() {
    const { route } = this.props;
    return route.path === 'saleBusiness' || route.path === 'rentBusiness';
  }

  getOfferType() {
    const { route } = this.props;
    switch (route.path) {
      case 'sales':
      case 'saleBusiness':
      case 'lands':
      case 'garages':
        return 'Продам';
      case 'rents':
      case 'rentBusiness':
        return 'Сдам';
      default:
        break;
    }
    return '';
  }

  itemsWithAd = (data) => {
    if (!(data instanceof Array)) {
      return data;
    }
    const items = data.slice();
    const adPosition = 9;
    if (items.length > 1 && items.length < adPosition) {
      items.push({
        adItem: true,
      });
    } else {
      for (let i = 1; i * adPosition < items.length; i += 1) {
        items.splice(i * adPosition + (i - 1), 0, {
          adItem: true,
        });
      }
    }
    return items;
  };

  renderListView = (items, properties) => {
    const { dispatch, route } = this.props;
    const { loading, additionalHeight } = this.state;
    if (items && items.length) {
      return (
        <ListView
          items={items}
          loading={loading}
          canLoadMore={!properties.noMore}
          updateScrollPosition={(scrollPosition) => {
            dispatch(setScrollPosition(scrollPosition));
          }}
          onLoad={this.handleClickLoadMore}
          handleScroll={this.handleScroll}
          additionalHeight={additionalHeight}
          eventClickAnnouncement={(announcement) => this.handleSendClickAnnouncementGA(route.path === 'garages' ? 'v2' : 'v1', announcement)}
          eventAddFavorite={(announcement) => this.handleEventAddFavoriteGA(route.path === 'garages' ? 'v2' : 'v1', announcement)}
          eventRemoveFavorite={(announcement) => this.handleEventRemoveFavoriteGA(route.path === 'garages' ? 'v2' : 'v1', announcement)}
        />
      );
    }

    return <div>{this.renderNotFound()}</div>;
  };

  renderPhotoView(items, properties) {
    const { location, dispatch, route } = this.props;
    const { additionalHeight } = this.state;
    if (items && items.length) {
      return (
        <PropertyList
          objects={items}
          route={location.pathname}
          onLoad={this.handleClickLoadMore}
          updateScrollPosition={(scrollPosition) => {
            dispatch(setScrollPosition(scrollPosition));
          }}
          canLoadMore={!properties.noMore}
          additionalHeight={additionalHeight}
          eventClickAnnouncement={(announcement) => this.handleSendClickAnnouncementGA(route.path === 'garages' ? 'v2' : 'v1', announcement)}
          eventAddFavorite={(announcement) => this.handleEventAddFavoriteGA(route.path === 'garages' ? 'v2' : 'v1', announcement)}
          eventRemoveFavorite={(announcement) => this.handleEventRemoveFavoriteGA(route.path === 'garages' ? 'v2' : 'v1', announcement)}
        />
      );
    }

    return <div>{this.renderNotFound()}</div>;
  }

  renderNotFound = () => {
    const stylesNotFound = {
      notFound: {
        padding: '50px 20px',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: '20px',
        link: {
          color: 'rgb(141, 198, 63)',
        },
      },
    };

    return (
      <div style={stylesNotFound.notFound}>
        Не найдено ни одного объявления. <br />
        <br />
        Обратите внимание, что с 01.06.2018 цену в поиске необходимо указывать в рублях, а не в
        тыс.руб. <br />
        <br />
        <RLink to="search" style={stylesNotFound.notFound.link}>
          Изменить параметры поиска
        </RLink>
      </div>
    );
  };

  render() {
    const {
      properties,
      route,
      selectedRegion,
      rentsFilter,
      salesFilter,
      lists,
      selectedViewType,
    } = this.props;
    const { loadList, counter } = this.state;
    let items =
      properties.items &&
      properties.items.map((property) => {
        return {
          ...property,
          isBusiness: this.getIsBusiness(),
          offerType: this.getOfferType(),
          type: property.realtyTypeName,
          area: this.getArea(property),
        };
      });

    items = this.itemsWithAd(items);
    const path = route.path.replace(/\/.*/, '');
    const region = selectedRegion.domain;
    const filter = path === 'rents' ? rentsFilter : salesFilter;
    const query = Object.keys(filter)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`;
      })
      .join('&');

    return (
      <Loader loaded={lists && properties.loaded && loadList} zIndex={1}>
        <Link
          to={`/${region}/${path}/map?${query}`}
          onClick={() => ym('reachGoal', 'search_by_map')}
          className={styles.linkToggle}
        >
          <div className={styles.toggleContainer}>
            <div className={styles.toggleButtonActive}>
              <span>Список</span>
            </div>
            <div className={styles.toggleButton}>
              <span>На карте</span>
            </div>
          </div>
        </Link>
        {counter && (
          <div className={styles.counter}>
            {properties.count > 0
              ? `${pluralize(properties.count, 'Найдено', 'Найдено', 'Найдено')} ${
                  properties.count
                } ${pluralize(properties.count, 'объявление', 'объявления', 'объявлений')}`
              : null}
          </div>
        )}
        {selectedViewType === 'list' && this.renderListView(items, properties)}
        {selectedViewType === 'photo' && this.renderPhotoView(items, properties)}
        <Link to={`/${region}/`} className={styles.filterButtonContainer}>
          <div className={styles.filterButton}>
            <img className={styles.filterIcon} alt="filterIcon" src={FilterIcon} />
            <span>Параметры</span>
          </div>
        </Link>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    properties: state.properties,
    filter: state.filter,
    selectedLocality: state.selectedLocality,
    salesFilter: state.salesFilter,
    rentsFilter: state.rentsFilter,
    lists: state.lists,
    dictionaries: state.dictionaries,
    selectedRegion: state.selectedRegion,
    selectedRubric: state.selectedRubric,
    districts: state.districts,
    selectedViewType: state.selectedViewType,
    scrollPosition: state.scrollPosition,
    selectedSorting: state.selectedSorting,
    user: state.user,
    currentFilterType: state.filterType,
  };
}

_ResultsPage.defaultProps = {
  selectedSorting: '',
  rentsFilter: {
    period: 1,
  },
  salesFilter: {},
};

_ResultsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  selectedSorting: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  properties: PropTypes.shape({
    loaded: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    invalidated: PropTypes.bool,
    noMore: PropTypes.bool,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  lists: listsFromPropTypes.isRequired,
  rentsFilter: PropTypes.shape({
    period: PropTypes.string,
  }),
  salesFilter: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedViewType: PropTypes.string.isRequired,
  user: PropTypes.objectOf({
    token: PropTypes.string,
    profile: PropTypes.objectOf(PropTypes.any),
  }),
};

export default connect(mapStateToProps)(_ResultsPage);
