/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import React from 'react';
import cn from './MortgageLink.css';

const MortgageLink = ({ url, bankName, monthlyPayment, customTitle }) => {
  return (
    <p className={cn.wrapper}>
      <a className={cn.base} href={url} target="_blank">
        {customTitle || (
          <span>
            Ипотека {monthlyPayment} в мес. от {bankName}
          </span>
        )}
      </a>
    </p>
  );
};

MortgageLink.defaultProps = {
  bankName: 'банка',
  monthlyPayment: 0,
};

MortgageLink.propTypes = {
  url: PropTypes.string.isRequired,
  bankName: PropTypes.string,
  monthlyPayment: PropTypes.number,
  customTitle: PropTypes.string.isRequired,
};

export default MortgageLink;
