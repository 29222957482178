import PropTypes from 'prop-types';
import React from 'react';
import { IconButton } from './ui/material';
import ym from './ReactYandexMetrika';
import SwapView from './ui/assets/swap_view.svg';
import ListView from './ui/assets/list_view_icon.svg';

export default class ButtonChangeView extends React.Component {
  constructor(...props) {
    super(...props);
    const { currentType } = this.props;
    this.state = {
      type: currentType || 'list',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChangeType } = this.props;
    const { type } = this.state;
    if (prevState.type !== type) {
      setTimeout(() => {
        onChangeType(type);
      }, 100);
    }
  }

  changeType = () => {
    const { type } = this.state;
    const nextType = type === 'list' ? 'photo' : 'list';

    ym('reachGoal', `change_view_to_${nextType}`);
    this.setState({
      type: nextType,
    });
  };

  render() {
    const { type } = this.state;
    return (
      <IconButton onClick={this.changeType}>
        {type === 'list' ? (
          <img src={SwapView} alt="swap-view" />
        ) : (
          <img src={ListView} alt="list-view" />
        )}
      </IconButton>
    );
  }
}

ButtonChangeView.defaultProps = {};

ButtonChangeView.propTypes = {
  onChangeType: PropTypes.func.isRequired,
  currentType: PropTypes.string.isRequired,
};
