/* eslint-disable no-unused-expressions */
/* eslint-disable max-classes-per-file */
import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import AutocompleteTheme from './assets/AutocompleteTheme';
import styles from './assets/Autocomplete.css';
import { FontIcon } from './material';

/**
 * based on https://github.com/moroshko/react-autosuggest
 */
export default class Autocomplete extends React.Component {
  constructor(...props) {
    super(...props);

    const { inputProps } = this.props;

    this.state = {
      value: inputProps.value || '',
      suggestions: [],
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  handleClearField = (e) => {
    this.setState({
      value: '',
    });
    this.input.focus();
    e.stopPropagation();
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { data } = this.props;
    data(value, (x, suggestions) => {
      this.setState({
        suggestions,
      });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.label}</span>;
  };

  renderSuggestionValue = (suggestion) => {
    const { onSelectRegion, onSelectLocality, onSelect } = this.props;
    !!onSelectRegion && onSelectRegion(suggestion);
    !!onSelectLocality && onSelectLocality(suggestion);
    !!onSelect && onSelect(suggestion);
    return suggestion.value;
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  render() {
    const { value, suggestions, inputProps } = this.state;
    const inputPropsObj = {
      onChange: this.onChange,
      ...inputProps,
      value,
    };
    return (
      <>
        <div
          className={styles.clearButton}
          role="button"
          onKeyDown={this.handleClearField}
          onClick={this.handleClearField}
        >
          <FontIcon style={{ fontSize: 18, color: '#757575' }} className="material-icons">
            close
          </FontIcon>
        </div>
        <AutosuggestCustom
          ref={this.storeInputReference}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.renderSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputPropsObj}
          theme={AutocompleteTheme}
        />
      </>
    );
  }
}

Autocomplete.defaultProps = {
  onSelect: () => {},
  onSelectRegion: () => {},
  onSelectLocality: () => {},
  inputProps: {},
};

Autocomplete.propTypes = {
  data: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onSelectRegion: PropTypes.func,
  onSelectLocality: PropTypes.func,
  inputProps: PropTypes.shape({
    value: PropTypes.string,
  }),
};

class AutosuggestCustom extends Autosuggest {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.inputProps.value });
  }
}
