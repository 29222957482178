import PropTypes from 'prop-types';
import React from 'react';
import Cookies from 'cookies-js';
import { connect } from 'react-redux';
import { selectedRegion as selectedRegionFromPropTypes } from '../lib/PropTypeValues';

const styles = {
  root: {
    padding: '30px 20px',
    color: '#6d6d6d',
    fontSize: 20,
    textAlign: 'center',
  },
  cancel: {
    marginTop: 20,
    textTransform: 'uppercase',
    color: 'white',
    border: 0,
    textAlign: 'center',
    fontSize: '20px',
    padding: '15px',
    backgroundColor: '#FF7F29',
    width: '100%',
    borderRadius: '4px',
  },
  seconds: {
    marginTop: 10,
    fontSize: 60,
  },
};

class _FullVersionPage extends React.Component {
  state = { seconds: 5 };

  componentDidMount() {
    const { selectedRegion } = this.props;
    const domain = document.location.hostname.split('.').slice(1).join('.');
    const subdomain = selectedRegion.domain;

    this.i = setInterval(() => {
      this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      if (this.state.seconds === 0) {
        // кука на сутки
        Cookies.set('fullVersion', 1, { expires: 86400, domain: `.${domain}` });
        document.location = `//${subdomain}.${domain}`;
        clearInterval(this.i);
      }
    }, 1e3);
  }

  handleClickCancel = () => {
    const { history, selectedRegion } = this.props;
    clearInterval(this.i);
    history.push(`/${selectedRegion.domain}/search`);
  };

  render() {
    const { seconds } = this.state;
    return (
      <div style={styles.root}>
        Через 5 секунд вы будете перенаправлены на полную версию сайта
        <div style={styles.seconds}>{seconds}</div>
        <button style={styles.cancel} type="button" onClick={this.handleClickCancel}>
          Отменить
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
  };
}

_FullVersionPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_FullVersionPage);
