import PropTypes from 'prop-types';
import React from 'react';

export default class PreviewText extends React.Component {
  constructor(...props) {
    super(...props);
    const { text } = this.props;
    this.state = { opened: text.length <= 300 };
  }

  toggleFull(e) {
    const { opened } = this.state;
    e.preventDefault();
    this.setState({
      opened: !opened,
    });
  }

  render() {
    const { text } = this.props;
    const { opened } = this.state;
    const full = text;
    const preview = text.slice(0, 300);

    return (
      <div>
        <div style={{ position: 'relative' }}>
          {opened ? full : preview}
          {!opened && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: 50,
                background:
                  'linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 80%,  rgba(255, 255, 255, 0) 100%)',
              }}
            >
              &nbsp;
            </div>
          )}
        </div>
        {!opened && (
          <div style={{ textAlign: 'center', marginTop: -5 }}>
            <a href="#" style={{ color: '#8dc63f' }} onClick={this.toggleFull.bind(this)}>
              показать полностью
            </a>
          </div>
        )}
      </div>
    );
  }
}

PreviewText.defaultProps = {};

PreviewText.propTypes = {
  text: PropTypes.string.isRequired,
};
