import React, { PureComponent } from 'react';
import { Panel } from '../ui';
import RLink from '../RLink';
import { formatPrice } from '../../utils';
import Line from './Line';
import cn from './User.css';
import FormPaper from '../SearchForm/FormPaper/FormPaper';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';
import { AccountDivider } from '../Divider/Divider';

class UserAccount extends PureComponent {
  render() {
    const {
      user: {
        profile: { statistics },
        profile,
      },
    } = this.props;

    return (
      <div className={cn.userAccount}>
        <Panel
          title="Личная информация"
          titleClassName={cn.firstPanelTitle}
          containerStyle={{ padding: '0px' }}
        >
          <FormPaper>
            <RLink to="user/profile" className={cn.arrowLink}>
              <span>
                ID: <span className={cn.orange}>{profile.id}</span>
              </span>
            </RLink>
            <AccountDivider />
            <RLink to="user/balance" className={cn.arrowLink}>
              <span>
                Баланс: <span className={cn.orange}>{formatPrice(profile.balance)}</span>
              </span>
            </RLink>
            <AccountDivider />
            {profile.legalFormId === 371 ? (
              <RLink to="user/recharge" className={cn.arrowLink}>
                <span>Пополнить баланс</span>
              </RLink>
            ) : (
              <RLink to="user/invoices" className={cn.arrowLink}>
                <span>Счета на оплату</span>
              </RLink>
            )}
          </FormPaper>
        </Panel>
        <div className={cn.fillerContainer}>
          <Panel
            title="Мои объявления"
            titleClassName={cn.panelTitle}
            containerStyle={{ padding: '0px' }}
          >
            <FormPaper>
              <RLink to="user/sale/apartments" className={cn.link}>
                <Line name="Продажа квартиры, комнаты" value={statistics.saleApartmentsCount} />
              </RLink>
              <AccountDivider />
              <RLink to="user/sale/lands" className={cn.link}>
                <Line name="Продажа дома, дачи, земля" value={statistics.saleLandsCount} />
              </RLink>
              <AccountDivider />
              <RLink to="user/sale/commercials" className={cn.link}>
                <Line name="Продажа коммерческая" value={statistics.saleCommercialsCount} />
              </RLink>
              <AccountDivider />
              <RLink to="user/sale/garages" className={cn.link}>
                <Line name="Продажа гаражей" value={statistics.saleGaragesCount} />
              </RLink>
              <AccountDivider />
              <RLink to="user/rent/apartments" className={cn.link}>
                <Line name="Аренда жилья" value={statistics.rentApartmentsCount} />
              </RLink>
              <AccountDivider />
              <RLink to="user/rent/commercials" className={cn.link}>
                <Line name="Аренда коммерческая" value={statistics.rentCommercialsCount} />
              </RLink>
            </FormPaper>
          </Panel>
          <div className={cn.filler}>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            display: 'block',
          }}
        >
          <RLink
            style={{
              textTransform: 'uppercase',
              color: '#fff',
              border: 0,
              textAlign: 'center',
              fontSize: '18px',
              padding: '15px 0',
              backgroundColor: '#FF7F29',
              margin: '16px',
              borderRadius: '4px',
            }}
            to="create-announcement"
            className={cn.link}
          >
            Добавить объявление
          </RLink>
        </div>
      </div>
    );
  }
}

UserAccount.propTypes = {
  user: userFromPropTypes.isRequired,
};

export default UserAccount;
