/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { map, isEmpty, first, isArray } from 'lodash';

import Slide from '@material-ui/core/Slide';
import { Dialog, FontIcon } from './material';
import LocalitySelect from '../LocalitySelect';
import styles from './assets/Select.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class SelectDialog extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      selected: isArray(props.value)
        ? props.value
        : typeof props.value === 'undefined'
        ? []
        : [props.value],
      isOpen: false,
    };
  }

  handleChange(e) {
    const { onSelect } = this.props;
    const selected = Array.isArray(this.getValue()) ? this.getValue() : [this.getValue()];
    this.setState({ selected });
    if (onSelect) {
      onSelect(e);
    }
  }

  getValue() {
    const { multiple } = this.props;
    // eslint-disable-next-line react/no-string-refs
    const selected = map(ReactDOM.findDOMNode(this.refs.select).selectedOptions, (option) => {
      if (option.value !== '') {
        return Number(option.value);
      }
      return option.value;
    });
    return multiple ? selected : selected[0];
  }

  showOptions = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
      return;
    }

    this.setState((state) => {
      return {
        isOpen: !state.isOpen,
      };
    });
  };

  renderOptions() {
    const { items } = this.props;
    return items.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
  }

  renderSelected() {
    const { selected } = this.state;
    const { items, emptyString } = this.props;
    const selectedItems = items.filter(
      (item) =>
        selected.indexOf(item.id) !== -1 ||
        (selected.indexOf(Number(item.id)) !== -1 && item.id !== '')
    );

    const string = isEmpty(selectedItems)
      ? emptyString || first(items).name
      : selectedItems.length > 1
      ? `Выбрано: ${selectedItems.length}`
      : selectedItems.map((item) => item.name).join(', ');

    return <div className={styles.selected}>{string}</div>;
  }

  render() {
    const { label } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={styles.root}>
        <Dialog
          open={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          TransitionComponent={Transition}
        >
          <div style={{ minHeight: 300 }}>
            <LocalitySelect regionId={55} />
          </div>
        </Dialog>

        <div className={styles.label}>{label}</div>
        <div
          className={styles.wrapper}
          role="button"
          onKeyDown={this.showOptions}
          onClick={this.showOptions}
        >
          {this.renderSelected()}
          <FontIcon className="material-icons" style={{ position: 'absolute', right: 5, top: 15 }}>
            keyboard_arrow_down
          </FontIcon>
        </div>
      </div>
    );
  }
}

SelectDialog.defaultProps = {
  emptyString: 'не важно',
  value: 0,
  multiple: false,
  onSelect: () => {},
  onClick: () => {},
};

SelectDialog.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  multiple: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  emptyString: PropTypes.string,
};
