/* eslint-disable no-restricted-globals */
import numeral from 'numeral';

export function formatPrice(price) {
  const language = {
    delimiters: {
      thousands: ' ',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'тыс.',
      million: 'млн',
      billion: 'b',
      trillion: 't',
    },
    ordinal: () => '.',
    currency: {
      symbol: '₽',
    },
  };

  numeral.language('ru', language);
  numeral.language('ru');

  return numeral(price).format('0,0[.]00 $');
}

export function filterToUrlParams(data) {
  return Object.keys(data)
    .map((key) => {
      if (Array.isArray(data[key])) {
        const result = [];
        data[key].forEach((value) => {
          result.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`);
        });
        return result.join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    })
    .join('&');
}

export function pluralize(number, one, two, five) {
  let num = Math.abs(number);
  num %= 100;
  if (num >= 5 && num <= 20) {
    return five;
  }
  num %= 10;
  if (num === 1) {
    return one;
  }
  if (num >= 2 && num <= 4) {
    return two;
  }
  return five;
}

export function detectOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS';
  }
  if (userAgent.match(/Android/i)) {
    return 'Android';
  }
  return 'unknown';
}

export function loadScript(url, onLoad) {
  const head = document.querySelector('head');
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  if (onLoad) {
    script.onload = onLoad;
  }
  head.appendChild(script);
}

export function toYMD(data) {
  const date = new Date(data);
  return [
    date.getFullYear(),
    `0${date.getMonth() + 1}`.slice(-2),
    `0${date.getDate()}`.slice(-2),
  ].join('-');
}

export function toDotDMY(data) {
  const date = new Date(data);
  return [
    `0${date.getDate()}`.slice(-2),
    `0${date.getMonth() + 1}`.slice(-2),
    date.getFullYear(),
  ].join('.');
}

export function toDotDMYhm(data) {
  const date = new Date(data);
  return `${[
    `0${date.getDate()}`.slice(-2),
    `0${date.getMonth() + 1}`.slice(-2),
    date.getFullYear(),
  ].join('.')} ${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`;
}

export function isLandDacha(props) {
  return props.realtyType === 'lands' && (props.refType === 1 || props.refType === 4);
}

export function isLandDachaByArgs(realtyType, refType) {
  return realtyType === 'lands' && (refType === 36 || refType === 35);
}

export function getNewFormat(type) {
  const types = {
    sales: {
      dealType: 'sale',
      realtyType: 'apartments',
    },
    lands: {
      dealType: 'sale',
      realtyType: 'lands',
    },
    saleBusiness: {
      dealType: 'sale',
      realtyType: 'commercials',
    },
    garages: {
      dealType: 'sale',
      realtyType: 'garages',
    },
    rents: {
      dealType: 'rent',
      realtyType: 'apartments',
    },
    rentDaily: {
      dealType: 'rentDaily',
      realtyType: 'apartments',
    },
    rentsDaily: {
      dealType: 'rentDaily',
      realtyType: 'apartments',
    },
    rentBusiness: {
      dealType: 'rent',
      realtyType: 'commercials',
    },
  };
  return types[type] ? types[type] : {};
}

export function getOldFormat(dealType, realtyType) {
  const types = {
    sale: {
      apartments: {
        type: 'sales',
      },
      lands: {
        type: 'lands',
      },
      commercials: {
        type: 'saleBusiness',
      },
      garages: {
        type: 'garages',
      },
    },
    rent: {
      apartments: {
        type: 'rents',
      },
      commercials: {
        type: 'rentBusiness'
      },
    },
    rentDaily: {
      apartments: {
        type: 'rents',
      },
    }
  };
  return !!types[dealType][realtyType] ? types[dealType][realtyType].type : '';
}

export function getRubricIdByParams(dealType, realtyType) {
  const types = {
    sale: {
      apartments: {
        rubricId: 1,
      },
      lands: {
        rubricId: 2,
      },
      commercials: {
        rubricId: 3,
      },
      garages: {
        rubricId: 11,
      },
    },
    rent: {
      apartments: {
        rubricId: 4,
      },
      // lands: {
      //   rubricId: 5,
      // },
      commercials: {
        rubricId: 6,
      },
    },
    rentDaily: {
      apartments: {
        rubricId: 4,
      },
    }
  };
  return !!types[dealType][realtyType] ? types[dealType][realtyType].rubricId : '';
}

export function getRandomElement(array) {
  const currentArray = array;
  let counter = currentArray.length;
  while (counter > 0) {
    const index = Math.floor(Math.random() * counter);
    counter -= 1;
    const temp = currentArray[counter];
    currentArray[counter] = currentArray[index];
    currentArray[index] = temp;
  }

  return currentArray[0];
}

export function truncText(input, cap, delimiter = '…') {
  const limit = Number(cap);
  if (isNaN(limit)) {
    return delimiter;
  }
  if (input.length <= limit) {
    return input;
  }
  const result = input.substr(0, limit);
  const i = result.lastIndexOf(' ');
  if (i === -1) {
    return delimiter;
  }

  return `${result.substr(0, i)} ${delimiter}`;
}

export function truncateString(str, num) {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
}

export const deadlinesBuilding = [
  { id: '255', name: 'Дом сдан' },
  { id: '20201', name: '1-й квартал 2020 г.' },
  { id: '20202', name: '2-й квартал 2020 г.' },
  { id: '20203', name: '3-й квартал 2020 г.' },
  { id: '20204', name: '4-й квартал 2020 г.' },
  { id: '20211', name: '1-й квартал 2021 г.' },
  { id: '20212', name: '2-й квартал 2021 г.' },
  { id: '20213', name: '3-й квартал 2021 г.' },
  { id: '20214', name: '4-й квартал 2021 г.' },
  { id: '20221', name: '1-й квартал 2022 г.' },
  { id: '20222', name: '2-й квартал 2022 г.' },
  { id: '20223', name: '3-й квартал 2022 г.' },
  { id: '20224', name: '4-й квартал 2022 г.' },
  { id: '20231', name: '1-й квартал 2023 г.' },
  { id: '20232', name: '2-й квартал 2023 г.' },
  { id: '20233', name: '3-й квартал 2023 г.' },
  { id: '20234', name: '4-й квартал 2023 г.' },
  { id: '20241', name: '1-й квартал 2024 г.' },
  { id: '20242', name: '2-й квартал 2024 г.' },
  { id: '20243', name: '3-й квартал 2024 г.' },
  { id: '20244', name: '4-й квартал 2024 г.' },
  { id: '20251', name: '1-й квартал 2025 г.' },
  { id: '20252', name: '2-й квартал 2025 г.' },
  { id: '20253', name: '3-й квартал 2025 г.' },
  { id: '20254', name: '4-й квартал 2025 г.' },
];

export const authorItems = [
  { id: 'all', name: 'Неважно' },
  { id: 'owners', name: 'Только от частных лиц' },
  { id: 'builders', name: 'Только от застройщиков' },
];

export const genderItems = [
  { id: 1, name: 'Мужчины' },
  { id: 2, name: 'Женщины' },
];

export const residentsItems = [
  { id: 1, name: 'Только один' },
  { id: 2, name: 'Не более двух' },
];

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function authorFilter(filter) {
  if (filter.only_owners) {
    return { value: 'owners', name: 'only_owners' };
  }
  if (!filter.only_owners && filter.only_builders) {
    return { value: 'builders', name: 'only_builders' };
  }
  return { value: 'all', name: 'only' };
}

export function convertFilter(filter, selectedLocality) {
  const search = { ...filter };
  search.regionId = search.region_fias || '';
  search['per-page'] = search.pageSize || '';
  search.localityId = !!selectedLocality && selectedLocality.id || '';
  search.district = search.o_d || search.district || '';
  search.microdistrict = search.m_m || search.microdistrict || '';
  if (search.objectTypeId instanceof Array) {
    search.objectTypeId = search.objectTypeId.join(',');
  }
  if (search.district instanceof Array) {
    search.district = search.district.join(',');
  }
  if (search.microdistrict instanceof Array) {
    search.microdistrict = search.microdistrict.join(',');
  }

  delete search.region_fias;
  delete search.pageSize;
  delete search.o_d;
  delete search.m_m;

  return search;
}

export const landsIds = [101, 103, 104];

export function getSectionIdByDealType(dealType) {
  return dealType.includes('rent') ? 1 : 0;
}

export function percentage (partialValue, totalValue) {
  return ((100 * partialValue) / totalValue).toFixed(2);
}
