import PropTypes from 'prop-types';
import React from 'react';
import { IconButton, FontIcon } from './ui/material';

export default class ButtonBack extends React.Component {
  onClick = (e) => {
    const { onClick } = this.props;
    e.preventDefault();
    onClick(e);
  };

  render() {
    return (
      <IconButton onClick={this.onClick}>
        <FontIcon className="material-icons" style={{ color: '#757575' }}>
          arrow_back
        </FontIcon>
      </IconButton>
    );
  }
}

ButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
};
