import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import styles from './TabsSelect.css';
import TabToggle from '../ui/TabToggle';
import TabsToggle from '../ui/TabsToggle';

class _TabsSelect extends Component {
  constructor(...props) {
    super(...props);
    const { buttons, filter, defaultSelectedName, defaultSelectedValue } = this.props;
    this.state = {
      selectedSection: isUndefined(
        buttons.find((element) => Object.keys(filter).includes(element.name))
      )
        ? defaultSelectedValue || ''
        : Number(filter[defaultSelectedName]) ||
          buttons.find((element) => Object.keys(filter).includes(element.name)).value,
      name: isUndefined(buttons.find((element) => Object.keys(filter).includes(element.name)))
        ? defaultSelectedName || ''
        : buttons.find((element) => Object.keys(filter).includes(element.name)).name,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChangeFilter } = this.props;
    const { selectedSection } = this.state;
    // eslint-disable-next-line eqeqeq
    if (prevState.selectedSection != selectedSection && onChangeFilter) {
      onChangeFilter(300);
    }
  }

  handleSelectSection = (event, value) => {
    const { buttons, onChangeInterval } = this.props;
    this.setState(
      {
        selectedSection: value,
        name: buttons.find((element) => element.value === value).name || '',
      },
      () => {
        if (onChangeInterval) {
          onChangeInterval(value);
        }
      }
    );
  };

  render() {
    const { buttons, defaultSelectedName, typeRealtyToggle } = this.props;
    const { selectedSection, name } = this.state;
    return (
      <div className={styles.root}>
        <input
          type="hidden"
          name={name || defaultSelectedName}
          value={selectedSection}
          ref={typeRealtyToggle}
        />

        <TabsToggle variant="fullWidth" onChange={this.handleSelectSection} value={selectedSection}>
          {buttons.map((button) => (
            <TabToggle key={button.value} label={button.label} value={button.value} />
          ))}
        </TabsToggle>
      </div>
    );
  }
}

_TabsSelect.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.any).isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  defaultSelectedName: PropTypes.string,
  defaultSelectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
  onChangeInterval: PropTypes.func,
  onChangeFilter: PropTypes.func,
  typeRealtyToggle: PropTypes.func,
};

_TabsSelect.defaultProps = {
  defaultSelectedValue: '',
  defaultSelectedName: '',
  onChangeInterval: null,
  onChangeFilter: null,
  typeRealtyToggle: () => {},
};

export default _TabsSelect;
