import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { values, isEmpty } from 'lodash';
import serialize from 'form-serialize';
import SaleFields from './SaleFields';
import GarageFields from './GarageFields';
import BusinessFields from './BusinessFields';
import SaleLandFields from './SaleLandFields';
import Sticky from '../Sticky';
import styles from './SearchForm.css';
import { showModal } from '../../actions';
import SubmitFormButton from '../SubmitFormButton';
import {
  selectedRegion as selectedRegionFromPropTypes,
  lists as listsFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
} from '../../lib/PropTypeValues';

class SalesForm extends React.Component {
  handleShowModal = (modalName, modalProps) => {
    const { dispatch } = this.props;
    dispatch(showModal(modalName, modalProps));
  };

  handleSubmit(e) {
    const { onSubmit } = this.props;
    e.preventDefault();
    const formdata = serialize(ReactDOM.findDOMNode(this.refs.form), { hash: true });

    // convert inner values to real values
    if (formdata.only === 'owners') {
      formdata.only_owners = 1;
    }

    if (formdata.only === 'builders') {
      formdata.only_builders = 1;
    }

    if (formdata.only === 'all') {
      delete formdata.only_builders;
      delete formdata.only_owners;
    }

    delete formdata.only;
    onSubmit(formdata);
  }

  onClearSelectedValue = (name) => {
    const { updateFilter, filter } = this.props;
    const currentFilter = {
      ...filter,
    };
    delete currentFilter[name];
    updateFilter(currentFilter);
  };

  onChangeFilter = (delay = 0) => {
    const { updateFilter } = this.props;
    const formdata = serialize(ReactDOM.findDOMNode(this.refs.form), { hash: true });
    if (delay) {
      setTimeout(() => {
        if (!isEmpty(formdata)) {
          updateFilter(formdata);
        }
      }, delay);
    }
    if (!delay) {
      if (!isEmpty(formdata)) {
        updateFilter(formdata);
      }
    }
  };

  renderFields() {
    const {
      lists,
      dictionaries,
      dictionariesV2,
      region,
      locality,
      rubric,
      onSelectLocality,
      onSelectRegion,
      filter,
      type,
      microdistricts,
      onSelectDistrict,
      onPriceNotification,
      rubrics,
      districts,
    } = this.props;

    const districtsSalesForm = values(districts);
    const passProps = {
      region,
      locality,
      districts: districtsSalesForm,
      filter,
      type,
      rubric,
      microdistricts,
      dictionaries,
      dictionariesV2,
      ...lists,
      onSelectLocality,
      onSelectRegion,
      onSelectDistrict,
      onPriceNotification,
      onChangeFilter: this.onChangeFilter,
      handleShowModal: this.handleShowModal,
      rubrics,
      lists,
      onClearSelectedValue: this.onClearSelectedValue,
    };

    const routeToComponent = {
      sales: SaleFields,
      saleBusiness: BusinessFields,
      lands: SaleLandFields,
      garages: GarageFields,
    };
    const props = { ...passProps, ref: 'fields', type: 'sales' };
    return React.createElement(routeToComponent[rubric.route], props);
  }

  render() {
    const { totalCount, rubric, isSelected } = this.props;
    return (
      <form
        className={styles.form}
        onSubmit={this.handleSubmit.bind(this)}
        ref="form"
        action={rubric.route}
      >
        {this.renderFields()}

        {isSelected && (
          <Sticky>
            <SubmitFormButton totalCount={totalCount} />
          </Sticky>
        )}
      </form>
    );
  }
}

SalesForm.defaultProps = {
  filter: {},
  onPriceNotification: () => {},
  microdistricts: [],
  districts: [],
  totalCount: 0,
  onSubmit: () => {},
  type: '',
};

SalesForm.propTypes = {
  lists: listsFromPropTypes.isRequired,
  filter: PropTypes.objectOf(PropTypes.any),
  region: selectedRegionFromPropTypes.isRequired,
  onPriceNotification: PropTypes.func,
  rubric: PropTypes.shape({
    route: PropTypes.string,
  }).isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  onSubmit: PropTypes.func,
  updateFilter: PropTypes.func.isRequired,
  totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func.isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  onSelectRegion: PropTypes.func.isRequired,
  type: PropTypes.string,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  districts: PropTypes.arrayOf(PropTypes.object),
  onSelectDistrict: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    salesFilter: state.salesFilter,
    dictionariesV2: state.dictionariesV2,
  };
}

export default connect(mapStateToProps)(SalesForm);
