import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import serialize from 'form-serialize';
import Loader from 'react-loader';
import RLink from '../RLink';
import { TextField } from '../ui';
import SubmitButton from '../SubmitButton';
import FormRow from '../SearchForm/FormRow';
import cn from './UserLogin.css';

export default class UserLogin extends React.Component {
  state = {
    errors: {},
  };

  handleSubmit(e) {
    const { onSubmit } = this.props;
    const { errors } = this.state;
    e.preventDefault();
    const formData = serialize(ReactDOM.findDOMNode(this.form), { hash: true });

    if (!formData.login || !formData.password) {
      const errorsSubmit = {};

      if (!formData.login) {
        errorsSubmit.login = 'Необходимо заполнить поле "Логин"';
      }
      if (!formData.password) {
        errorsSubmit.password = 'Необходимо заполнить поле "Пароль"';
      }

      this.setState({ errors: errorsSubmit });
    } else {
      onSubmit(formData);

      if (Object.keys(errors).length !== 0) {
        this.setState({
          errors: {},
        });
      }
    }
  }

  render() {
    const { error, loading } = this.props;
    const { errors } = this.state;

    return (
      <div style={{ padding: '0 10px', fontSize: '14px' }}>
        <div className={cn.title}>Вход</div>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          ref={(n) => {
            this.form = n;
          }}
          action=""
        >
          <FormRow>
            <TextField
              placeholder="Логин"
              name="login"
              type="text"
              isError={errors.login || error}
              errorText={errors.login}
            />
          </FormRow>
          <FormRow>
            <TextField
              placeholder="Пароль"
              name="password"
              type="password"
              isError={errors.password || error}
              errorText={errors.password || error}
            />
          </FormRow>
          <div style={{ position: 'relative', minHeight: '57px' }}>
            <Loader loaded={!loading}>
              <SubmitButton value="Войти" />
            </Loader>
          </div>
          <div style={{ position: 'relative', minHeight: '90px' }}>
            <RLink to="user/restorepass" className={cn.borderButton}>
              <span>Восстановить пароль</span>
            </RLink>
            <RLink to="user/register" className={cn.borderButton}>
              <span>Зарегистрироваться</span>
            </RLink>
          </div>
        </form>
      </div>
    );
  }
}

UserLogin.defaultProps = {
  error: false,
  onSubmit: () => {},
  loading: false,
};

UserLogin.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.bool,
  loading: PropTypes.bool,
};
