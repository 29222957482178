const CheckboxStyle = {
  root: {
    marginLeft: 'auto',
  },
  icon: {
    borderRadius: 2,
    width: 16,
    height: 16,
    margin: 3,
    border: 'solid 1px #757575',
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
};

export default CheckboxStyle;
