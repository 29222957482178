import PropTypes from 'prop-types';
import React from 'react';

class Cluster extends React.Component {
  constructor(...props) {
    super(...props);

    this.maxZoom = 16;
  }

  shouldComponentUpdate(nextProps) {
    const { properties } = this.props;
    return nextProps.properties.items !== properties.items;
  }

  componentDidUpdate() {
    const { map, ymaps, properties } = this.props;

    map.geoObjects.removeAll();

    const geoObjects = [];

    const objectManager = new ymaps.ObjectManager({
      clusterize: true,
      clusterDisableClickZoom: true,
      clusterOpenBalloonOnClick: false,
    });

    if (properties.loaded) {
      properties.items.forEach((item) => {
        geoObjects.push({
          type: 'Feature',
          id: item.id,
          geometry: {
            type: 'Point',
            coordinates: [item.lat, item.lon],
          },
        });
      });
    }

    objectManager.add(geoObjects);

    objectManager.clusters.events.add('click', (e) => {
      const objectId = e.get('objectId');
      const cluster = objectManager.clusters.getById(objectId);

      if (map.getZoom() >= this.maxZoom) {
        this.setBalloonData(cluster.properties.geoObjects);
      } else if (map.getZoom() < 14) {
        map.setZoom(14);
      } else {
        map.setZoom(map.getZoom() + 1);
      }

      const geo = cluster.geometry.coordinates;
      const projection = map.options.get('projection');
      const globalPixel = projection.toGlobalPixels(geo, map.getZoom());
      const size = map.container.getSize();

      const top =
        map.getZoom() >= this.maxZoom ? globalPixel[1] + size[1] / 2 - 150 : globalPixel[1];
      map.panTo(projection.fromGlobalPixels([globalPixel[0], top], map.getZoom()), {
        useMapMargin: false,
        checkZoomRange: true,
      });
    });

    objectManager.objects.events.add('click', (e) => {
      const objectId = e.get('objectId');
      const object = objectManager.objects.getById(objectId);

      if (map.getZoom() < this.maxZoom) {
        map.setZoom(this.maxZoom);
      }

      this.setBalloonData([object]);

      const pointCoordinates = object.geometry.coordinates;
      map.panTo(pointCoordinates, { useMapMargin: true });
    });

    map.geoObjects.add(objectManager);
  }

  setBalloonData(objects) {
    const { onClick } = this.props;
    const ids = objects.map((item) => {
      return item.id;
    });

    onClick(ids);
  }

  render() {
    return null;
  }
}

Cluster.defaultProps = {
  onClick: () => {},
  properties: {},
  map: {},
  ymaps: {},
};

Cluster.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  map: PropTypes.objectOf(PropTypes.any),
  ymaps: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
};

export default Cluster;
