import PropTypes from 'prop-types';
import React from 'react';
import Autocomplete from './ui/Autocomplete';
import Api from '../api';

export default class LocalitySelect extends React.Component {
  handleSelect = (suggestion) => {
    const { onSelectLocality, onSelectEvent } = this.props;
    onSelectLocality(suggestion.data);
    if (onSelectEvent) {
      onSelectEvent(suggestion.data);
    }
  };

  getSuggestions = (input, callback) => {
    const { onChangeList, regionId } = this.props;
    if (onChangeList) {
      onChangeList();
    }
    if (this.t) {
      clearTimeout(this.t);
    }

    this.t = setTimeout(() => {
      Api.getLocalities(regionId, input).then((localities) => {
        callback(null, localities);
      });
    }, 100);
  };

  render() {
    const { value, name } = this.props;
    const inputProps = {
      value: value || '',
      placeholder: 'Населённый пункт',
      name,
      autoFocus: true,
    };

    return (
      <Autocomplete
        data={this.getSuggestions}
        onSelectLocality={this.handleSelect}
        inputProps={inputProps}
      />
    );
  }
}

LocalitySelect.defaultProps = {
  name: 'locality_fias_id',
  onSelectEvent: null,
  onChangeList: null,
  value: '',
};

LocalitySelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onSelectLocality: PropTypes.func.isRequired,
  onSelectEvent: PropTypes.func,
  onChangeList: PropTypes.func,
  regionId: PropTypes.number.isRequired,
};
