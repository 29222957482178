import React from 'react';
import RLink from '../RLink';
import { formatPrice } from '../../utils';
import Line from './Line';
import cn from './User.css';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';
import { AccountDivider } from '../Divider/Divider';
import FormPaper from '../SearchForm/FormPaper/FormPaper';

const UserBalance = ({ user: { profile } }) => {
  return (
    <div className={cn.userBalance}>
      <FormPaper>
        <Line name="Баланс" value={formatPrice(profile.balance)} />
      </FormPaper>
      <FormPaper>
        <RLink to="user/payments" className={cn.arrowLink}>
          <span>Расходы и платежи</span>
        </RLink>
        <AccountDivider />
        <RLink to="user/tariff" className={cn.arrowLink}>
          <span>Ознакомиться с тарифами</span>
        </RLink>
      </FormPaper>
    </div>
  );
};

UserBalance.propTypes = {
  user: userFromPropTypes.isRequired,
};

export default UserBalance;
