import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';
import rootReducer from '../reducers';
import { userAuthCookie } from '../middlewares';

const reducers = require('../reducers');

const finalCreateStore = compose(
  applyMiddleware(thunkMiddleware, userAuthCookie),
  persistState(
    [
      'regions',
      'selectedRegion',
      'salesFilter',
      'rentsFilter',
      'selectedViewType',
      'selectedSorting',
    ],
    { key: 'mlsn_init2009' }
  ),
  persistState(['lists'], { key: 'lists2510' }),
  persistState(['dictionaries'], { key: 'dictionaries1510' }),
  persistState(['dictionariesV2'], { key: 'dictionariesV21510' }),
  persistState(['favorites'], { key: 'favorites' }),
  persistState(['user'], { key: 'user' }),
  !!window && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
)(createStore);

const store = finalCreateStore(rootReducer);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('../reducers', () => {
    const nextRootReducer = reducers;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
