/* eslint-disable no-nested-ternary */
import { camelCaseKeys, nameById, shortNameById } from './utils';

const landsInfo = (object) => {
  return [
    {
      key: 'Удалённость от города',
      value: object.distance && `${object.distance} км`,
    },
    {
      key: 'Площадь дома',
      value: object.houseSquare && `${object.houseSquare} кв.м.`,
    },
    {
      key: 'Площадь участка',
      value: object.square ? `${object.square} сот.` : '',
    },
    {
      key: 'Электроснабжение',
      value: object.isPower,
    },
    {
      key: 'Водоснабжение',
      value: object.isWater,
    },
    {
      key: 'Газоснабжение',
      value: object.isGas,
    },
    {
      key: 'Центральная канализация',
      value: object.isCanalization,
    },
    {
      key: 'Курортная зона',
      value: object.isResortArea,
    },
    {
      key: 'Инвестиционный проект',
      value: object.isInvestmentPrj,
    },
    {
      key: 'Охрана',
      value: object.isProtection,
    },
    {
      key: 'Гараж',
      value: object.isGarage,
    },
    {
      key: 'Баня',
      value: object.isBath,
    },
  ];
};

export default {
  sales: (object, lists) => {
    const camelCased = camelCaseKeys(object);
    const areaTotal = camelCased.sqTotal || camelCased.sqLiving;
    const price = camelCased.refPrice * 1000;
    return {
      ...camelCased,
      deadline: camelCased.deadline || '',
      dealTypeName: camelCased.new ? 'Новостройка' : 'Продажа',
      realtyType: 'sales',
      realtyTypeName: nameById(lists.realty.sales, camelCased.typeId),
      realtyTypeShortName: shortNameById(lists.realty.sales, camelCased.typeId),
      areaTotal,
      areaLiving: camelCased.sqLiving,
      areaKitchen: camelCased.sqKitchen,
      dateUpdate: camelCased.updateDt,
      price,
      priceSquare: camelCased.priceM2 * 1000,
      floorsTotal: camelCased.floorTotal,
      viewCount: null,
      buildingMaterial: nameById(lists.buildingMaterial, camelCased.materialId),
    };
  },
  saleBusiness: (object, lists) => {
    const camelCased = camelCaseKeys(object);
    const areaTotal = camelCased.square;

    return {
      ...camelCased,
      dealTypeName: 'Продажа',
      realtyType: 'saleBusiness',
      realtyTypeName: nameById(lists.realty.businessSales, camelCased.rubricId),
      realtyTypeShortName: shortNameById(lists.realty.businessSales, camelCased.rubricId),
      areaTotal,
      dateUpdate: camelCased.updDt,
      price: camelCased.price * 1000,
      priceSquare: camelCased.priceM2 * 1000,
      floorsTotal: camelCased.floorTotal,
      viewCount: camelCased.reviewCount,
      hasPrivateEntrance: camelCased.isPrivateEntrance,
    };
  },
  rents: (object, lists) => {
    const camelCased = camelCaseKeys(object);
    const areaTotal = camelCased.sqTotal;

    const priceAdditionOptionsById = {
      0: ['+\xa0оплата\xa0эл.\xa0энергии'],
      1: [
        Number(camelCased.puValue)
          ? `+\xa0КУ\xa0(${camelCased.puValue}), +\xa0оплата\xa0эл.\xa0энергии`
          : '+\xa0КУ +\xa0оплата\xa0эл.\xa0энергии',
      ],
      2: ['+\xa0по\xa0счетчикам'],
    };

    const priceAddition = priceAdditionOptionsById[camelCased.pu] || [];

    return {
      ...camelCased,
      priceAddition: (camelCased.period !== 2 && priceAddition.join(' ')) || '',
      dealTypeName: 'Аренда',
      realtyType: 'rents',
      realtyTypeName:
        nameById(lists.realty.dailyRents, camelCased.rooms) ||
        nameById(lists.realty.longtermRents, camelCased.rooms),
      realtyTypeShortName:
        shortNameById(lists.realty.dailyRents, camelCased.rooms) ||
        shortNameById(lists.realty.longtermRents, camelCased.rooms),
      areaTotal,
      areaLiving: camelCased.sqLiving,
      areaKitchen: camelCased.sqKitchen,
      dateUpdate: camelCased.dt,
      price: camelCased.price,
      floorsTotal: camelCased.floorTotal,
      layout: nameById(lists.layout, camelCased.plannindId),
      furniture: nameById(lists.furniture, camelCased.furniture),
      viewCount: null,
      districtId: object.district,
    };
  },
  rentBusiness: (object, lists) => {
    const camelCased = camelCaseKeys(object);

    return {
      ...camelCased,
      dealTypeName: 'Аренда',
      realtyType: 'rentBusiness',
      realtyTypeName: nameById(lists.realty.businessRents, camelCased.typeId),
      realtyTypeShortName: shortNameById(lists.realty.businessRents, camelCased.typeId),
      areaTotal: camelCased.area,
      dateUpdate: camelCased.dt,
      price: camelCased.price * 1000,
      priceSquare: null,
      floorsTotal: camelCased.floorOf,
      viewCount: camelCased.reviewCount,
      hasPrivateEntrance: camelCased.entrance,
      districtId: object.district,
    };
  },
  lands: (object, lists) => {
    const camelCased = camelCaseKeys(object);
    const areaTotal = camelCased.sqTotal;
    const price = camelCased.refPrice * 1000;

    const info = landsInfo(camelCased);
    return {
      ...camelCased,
      dealTypeName: 'Продажа',
      realtyType: 'lands',
      realtyTypeName: nameById(lists.realty.lands, camelCased.rubricId),
      realtyTypeShortName: nameById(lists.realty.lands, camelCased.rubricId),
      areaTotal,
      areaLiving: camelCased.sqLiving,
      areaKitchen: camelCased.sqKitchen,
      dateUpdate: camelCased.updDt,
      price,
      priceSquare: camelCased.salePrice2 * 1000,
      floorsTotal: camelCased.floorTotal,
      viewCount: camelCased.reviewCount,
      buildingMaterial: nameById(lists.buildingMaterial, camelCased.materialId),
      info,
      refStreet: object.ref_street,
    };
  },
  favorites: (object, lists) => {
    const {
      photos,
      squareTotal,
      squareLand,
      location,
      price,
      dealType,
      realtyType,
      status,
    } = object;
    let street = '';
    if (status === 'deleted') {
      return object;
    }
    if (location.house) {
      street = location.house.fullName;
    } else if (location.street) {
      street = location.street.fullName;
    } else if (location.locality) {
      street = location.locality.fullName;
    }

    return {
      ...object,
      imagesSquare120: (photos.all || []).map((photo) =>
        photo.url.replace('{{ size }}', 'square120')
      ),
      images320X240: (photos.all || []).map((photo) => photo.url.replace('{{ size }}', '320x240')),
      images120X120: (photos.all || []).map((photo) => photo.url.replace('{{ size }}', '120x120')),
      images: (photos.all || []).map((photo) => photo.url.replace('{{ size }}', 'large')),
      realtyTypeShortName: shortNameById(
        lists[dealType][realtyType].objectType.items,
        object.objectTypeId
      ).toLowerCase(),
      dealTypeName:
        object.dealType === 'sale' ? (object.isNewBuilding ? 'Новостройка' : 'Продажа') : 'Аренда',
      area: squareTotal,
      refStreet: street,
      refPriceRub: price,
      districtId: location.localityDistrict ? location.localityDistrict.id : null,
      isSpecial: object.isPremium ? 1 : 0,
      isPromo: object.isPromo ? 1 : 0,
      refSquare: squareLand,
      refType: object.objectTypeId,
    };
  },
  garages: (object, lists) => {
    const { photos, squareTotal, location, price, dealType, realtyType, status } = object;
    let street = '';
    if (status === 'deleted') {
      return object;
    }
    if (location.house) {
      street = location.house.fullName;
    } else if (location.street) {
      street = location.street.fullName;
    } else if (location.locality) {
      street = location.locality.fullName;
    }

    return {
      ...object,
      imagesSquare120:
        (photos.all || []).map((photo) => photo.url.replace('{{ size }}', 'square120')) || [],
      images320X240:
        (photos.all || []).map((photo) => photo.url.replace('{{ size }}', '320x240')) || [],
      images120X120:
        (photos.all || []).map((photo) => photo.url.replace('{{ size }}', '120x120')) || [],
      images: (photos.all || []).map((photo) => photo.url.replace('{{ size }}', 'large')) || [],
      realtyTypeShortName: shortNameById(
        lists[dealType][realtyType].objectType.items,
        object.objectTypeId
      ).toLowerCase(),
      dealTypeName:
        object.dealType === 'sale' ? (object.isNewBuilding ? 'Новостройка' : 'Продажа') : 'Аренда',
      contactName: object.contactName || (object.author ? object.author.fullName : ''),
      area: squareTotal,
      areaTotal: squareTotal,
      refStreet: street,
      localityFias: location.locality ? location.locality.name : '',
      lat: location.lonLat ? location.lonLat[1] : null,
      lon: location.lonLat ? location.lonLat[0] : null,
      userPhones: object.contactPhones && object.contactPhones.map((item) => item.formatted),
      refUpdateDt: object.updated,
      refInsertDt: object.inserted,
      refPriceRub: price,
      refComment: object.description,
      districtId: location.localityDistrict ? location.localityDistrict.id : null,
      isSpecial: object.isPremium ? 1 : 0,
      isPromo: object.isPromo ? 1 : 0,
      statusId: status === 'active' ? 0 : 1,
    };
  },
};
