import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { find, result } from 'lodash';
import { formatPrice } from '../../utils';
import cn from './style.css';
import {
  selectedRegion as selectedRegionFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../lib/PropTypeValues';

class SimilarObjects extends React.Component {
  getUrl(data) {
    const { selectedRegion } = this.props;
    const { realtyType, dealType, id } = data;
    let section = realtyType;

    if (dealType === 'sale') {
      const sections = {
        apartments: 'sales',
        lands: 'lands',
        commercials: 'saleBusiness',
        garages: 'garages',
      };
      section = sections[realtyType];
    } else if (dealType === 'rent') {
      section = realtyType === 'commercials' ? 'rentBusiness' : 'rents';
    } else if (dealType === 'rentDaily') {
      section = 'rents';
    }

    return [`/${selectedRegion.domain}`, section, id].join('/');
  }

  getFormattedPrice = (price) => {
    return price > 0 ? formatPrice(price) : 'Цена договорная';
  };

  getTypeName(item) {
    const { dictionaries } = this.props;
    return result(
      find(dictionaries[item.dealType][item.realtyType].objectType.items, {
        id: item.objectTypeId,
      }),
      'middleName'
    );
  }

  getSquare = (item) => {
    if (item.realtyType === 'lands' && (item.objectTypeId === 1 || item.objectTypeId === 4)) {
      return item.squareLand > 0 ? `, ${item.squareLand} сот.` : null;
    }
    return item.squareTotal > 0 ? `, ${item.squareTotal} м²` : null;
  };

  getAddress = (item) => {
    if (item.location.house) {
      return item.location.house.fullName;
    }

    if (item.realtyType === 'lands' && item.objectTypeId === 4) {
      return item.gardenAssociation && `СТ ${item.gardenAssociation.name}`;
    }

    return item.location.street && item.location.street.fullName;
  };

  render() {
    const { similarObjects } = this.props;

    return (
      <div className={cn.similarObjectBlock}>
        {similarObjects.loaded &&
          similarObjects.payload.items
            .filter((item) => item.photos.main)
            .map((item) => (
              <div key={item.id} className={cn.similarObject}>
                <Link to={this.getUrl(item)}>
                  <div
                    className={cn.photo}
                    style={{
                      height: '135px',
                      background: `url(${item.photos.main.url.replace('{{ size }}', '320x240')})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      marginBottom: '10px',
                    }}
                  />

                  <div className={cn.price}>{this.getFormattedPrice(item.price)}</div>

                  <div>
                    {this.getTypeName(item)}
                    {this.getSquare(item)}
                  </div>

                  <div>{this.getAddress(item)}</div>
                </Link>
              </div>
            ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    similarObjects: state.similarObjects,
    dictionaries: state.dictionaries,
    selectedRegion: state.selectedRegion,
    lists: state.lists,
  };
}

SimilarObjects.defaultProps = {
  similarObjects: {},
};

SimilarObjects.propTypes = {
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  similarObjects: PropTypes.shape({
    payload: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
};

export default connect(mapStateToProps)(SimilarObjects);
