import PropTypes from 'prop-types';
import React from 'react';
import { RangeField } from '../ui';
import ModalButton from '../ui/ModalButton';
import Switch from '../ui/Switch';
import { FormControlLabel } from '../ui/material';
import FormPaper from './FormPaper/FormPaper';
import { SearchDivider } from '../Divider/Divider';
import {
  lists as listsFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  realty as realtyFromPropTypes,
} from '../../lib/PropTypeValues';

export default class SaleLandFields extends React.Component {
  render() {
    const {
      filter,
      rubrics,
      rubric,
      lists,
      searchPeriod,
      microdistricts,
      onClearSelectedValue,
      realty,
      locality,
      handleShowModal,
      onSelectLocality,
      districts,
      onChangeFilter,
      buildingMaterial,
      condition,
    } = this.props;
    const types = realty.lands.map((type) => {
      return {
        id: type.id,
        name: type.name,
        shortName: type.name,
      };
    });

    return (
      <div>
        <FormPaper>
          <ModalButton
            label="Населенный пункт"
            valueLabel={locality.name || filter.locality_fias_id}
            value={locality.id || filter.locality_fias_id}
            name="locality_fias_id"
            fieldRef={(input) => (this.locality_fias_id = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_LOCALITY_MODAL', {
                filterType: 'sale',
                title: 'Населенный пункт',
                filter,
                name: 'locality_fias_id',
                onSelectLocality,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Район"
            valueLabel={filter.o_d}
            value={filter.o_d}
            name="o_d"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.o_d = input;
            }}
            list={districts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: districts,
                title: 'Район',
                value: filter.o_d,
                filter,
                name: 'o_d',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Микрорайон"
            valueLabel={filter.m_m}
            value={filter.m_m}
            name="m_m"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.m_m = input;
            }}
            list={microdistricts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: microdistricts,
                title: 'Микрорайон',
                value: filter.m_m,
                filter,
                name: 'm_m',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Улица, дом"
            valueLabel={filter.addr}
            value={filter.addr}
            name="addr"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.addr = input;
            }}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_STREET_MODAL', {
                filterType: 'sale',
                title: 'Улица, дом',
                filter,
                name: 'addr',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Тип недвижимости"
            valueLabel={rubric.name}
            showModal={() => {
              handleShowModal('CHANGE_RUBRIC', {
                filterType: 'sale',
                rubrics,
                value: rubric.id,
                filter,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Тип объекта"
            valueLabel={filter.ref_type}
            value={filter.ref_type}
            name="ref_type"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.ref_type = input)}
            list={types}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: types,
                title: 'Тип объекта',
                value: filter.ref_type,
                filter,
                name: 'ref_type',
              });
            }}
          />
          <SearchDivider />
          <input type="hidden" defaultValue={1} name="is_normal_price" />
          <RangeField
            minName="ref_minprice"
            maxName="ref_maxprice"
            mainLabel="Цена, ₽"
            label=""
            measureLabel=""
            min={filter.ref_minprice}
            max={filter.ref_maxprice}
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.photo}
                name="photo"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.photo = input;
                }}
              />
            }
            label="Только с фото"
            labelPlacement="start"
          />
        </FormPaper>

        <input type="hidden" name="has_cords" defaultValue={filter.has_cords} />

        <div>
          <input type="hidden" name="left_lat" defaultValue={filter.left_lat} />
          <input type="hidden" name="left_lon" defaultValue={filter.left_lon} />
          <input type="hidden" name="right_lat" defaultValue={filter.right_lat} />
          <input type="hidden" name="right_lon" defaultValue={filter.right_lon} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[0]} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[1]} />
          <input type="hidden" name="zoom" defaultValue={filter.zoom} />
        </div>

        <FormPaper>
          <ModalButton
            label="Направление"
            valueLabel={filter.direction}
            value={filter.direction}
            name="direction"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.direction = input;
            }}
            list={lists.directionsList}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: lists.directionsList,
                title: 'Тип объекта',
                value: filter.direction,
                filter,
                name: 'direction',
              });
            }}
          />
          <SearchDivider />
          <RangeField
            mainLabel="Удаленность от города, км"
            label=""
            measureLabel=""
            minName="distanceMin"
            maxName="distanceMax"
            min={filter.distanceMin}
            max={filter.distanceMax}
            onChangeFilter={onChangeFilter}
          />
          <RangeField
            mainLabel="Общая площадь дома, м²"
            label=""
            measureLabel=""
            minName="sq_total_min"
            maxName="sq_total_max"
            min={filter.sq_total_min}
            max={filter.sq_total_max}
            onChangeFilter={onChangeFilter}
          />
          <RangeField
            mainLabel="Площадь участка, сот"
            label=""
            measureLabel=""
            minName="minArea"
            maxName="maxArea"
            min={filter.minArea}
            max={filter.maxArea}
            onChangeFilter={onChangeFilter}
          />
          <ModalButton
            label="Материал дома"
            valueLabel={filter.material_id}
            value={filter.material_id}
            name="material_id"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.material_id = input;
            }}
            list={buildingMaterial}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: buildingMaterial,
                title: 'Материал дома',
                value: filter.material_id,
                filter,
                name: 'material_id',
              });
            }}
          />
        </FormPaper>
        <FormPaper>
          <ModalButton
            label="Вид отопления"
            valueLabel={filter.heating}
            value={filter.heating}
            name="heating"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.heating = input;
            }}
            list={lists.heating}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: lists.heating,
                title: 'Вид отопления',
                value: filter.heating,
                filter,
                name: 'heating',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Состояние/ремонт"
            valueLabel={filter.renovation_type}
            value={filter.renovation_type}
            name="renovation_type"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.renovation_type = input;
            }}
            list={condition.sales}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: condition.sales,
                title: 'Состояние/ремонт',
                value: filter.renovation_type,
                filter,
                name: 'renovation_type',
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_power}
                name="is_power"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_power = input;
                }}
              />
            }
            label="Электроснабжение"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_water}
                name="is_water"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_water = input;
                }}
              />
            }
            label="Водоснабжение"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_gas}
                name="is_gas"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_gas = input;
                }}
              />
            }
            label="Газоснабжение"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_canalization}
                name="is_canalization"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_canalization = input;
                }}
              />
            }
            label="Центральная канализация"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_protection}
                name="is_protection"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_protection = input;
                }}
              />
            }
            label="Охрана"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_garage}
                name="is_garage"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_garage = input;
                }}
              />
            }
            label="Гараж"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_bath}
                name="is_bath"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_bath = input;
                }}
              />
            }
            label="Баня"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_outbuildings}
                name="is_outbuildings"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_outbuildings = input;
                }}
              />
            }
            label="Хоз. постройки"
            labelPlacement="start"
          />
        </FormPaper>
        <FormPaper>
          <ModalButton
            label="Дата публикации"
            valueLabel={
              filter.interval &&
              [{ id: '', name: 'за все время' }, ...searchPeriod.sales].find(
                (type) => Number(type.id) === filter.interval
              ).name
            }
            value={filter.interval}
            name="interval"
            fieldRef={(input) => {
              this.interval = input;
            }}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: [{ id: '', name: 'за все время' }, ...searchPeriod.sales],
                title: 'Дата публикации',
                value: filter.interval,
                filter,
                name: 'interval',
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.only_owner}
                name="only_owner"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.only_owner = input;
                }}
              />
            }
            label="Только от частных лиц"
            labelPlacement="start"
          />
        </FormPaper>
      </div>
    );
  }
}

SaleLandFields.defaultProps = {
  microdistricts: [],
  districts: [],
};

SaleLandFields.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  condition: PropTypes.objectOf(PropTypes.any).isRequired,
  searchPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  realty: realtyFromPropTypes.isRequired,
  lists: listsFromPropTypes.isRequired,
  rubric: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  districts: PropTypes.arrayOf(PropTypes.object),
  onClearSelectedValue: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  buildingMaterial: PropTypes.arrayOf(PropTypes.object).isRequired,
};
