import PropTypes from 'prop-types';

const sort = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const objectType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const buildingType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const material = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const planning = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const renovationType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const claimReason = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const sanitary = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const balcony = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const floorCovering = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const outerDoorType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const windowType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const windowView = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const parkingType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const deadline = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const interval = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const heating = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const usageCategory = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const showType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const furniture = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const rentPeriod = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const utilities = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const tenantCountPreference = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const tenantGenderPreference = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const floorPosition = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const section = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const category = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const userType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const activityType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const transactionType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const services = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

const messages = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
});

export const user = PropTypes.shape({
  token: PropTypes.string,
  loading: PropTypes.bool,
  profile: PropTypes.shape({
    legalFormId: PropTypes.number,
  }),
});

export const apartmentsSale = PropTypes.shape({
  sort,
  objectType,
  buildingType,
  material,
  planning,
  renovationType,
  claimReason,
  sanitary,
  balcony,
  floorCovering,
  outerDoorType,
  windowType,
  windowView,
  parkingType,
  deadline,
  interval,
});

export const apartmentsRent = PropTypes.shape({
  sort,
  objectType,
  buildingType,
  planning,
  renovationType,
  claimReason,
  interval,
  showType,
  furniture,
  rentPeriod,
  utilities,
  tenantCountPreference,
  tenantGenderPreference,
});

export const apartmentsRentDaily = PropTypes.shape({
  sort,
  objectType,
  buildingType,
  planning,
  renovationType,
  claimReason,
  interval,
  showType,
  furniture,
  utilities,
});

export const lands = PropTypes.shape({
  sort,
  objectType,
  claimReason,
  heating,
  material,
  renovationType,
  usageCategory,
  interval,
});

export const commercials = PropTypes.shape({
  sort,
  objectType,
  claimReason,
  interval,
});

export const garages = PropTypes.shape({
  sort,
  objectType,
  claimReason,
  interval,
});

export const realty = PropTypes.shape({
  sales: PropTypes.arrayOf(PropTypes.object),
  longtermRents: PropTypes.arrayOf(PropTypes.object),
  dailyRents: PropTypes.arrayOf(PropTypes.object),
  businessSales: PropTypes.arrayOf(PropTypes.object),
  lands: PropTypes.arrayOf(PropTypes.object),
});

export const searchPeriod = PropTypes.shape({
  sales: PropTypes.arrayOf(PropTypes.object),
  rents: PropTypes.arrayOf(PropTypes.object),
});

export const sale = PropTypes.shape({
  apartments: apartmentsSale,
  lands,
  commercials,
  garages,
});

export const rent = PropTypes.shape({
  apartments: apartmentsRent,
  commercials,
});

export const rentDaily = PropTypes.shape({
  apartments: apartmentsRentDaily,
});

export const lists = PropTypes.shape({
  realty,
  building: PropTypes.arrayOf(PropTypes.object),
  door: PropTypes.arrayOf(PropTypes.object),
  floorCover: PropTypes.arrayOf(PropTypes.object),
  buildingMaterial: PropTypes.arrayOf(PropTypes.object),
  furniture: PropTypes.arrayOf(PropTypes.object),
  parking: PropTypes.arrayOf(PropTypes.object),
  balcony: PropTypes.arrayOf(PropTypes.object),
  material: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.arrayOf(PropTypes.object),
  condition: PropTypes.objectOf(PropTypes.any),
  windowMaterial: PropTypes.arrayOf(PropTypes.object),
  viewFromWindow: PropTypes.arrayOf(PropTypes.object),
  sanitary: PropTypes.arrayOf(PropTypes.object),
  searchPeriod,
  directionsList: PropTypes.arrayOf(PropTypes.object),
  heating: PropTypes.arrayOf(PropTypes.object),
  rentLease: PropTypes.arrayOf(PropTypes.object),
  loaded: PropTypes.bool,
});

export const dictionaries = PropTypes.shape({
  sale,
  rent,
  rentDaily,
  floorPosition,
  section,
  article: PropTypes.shape({
    category,
  }),
  user: PropTypes.shape({
    userType,
    activityType,
  }),
  billing: PropTypes.shape({
    transactionType,
  }),
  market: PropTypes.shape({
    realty: PropTypes.shape({
      services,
    }),
  }),
  system: PropTypes.shape({
    messages,
  }),
  loaded: PropTypes.bool,
});

export const selectedRegion = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.number,
  name: PropTypes.string,
  capitalId: PropTypes.number,
  capitalName: PropTypes.string,
  domain: PropTypes.string,
});

export const selectedLocality = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const mortgage = PropTypes.shape({
  id: PropTypes.number,
  bankName: PropTypes.string,
  comment: PropTypes.string,
  customTitle: PropTypes.string,
  url: PropTypes.string,
});

export const userObjects = PropTypes.shape({
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  list: PropTypes.shape({
    active: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
      _links: PropTypes.shape({
        self: PropTypes.shape({
          href: PropTypes.string,
        }),
      }),
      _meta: PropTypes.shape({
        totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        pageCount: PropTypes.number,
        currentPage: PropTypes.number,
        perPage: PropTypes.number,
      }),
    }),
    archived: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
      _links: PropTypes.shape({
        self: PropTypes.shape({
          href: PropTypes.string,
        }),
      }),
      _meta: PropTypes.shape({
        totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        pageCount: PropTypes.number,
        currentPage: PropTypes.number,
        perPage: PropTypes.number,
      }),
    }),
  }),
});
