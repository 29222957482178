const initialState = {
  sales: [
    { id: '', name: 'По умолчанию' },
    { id: 'update_dt.desc', name: 'Сначала новые' },
    { id: 'update_dt', name: 'Сначала старые' },
    { id: 'price', name: 'Сначала недорогие' },
    { id: 'price.desc', name: 'Сначала дорогие' },
    { id: 'price_m2', name: 'Сначала недорогие за кв.м.' },
    { id: 'price_m2.desc', name: 'Сначала дорогие за кв.м.' },
    { id: 'sq_total', name: 'Сначала небольшие' },
    { id: 'sq_total.desc', name: 'Сначала большие' },
  ],
  lands: [
    { id: '', name: 'По умолчанию' },
    { id: 'ins_dt.desc', name: 'Сначала новые' },
    { id: 'ins_dt', name: 'Сначала старые' },
    { id: 'sale_price', name: 'Сначала недорогие' },
    { id: 'sale_price.desc', name: 'Сначала дорогие' },
    { id: 'sale_price2', name: 'Сначала недорогие за кв.м.' },
    { id: 'sale_price2.desc', name: 'Сначала дорогие за кв.м.' },
    { id: 'sq_total', name: 'Сначала небольшие дома' },
    { id: 'sq_total.desc', name: 'Сначала большие дома' },
    { id: 'square', name: 'Сначала небольшие участки' },
    { id: 'square.desc', name: 'Сначала большие участки' },
  ],
  rents: [
    { id: '', name: 'По умолчанию' },
    { id: 'ins_dt.desc', name: 'Сначала новые' },
    { id: 'ins_dt', name: 'Сначала старые' },
    { id: 'price', name: 'Сначала недорогие' },
    { id: 'price.desc', name: 'Сначала дорогие' },
    { id: 'sq_total', name: 'Сначала небольшие' },
    { id: 'sq_total.desc', name: 'Сначала большие' },
  ],
  saleBusiness: [
    { id: '', name: 'По умолчанию' },
    { id: 'ins_dt.desc', name: 'Сначала новые' },
    { id: 'ins_dt', name: 'Сначала старые' },
    { id: 'price', name: 'Сначала недорогие' },
    { id: 'price.desc', name: 'Сначала дорогие' },
    { id: 'price_m2', name: 'Сначала недорогие за кв.м.' },
    { id: 'price_m2.desc', name: 'Сначала дорогие за кв.м.' },
    { id: 'square', name: 'Сначала небольшие' },
    { id: 'square.desc', name: 'Сначала большие' },
  ],
  rentBusiness: [
    { id: '', name: 'По умолчанию' },
    { id: 'update_dt.desc', name: 'Сначала новые' },
    { id: 'update_dt', name: 'Сначала старые' },
    { id: 'full_price', name: 'Сначала недорогие' },
    { id: 'full_price.desc', name: 'Сначала дорогие' },
    { id: 'price', name: 'Сначала недорогие за кв.м.' },
    { id: 'price.desc', name: 'Сначала дорогие за кв.м.' },
    { id: 'sq_total', name: 'Сначала небольшие' },
    { id: 'sq_total.desc', name: 'Сначала большие' },
  ],
  favorites: [
    { id: '', name: 'По умолчанию' },
    { id: 'update_dt.desc', name: 'Сначала новые' },
    { id: 'update_dt', name: 'Сначала старые' },
    { id: 'full_price', name: 'Сначала недорогие' },
    { id: 'full_price.desc', name: 'Сначала дорогие' },
    { id: 'price', name: 'Сначала недорогие за кв.м.' },
    { id: 'price.desc', name: 'Сначала дорогие за кв.м.' },
    { id: 'sq_total', name: 'Сначала небольшие' },
    { id: 'sq_total.desc', name: 'Сначала большие' },
  ],
  garages: [
    { id: '', name: 'По умолчанию' },
    { id: '-inserted', name: 'Сначала новые' },
    { id: 'inserted', name: 'Сначала старые' },
    { id: 'price', name: 'Сначала недорогие' },
    { id: '-price', name: 'Сначала дорогие' },
    { id: 'pricePerSquareMeter', name: 'Сначала недорогие за кв.м.' },
    { id: '-pricePerSquareMeter', name: 'Сначала дорогие за кв.м.' },
    { id: 'squareTotal', name: 'Сначала небольшие' },
    { id: '-squareTotal', name: 'Сначала большие' },
  ],
};

export default function sortParams(state = initialState) {
  return state;
}
