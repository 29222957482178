/* eslint-disable max-classes-per-file */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import Infinite from 'react-infinite';
import cn from './ComplexListView.css';
import { Card } from '../ui/material';
import RLink from '../RLink';
import { formatPrice } from '../../utils';
import ButtonFavorite from '../ButtonFavorite';
import { lists as listsFromPropTypes } from '../../lib/PropTypeValues';

const inlineStyles = {
  item: {
    link: {
      textDecoration: 'none',
    },
    badgePremium: {
      background: '#FF7F29',
      position: 'absolute',
      color: 'white',
      bottom: 7,
      textAlign: 'center',
      width: 100,
      padding: '3px 0',
      fontSize: 14,
    },
    badgeLifted: {
      background: 'rgb(141, 198, 63)',
      position: 'absolute',
      color: 'white',
      bottom: 7,
      textAlign: 'center',
      width: 100,
      padding: '3px 0',
      fontSize: 14,
    },
  },
};

class ComplexListView extends React.Component {
  componentDidMount() {
    const { scrollPosition } = this.props;
    ReactDOM.findDOMNode(this.refs.list).scrollTop = scrollPosition;
  }

  handleClickItem = () => {
    const { updateScrollPosition } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.list);
    updateScrollPosition(node.scrollTop);
  };

  render() {
    const { loading, canLoadMore, onLoad, items, districts, domain, lists, eventAddFavorite, eventRemoveFavorite } = this.props;
    return (
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
          paddingTop: 5,
          background: '#f9f9f9',
        }}
      >
        <Infinite
          ref="list"
          elementHeight={120}
          isInfiniteLoading={loading}
          infiniteLoadBeginEdgeOffset={250}
          containerHeight={window.innerHeight - 80}
          loadingSpinnerDelegate={
            canLoadMore ? (
              <div style={{ textAlign: 'center', margin: 20 }}>Загрузка ...</div>
            ) : (
              <div />
            )
          }
          onInfiniteLoad={onLoad}
        >
          {items.map((item) => (
            <ListItem
              {...item}
              onClick={this.handleClickItem}
              districts={districts}
              domain={domain}
              lists={lists}
              eventAddFavorite={() => eventAddFavorite(item)}
              eventRemoveFavorite={() => eventRemoveFavorite(item)}
            />
          ))}
        </Infinite>
      </ul>
    );
  }
}

ComplexListView.defaultProps = {
  districts: [],
  canLoadMore: false,
  loading: false,
};

ComplexListView.propTypes = {
  loading: PropTypes.bool,
  canLoadMore: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  districts: PropTypes.arrayOf(PropTypes.any),
  domain: PropTypes.string.isRequired,
  lists: listsFromPropTypes.isRequired,
  scrollPosition: PropTypes.func.isRequired,
  updateScrollPosition: PropTypes.func.isRequired,
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired,
};

class ListItem extends React.Component {
  getUid() {
    const { realtyType, id } = this.props;
    return `${realtyType}_${id}`;
  }

  renderBadge = (type) => {
    if (type === 'premium') {
      return <div style={inlineStyles.item.badgePremium}>премиум</div>;
    }
    if (type === 'liftedUp') {
      return <div style={inlineStyles.item.badgeLifted}>поднято</div>;
    }
    return null;
  };

  render() {
    const {
      photos,
      squareTotal,
      location,
      price,
      id,
      floor,
      floorTotal,
      lists,
      objectTypeId,
      isPremium,
      isPromo,
      onClick,
      dealType,
      realtyType,
      eventAddFavorite,
      eventRemoveFavorite
    } = this.props;
    const size = 'square120';
    const floorItem = [floor || '-', floorTotal || '-'].join('/');
    const floorString = floor && floorTotal ? `, ${floorItem} эт.` : '';
    const district = location.localityDistrict && `${location.localityDistrict.name} р-н`;
    const realtyTypeShortName = (find(lists.realty.sales, { id: objectTypeId }) || {}).shortName;
    const badgeType = isPremium ? 'premium' : isPromo !== false && 'liftedUp';

    const address = location.house ? location.house.fullName : location.street.fullName;

    return (
      <li className={cn.item} key={id} onClick={onClick}>
        <Card>
          <RLink to={`sales/${id}`} style={inlineStyles.item.link}>
            <div className={cn.itemContainer}>
              <div className={cn.itemPhotoWrapper}>
                {photos.main && photos.main.url ? (
                  <img
                    src={photos.main.url.replace('{{ size }}', size)}
                    alt=""
                    style={{ width: 100 }}
                  />
                ) : (
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      lineHeight: '100px',
                      color: '#aaa',
                    }}
                  >
                    нет фото
                  </div>
                )}
                {badgeType && this.renderBadge(badgeType)}
              </div>
              <div style={{ maxHeight: 140, float: 'left', maxWidth: 180 }}>
                <ButtonFavorite
                  uid={this.getUid()}
                  object={{
                    id,
                    dealType,
                    realtyType,
                  }}
                  style={{ position: 'absolute', right: 0, top: 0, zIndex: 1, textAlign: 'center' }}
                  iconColor="#FF7F29"
                  iconStyle={{ width: 24, height: 24 }}
                  eventAddFavorite={eventAddFavorite}
                  eventRemoveFavorite={eventRemoveFavorite}
                />
                <p
                  style={{
                    color: 'rgb(141, 198, 63)',
                    fontSize: 20,
                    marginTop: 7,
                    marginBottom: 5,
                  }}
                >
                  {price ? formatPrice(Math.floor(price)) : 'Цена договорная'}
                </p>

                <p style={{ marginTop: 12, marginBottom: 0, color: '#323232', lineHeight: '20px' }}>
                  <span style={{ fontSize: '14px' }}>
                    {realtyTypeShortName} {squareTotal} м<sup>2</sup> {floorString}
                  </span>
                  <br />
                  <span style={{ fontSize: '12px' }}>{address}</span>
                  <br />
                  <span style={{ fontSize: '14px', color: 'grey' }}>{district}</span>
                </p>
              </div>
            </div>
          </RLink>
        </Card>
      </li>
    );
  }
}

ListItem.defaultProps = {
  photos: {},
  price: null,
  isPremium: null,
  isPromo: null,
};

ListItem.propTypes = {
  photos: PropTypes.objectOf(PropTypes.any),
  squareTotal: PropTypes.number.isRequired,
  location: PropTypes.shape({
    localityDistrict: PropTypes.objectOf(PropTypes.any),
    house: PropTypes.objectOf(PropTypes.any),
    street: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  price: PropTypes.number,
  id: PropTypes.number.isRequired,
  floor: PropTypes.number.isRequired,
  floorTotal: PropTypes.number.isRequired,
  lists: listsFromPropTypes.isRequired,
  objectTypeId: PropTypes.number.isRequired,
  isPremium: PropTypes.number,
  isPromo: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired,
  dealType: PropTypes.string.isRequired,
  realtyType: PropTypes.string.isRequired,
};

export default connect((state) => state)(ComplexListView);
