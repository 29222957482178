import PropTypes from 'prop-types';
import React from 'react';
import { RangeField } from '../ui';
import ModalButton from '../ui/ModalButton';
import { FormControlLabel } from '../ui/material';
import FormPaper from './FormPaper/FormPaper';
import { SearchDivider } from '../Divider/Divider';
import Switch from '../ui/Switch';
import {
  selectedLocality as selectedLocalityFromPropTypes,
  realty as realtyFromPropTypes,
} from '../../lib/PropTypeValues';

export default class BusinessFields extends React.Component {
  render() {
    const {
      filter,
      rubrics,
      rubric,
      searchPeriod,
      microdistricts,
      onClearSelectedValue,
      type,
      realty,
      locality,
      handleShowModal,
      onSelectLocality,
      districts,
      onChangeFilter,
    } = this.props;

    const searchPeriods = searchPeriod[type];
    const typeFilterName = 'ref_type';
    const realtyTypes = type === 'sales' ? 'businessSales' : 'businessRents';

    const types = realty[realtyTypes].map((item) => {
      return {
        id: item.id,
        name: item.name,
        shortName: item.name,
      };
    });

    return (
      <div>
        <FormPaper>
          <ModalButton
            label="Населенный пункт"
            valueLabel={locality.name || filter.locality_fias_id}
            value={locality.id || filter.locality_fias_id}
            name="locality_fias_id"
            fieldRef={(input) => (this.locality_fias_id = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_LOCALITY_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                title: 'Населенный пункт',
                filter,
                name: 'locality_fias_id',
                onSelectLocality,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Район"
            valueLabel={filter.o_d}
            value={filter.o_d}
            name="o_d"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.o_d = input)}
            list={districts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: districts,
                title: 'Район',
                value: filter.o_d,
                filter,
                name: 'o_d',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Микрорайон"
            valueLabel={filter.m_m}
            value={filter.m_m}
            name="m_m"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.m_m = input)}
            list={microdistricts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: microdistricts,
                title: 'Микрорайон',
                value: filter.m_m,
                filter,
                name: 'm_m',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Улица, дом"
            valueLabel={filter.addr}
            value={filter.addr}
            name="addr"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.addr = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_STREET_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                title: 'Улица, дом',
                filter,
                name: 'addr',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Тип недвижимости"
            valueLabel={rubric.name}
            showModal={() => {
              handleShowModal('CHANGE_RUBRIC', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                rubrics,
                value: rubric.id,
                filter,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Тип объекта"
            valueLabel={filter[typeFilterName]}
            value={filter[typeFilterName]}
            name="ref_type"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.ref_type = input)}
            list={types}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: types,
                title: 'Тип объекта',
                value: filter[typeFilterName],
                filter,
                name: 'ref_type',
              });
            }}
          />
          <SearchDivider />
          <input type="hidden" defaultValue={1} name="is_normal_price" />
          <RangeField
            minName="ref_minprice"
            maxName="ref_maxprice"
            mainLabel="Цена, ₽"
            label=""
            measureLabel=""
            min={filter.ref_minprice}
            max={filter.ref_maxprice}
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.photo}
                name="photo"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.photo = input)}
              />
            }
            label="Только с фото"
            labelPlacement="start"
          />
        </FormPaper>

        <input type="hidden" name="has_cords" defaultValue={filter.has_cords} />

        <div>
          <input type="hidden" name="left_lat" defaultValue={filter.left_lat} />
          <input type="hidden" name="left_lon" defaultValue={filter.left_lon} />
          <input type="hidden" name="right_lat" defaultValue={filter.right_lat} />
          <input type="hidden" name="right_lon" defaultValue={filter.right_lon} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[0]} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[1]} />
          <input type="hidden" name="zoom" defaultValue={filter.zoom} />
        </div>

        <FormPaper>
          <RangeField
            mainLabel="Общая площадь, м²"
            label=""
            measureLabel=""
            minName="minArea"
            maxName="maxArea"
            min={filter.minArea}
            max={filter.maxArea}
            onChangeFilter={onChangeFilter}
          />
          <RangeField
            mainLabel="Этаж"
            label=""
            measureLabel=""
            minName="floor_min"
            maxName="floor_max"
            min={filter.floor_min}
            max={filter.floor_max}
            onChangeFilter={onChangeFilter}
          />

          <RangeField
            mainLabel="Цена за м²"
            label=""
            measureLabel=""
            minName="minMeterPrice"
            maxName="maxMeterPrice"
            min={filter.minMeterPrice}
            max={filter.maxMeterPrice}
            onChangeFilter={onChangeFilter}
          />

          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.floor_only_first}
                name="floor_only_first"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.floor_only_first = input)}
              />
            }
            label="Только первый этаж"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.floor_no_basement}
                name="floor_no_basement"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.floor_no_basement = input)}
              />
            }
            label="Кроме подвала / полуподвала"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_first_line}
                name="is_first_line"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_first_line = input)}
              />
            }
            label="Первая линия"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_isolated}
                name="is_isolated"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_isolated = input)}
              />
            }
            label="Отдельно стоящее здание"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_private_entrance}
                name="is_private_entrance"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_private_entrance = input)}
              />
            }
            label="Отдельный вход"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Дата публикации"
            valueLabel={
              filter.interval &&
              [{ id: '', name: 'за все время' }, ...searchPeriods].find(
                (item) => Number(item.id) === Number(filter.interval)
              ).name
            }
            value={filter.interval}
            name="interval"
            fieldRef={(input) => (this.interval = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: [{ id: '', name: 'за все время' }, ...searchPeriods],
                title: 'Дата публикации',
                value: filter.interval,
                filter,
                name: 'interval',
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.only_owner}
                name="only_owner"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.only_owner = input)}
              />
            }
            label="Только от частных лиц"
            labelPlacement="start"
          />
        </FormPaper>
      </div>
    );
  }
}

BusinessFields.defaultProps = {
  microdistricts: [],
  districts: [],
};

BusinessFields.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  searchPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  realty: realtyFromPropTypes.isRequired,
  rubric: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  districts: PropTypes.arrayOf(PropTypes.object),
  onClearSelectedValue: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
