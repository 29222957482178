import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import TextField from '../components/ui/TextField';
import { setRegion, setCurrentPageType } from '../actions';
import { selectedRegion as selectedRegionFromPropTypes } from '../lib/PropTypeValues';

const styles = {
  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    marginTop: 10,
  },
  listItem: {
    padding: '10px',
  },
};

class _RegionPage extends React.Component {
  state = {
    term: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('empty'));
  }

  handleSelectRegion = (region) => {
    const { dispatch, history } = this.props;
    dispatch(setRegion(region));
    history.push(`/${region.domain}/`);
  };

  render() {
    const { regions, selectedRegion } = this.props;
    const { term } = this.state;

    const filteredRegions = regions.items.filter(
      (region) => region.name.toLowerCase().indexOf(term.toLowerCase()) === 0
    );
    const regionName = selectedRegion ? selectedRegion.name : '';
    const loaded = filteredRegions.length > 0;

    return (
      <div style={{ padding: '20px' }}>
        <TextField
          placeholder="Введите название региона"
          defaultValue={regionName}
          onChange={(e) => this.setState({ term: e.target.value })}
        />
        <Loader loaded={loaded}>
          <ul style={styles.list} role="menu">
            {filteredRegions.map((region) => (
              <li>
                <div
                  role="menuitem"
                  key={region.domain}
                  onClick={() => this.handleSelectRegion(region)}
                  onKeyDown={() => this.handleSelectRegion(region)}
                  style={styles.listItem}
                >
                  {region.capitalName !== region.name
                    ? `${region.name} (${region.capitalName})`
                    : `${region.name}`}
                </div>
              </li>
            ))}
          </ul>
        </Loader>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    regions: state.regions,
    selectedRegion: state.selectedRegion,
  };
}

_RegionPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  regions: PropTypes.shape({
    loaded: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_RegionPage);
