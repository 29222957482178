import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox as MuiCheckbox } from './material';

export default class Checkbox extends React.Component {
  isChecked() {
    return this.refs.root.isChecked();
  }

  render() {
    const { value } = this.props;
    const style = {
      marginLeft: '10px',
    };

    return <MuiCheckbox ref="root" {...this.props} style={style} value={value} />;
  }
}

Checkbox.defaultProps = {
  value: '1',
};

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};
