import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader';

export default class Image extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      loaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (prevProps.src !== src) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loaded: false,
      });
    }
  }

  handleImageLoaded = () => {
    this.setState({
      loaded: true,
    });
  };

  render() {
    const { style, imageStyle } = this.props;
    const { loaded } = this.state;
    const styleImg = loaded ? {} : { opacity: 0 };
    const propsStyle = style;
    const propsImageStyle = imageStyle;

    return (
      <div style={{ ...propsStyle, textAlign: 'center' }}>
        <img
          {...this.props}
          onLoad={this.handleImageLoaded}
          style={{ ...styleImg, ...propsImageStyle }}
          alt="img"
        />
        <Loader loaded={loaded} zIndex={1} />
      </div>
    );
  }
}

Image.defaultProps = {
  style: {},
  imageStyle: {},
  src: null,
};

Image.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  imageStyle: PropTypes.objectOf(PropTypes.any),
  src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
};
