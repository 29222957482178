import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import WhiteNewLogo from './white_new_logo.svg';
import NewLogo from './new_logo.svg';
import styles from './Logo.css';

const Logo = ({ className, style, color }) => {
  return (
    <img
      className={classNames(styles.root, className)}
      style={style}
      src={color ? WhiteNewLogo : NewLogo}
      alt="МЛСН.РУ"
    />
  );
};

Logo.propTypes = {
  color: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
};

Logo.defaultProps = {
  color: false,
  className: '',
};

export default Logo;
