import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentPageType, createUserInvoice } from '../../actions';
import cn from '../../components/User/UserLogin.css';
import FormRow from '../../components/SearchForm/FormRow';
import { TextField } from '../../components/ui';
import SubmitButton from '../../components/SubmitButton';
import {
  user as userFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
} from '../../lib/PropTypeValues';

class _InvoiceCreatePage extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      errors: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('userInvoiceCreate'));
  }

  handlerSubmit(e) {
    const { dispatch } = this.props;
    e.preventDefault();
    const sum = this.amount.getDOMNode().value;
    dispatch(createUserInvoice(sum))
      .then(() => {
        const { selectedRegion, history } = this.props;
        history.push(`/${selectedRegion.domain}/user/invoices`);
      })
      .catch((err) => {
        if (err.errorList && err.errorList.length) {
          let errors = 'Ошибка: ';
          errors += err.errorList.map((error) => error.message);
          this.setState({
            errors,
          });
        } else {
          alert(err);
        }
      });
  }

  render() {
    const { user } = this.props;
    const { errors } = this.state;

    if (user.profile.legalFormId !== 372) {
      return null;
    }

    return (
      <div style={{ padding: '0 10px', marginTop: '20px', fontSize: '14px' }}>
        <div className={cn.title}>Выписка счета</div>
        <form action="#" method="POST" onSubmit={this.handlerSubmit.bind(this)}>
          <FormRow>
            <TextField
              required="required"
              min="0"
              step="1"
              pattern="\d+"
              placeholder="Сумма"
              name="amount"
              type="number"
              inputRef={(input) => {
                this.amount = input;
              }}
            />
          </FormRow>
          <div style={{ margin: '0 0 15px 0', color: 'red' }}>{errors}</div>
          <div style={{ position: 'relative', minHeight: '57px' }}>
            <SubmitButton value="Выписать" />
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedRegion: state.selectedRegion,
  };
}

_InvoiceCreatePage.propTypes = {
  user: userFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_InvoiceCreatePage);
