import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import { updateSalesFilter, updateRentsFilter, addNotification, clearTotalCount } from '../actions';

class ButtonResetFilter extends React.Component {
  handleClick = () => {
    const { dispatch } = this.props;
    dispatch(updateRentsFilter({}));
    dispatch(updateSalesFilter({}));
    dispatch(clearTotalCount());

    dispatch(addNotification('Фильтр очищен'));

    ym('reachGoal', 'reset_filter');
  };

  render() {
    return (
      <div
        style={{ marginLeft: 'auto', marginRight: '10px', fontSize: '15px' }}
        onClick={this.handleClick}
        role="button"
      >
        Очистить
      </div>
    );
  }
}

ButtonResetFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect((state) => state)(ButtonResetFilter);
