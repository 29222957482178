import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { isEqual } from 'lodash';

function loadScript(url) {
  const head = document.querySelector('head');
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  head.appendChild(script);
}

export default class Map extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {};
  }

  componentDidMount() {
    window.initMap = this.initMap.bind(this);
    if (!window.ymaps) {
      loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU&ns=&onload=initMap&mode=release');
    } else {
      this.initMap();
    }
  }

  componentDidUpdate(prevProps) {
    const { mapState, eventHandlers } = this.props;
    const { instance } = this.state;

    // On change map center
    if (!isEqual(prevProps.mapState.center, mapState.center)) {
      instance.panTo(mapState.center);
    }

    // todo: add deep comparison for equality
    if (!isEqual(Object.keys(prevProps.eventHandlers), Object.keys(eventHandlers))) {
      // register new events
      Object.keys(eventHandlers).forEach((key) => {
        if (prevProps.eventHandlers[key] === undefined) {
          instance.events.add(key, eventHandlers[key]);
        }
      });

      // remove old event handlers
      Object.keys(prevProps.eventHandlers).forEach((key) => {
        if (eventHandlers[key] === undefined) {
          instance.events.remove(key, prevProps.eventHandlers[key]);
        }
      });
    }
  }

  componentWillUnmount() {
    delete window.initMap;
  }

  initMap(ymaps) {
    const { eventHandlers, mapState, onLoad } = this.props;
    this.ymaps = ymaps;

    const instance = new this.ymaps.Map(ReactDOM.findDOMNode(this), mapState, {
      suppressMapOpenBlock: true,
    });
    this.setState({ instance });

    // add event handlers
    Object.keys(eventHandlers).forEach((event) => {
      instance.events.add(event, eventHandlers[event]);
    });

    onLoad(ymaps, instance);
  }

  render() {
    const { ymaps } = this;
    const { children, containerProps } = this.props;
    const { instance } = this.state;
    let childrenOfMap;

    if (instance) {
      childrenOfMap = React.Children.map(children, (child) => {
        return child ? React.cloneElement(child, { ymaps, map: instance }) : '';
      });
    }

    return (
      <div style={{ width: '100%', height: '100%' }} {...containerProps}>
        {childrenOfMap}
      </div>
    );
  }
}

Map.defaultProps = {
  eventHandlers: {},
  containerProps: {},
  mapOptions: {},
  children: () => {},
  onLoad: () => {},
};

Map.propTypes = {
  containerProps: PropTypes.objectOf(PropTypes.any),
  mapState: PropTypes.objectOf(PropTypes.any).isRequired,
  mapOptions: PropTypes.objectOf(PropTypes.any),
  eventHandlers: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  onLoad: PropTypes.func,
};
