import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Divider as MuiDivider } from '../ui/material';
import {
  MenuDividerStyle,
  SearchDividerStyle,
  ContactsDividerStyle,
  AccountDividerStyle,
} from './styles';

function Divider(props) {
  const { classes } = props;
  return <MuiDivider className={classes.root} />;
}

Divider.defaultProps = {
  classes: {},
};

Divider.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
};

export const MenuDivider = withStyles(MenuDividerStyle)(Divider);
export const SearchDivider = withStyles(SearchDividerStyle)(Divider);
export const ContactsDivider = withStyles(ContactsDividerStyle)(Divider);
export const AccountDivider = withStyles(AccountDividerStyle)(Divider);
