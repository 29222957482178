import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import ComplexView from '../components/ComplexView';
import { loadComplex, loadComplexHistory, setCurrentPageType } from '../actions';
import {
  selectedRegion as selectedRegionFromPropTypes,
  lists as listsFromPropTypes,
} from '../lib/PropTypeValues';

class _ComplexPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(loadComplex(match.params.id));
    dispatch(loadComplexHistory(match.params.id));
    dispatch(setCurrentPageType('complex'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  render() {
    const { complex, lists, params, selectedRegion } = this.props;
    return (
      <Loader loaded={complex.loaded}>
        <ComplexView
          lists={lists}
          params={params}
          complexHistory={complex.history}
          selectedRegion={selectedRegion}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...complex.data}
        />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    complex: state.complex,
    selectedRegion: state.selectedRegion,
    lists: state.lists,
  };
}

_ComplexPage.defaultProps = {
  params: null,
};

_ComplexPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  complex: PropTypes.shape({
    loaded: PropTypes.bool,
    history: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  lists: listsFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_ComplexPage);
