import React from 'react';

const SquareMeter = () => {
  return (
    <span>
      м<sup>2</sup>
    </span>
  );
};

export default SquareMeter;
