import React from 'react';

export function AccountIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8473 12.7473L20.1681 14.7113C21.2837 15.2184 22 16.3308 22 17.5562V18.4464C22 20.1723 20.6009 21.5714 18.875 21.5714H5.125C3.39911 21.5714 2 20.1723 2 18.4464V17.5562C2 16.3308 2.71626 15.2184 3.83187 14.7113L8.1527 12.7473C6.69011 11.6033 5.75 9.82217 5.75 7.82143C5.75 4.36965 8.54822 1.57143 12 1.57143C15.4518 1.57143 18.25 4.36965 18.25 7.82143C18.25 9.82217 17.3099 11.6033 15.8473 12.7473ZM9.45158 13.53L4.34912 15.8493C3.67976 16.1535 3.25 16.8209 3.25 17.5562V18.4464C3.25 19.482 4.08947 20.3214 5.125 20.3214H18.875C19.9105 20.3214 20.75 19.482 20.75 18.4464V17.5562C20.75 16.8209 20.3202 16.1535 19.6509 15.8493L14.5484 13.53C13.7701 13.8779 12.9077 14.0714 12 14.0714C11.0923 14.0714 10.2299 13.8779 9.45158 13.53ZM17 7.82143C17 10.5829 14.7614 12.8214 12 12.8214C9.23858 12.8214 7 10.5829 7 7.82143C7 5.06 9.23858 2.82143 12 2.82143C14.7614 2.82143 17 5.06 17 7.82143Z"
        fill="white"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12.4762 11.5238H15.8095C16.0725 11.5238 16.2857 11.737 16.2857 12C16.2857 12.263 16.0725 12.4762 15.8095 12.4762H12.4762V15.8095C12.4762 16.0725 12.263 16.2857 12 16.2857C11.737 16.2857 11.5238 16.0725 11.5238 15.8095V12.4762H8.19047C7.92748 12.4762 7.71428 12.263 7.71428 12C7.71428 11.737 7.92748 11.5238 8.19047 11.5238H11.5238V8.19048C11.5238 7.92749 11.737 7.71429 12 7.71429C12.263 7.71429 12.4762 7.92749 12.4762 8.19048V11.5238ZM21.0477 12C21.0477 16.9969 16.9969 21.0476 12 21.0476C7.00318 21.0476 2.95243 16.9969 2.95243 12C2.95243 7.00313 7.00318 2.95237 12 2.95237C16.9969 2.95237 21.0477 7.00313 21.0477 12Z"
        fill="white"
      />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8373 21.0516L17.5549 16.7692C18.9383 15.2022 19.7778 13.1436 19.7778 10.8889C19.7778 5.97969 15.7981 2 10.8889 2C5.97969 2 2 5.97969 2 10.8889C2 15.7981 5.97969 19.7778 10.8889 19.7778C13.1436 19.7778 15.2022 18.9383 16.7692 17.5549L21.0516 21.8373C21.2686 22.0542 21.6203 22.0542 21.8373 21.8373C22.0542 21.6203 22.0542 21.2686 21.8373 21.0516ZM18.6667 10.8889C18.6667 15.1844 15.1844 18.6667 10.8889 18.6667C6.59335 18.6667 3.11112 15.1844 3.11112 10.8889C3.11112 6.59334 6.59335 3.11111 10.8889 3.11111C15.1844 3.11111 18.6667 6.59334 18.6667 10.8889Z"
        fill="white"
      />
    </svg>
  );
}

export function FavoriteIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7143 9.09524C21.7143 4.98713 19.3263 2.42857 15.6032 2.42857C13.9057 2.42857 12.5924 3.01014 11.7143 4.15716C10.8362 3.01014 9.52285 2.42857 7.82541 2.42857C4.10228 2.42857 1.71429 4.98713 1.71429 9.09524C1.71429 12.3107 4.96805 15.9473 11.4125 20.1172C11.5961 20.2361 11.8324 20.2361 12.0161 20.1172C18.4605 15.9473 21.7143 12.3107 21.7143 9.09524ZM15.6032 3.53967C18.6949 3.53967 20.6032 5.58428 20.6032 9.09522C20.6032 11.7609 17.6602 15.0941 11.7143 18.9879C5.76839 15.0941 2.82543 11.7609 2.82543 9.09522C2.82543 5.58428 4.73374 3.53967 7.82543 3.53967C9.47381 3.53967 10.5743 4.1685 11.2174 5.45478C11.4222 5.86426 12.0065 5.86426 12.2112 5.45478C12.8544 4.1685 13.9548 3.53967 15.6032 3.53967Z"
        fill="white"
      />
    </svg>
  );
}

export function MySearchIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 7.62857C22 5.04046 20.5672 3.42857 18.3333 3.42857C17.3148 3.42857 16.5269 3.79496 16 4.51758C15.4731 3.79496 14.6851 3.42857 13.6666 3.42857C11.4328 3.42857 9.99998 5.04046 9.99998 7.62857C9.99998 9.65434 11.9522 11.9454 15.8189 14.5724C15.9291 14.6473 16.0709 14.6473 16.1811 14.5724C20.0477 11.9454 22 9.65434 22 7.62857ZM9.14203 4.12567C9.47129 4.12567 9.79534 4.14795 10.1128 4.19109C9.9287 4.44564 9.77033 4.72859 9.63963 5.03795C9.47547 5.02502 9.30953 5.01843 9.14205 5.01843C5.69067 5.01843 2.89278 7.81632 2.89278 11.2677C2.89278 14.7191 5.69067 17.517 9.14205 17.517C11.4291 17.517 13.4292 16.2884 14.5184 14.4552C14.7526 14.6292 14.9945 14.8044 15.244 14.981C15.025 15.3403 14.7751 15.6786 14.498 15.9924L17.9388 19.4332C18.1131 19.6075 18.1131 19.8902 17.9388 20.0645C17.7645 20.2388 17.4819 20.2388 17.3076 20.0645L13.8668 16.6237C12.6077 17.7352 10.9536 18.4097 9.14203 18.4097C5.1976 18.4097 2 15.2121 2 11.2677C2 7.32327 5.1976 4.12567 9.14203 4.12567Z"
        fill="white"
      />
    </svg>
  );
}

export function MapMarkerIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6609 20.8674C7.23483 16.7818 5 13.1717 5 10C5 6.13401 8.13401 3 12 3C15.866 3 19 6.13401 19 10C19 13.1717 16.7652 16.7818 12.3391 20.8674C12.1476 21.0442 11.8524 21.0442 11.6609 20.8674ZM8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM12 4C15.3137 4 18 6.68629 18 10C18 12.7518 16.0134 16.0353 12 19.8164C7.9866 16.0353 6 12.7518 6 10C6 6.68629 8.68629 4 12 4Z"
        fill="white"
      />
    </svg>
  );
}
export function IpotekaIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0498 21.246C5.91173 21.4852 5.99367 21.791 6.23282 21.9291C6.47196 22.0671 6.77776 21.9852 6.91583 21.746L17.886 2.7452C18.024 2.50605 17.9421 2.20026 17.703 2.06219C17.4638 1.92412 17.158 2.00605 17.0199 2.2452L6.0498 21.246ZM6.5 10.0003C8.433 10.0003 10 8.43334 10 6.50034C10 4.56734 8.433 3.00034 6.5 3.00034C4.567 3.00034 3 4.56734 3 6.50034C3 8.43334 4.567 10.0003 6.5 10.0003ZM6.5 11.0003C8.98528 11.0003 11 8.98562 11 6.50034C11 4.01506 8.98528 2.00034 6.5 2.00034C4.01472 2.00034 2 4.01506 2 6.50034C2 8.98562 4.01472 11.0003 6.5 11.0003ZM17.5 21.0003C19.433 21.0003 21 19.4333 21 17.5003C21 15.5673 19.433 14.0003 17.5 14.0003C15.567 14.0003 14 15.5673 14 17.5003C14 19.4333 15.567 21.0003 17.5 21.0003ZM17.5 22.0003C19.9853 22.0003 22 19.9856 22 17.5003C22 15.0151 19.9853 13.0003 17.5 13.0003C15.0147 13.0003 13 15.0151 13 17.5003C13 19.9856 15.0147 22.0003 17.5 22.0003Z"
        fill="white"
      />
    </svg>
  );
}
export function PodborRieltoraIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.151 21.8564V5.06347C21.151 4.84612 20.9748 4.66992 20.7574 4.66992H15.4751C14.9927 4.66992 14.5631 4.36473 14.4044 3.90922L14.2035 3.33303C13.8731 2.38511 12.9792 1.75 11.9753 1.75C11.0413 1.75 10.1952 2.30092 9.81731 3.15509L9.45036 3.98462C9.26608 4.40123 8.8534 4.66992 8.39786 4.66992H3.24316C3.02581 4.66992 2.84961 4.84612 2.84961 5.06347V21.8564C2.84961 22.0738 3.02581 22.25 3.24316 22.25H20.7574C20.9748 22.25 21.151 22.0738 21.151 21.8564ZM3.24316 3.66992C2.47352 3.66992 1.84961 4.29384 1.84961 5.06347V21.8564C1.84961 22.6261 2.47352 23.25 3.24316 23.25H20.7574C21.527 23.25 22.151 22.6261 22.151 21.8564V5.06347C22.151 4.29384 21.527 3.66992 20.7574 3.66992H15.4751C15.4181 3.66992 15.3674 3.63388 15.3486 3.58008L15.1478 3.00389C14.6774 1.65426 13.4046 0.75 11.9753 0.75C10.6455 0.75 9.44077 1.53439 8.90279 2.75055L8.53584 3.58008C8.51168 3.6347 8.45758 3.66992 8.39786 3.66992H3.24316ZM11.9999 13.2479C13.4038 13.2479 14.5419 12.1098 14.5419 10.706C14.5419 9.30209 13.4038 8.16403 11.9999 8.16403C10.5961 8.16403 9.45801 9.30209 9.45801 10.706C9.45801 12.1098 10.5961 13.2479 11.9999 13.2479ZM11.9999 14.2479C13.9561 14.2479 15.5419 12.6621 15.5419 10.706C15.5419 8.7498 13.9561 7.16403 11.9999 7.16403C10.0438 7.16403 8.45801 8.7498 8.45801 10.706C8.45801 12.6621 10.0438 14.2479 11.9999 14.2479ZM11.2213 15.6093H12.7788C14.8215 15.6093 16.5332 17.0265 16.9844 18.9313H7.01569C7.46689 17.0265 9.17862 15.6093 11.2213 15.6093ZM12.7788 14.6093C15.3762 14.6093 17.539 16.4701 18.0069 18.9313C18.0685 19.2551 18.1007 19.5894 18.1007 19.9313H17.1007H6.89941H5.89941C5.89941 19.5894 5.93164 19.2551 5.99322 18.9313C6.46114 16.4701 8.62394 14.6093 11.2213 14.6093H12.7788Z"
        fill="white"
      />
    </svg>
  );
}
export function OcenkaIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15L14 15C17.3084 15 20 12.0841 20 8.5C20 4.9159 17.3084 2 14 2L8.42857 2C8.19187 2 8 2.20786 8 2.46429L8 14H5.5C5.22385 14 5 14.2239 5 14.5C5 14.7761 5.22385 15 5.5 15H8V17H5.52C5.2328 17 5 17.2239 5 17.5C5 17.7761 5.2328 18 5.52 18H8V21.5556C8 21.801 8.22385 22 8.5 22C8.77615 22 9 21.801 9 21.5556V18H17.48C17.7671 18 18 17.7761 18 17.5C18 17.2239 17.7671 17 17.48 17H9V15ZM14 14L8.92308 14L8.92308 3L14 3C16.8358 3 19.0769 5.42791 19.0769 8.5C19.0769 11.5721 16.8358 14 14 14Z"
        fill="white"
      />
    </svg>
  );
}
export function SurveyIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5 12C22.5 16.6053 17.8975 20.5 12 20.5C6.10252 20.5 1.5 16.6053 1.5 12C1.5 7.39471 6.10252 3.5 12 3.5C17.8975 3.5 22.5 7.39471 22.5 12Z" stroke="white"/>
      <path d="M0 22C4 19.5 3.5 17.5 3 16.5L7 19.5C6 21 3 22 0 22Z" fill="white"/>
    </svg>
  );
}

