import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import styles from './PropertyItem.css';
import { Image } from '../../ui';

export default class PropertyImage extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      currentPhoto: 0,
    };
  }

  updatePhotoIndex = (index) => {
    this.setState({
      currentPhoto: index,
    });
  };

  render() {
    const { src, heightImg } = this.props;
    const settings = {
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      adaptiveHeight: true,
      arrows: false,
      draggable: false,
      centerMode: false,
      centerPadding: 0,
      useCSS: true,
      lazyLoad: 'progressive',
      speed: 200,
    };
    const { currentPhoto } = this.state;
    const photos = src;

    return (
      <section>
        <div className={styles.image}>
          {!!photos && photos.length > 1 ? (
            <div className={styles.sliderWrapper}>
              <Slider
                {...settings}
                className={styles.SliderContainer}
                afterChange={this.updatePhotoIndex}
              >
                {photos.map((photo, index) => (
                  <div key={photo}>
                    <img className={styles.Slide} alt={`photo_slide_${index}`} src={photo} />
                  </div>
                ))}
              </Slider>
              <div className={styles.photoCounter}>{`${currentPhoto + 1} из ${photos.length}`}</div>
            </div>
          ) : (
            !!photos && (
              <Image
                src={photos}
                alt=""
                imageStyle={{
                  width: '100%',
                  maxHeight: '345px',
                  height: heightImg,
                  objectFit: 'contain',
                }}
                style={{
                  height: '100%',
                  maxHeight: '345px',
                  background: 'white',
                  overflow: 'hidden',
                }}
              />
            )
          )}
          {!photos && (
            <div
              className={styles.placeholder}
              style={{
                height: heightImg,
                maxHeight: '345px',
                lineHeight: `${heightImg}px`,
              }}
            >
              нет фото
            </div>
          )}
        </div>
      </section>
    );
  }
}

PropertyImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]).isRequired,
  heightImg: PropTypes.number.isRequired,
};
