import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import { values, isEqual, find, isEmpty } from 'lodash';
import qs from 'query-string';
import Cookies from 'cookies-js';
import TabsToggle from '../components/ui/TabsToggle';
import TabToggle from '../components/ui/TabToggle';
import { SalesForm, RentsForm } from '../components/SearchForm';
import {
  setLocality,
  setSection,
  selectDistricts,
  updateSalesFilter,
  updateRentsFilter,
  setCurrentPageType,
  invalidateProperties,
  addNotification,
  setRegionBySearch,
  loadProperties,
  stopReload,
  loadGarageProperties,
  setRentsInterval,
  updateFilterType,
  showModal,
} from '../actions';
import TabPanel from '../components/ui/TabPanel';
import styles from './assets/SearchPage.css';
import { convertFilter } from '../utils';
import {
  lists as listsFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
} from '../lib/PropTypeValues';

import { gtagPushEventNewPage } from '../classes/analytics/gtagUtils';
import GtagBuilder from '../classes/analytics/gtagEventBuilder';

class _SearchPage extends Component {
  static getDerivedStateFromProps(props, state) {
    if (Number(props.totalCount) !== state.totalCount) {
      return {
        totalCount: props.totalCount,
      };
    }
    return null;
  }

  constructor(...props) {
    super(...props);
    this.state = {
      reload: false,
      totalCount: 0,
      microdistricts: [],
    };
  }

  componentDidMount() {
    const {
      dispatch,
      rentsFilter,
      user,
      cianModalContext,
    } = this.props;
    dispatch(setCurrentPageType('search'));
    this.setMicrodistricts();
    dispatch(setRentsInterval(rentsFilter.period || 1));
    const cianGtagEvent = new GtagBuilder({})
      .setEvent('newpage')
      .setEventCategory('page')
      .setEventAction('newpage')
      .setEventLabel('open')
      .setUser(user)
      .setPage({
        pageType: 'Home',
        siteType: 'mobile'
      })
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      salesRubric,
      rentsRubric,
      districts,
      selectedSection,
      microdistricts,
      selectedRegion,
      salesFilter,
      rentsFilter,
      reload,
      dictionaries,
      cianModalContext,
    } = this.props;
    const { totalCount } = this.state;
    return (
      !isEqual(nextProps.salesRubric, salesRubric) ||
      !isEqual(nextProps.rentsRubric, rentsRubric) ||
      // eslint-disable-next-line react/destructuring-assignment
      !isEqual(nextState.reload, this.state.reload) ||
      !isEqual(nextProps.districts, districts) ||
      !isEqual(nextProps.selectedSection, selectedSection) ||
      !isEqual(nextProps.microdistricts, microdistricts) ||
      // eslint-disable-next-line react/destructuring-assignment
      !isEqual(nextState.microdistricts, this.state.microdistricts) ||
      !isEqual(nextProps.selectedRegion, selectedRegion) ||
      !isEqual(nextState.totalCount, totalCount) ||
      !isEqual(nextProps.salesFilter, salesFilter) ||
      !isEqual(nextProps.rentsFilter, rentsFilter) ||
      !isEqual(nextProps.reload, reload) ||
      !isEqual(nextProps.dictionaries, dictionaries) ||
      !isEqual(nextProps.cianModalContext, cianModalContext)
    );
  }

  componentDidUpdate(prevProps) {
    const { districts, reload, dispatch, cianModalContext } = this.props;
    if (reload) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          reload: true,
        },
        () => this.setState({ reload: false })
      );
      dispatch(stopReload());
    }
    if (prevProps.districts !== districts) {
      this.setMicrodistricts();
    }

    if (!Cookies.get('unionCianModal') && cianModalContext && cianModalContext.is_visible) {
      this.handleOpenUnionCianModal();
    }
  }

  handleSelectSection = (sectionId, value) => {
    const { dispatch } = this.props;
    dispatch(setSection(value));
  };

  handleSelectLocality = (locality) => {
    const { dispatch } = this.props;
    dispatch(setLocality(locality));
  };

  handleSelectRegion = (region) => {
    const { dispatch } = this.props;
    dispatch(setRegionBySearch(region));
  };

  handleSelectDistrict = (districts) => {
    const { dispatch } = this.props;
    dispatch(selectDistricts(districts));
  };

  handlePriceNotification = () => {
    const { dispatch } = this.props;
    dispatch(addNotification('Цена в тыс. руб., а не в руб.'));
  };

  handleFormSubmit = (type, filter) => {
    const {
      rentsRubric,
      salesRubric,
      selectedRegion,
      dispatch,
      history,
      selectedRentsInterval,
    } = this.props;

    if (type === 'rents') {
      dispatch(updateRentsFilter(filter));
      dispatch(updateFilterType(selectedRentsInterval.dealType, rentsRubric.realtyType));
    } else {
      dispatch(updateSalesFilter(filter));
      dispatch(updateFilterType(salesRubric.type, salesRubric.realtyType));
    }
    const mapPath = filter.has_cords ? '/map' : '';
    const redirectPath = type === 'rents' ? rentsRubric.route : salesRubric.route;
    dispatch(invalidateProperties());
    history.push({
      pathname: `/${selectedRegion.domain}/${redirectPath}${mapPath}`,
      search: qs.stringify(filter, {
        arrayFormat: `${redirectPath === 'garages' ? 'comma' : 'none'}`,
      }),
    });
  };

  handleUpdateSalesFilter = (filter) => {
    const { salesRubric, lists, dictionaries, dispatch, selectedLocality } = this.props;

    dispatch(updateSalesFilter(filter));
    if (salesRubric.route === 'garages') {
      dispatch(
        loadGarageProperties(
          convertFilter({ ...filter }, selectedLocality),
          `sale/${salesRubric.route}`,
          dictionaries
        )
      );
    } else {
      dispatch(loadProperties({ ...filter }, salesRubric.route, lists));
    }
  };

  handleUpdateRentsFilter = (filter) => {
    const { rentsRubric, lists, dictionaries, dispatch, selectedLocality } = this.props;

    dispatch(updateRentsFilter(filter));
    if (rentsRubric.route === 'garages') {
      dispatch(
        loadGarageProperties(
          convertFilter({ ...filter }, selectedLocality),
          `sale/${rentsRubric.route}`,
          dictionaries
        )
      );
    } else {
      dispatch(loadProperties({ ...filter }, rentsRubric.route, lists));
    }
  };

  handleOpenUnionCianModal = () => {
    const {
      dispatch,
      history,
      selectedRegion,
      cianModalContext,
    } = this.props;
    dispatch(
      showModal('UNION_CIAN_MODAL', {
        title: '',
        history,
        timer: selectedRegion.code === 55 ? null : 20,
        header: cianModalContext.title,
        description: cianModalContext.description,
      })
    );
    // показываем каждые cianModalContext.frequency дней UNION_CIAN_MODAL
    Cookies.set('unionCianModal', '1', { expires: 86400 * cianModalContext.frequency });
  }

  setMicrodistricts = () => {
    const { districts } = this.props;
    if (!isEmpty(districts)) {
      const microdistricts = values(districts)
        .map((dist) => dist.microdistricts)
        .reduce((prev, curr) => {
          return [...prev, ...curr];
        }, []);
      this.setState({
        microdistricts,
      });
    } else {
      this.setState({
        microdistricts: [],
      });
    }
  };

  render() {
    const { totalCount, microdistricts, reload } = this.state;
    const {
      lists,
      dictionaries,
      rubrics,
      districts,
      selectedRegion,
      selectedLocality,
      selectedSection,
      selectedRentsInterval,
      isShowMls,
      salesRubric,
      rentsRubric,
      salesFilter,
      rentsFilter,
    } = this.props;

    const saleRubrics = rubrics.filter((rubric) => rubric.type === 'sale');
    const rentRubrics = rubrics.filter((rubric) => rubric.type === 'rent');

    const mapUrlToRubric = (rubric, section) => {
      const mappings = {
        sales: {
          sales: 1,
          lands: 2,
          businessSales: 3,
          garages: 11,
        },
        rents: {
          longtermRents: 4,
          dailyRents: 5,
          businessRents: 6,
        },
      };

      const defaultRubric =
        section === 'sales' ? mappings.sales.sales : mappings.rents.longtermRents;
      const selectedRubric = section === 'sales' ? salesRubric : rentsRubric;

      return rubric
        ? find(rubrics, 'id', mappings[section][rubric])
        : selectedRubric || find(rubrics, 'id', defaultRubric);
    };

    return (
      <Loader loaded={lists.loaded && !reload} zIndex={2}>
        <div className={styles.TabsWrapper}>
          <TabsToggle
            onChange={this.handleSelectSection}
            variant="fullWidth"
            value={selectedSection}
          >
            <TabToggle label="Продажа" />
            <TabToggle label="Аренда" />
          </TabsToggle>
        </div>
        <TabPanel value={selectedSection} index={0}>
          <SalesForm
            rubrics={saleRubrics}
            region={selectedRegion}
            lists={lists}
            dictionaries={dictionaries}
            isSelected={selectedSection === 0}
            locality={selectedLocality}
            districts={values(districts)}
            microdistricts={microdistricts}
            rubric={mapUrlToRubric(0, 'sales')}
            filter={salesFilter}
            onSelectLocality={this.handleSelectLocality}
            onSelectRegion={this.handleSelectRegion}
            onSelectDistrict={this.handleSelectDistrict}
            onPriceNotification={this.handlePriceNotification}
            onSubmit={(filter) => this.handleFormSubmit('sales', filter)}
            updateFilter={this.handleUpdateSalesFilter}
            totalCount={totalCount}
          />
        </TabPanel>
        <TabPanel value={selectedSection} index={1}>
          <RentsForm
            rubrics={rentRubrics}
            region={selectedRegion}
            lists={lists}
            locality={selectedLocality}
            districts={values(districts)}
            isSelected={selectedSection === 1}
            microdistricts={microdistricts}
            rubric={mapUrlToRubric(0, 'rents')}
            filter={rentsFilter}
            onSelectLocality={this.handleSelectLocality}
            onSelectRegion={this.handleSelectRegion}
            onSelectDistrict={this.handleSelectDistrict}
            onSubmit={(filter) => this.handleFormSubmit('rents', filter)}
            updateFilter={this.handleUpdateRentsFilter}
            totalCount={totalCount}
            selectedRentsInterval={selectedRentsInterval}
            isShowMls={isShowMls}
          />
        </TabPanel>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    salesFilter: state.salesFilter,
    rentsFilter: state.rentsFilter,
    lists: state.lists,
    dictionaries: state.dictionaries,
    rubrics: state.rubrics,
    districts: state.districts,
    microdistricts: state.microdistricts,
    selectedRegion: state.selectedRegion,
    salesRubric: state.selectedSalesRubric,
    rentsRubric: state.selectedRentsRubric,
    selectedLocality: state.selectedLocality,
    selectedSection: state.selectedSection,
    totalCount: state.properties.count,
    reload: state.properties.reload,
    selectedRentsInterval: state.selectedRentsInterval,
    isShowMls: !!state.user.profile && state.user.profile.isRealtor && state.user.profile.hasMls,
    user: state.user,
    cianModalContext: state.appConfig.integrationSettings && state.appConfig.integrationSettings.common_popup,
  };
}

_SearchPage.defaultProps = {
  totalCount: 0,
  districts: [],
  rentsFilter: {
    period: 1,
  },
  salesFilter: {},
  microdistricts: [],
  isShowMls: false,
  reload: false,
};

_SearchPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  lists: listsFromPropTypes.isRequired,
  rentsRubric: PropTypes.shape({
    route: PropTypes.string,
  }).isRequired,
  salesRubric: PropTypes.shape({
    route: PropTypes.string,
  }).isRequired,
  totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  districts: PropTypes.any,
  rentsFilter: PropTypes.shape({
    period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  salesFilter: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  selectedSection: PropTypes.number.isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRentsInterval: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  isShowMls: PropTypes.bool,
  reload: PropTypes.bool,
  user: PropTypes.objectOf({
    token: PropTypes.string,
    profile: PropTypes.objectOf(PropTypes.any),
  }),
  cianModalContext: PropTypes.objectOf(PropTypes.any),
};

export default connect(mapStateToProps)(_SearchPage);
