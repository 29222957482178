import React, { Fragment } from 'react';
import { TableBuilder } from './tableBuilder';
import cn from '../../../../components/User/UserTariff.css'
import { tariffRowBuilder } from "./tariffRowBuilder";

export default class TariffDiscountTableBuilder extends TableBuilder {
  constructor(...props) {
    super(...props);
    this.header = [];
    this.table = [];
  }
  addHeader () {
    this.header.push(<div className={cn.tableHeader}>{this.getTableHeader(this.rubric.slug)}</div>);
    return this;
  }
  addColumn (type) {
    switch (type) {
      case 'count': {
        const builder = new tariffRowBuilder(this.rubric.pricing);
        this.table.push(
          <td width="40%">{builder.buildRows('count').renderRows()}</td>
        );
        return this;
      }
      case 'price': {
        const builder = new tariffRowBuilder(this.rubric.pricing);
        this.table.push(
          <td width="40%">{builder.buildRows('price').renderRows()}</td>
        );
        return this;
      }
      case 'discount': {
        const builder = new tariffRowBuilder(this.rubric.pricing);
        this.table.push(
          <td width="20%">{builder.buildRows('discount').renderRows()}</td>
        );
        return this;
      }
    }
    return this;
  }
  buildTable () {
    this.addHeader().addColumn('count').addColumn('price').addColumn('discount');
    return this;
  }
  renderTable () {
    return (
      <Fragment>
        {this.header}
        <table><tr>{this.table}</tr></table>
      </Fragment>
    )
  }
}