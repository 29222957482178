import 'whatwg-fetch';
import { filterToUrlParams } from '../utils';
import mappings from './mappings';
import config from '../config';

const API_ROOT = config.api_url.v1;
const GEO_API_ROOT = config.api_url.geo;
const GIS_API_ROOT = config.api_url.gis;
const options = {
  credentials: 'include',
  headers: {
    'X-MLSN-User-Agent': '323:320:1.0.0',
  },
};

export default {
  fetchRegions() {
    const url = `${API_ROOT}/lists/domain`;
    return fetch(url, options)
      .then((response) => response.json())
      .then((json) => mappings.regions(json.data));
  },

  getCurrentRegionByIp() {
    return fetch(GEO_API_ROOT, options)
      .then((response) => response.json())
      .then((json) => mappings.detectedRegion(json.region));
  },

  fetchMicrodistrictRating(id) {
    return fetch(`${GIS_API_ROOT}/rating/microdistrict/${id}`, options).then((resp) => resp.json());
  },

  fetchLists(regionId) {
    const params = [
      `building{cityId:${regionId}}`,
      'material',
      `realty{cityId:${regionId}}`,
      'parking',
      'planning',
      'sanitary',
      'balcony',
      'saleRenovation',
      'rentRenovation',
      'windowType',
      'windowView',
      'door',
      'floorCover',
      'saleSearchPeriod',
      'rentSearchPeriod',
      'furniture',
      'landRubrics',
      'businessSaleRubrics',
      'businessRentRubrics',
      'rentTypes',
      'dailyRentTypes',
      'heating',
      `DirectionsList{region:${regionId}}`,
      'rentLease',
    ].join(',');

    const url = `${API_ROOT}/lists/group?lists=${params}`;

    return fetch(url, options)
      .then((response) => response.json())
      .then((json) => mappings.lists(json.data));
  },

  getObject(id, type) {
    return fetch(`${API_ROOT}/${type}/${id}`, options);
  },

  fetchProperties(params, type, lists) {
    const selfParams = params;
    if (selfParams.m_m) {
      delete selfParams.o_d;
    }

    const url = `${API_ROOT}/${type}/?${filterToUrlParams(selfParams)}`;
    return fetch(url, options)
      .then((response) => response.json())
      .then((json) => mappings.properties(json, lists, type));
  },

  getLocalities(regionId, term) {
    const url = `${API_ROOT}/autocomplete/locality?region=${regionId}&term=${term}`;
    return fetch(url, options).then((response) => response.json());
  },

  getStreets(regionId, term, localityId = '') {
    const url = `${API_ROOT}/autocomplete/address?region=${regionId}&locality=${localityId}&term=${term}`;
    return fetch(url, options).then((response) => response.json());
  },

  getDistricts(localityId) {
    const url = `${API_ROOT}/lists/okatoDistricts?localityId=${localityId}`;
    return fetch(url, options)
      .then((response) => response.json())
      .then((json) => json.data);
  },

  bidObject(path, phone, comment) {
    const url = [API_ROOT, path, 'bid'].join('/');

    const data = new FormData();
    data.append('auser_phone', phone);
    data.append('auser_comment', comment);

    return fetch(url, {
      method: 'post',
      body: data,
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        return json;
      });
  },

  sendSMS(phone) {
    const url = `${API_ROOT}/users/sms/?number=${phone}`;
    return fetch(url, options).then((response) => response.json());
  },

  sendRestoreSMS(phone) {
    const url = `${API_ROOT}/users/restoresms/?number=${phone}`;
    return fetch(url, options).then((response) => response.json());
  },

  userRegister(user) {
    const url = `${API_ROOT}/users`;
    return fetch(url, {
      method: 'post',
      body: user,
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => response.json());
  },

  restorePassword(data) {
    const url = `${API_ROOT}/users/restorepass`;

    return fetch(url, {
      method: 'post',
      body: data,
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => response.json());
  },

  payments(params) {
    const url = `${API_ROOT}/users/balance/?${filterToUrlParams(params)}`;
    return fetch(url, options).then((response) => response.json());
  },

  loadCheckOverrun(token, sectionId) {
    return fetch(
      `${API_ROOT}/users/checkOverrun?section_id=${sectionId}&token=${token}`,
      options
    ).then((response) => response.json());
  },
};
