import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { throttle } from 'lodash';
import Modal from 'react-modal';
import Loader from 'react-loader';
import ym from 'react-yandex-metrika';
import { Link } from 'react-router-dom';
import styles from './PropertyView.css';
import { FontIcon } from '../ui/material';
import SalesInfo from './SalesInfo';
import BusinessSaleInfo from './BusinessSaleInfo';
import BusinessRentInfo from './BusinessRentInfo';
import RentsInfo from './RentsInfo';
import LandsInfo from './LandsInfo';
import GaragesInfo from './GaragesInfo';
import StaticMap from './StaticMap';
import PhotoSlider from './PhotoSlider';
import { Panel } from '../ui';
import RLink from '../RLink';
import { Map, Placemark } from '../YandexMaps';
import YandexPanorama from '../YandexPanorama';
import PreviewText from '../PreviewText';
import { formatPrice, isLandDacha, getNewFormat } from '../../utils';
import SimilarObjects from '../SimilarObjects';
import BadgeLabel from '../ui/BadgeLabel';
import { MapIcon, PanoramaIcon, UpArrowIcon, DownArrowIcon } from '../ui/Icons';
import ButtonFavorite from '../ButtonFavorite';
import ContactsView from '../ContactsView';
import mapIcon from '../ui/assets/mapIcon.png';
import { hitContacts } from '../../actions';
import MortgageLink from '../MortgageLink';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';

const buttonFavoriteStyles = {
  buttonFavorite: {
    position: 'absolute',
    right: 10,
    top: -2,
    zIndex: 1,
    textAlign: 'center',
  },
  buttonFavoriteIcon: {
    padding: 3,
    verticalAlign: 'middle',
    borderRadius: 10,
  },
};

export default class PropertyView extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      mapIsOpen: false,
      mapLoaded: false,
      panoramaIsOpen: false,
      isOpenPhotoSlider: false,
      sticky: true,
      isOpenContacts: false,
    };

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    const { realtyType, id } = this.props;
    this.handleScroll();

    window.vkRetargeting('view_product', realtyType, {
      products: [{ id: id.toString() }],
    });
  }

  componentDidUpdate(prevProps) {
    const { similarObjects, realtyType, id } = this.props;
    if (similarObjects.loaded && prevProps.similarObjects.payload !== similarObjects.payload) {
      const similarObjectIds = [];
      similarObjects.payload.items.map((item) => similarObjectIds.push(item.id));

      window.vkRetargeting('view_product', realtyType, {
        products: [{ id: id.toString(), recommended_ids: similarObjectIds.join(',') }],
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    throttle(() => {
      const dock = ReactDOM.findDOMNode(this.refs.contactsBtn);

      if (!dock) {
        this.setState({ sticky: true });
      } else if (window.innerHeight > dock.getBoundingClientRect().bottom) {
        this.setState({ sticky: false });
      } else {
        this.setState({ sticky: true });
      }
    }, 50)();
  };

  onOpenPhotoSlider = () => {
    this.setState({
      isOpenPhotoSlider: true,
    });
  };

  handleMapLoad = () => {
    this.setState({
      mapLoaded: true,
    });
  };

  onCloseContacts(e) {
    e.preventDefault();
    this.setState({
      isOpenContacts: false,
    });
  }

  onClosePhotoSlider = () => {
    this.setState({
      isOpenPhotoSlider: false,
    });
  };

  onOpenContacts(e) {
    const { similarObjects, id, realtyType } = this.props;
    e.preventDefault();
    this.setState({
      isOpenContacts: true,
    });
    if (window.yaCounter25608281) {
      window.yaCounter25608281.reachGoal('SHOW_CONTACTS_ALL');
    }
    ym('reachGoal', 'contacts');

    const similarObjectIds = [];
    if (similarObjects.loaded) {
      similarObjects.payload.items.map((item) => similarObjectIds.push(item.id));
    }

    const products = similarObjectIds.length
      ? [{ id: id.toString(), recommended_ids: similarObjectIds.join(',') }]
      : [{ id: id.toString() }];

    window.vkRetargeting('purchase', realtyType, {
      products,
    });

    hitContacts({
      realtyId: id,
      ...getNewFormat(realtyType),
    });
    try {
      this.props.handleSendViewContactsGA();
    } catch (e) {
      console.error('Ошибка в handleSendViewContactsGA', e)
    }
  }

  closePanorama(e) {
    e.preventDefault();
    this.setState({
      panoramaIsOpen: false,
    });
  }

  closeMap(e) {
    e.preventDefault();
    this.setState({
      mapIsOpen: false,
      mapLoaded: false,
    });
  }

  openPanorama(e) {
    e.preventDefault();
    this.setState({
      panoramaIsOpen: true,
      mapIsOpen: false,
    });
    ym('reachGoal', 'panorama');
  }

  openMap(e) {
    ym('reachGoal', 'show_map');
    e.preventDefault();
    this.setState({
      mapIsOpen: true,
      panoramaIsOpen: false,
    });
  }

  renderPrice() {
    const {
      price,
      priceSquare,
      deltaPrice,
      refPriceRub,
      selectedRegion,
      realtyType,
      id,
      priceAddition,
    } = this.props;
    const hrefToPriceHistory = [`/${selectedRegion.domain}`, realtyType, id, 'priceHistory'].join(
      '/'
    );
    const arrow =
      deltaPrice > 0 ? (
        <UpArrowIcon color="#94c133" size="20" stroke="1" />
      ) : (
        <DownArrowIcon color="#ff3232" size="20" stroke="1" />
      );

    if (price) {
      return (
        <span>
          {deltaPrice ? (
            <Link
              to={hrefToPriceHistory}
              style={{ textDecoration: 'none' }}
              onClick={() => ym('reachGoal', 'price-history')}
            >
              {arrow} <span className={styles.price}>{formatPrice(refPriceRub)}</span>
            </Link>
          ) : (
            <span className={styles.price}>{formatPrice(refPriceRub)}</span>
          )}

          {priceSquare > 0 && (
            <div className={styles.priceSquare}>
              {formatPrice(priceSquare)}/{isLandDacha(this.props) ? 'сот.' : 'м²'}
            </div>
          )}

          {!!priceAddition && <div className={styles.priceSquare}>{priceAddition}</div>}
        </span>
      );
    }
    if (deltaPrice && !price) {
      return (
        <Link
          to={hrefToPriceHistory}
          style={{ textDecoration: 'none' }}
          onClick={() => ym('reachGoal', 'price-history')}
        >
          {arrow}{' '}
          <span className={styles.price} style={{ fontSize: 22 }}>
            Цена договорная
          </span>
        </Link>
      );
    }

    return <span className={styles.price}>Цена договорная</span>;
  }

  renderMainInfo() {
    const {
      refUpdateDt,
      refInsertDt,
      floorTotal,
      floor,
      hits,
      refSquare,
      areaTotal,
      mortgage,
      dealTypeName,
      realtyTypeShortName,
      localityFias,
      refStreet,
      rcid,
      resComName,
      districtOkato,
      microdistrictMlsnName,
    } = this.props;
    const iconStyle = { color: '#888', fontSize: 18, marginRight: 7, position: 'relative', top: 4 };
    const dateUpdate = new Date(refUpdateDt * 1000).toLocaleDateString('ru-RU');
    const dateCreate = new Date(refInsertDt * 1000).toLocaleDateString('ru-RU');
    const floorProperty = [floor || '-', floorTotal || '-'].join('/');

    const viewCountIcon = hits ? (
      <FontIcon className="material-icons" style={iconStyle}>
        visibility
      </FontIcon>
    ) : (
      ''
    );

    const landArea = refSquare ? `${refSquare} сот.` : '';
    const houseArea = areaTotal ? `${areaTotal} м²` : '';
    const square = isLandDacha(this.props) ? landArea : houseArea;
    return (
      <div className={styles.mainInfo}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{this.renderPrice()}</div>
          <div style={{ fontSize: '14px', margin: '5px 0', color: '#666', fontWeight: 300 }}>
            {/* <div style={{ margin: '4px 0' }}>id {this.props.id}</div> */}
            <div style={{ textAlign: 'right' }}>
              {viewCountIcon}
              <span>{hits || ''}</span>
            </div>
          </div>
        </div>
        {!!mortgage && <MortgageLink {...mortgage} />}
        <p style={{ margin: '15px 0 0' }}>
          {[
            dealTypeName,
            realtyTypeShortName,
            square,
            floor && floorTotal ? `${floorProperty} эт.` : '',
          ]
            .filter((n) => n)
            .join(', ')}
        </p>
        <p style={{ margin: '5px 0 15px' }}>{[localityFias.trim(), refStreet.trim()].join(', ')}</p>
        {!!rcid && (
          <p>
            <RLink
              to={`complex/${rcid}`}
              style={{ color: '#8dc63f', textDecoration: 'none' }}
              onClick={ym('reachGoal', 'residential-complex')}
            >
              <FontIcon
                className="material-icons"
                style={{
                  color: '#8dc63f',
                  fontSize: 24,
                  marginRight: 4,
                  verticalAlign: 'text-bottom',
                }}
              >
                home
              </FontIcon>
              {`ЖК "${resComName}"`}
            </RLink>
          </p>
        )}
        <div style={{ float: 'left', marginBottom: '15px' }}>
          <p style={{ fontSize: '14px', margin: '5px 0', color: '#666', fontWeight: 300 }}>
            {districtOkato && `Район: ${districtOkato}`}
          </p>
          <p
            style={{
              fontSize: '14px',
              margin: '5px 0',
              color: '#666',
              fontWeight: 300,
              width: '240px',
            }}
          >
            {microdistrictMlsnName && `Микрорайон: ${microdistrictMlsnName}`}
          </p>
        </div>
        <div style={{ textAlign: 'right' }}>
          <a
            href="#"
            style={{ color: '#8dc63f', textDecoration: 'none' }}
            onClick={this.openMap.bind(this)}
          >
            <img src={mapIcon} width={45} alt="карта" />
          </a>
        </div>
        <div
          style={{
            clear: 'both',
            fontSize: '13px',
            color: '#666',
            fontWeight: 300,
            marginTop: '20px',
          }}
        >
          <div style={{ float: 'left' }}>Добавлено: {dateCreate}</div>
          <div style={{ textAlign: 'right' }}>Обновлено: {dateUpdate}</div>
        </div>
      </div>
    );
  }

  renderContactSeller() {
    const { statusId } = this.props;
    const { isOpenPhotoSlider, mapIsOpen, panoramaIsOpen, sticky } = this.state;

    if (statusId !== 0) {
      return null;
    }

    const adaptive = isOpenPhotoSlider || mapIsOpen || panoramaIsOpen ? styles.adaptive : null;

    return (
      <div
        style={{ position: sticky ? 'fixed' : 'relative' }}
        className={`${styles.sticky} ${adaptive}`}
      >
        <span
          role="button"
          onClick={this.onOpenContacts.bind(this)}
          className={styles.contactSellerBtn}
        >
          Контакты продавца
        </span>
      </div>
    );
  }

  renderInfoTable() {
    const { viewType } = this.props;
    switch (viewType) {
      case 'sales':
        return <SalesInfo {...this.props} />;
      case 'rents':
        return <RentsInfo {...this.props} />;
      case 'saleBusiness':
        return <BusinessSaleInfo {...this.props} />;
      case 'rentBusiness':
        return <BusinessRentInfo {...this.props} />;
      case 'lands':
        return <LandsInfo {...this.props} />;
      case 'garages':
        return <GaragesInfo {...this.props} />;
      default:
        return <SalesInfo {...this.props} />;
    }
  }

  renderMap() {
    const { lat, lon } = this.props;
    const map = (
      <div style={{ padding: '0 20px', position: 'relative' }}>
        <a href="" onClick={this.openMap.bind(this)}>
          <StaticMap lat={lat} lon={lon} zoom={16} />
        </a>
        <div style={{ position: 'absolute', top: 10, right: 25 }}>
          <FontIcon className="material-icons" color="#666" style={{ fontSize: 38 }}>
            zoom_in
          </FontIcon>
        </div>
      </div>
    );

    const noMap = (
      <div style={{ padding: '0 20px', position: 'relative', color: '#666' }}>
        Координаты объекта не определены
      </div>
    );

    return lat && lon ? map : noMap;
  }

  renderPager() {
    const { selectedRegion, realtyType, prevPropertyId, nextPropertyId } = this.props;

    if (!prevPropertyId && !nextPropertyId) {
      return null;
    }

    const hrefPrev = [`/${selectedRegion.domain}`, realtyType, prevPropertyId].join('/');
    const hrefNext = [`/${selectedRegion.domain}`, realtyType, nextPropertyId].join('/');

    return (
      <div className={styles.pager}>
        {prevPropertyId && (
          <Link
            to={hrefPrev}
            onClick={() => {
              ym('reachGoal', 'card-pager');
            }}
          >
            &lt; Предыдущее
          </Link>
        )}
        {nextPropertyId && (
          <Link
            to={hrefNext}
            onClick={() => {
              ym('reachGoal', 'card-pager');
            }}
          >
            Следующее &gt;
          </Link>
        )}
      </div>
    );
  }

  render() {
    const {
      realtyType,
      id,
      dealType,
      images,
      isSpecial,
      refComment,
      similarObjects,
      viewType,
      refStreet,
      lat,
      lon,
      eventAddFavorite,
      eventRemoveFavorite
    } = this.props;
    const { isOpenContacts, mapIsOpen, mapLoaded, panoramaIsOpen } = this.state;
    const modalStyles = {
      overlay: {
        background: 'rgba(0,0,0,0.9)',
        zIndex: 5,
      },
      content: {
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        padding: 0,
        border: 'none',
        borderRadius: 'none',
        background: 'transparent',
        overflowY: 'hidden',
      },
    };

    const modalStylesContact = { ...modalStyles };
    modalStylesContact.content.overflowY = 'none';
    modalStylesContact.overlay.background = '#fff';

    const FavoriteBtn = (
      <ButtonFavorite
        uid={`${realtyType}_${id}`}
        object={{
          type: !dealType ? 'old' : 'new',
          id,
          dealType: dealType || null,
          realtyType,
        }}
        alternativeView
        ymClick={() => ym('reachGoal', 'favorites_button_property_page')}
        style={buttonFavoriteStyles.buttonFavorite}
        iconStyle={buttonFavoriteStyles.buttonFavoriteIcon}
        iconSize="30"
        eventAddFavorite={eventAddFavorite}
        eventRemoveFavorite={eventRemoveFavorite}
      />
    );

    return (
      <div style={{ marginBottom: '12px' }}>
        {images.length > 0 && (
          <div className={styles.root}>
            <PhotoSlider
              photos={images}
              favoriteBtn={FavoriteBtn}
              onOpen={this.onOpenPhotoSlider}
              onClose={this.onClosePhotoSlider}
            />
          </div>
        )}

        {isSpecial !== 0 && (
          <div className={styles.premiumBadge}>
            <BadgeLabel.Premium />
          </div>
        )}

        {!images.length && (
          <div style={{ padding: 30, color: 'grey', textAlign: 'center', fontSize: 18 }}>
            Нет фото
          </div>
        )}

        {this.renderMainInfo()}
        <div style={{ height: 10 }} />

        <Panel title="Параметры" titleClassName={styles.panelTitle}>
          <div style={{ padding: '0 20px' }}>{this.renderInfoTable()}</div>
        </Panel>

        <Panel title="Описание" titleClassName={styles.panelTitle}>
          <div style={{ padding: '0 20px', fontWeight: 300, color: '#252525', fontSize: '16px' }}>
            <PreviewText text={refComment} />
          </div>
        </Panel>

        {this.renderPager()}

        {similarObjects.loaded && (
          <Panel
            title="Похожие объявления"
            titleClassName={styles.panelTitle}
            containerStyle={{ padding: '15px 0' }}
          >
            <SimilarObjects viewType={viewType} />
          </Panel>
        )}

        {!isOpenContacts && (
          <div ref="contactsBtn" style={{ height: 44, margin: '0 10px' }}>
            {this.renderContactSeller()}
          </div>
        )}
        <Modal isOpen={mapIsOpen} style={modalStyles}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 7,
              backgroundColor: 'rgba(0, 0, 0, 0.4);',
              height: 50,
            }}
          >
            <div style={{ left: 15, top: 15, position: 'fixed', color: 'white' }}>
              {refStreet.trim()}
            </div>
            <a href="#" style={{ textDecoration: 'none' }} onClick={this.openPanorama.bind(this)}>
              <div style={{ position: 'absolute', top: '65px', right: '20px' }}>
                <PanoramaIcon />
              </div>
            </a>
            <div style={{ right: 15, top: 12, position: 'fixed', color: 'white' }}>
              <a href="#" onClick={this.closeMap.bind(this)}>
                <FontIcon className="material-icons">close</FontIcon>
              </a>
            </div>
          </div>
          <Loader loaded={mapLoaded} color="white" />
          <div style={{ width: '100%', height: '100%' }}>
            <Map
              mapState={{
                center: [lat, lon],
                zoom: 16,
                controls: ['zoomControl'],
              }}
              onLoad={this.handleMapLoad}
            >
              <Placemark geometry={[lat, lon]} />
            </Map>
          </div>
        </Modal>

        <Modal isOpen={panoramaIsOpen} style={modalStyles}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 7,
              backgroundColor: 'rgba(0, 0, 0, 0.4);',
              height: 50,
            }}
          >
            <div style={{ left: 15, top: 15, position: 'fixed', color: 'white' }}>
              {refStreet.trim()}
            </div>
            <a href="#" style={{ textDecoration: 'none' }} onClick={this.openMap.bind(this)}>
              <div style={{ position: 'absolute', top: '65px', right: '20px' }}>
                <MapIcon />
              </div>
            </a>
            <div style={{ right: 15, top: 12, position: 'fixed', color: 'white' }}>
              <a href="#" onClick={this.closePanorama.bind(this)}>
                <FontIcon className="material-icons">close</FontIcon>
              </a>
            </div>
          </div>
          <div style={{ width: '100%', height: '100%' }}>
            {panoramaIsOpen && <YandexPanorama coordinates={[lat, lon]} />}
          </div>
        </Modal>

        <Modal isOpen={isOpenContacts} style={modalStylesContact}>
          <div style={{ position: 'relative', backgroundColor: '#fff', height: 50 }}>
            <div style={{ left: 15, top: 15, position: 'absolute', color: 'rgba(0, 0, 0, 0.87)' }}>
              {refStreet.trim()}
            </div>
            <div style={{ right: 15, top: 12, position: 'absolute' }}>
              <a href="#" onClick={this.onCloseContacts.bind(this)}>
                <FontIcon className="material-icons" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  close
                </FontIcon>
              </a>
            </div>
          </div>
          <div style={{ width: '100%', height: '100%', backgroundColor: '#FFF' }}>
            <ContactsView />
          </div>
        </Modal>
      </div>
    );
  }
}

PropertyView.defaultProps = {
  price: 0,
  priceSquare: 0,
  deltaPrice: null,
  refPriceRub: 0,
  refUpdateDt: 0,
  refInsertDt: 0,
  floorTotal: 0,
  floor: 0,
  hits: 0,
  refSquare: 0,
  areaTotal: 0,
  rcid: 0,
  mortgage: null,
  dealTypeName: '',
  realtyTypeShortName: '',
  localityFias: '',
  refStreet: '',
  resComName: '',
  districtOkato: '',
  microdistrictMlsnName: '',
  lat: 0,
  lon: 0,
  prevPropertyId: 0,
  nextPropertyId: 0,
  images: [],
  isSpecial: 0,
  refComment: '',
  microdistrictRating: null,
  priceAddition: null,
  id: null,
  realtyType: null,
  dealType: null,
  statusId: null,
};

PropertyView.propTypes = {
  similarObjects: PropTypes.shape({
    payload: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }).isRequired,
  id: PropTypes.number,
  realtyType: PropTypes.string,
  price: PropTypes.number,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  priceSquare: PropTypes.number,
  deltaPrice: PropTypes.number,
  refPriceRub: PropTypes.number,
  refUpdateDt: PropTypes.number,
  refInsertDt: PropTypes.number,
  floorTotal: PropTypes.number,
  floor: PropTypes.number,
  hits: PropTypes.number,
  refSquare: PropTypes.number,
  areaTotal: PropTypes.number,
  mortgage: PropTypes.objectOf(PropTypes.any),
  dealTypeName: PropTypes.string,
  realtyTypeShortName: PropTypes.string,
  localityFias: PropTypes.string,
  refStreet: PropTypes.string,
  rcid: PropTypes.number,
  resComName: PropTypes.string,
  districtOkato: PropTypes.string,
  microdistrictMlsnName: PropTypes.string,
  statusId: PropTypes.number,
  lat: PropTypes.number,
  lon: PropTypes.number,
  viewType: PropTypes.string.isRequired,
  prevPropertyId: PropTypes.number,
  nextPropertyId: PropTypes.number,
  dealType: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.any),
  isSpecial: PropTypes.number,
  priceAddition: PropTypes.string,
  refComment: PropTypes.string,
  microdistrictRating: PropTypes.objectOf(PropTypes.any),
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired,
};
