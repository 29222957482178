import 'whatwg-fetch';
import { filterToUrlParams } from '../utils';
import mappings from './mappings';
import config from '../config';

const API_ROOT = config.api_url.v2;
const GIS_API_ROOT = config.api_url.gis;
const options = {
  credentials: 'include',
  headers: {
    'X-MLSN-User-Agent': '323:320:1.0.0',
  },
};

export default {
  getComplex(id) {
    return fetch(`${API_ROOT}/resComplexes/${id}`).then((response) => response.json(), options);
  },

  getComplexHistory(id) {
    return fetch(`${API_ROOT}/resComplexes/${id}/history`, options).then((response) =>
      response.json()
    );
  },

  getGarageObject(id, lists = {}) {
    return fetch(`${API_ROOT}/sale/garages/${id}`, options)
      .then((response) => response.json())
      .then((json) => mappings.property.garages(json, lists));
  },

  getMortgage(realtyId, type) {
    return fetch(`${API_ROOT}/sale/${type}/${realtyId}/mortgage`, options).then((response) =>
      response.json()
    );
  },

  fetchPriceHistory(id, type, lists = {}) {
    const url = `${API_ROOT}/${type}/${id}`;
    return fetch(url, options)
      .then((response) => response.json())
      .then((json) => mappings.property.garages(json, lists));
  },
  fetchProperties(params, type) {
    const url = `${API_ROOT}/${type}?${filterToUrlParams(params)}`;
    return fetch(url, options).then((response) => response.json());
  },

  fetchGarageProperties(params, type, lists) {
    return this.fetchProperties(params, type).then((json) => {
      // eslint-disable-next-line no-param-reassign
      json.items = json.items.map((item) => mappings.property.garages(item, lists));
      return json;
    });
  },

  fetchDictionaries() {
    const url = `${API_ROOT}/dictionaries`;
    return fetch(url, options).then((response) => response.json());
  },

  fetchDictionariesV2() {
    const url = `${API_ROOT}/dictionaries?v=2`;
    return fetch(url, options).then((response) => response.json());
  },

  getUserObjects(object) {
    const { userId, dealType, realtyType, realtyStatus, ...params } = object;
    const url = `/users/${userId}/${dealType}/${realtyType}/${realtyStatus}?${filterToUrlParams(
      params
    )}`;
    return fetch(API_ROOT + url, options).then((response) => response.json());
  },

  userLogin(data) {
    return fetch(`${API_ROOT}/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
      body: `login=${data.login}&password=${data.password}`,
    })
      .then((response) => response.json())
      .catch(() => ({ error: 'Произошла неизвестная ошибка, попробуйте позже' }));
  },

  loadProfile(token) {
    return fetch(`${API_ROOT}/profile?token=${token}`, options).then((response) => response.json());
  },

  addToFavorites(object) {
    const { userId, dealType, realtyType, realtyId, token } = object;
    const url = `/users/${userId}/favorites/${dealType}/${realtyType}/${realtyId}?token=${token}`;
    return fetch(API_ROOT + url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => response.json());
  },

  removeFromFavorites(object) {
    const { userId, dealType, realtyType, realtyId, token } = object;
    const url = `/users/${userId}/favorites/${dealType}/${realtyType}/${realtyId}?token=${token}`;
    return fetch(API_ROOT + url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => response.json());
  },

  syncFavorites(object) {
    const { userId, favorites, token } = object;
    const url = `/users/${userId}/favorites/sync?token=${token}`;
    return fetch(API_ROOT + url, {
      method: 'PUT',
      credentials: 'include',
      body: `favorites=${encodeURI(JSON.stringify(favorites))}`,
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => response.json());
  },

  billing(object) {
    const { userId, data, token } = object;
    const url = `/users/${userId}/billing?token=${token}&${filterToUrlParams(data)}`;
    return fetch(API_ROOT + url, options).then((response) => response.json());
  },

  getObjects(favorites, lists) {
    const url = '/getObjects';
    return fetch(API_ROOT + url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
      body: `query=${encodeURI(JSON.stringify(favorites))}`,
    })
      .then((response) => response.json())
      .then((json) => json.items.map((item) => mappings.property.favorites(item, lists)));
  },

  bidObject(object) {
    const { id, dealType, realtyType, phone, comment } = object;
    const url = [API_ROOT, dealType, realtyType, id, 'bid'].join('/');

    const data = `phone=${phone}&comment=${comment}&captcha=1`;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
      body: encodeURI(data),
    }).then((response) => response.status === 201);
  },

  getSimilarObjects(path) {
    const url = [API_ROOT, path.dealType, path.realtyType, path.id, 'similarObjects'].join('/');
    return fetch(`${url}?per-page=10`, options).then((response) => response.json());
  },

  invoices(object) {
    const { userId, data, token } = object;
    const url = `/users/${userId}/invoices?token=${token}&${filterToUrlParams(data)}`;
    return fetch(API_ROOT + url, options).then((response) => response.json());
  },

  invoiceCreate(params) {
    const { userId, sum, token } = params;
    const url = `/users/${userId}/invoices?token=${token}`;

    return fetch(API_ROOT + url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
      body: encodeURI(`sum=${sum}`),
    });
  },

  hitContact(object) {
    const { dealType, realtyType, realtyId } = object;
    const url = `/${dealType}/${realtyType}/${realtyId}/hitContacts?page=card`;

    return fetch(API_ROOT + url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    });
  },

  // Сохраненный поиск
  getSavedSearchList(object) {
    const { userId, token, filterVersion } = object;
    const url = `/users/${userId}/filters?v=${filterVersion}&token=${token}`;
    return fetch(API_ROOT + url, options).then((response) => ({
      status: response.status,
      payload: response.json(),
    }));
  },

  removeSavedSearch(object) {
    const { userId, filterId, token } = object;
    const url = `/users/${userId}/filters/${filterId}?&token=${token}`;
    return fetch(API_ROOT + url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => ({ status: response.status }));
  },

  unsubscribeSavedSearch(object) {
    const { userId, filterId, token } = object;
    const url = `/users/${userId}/filters/${filterId}/unsubscribe?&token=${token}`;
    return fetch(API_ROOT + url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => ({ status: response.status }));
  },

  filtersAdd(object) {
    const { userId, data, token } = object;
    const url = `/users/${userId}/filters?token=${token}`;
    return fetch(API_ROOT + url, {
      method: 'POST',
      credentials: 'include',
      body: data,
      headers: {
        'X-MLSN-User-Agent': '323:320:1.0.0',
      },
    }).then((response) => {
      return { status: response.status, response: response.json() };
    });
  },

  getLocalityById(localityId) {
    const url = `/localities/${localityId}`;
    return fetch(GIS_API_ROOT + url, options).then((response) => response.json());
  },

  getUserTariff(object) {
    const { userId, token } = object;
    const url = `/users/${userId}/tariff?&token=${token}`;
    return fetch(API_ROOT + url, options).then((response) => {
      return response.json().then(data => ({ status: response.status, response: data }));
    });
  },

  getConfig(object) {
    const { subdomain } = object;
    const url = `/cian/redirect?domain=${subdomain}.mlsn.ru`;
    return fetch(API_ROOT + url, options).then((response) => {
      return response.json().then(data => ({ status: response.status, response: data }));
    });
  },
};
