const initialState = [
  {
    id: 1,
    name: 'Длительно',
    type: 'longTerm',
    dealType: 'rent',
  },
  {
    id: 2,
    name: 'Посуточно',
    type: 'daily',
    dealType: 'rentDaily',
  },
];

export default function intervals(state = initialState) {
  return state;
}
