import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { LeftNav, List, ListItem, ListItemText, ListItemIcon } from '../ui/material';
import Logo from '../Logo';
import LogoCian from '../LogoCian';
import CianBanner from '../CianBanner';
import UnionIcon from '../UnionIcon';
import styles from './MainMenu.css';
import { detectOS } from '../../utils';
import {
  AccountIcon,
  AddIcon,
  SearchIcon,
  FavoriteIcon,
  MapMarkerIcon,
  MySearchIcon,
  IpotekaIcon,
  PodborRieltoraIcon,
  OcenkaIcon,
  SurveyIcon,
} from './MainMenuIcons';
import { MenuDivider } from '../Divider/Divider';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';

class MainMenu extends React.Component {
  constructor(...props) {
    super(...props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(menuItem) {
    const { region, history, toggleMenu } = this.props;
    /* yandex metrika goals */
    switch (menuItem.route) {
      case `/${region}/user/login`:
      case `/${region}/user/account`:
        ym('reachGoal', 'dashboard_page_enter');
        break;
      case '/full-version':
        ym('reachGoal', 'full_version_menu_item');
        break;
      case `/${region}/create-announcement`:
        ym('reachGoal', 'new_announcement_mobile');
        break;
      case '/region/':
        ym('reachGoal', 'choose_region');
        break;
      case `/${region}/search`:
        ym('reachGoal', 'search');
        break;
      default:
        break;
    }

    if (menuItem.type === 'mobileLink') {
      ym('reachGoal', 'mobile');
      window.location.href = menuItem.payload;
    }
    if (menuItem.type === 'externalLink') {
      window.location.href = menuItem.payload;
    }

    history.push(menuItem.route);
    toggleMenu();
  }

  render() {
    const {
      favorites,
      user,
      region,
      regionName,
      isOpenMenu,
      toggleMenu,
      savedSearchTotalCount,
      handleOpenUnionCianModal,
    } = this.props;
    const favLabel = favorites ? `(${favorites})` : null;
    const savedSeatchCountLabel = savedSearchTotalCount ? `(${savedSearchTotalCount})` : null;
    const accountSection = !user.loaded
      ? {
          route: `/${region}/user/login`,
          text: 'Личный кабинет / Войти',
          Icon: AccountIcon,
        }
      : {
          route: `/${region}/user/account`,
          text: user.profile.fullName ? user.profile.fullName : 'Личный кабинет',
          Icon: AccountIcon,
          selectedText: !!user.profile.fullName,
        };
    const surveySection = region === 'omsk'
      ? {
          type: 'externalLink',
          payload: 'https://survey.cian.site/effectivesitesmlsn',
          text: 'Пройти опрос',
          Icon: SurveyIcon,
        }
      : null

    const menuItems = [
      accountSection,
      {
        route: `/${region}/create-announcement`,
        text: 'Добавить объявление',
        Icon: AddIcon,
      },
      {
        route: `/${region}/search`,
        text: 'Поиск объявлений',
        Icon: SearchIcon,
      },
      {
        route: '/favorites',
        text: ['Избранное', favLabel].join(' '),
        Icon: FavoriteIcon,
      },
      {
        route: '/saved-search',
        text: ['Мои поиски', savedSeatchCountLabel].join(' '),
        Icon: MySearchIcon,
      },
      {
        type: 'externalLink',
        payload: 'https://cian.ru/ipoteka-main/?utm_source=mlsn&utm_medium=redirect&utm_campaign=menu&platform=mobile',
        text: 'Ипотека',
        Icon: IpotekaIcon,
        balloonText: 'Циан',
      },
      {
        type: 'externalLink',
        payload: 'https://www.cian.ru/kalkulator-nedvizhimosti/?utm_source=mlsn&utm_medium=redirect&utm_campaign=menu&platform=mobile',
        text: 'Оценка',
        Icon: OcenkaIcon,
        balloonText: 'Циан',
      },
      {
        type: 'externalLink',
        payload: 'https://cian.ru/application-form/?utm_source=mlsn&utm_medium=redirect&utm_campaign=menu&platform=mobile',
        text: 'Подбор риелтора',
        Icon: PodborRieltoraIcon,
        balloonText: 'Циан',
      },
      // {
      //   type: 'mobileLink',
      //   payload:
      //     detectOS() === 'Android'
      //       ? 'https://play.google.com/store/apps/details?id=ru.mlsn.realty'
      //       : 'https://itunes.apple.com/ru/app/mlsn.ru/id1039815631',
      //   text: 'Мобильное приложение',
      // },
      {
        route: '/full-version',
        text: 'Полная версия сайта',
      },
      {
        type: 'mobileLink',
        payload: 'mailto:info@mlsn.ru',
        text: 'Обратная связь',
      },
      {
        route: '/region/',
        text: regionName || 'Выбор региона',
        Icon: MapMarkerIcon,
        selectedText: !!regionName,
      },
    ].filter((x) => x);

    return (
      <LeftNav
        anchor="left"
        open={isOpenMenu}
        onClose={toggleMenu}
        onOpen={toggleMenu}
        className={styles.root}
        disableSwipeToOpen
      >
        <div className={styles.logo}>
          <Link to={`/${region}/search`} onClick={() => toggleMenu()} className={styles.titleLogo}>
            <Logo color style={{ height: '25px' }} />
          </Link>
          <div onClick={() => handleOpenUnionCianModal()}>
            <UnionIcon whiteIcon style={{ margin: '8px 0px 11px 8px', height: '9px' }} />
            <LogoCian whiteIcon style={{ margin: '0px 5px 5px', height: '31px' }} />
          </div>
        </div>
        <div className={styles.logoText}>
          Мы объединились!
        </div>
        <CianBanner handleOnClick={handleOpenUnionCianModal} />
        <List>
          {menuItems.map((Item, index) => (
            <Fragment key={Item.text}>
              <ListItem button onClick={() => this.handleChange(Item)}>
                {Item.Icon ? (
                  <ListItemIcon>
                    <Item.Icon />
                  </ListItemIcon>
                ) : null}
                <ListItemText
                  disableTypography
                  primary={Item.text}
                  classes={{ root: Item.selectedText ? styles.selectedText : styles.commonListItemText }}
                />
                {Item.balloonText ? (
                  <ListItemText
                    disableTypography
                    primary={Item.balloonText}
                    classes={{ root: styles.balloonText }}
                  />
                ) : null}
              </ListItem>
              {index === 0 ? <MenuDivider /> : null}
            </Fragment>
          ))}
        </List>
      </LeftNav>
    );
  }
}

MainMenu.defaultProps = {
  favorites: 0,
  regionName: 'Выбор региона',
};

MainMenu.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  regionName: PropTypes.string,
  savedSearchTotalCount: PropTypes.string,
  region: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: userFromPropTypes.isRequired,
  favorites: PropTypes.number,
  handleOpenUnionCianModal: PropTypes.func.isRequired,
};

export default withRouter(MainMenu);
