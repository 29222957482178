import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import serialize from 'form-serialize';
import RLink from '../RLink';
import { TextField } from '../ui';
import SubmitButton from '../SubmitButton';
import FormRow from '../SearchForm/FormRow';
import cn from './UserRegisterRestore.css';
import {
  restoreSMS as actionRestoreSMS,
  restorePassword as actionRestorePassword,
} from '../../actions';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';

class UserRestore extends React.Component {
  state = {
    timer: 0,
    redirectTimer: 5,
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.redirectTimer);
  }

  handleSubmit(e) {
    const { dispatch, restorePassword } = this.props;
    e.preventDefault();
    const formdata = serialize(ReactDOM.findDOMNode(this.form));

    dispatch(actionRestorePassword(formdata)).then(() => {
      if (restorePassword.status === true) {
        this.timerToRedirect();
      }
    });
  }

  handleSMS = (e) => {
    const { dispatch, restoreSMS } = this.props;
    e.preventDefault();
    const formdata = serialize(ReactDOM.findDOMNode(this.form), { hash: true });
    dispatch(actionRestoreSMS(formdata.phone)).then(() => {
      if (restoreSMS.loaded && !restoreSMS.error) {
        this.runTimer();
      }
    });
  };

  getError(field) {
    const {
      restorePassword: { errors },
    } = this.props;
    if (errors[field]) {
      return errors[field][0];
    }
    return null;
  }

  timerToRedirect() {
    const { selectedRegion, history } = this.props;
    const { redirectTimer } = this.state;
    this.redirectTimer = setInterval(() => {
      this.setState({ redirectTimer: redirectTimer - 1 });
      if (redirectTimer === 0) {
        history.push(`/${selectedRegion.domain}/user/login`);
        clearInterval(this.redirectTimer);
      }
    }, 1000);
  }

  runTimer() {
    const { timer } = this.state;
    this.setState({
      timer: 30,
    });
    this.timer = setInterval(() => {
      if (timer > 0) {
        this.setState({
          timer: timer - 1,
        });
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  render() {
    const { restoreSMS, restorePassword } = this.props;
    const { timer, redirectTimer } = this.state;

    return restorePassword.status === true ? (
      <div className={cn.restore}>
        <div className={cn.titleSuccess}>
          Пароль успешно изменён!
          <br />
          Через 5 секунд вы будете перенаправлены на форму входа
        </div>
        <div className={cn.redirectTimer}>{redirectTimer}</div>
        <RLink to="user/login" className={cn.button}>
          <span>Войти</span>
        </RLink>
      </div>
    ) : (
      <div className={cn.restore}>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          ref={(n) => {
            this.form = n;
          }}
          action=""
        >
          <input type="hidden" name="userId" value={restoreSMS.userId} />
          <div className={cn.phoneBlock}>
            <div className={cn.phone}>
              <div className={cn.phoneLabel}>
                <span>+7</span>
              </div>
              <div className={cn.phoneField}>
                <TextField
                  placeholder="Телефон"
                  name="phone"
                  type="text"
                  isError={
                    (restoreSMS.error ? restoreSMS.error.phone[0] : false) || this.getError('phone')
                  }
                  errorText={
                    (restoreSMS.error ? restoreSMS.error.phone[0] : false) || this.getError('phone')
                  }
                />
              </div>
            </div>
            <div style={{ position: 'relative', minHeight: 40, marginBottom: 15 }}>
              <Loader loaded={!restoreSMS.loading}>
                <button
                  className={cn.smsButton}
                  onClick={this.handleSMS}
                  disabled={!!timer}
                  type="button"
                >
                  {restoreSMS.loaded && !restoreSMS.error ? (
                    <span>Отправить код повторно{timer ? ` (${timer})` : ''}</span>
                  ) : (
                    <span>Отправить код подтверждения</span>
                  )}
                </button>
              </Loader>
            </div>
          </div>
          <FormRow>
            <TextField
              placeholder="Код из СМС"
              name="code"
              type="text"
              isError={this.getError('userId')} // @BUG in API v1
              errorText={this.getError('userId')} // @BUG in API v1
            />
          </FormRow>
          <FormRow>
            <TextField
              placeholder="Новый пароль"
              name="password"
              type="password"
              isError={this.getError('password')}
              errorText={this.getError('password')}
            />
          </FormRow>
          <FormRow>
            <TextField
              placeholder="Повторите пароль"
              name="repeatPassword"
              type="password"
              isError={this.getError('repeatPassword')}
              errorText="Пароли не совпадают"
            />
          </FormRow>
          <div style={{ position: 'relative', minHeight: '57px' }}>
            <Loader loaded={!restorePassword.loading}>
              <SubmitButton value="Восстановить пароль" />
            </Loader>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
    restorePassword: state.restorePassword,
    restoreSMS: state.restoreSMS,
  };
}

UserRestore.defaultProps = {
  restorePassword: {},
  restoreSMS: {},
};

UserRestore.propTypes = {
  dispatch: PropTypes.func.isRequired,
  restorePassword: PropTypes.shape({
    status: PropTypes.number,
    errors: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
  }),
  restoreSMS: PropTypes.shape({
    userId: PropTypes.number,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(UserRestore);
