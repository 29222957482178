import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { hideModal } from '../../../actions';
import { FontIcon } from '../../ui/material';
import styles from './Modals.css';
import StyledDialog from '../ModalWithStyles';
import { StyledDialogContent } from '../DialogContentWithStyles';
import UnionCianBanner from '../../ui/assets/union_cian_banner.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UnionCianModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      timer: props.timer,
    };
  }

  componentDidMount() {
    const { timer, redirectCianUri } = this.props;
    if (timer !== null) {
      this.timer = setInterval(() => {
        this.setState((prevState) => ({ timer: prevState.timer - 1 }));
        if (this.state.timer === 0) {
          clearInterval(this.timer);
          let searchParams = new URLSearchParams();
          searchParams.set('utm_source', 'mlsn');
          searchParams.set('utm_medium', 'redirect');
          searchParams.set('utm_campaign', 'popup');
          searchParams.set('platform', 'mobile');
          window.open(`${redirectCianUri}?${searchParams.toString()}`) ||
          window.location.replace(`${redirectCianUri}?${searchParams.toString()}`);
        }
      }, 1e3);
    }
  }

  handleClickOpenLink = () => {
    const { dispatch, redirectCianUri } = this.props;
    window.location.href = `${redirectCianUri}?utm_source=mlsn&utm_medium=redirect&utm_campaign=popup&platform=mobile`;
    dispatch(hideModal());
  };

  handleClickCancel = () => {
    const { dispatch } = this.props;
    clearInterval(this.timer);
    dispatch(hideModal());
  };

  render() {
    const {
      dispatch,
      title,
      description,
      header,
    } = this.props;
    const {
      timer
    } = this.state;
    return (
      <div>
        <StyledDialog
          open={true}
          onClose={() => dispatch(hideModal())}
          TransitionComponent={Transition}
        >
          <div className={styles.header}>
          <div className={styles.headerName}>{title}</div>
            <div className={styles.headerClose}>
              <FontIcon className="material-icons" onClick={() => dispatch(hideModal())} style={{color: 'black', fontSize: '19px'}}>close</FontIcon>
            </div>
          </div>
          <StyledDialogContent>
            <div className={styles.unionModalBody}>
              <img className={styles.unionBanner} src={UnionCianBanner} />
              <div className={styles.unionHeaderContent}>
                {header}
              </div>
              <div className={styles.unionDescription}>
                {description}
              </div>
            </div>
          </StyledDialogContent>
            <button className={styles.saveButton} onClick={() => this.handleClickOpenLink()}>
              Перейти на Циан
            </button>
            {timer !== null && <div className={styles.timerDescription}>Автопереход через {timer} сек</div>}
            <button className={styles.orangeSecondSaveButton} onClick={() => this.handleClickCancel()}>
              Остаться на МЛСН
            </button>
        </StyledDialog>
      </div>
    )
  }
}

UnionCianModal.propTypes = {
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  timer: PropTypes.number,
  description: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  redirectCianUri: PropTypes.string.isRequired,

  selectedRegion: PropTypes.object.isRequired,
};

UnionCianModal.defaultProps = {
  timer: 20,
  description: '',
  header: '',
  redirectCianUri: 'https://cian.ru/',
};

export default connect(
  (state, ownProps) => ({
    title: ownProps.title,
    history: ownProps.history,

    selectedRegion: state.selectedRegion,
    filterType: state.filterType,
    salesFilter: state.salesFilter,
    rentsFilter: state.rentsFilter,
    selectedLocality: state.selectedLocality,
    redirectCianUri: state.appConfig.redirectCianUri,
  })
)(UnionCianModal)