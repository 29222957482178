import PropTypes from 'prop-types';
import React from 'react';
import styles from './PropertyView.css';

export default class RentsInfo extends React.Component {
  renderArea(area) {
    return area ? (
      <span>
        {area} м<sup>2</sup>
      </span>
    ) : (
      this.renderNotFilled()
    );
  }

  renderNotFilled = () => {
    return <span style={{ color: '#bbb' }}>&mdash;</span>;
  };

  renderRow(param) {
    let value = '';
    switch (param.value) {
      case 0:
        value = this.renderNotFilled();
        break;
      case 1:
        value = 'есть';
        break;
      case 2:
        value = 'нет';
        break;
      default:
        value = param.value || this.renderNotFilled();
    }

    return (
      <tr key={param.name}>
        <td className={styles.paramName}>{param.name}</td>
        <td className={styles.paramValue}>{value}</td>
      </tr>
    );
  }

  render() {
    const {
      floor,
      floorTotal,
      areaTotal,
      areaLiving,
      areaKitchen,
      layout,
      balcony,
      leaseId,
      furniture,
      fridge,
      tv,
      washing,
      prepayment,
    } = this.props;
    const lease = {
      2: 'от 1 года и более',
      3: 'любой срок',
      6: 'на короткий срок',
    };
    const params = [
      { name: 'Этаж', value: [floor, floorTotal].join('/') },
      { name: 'Общая площадь', value: this.renderArea(areaTotal) },
      { name: 'Жилая площадь', value: this.renderArea(areaLiving) },
      { name: 'Площадь кухни', value: this.renderArea(areaKitchen) },
      { name: 'Планировка', value: layout },
      { name: 'Балкон/лоджия', value: balcony },
      {
        name: 'Срок аренды',
        value: lease[leaseId] ? lease[leaseId] : 'любой срок',
      },
      { name: 'Мебель', value: furniture },
      { name: 'Холодильник', value: fridge },
      { name: 'Телевизор', value: tv },
      { name: 'Стир. машина', value: washing },
      { name: 'Предоплата', value: prepayment },
    ];

    return (
      <table className={styles.infoTable}>
        {params.filter((param) => param.value).map((param) => this.renderRow(param))}
      </table>
    );
  }
}

RentsInfo.defaultProps = {
  layout: null,
  floor: null,
  floorTotal: null,
  areaTotal: null,
  areaLiving: null,
  areaKitchen: null,
  prepayment: null,
  balcony: null,
  leaseId: null,
  furniture: null,
  fridge: null,
  tv: null,
  washing: null,
};

RentsInfo.propTypes = {
  floor: PropTypes.number,
  floorTotal: PropTypes.number,
  areaTotal: PropTypes.number,
  areaLiving: PropTypes.number,
  areaKitchen: PropTypes.number,
  layout: PropTypes.number,
  balcony: PropTypes.number,
  leaseId: PropTypes.number,
  furniture: PropTypes.string,
  fridge: PropTypes.number,
  tv: PropTypes.number,
  washing: PropTypes.number,
  prepayment: PropTypes.number,
};
