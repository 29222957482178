/* eslint-disable no-restricted-globals */
import { values, camelCase, find, result } from 'lodash';

export function nameById(list, id) {
  return result(find(list, { id }), 'name');
}

export function shortNameById(list, id) {
  return result(find(list, { id }), 'shortName');
}

export function toNumberIfNumber(value) {
  return isNaN(value) || value === null || Array.isArray(value) || value === ''
    ? value
    : Number(value);
}

export function camelCaseKeys(object) {
  const selfResult = {};
  Object.keys(object).forEach((key) => {
    selfResult[camelCase(key)] = toNumberIfNumber(object[key]);
  });
  return selfResult;
}

export function convert(objects) {
  return values(objects).map(camelCaseKeys);
}

export function toArray(object) {
  return Object.keys(object).map((key) => {
    return { id: toNumberIfNumber(key), name: object[key] };
  });
}

export function filterDisabled(object) {
  return Number(object.enabled) === 1;
}
