import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { UserInvoices } from '../../components/User';
import { setCurrentPageType } from '../../actions';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';

class _InvoicesPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('userInvoices'));
  }

  render() {
    const { user } = this.props;
    if (user.profile.legalFormId !== 372) {
      return null;
    }

    return (
      <Loader loaded={!user.loading}>
        <UserInvoices />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

_InvoicesPage.propTypes = {
  user: userFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_InvoicesPage);
