import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { UserProfile } from '../../components/User';
import { setCurrentPageType } from '../../actions';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';

class _ProfilePage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('user'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        <UserProfile user={user} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

_ProfilePage.propTypes = {
  user: userFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_ProfilePage);
