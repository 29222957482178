import React from 'react';
import RLink from '../components/RLink';

const NotFoundPage = () => {
  return (
    <div style={{ padding: '30px 10px', textAlign: 'center' }}>
      Страница не найдена
      <div>
        <p>
          <RLink to="search">Перейти на главную</RLink>
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
