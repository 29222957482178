/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import Loader from 'react-loader';
import ym from 'react-yandex-metrika';
import TabToggle from '../components/ui/TabToggle';
import TabsToggle from '../components/ui/TabsToggle';
import PropertyList from '../components/PropertyList';
import ListView from '../components/ListView';
import {
  clearFavorites,
  setSortingSection,
  setCurrentPageType,
  loadFavoriteProperties,
} from '../actions';
import TabPanel from '../components/ui/TabPanel';

import {
  selectedRegion as selectedRegionFromPropTypes
} from '../lib/PropTypeValues';

import {
  gtagPushEventNewPage,
  getCianDealType,
  getExtraCategory
} from '../classes/analytics/gtagUtils';
import GtagBuilderV2 from '../classes/analytics/gtagEventBuilderV2';

const styles = {
  info: {
    padding: '10px 20px',
    marginBottom: 2,
  },
  notFound: {
    textAlign: 'center',
    padding: 20,
  },
};

class _FavoritesPage extends Component {
  state = {
    selectedSection: 0,
    sale: {
      favorites: [],
      page: 1,
    },
    rent: {
      favorites: [],
      page: 1,
    },
  };

  componentDidMount() {
    const { dispatch } = this.props;
    ym('reachGoal', 'favorites_page');
    dispatch(setCurrentPageType('favorites'));
    dispatch(setSortingSection(''));
    dispatch(clearFavorites());
    this.initFavorites();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setSortingSection(''));
  }

  getFavoriteSales() {
    const { favorites } = this.props;
    return favorites
      .filter(
        (item) =>
          item &&
          includes(
            ['sale_apartments', 'sale_lands', 'sale_commercials', 'sale_garages'],
            item.section
          )
      )
      .map((item) => ({
        id: item.id,
        section: item.section,
      }));
  }

  getFavoriteRents() {
    const { favorites } = this.props;
    return favorites
      .filter(
        (item) =>
          item &&
          includes(['rent_apartments', 'rentDaily_apartments', 'rent_commercials'], item.section)
      )
      .map((item) => ({
        id: item.id,
        section: item.section,
      }));
  }

  isLoadMoreFavorites = (section) => {
    const perPage = 10;
    // eslint-disable-next-line react/destructuring-assignment
    const { page, favorites } = this.state[section];
    const position = (page - 1) * perPage;
    return favorites.length > position;
  };

  loadFavorites = (section) => {
    const { dispatch } = this.props;
    if (!this.isLoadMoreFavorites(section)) {
      return;
    }
    const perPage = 10;
    // eslint-disable-next-line react/destructuring-assignment
    const { page, favorites } = this.state[section];
    const position = (page - 1) * perPage;
    const chunk = favorites.slice(position, position + perPage);
    dispatch(loadFavoriteProperties(section, chunk))
    this.setState({
      [section]: {
        ...this.state[section],
        page: page + 1,
      },
    });
    try {
      this.handleSendGA();
    } catch (e) {
      console.error('Ошибка в handleSendGA', e);
    }
  };

  initFavorites = () => {
    const { sale, rent } = this.state;
    this.setState(
      {
        sale: {
          ...sale,
          favorites: this.getFavoriteSales(),
        },
        rent: {
          ...rent,
          favorites: this.getFavoriteRents(),
        },
      },
      () => {
        this.loadFavorites('sale');
        this.loadFavorites('rent');
      }
    );
  };

  handleSelectSection = (event, value) => {
    this.setState({
      selectedSection: value,
    });
  };

  handleSendGA = () => {
    const {
      user,
      selectedViewType,
      selectedRegion,
      favoriteProperties,
    } = this.props;
    const { selectedSection } = this.state;
    const { page } = this.state[selectedSection === 0 ? 'sale' : 'rent'];
    const salesCount = this.favoriteSalesCount();
    const rentCount = this.favoriteRentsCount();
    const cianGtagEvent =  new GtagBuilderV2({})
      .setEvent('newpage')
      .setEventCategory('page')
      .setEventAction('newpage')
      .setEventLabel('open')
      .setUser(user)
      .setPage({
        pageType: 'ListingFavorites',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        sortType: 'default',
        offersQty: selectedSection === 0 ? Number(salesCount) : Number(rentCount),
        pageNumber: Number(page)
      })
      .setProducts(favoriteProperties[selectedSection === 0 ? 'sale' : 'rent'].items)
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleSendClickAnnouncementGA = (announcement) => {
    const {
      user,
      selectedViewType,
      selectedRegion,
    } = this.props;
    const { selectedSection } = this.state;
    const { page } = this.state[selectedSection === 0 ? 'sale' : 'rent'];
    const salesCount = this.favoriteSalesCount();
    const rentCount = this.favoriteRentsCount();
    const cianGtagEvent =  new GtagBuilderV2({})
      .setEvent('oldevent')
      .setEventCategory('Listing')
      .setEventAction('to_card')
      .setEventLabel('from_listing')
      .setUser(user)
      .setPage({
        pageType: 'ListingFavorites',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        sortType: 'default',
        offersQty: selectedSection === 0 ? Number(salesCount) : Number(rentCount),
        pageNumber: Number(page),
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }


  sortItems(items) {
    const { selectedSorting } = this.props;
    if (!selectedSorting) {
      return items;
    }

    let sortField;
    const desc = selectedSorting.indexOf('.desc') !== -1;

    switch (selectedSorting) {
      case 'update_dt':
      case 'update_dt.desc':
        sortField = 'updated';
        break;
      case 'full_price':
      case 'full_price.desc':
        sortField = 'price';
        break;
      case 'price':
      case 'price.desc':
        sortField = 'pricePerSquareMeter';
        break;
      case 'sq_total':
      case 'sq_total.desc':
        sortField = 'squareTotal';
        break;
      default:
        break;
    }

    if (desc) {
      return [...items].sort((a, b) => b[sortField] - a[sortField]);
    }
    return [...items].sort((a, b) => a[sortField] - b[sortField]);
  }

  favoriteSalesCount() {
    const { sale } = this.state;
    return sale.favorites.length || 0;
  }

  favoriteSales() {
    const { favoriteProperties } = this.props;
    return favoriteProperties.sale.items || [];
  }

  favoriteRentsCount() {
    const { rent } = this.state;
    return rent.favorites.length || 0;
  }

  favoriteRents() {
    const { favoriteProperties } = this.props;
    return favoriteProperties.rent.items || [];
  }

  canLoadMoreFavorites(section) {
    const { favoriteProperties } = this.props;
    if (!this.isLoadMoreFavorites(section)) {
      return false;
    }

    const { page } = this.state[section];
    const loadedPage = favoriteProperties[section].page;

    return page === loadedPage;
  }

  renderFavoriteItems(items, section) {
    const { selectedViewType } = this.props;
    if (!items.length) {
      return <div style={styles.notFound}>Вы еще не добавляли объекты в избранное</div>;
    }

    return selectedViewType === 'list' ? (
      <ListView
        items={items}
        topHeight={101}
        loading={!this.canLoadMoreFavorites(section)}
        canLoadMore={this.isLoadMoreFavorites(section)}
        onLoad={() => this.loadFavorites(section)}
        additionalHeight={0}
        eventClickAnnouncement={this.handleSendClickAnnouncementGA}
      />
    ) : (
      <PropertyList
        objects={items}
        topHeight={101}
        loading={!this.canLoadMoreFavorites(section)}
        canLoadMore={this.isLoadMoreFavorites(section)}
        onLoad={() => this.loadFavorites(section)}
        additionalHeight={0}
        eventClickAnnouncement={this.handleSendClickAnnouncementGA}
      />
    );
  }

  render() {
    const { selectedSection } = this.state;
    const salesCount = this.favoriteSalesCount();
    const rentCount = this.favoriteRentsCount();

    const saleItems = this.favoriteSales();
    const rentItems = this.favoriteRents();
    return (
      <>
        <TabsToggle
          variant="fullWidth"
          onChange={this.handleSelectSection}
          value={selectedSection}
          style={{ margin: '0 5px 10px' }}
        >
          <TabToggle label={`Продажа - ${salesCount}`} />
          <TabToggle label={`Аренда - ${rentCount}`} />
        </TabsToggle>
        <TabPanel value={selectedSection} index={0}>
          <div style={{ minHeight: 80 }}>
            <Loader loaded={!salesCount || !!saleItems[0]} zIndex={1}>
              {this.renderFavoriteItems(saleItems, 'sale')}
            </Loader>
          </div>
        </TabPanel>
        <TabPanel value={selectedSection} index={1}>
          <div style={{ minHeight: 80 }}>
            <Loader loaded={!rentCount || !!rentItems[0]} zIndex={1}>
              {this.renderFavoriteItems(rentItems, 'rent')}
            </Loader>
          </div>
        </TabPanel>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedViewType: state.selectedViewType,
    currentPageType: state.currentPageType,
    selectedSorting: state.selectedSorting,
    favorites: state.favorites,
    favoriteProperties: state.favoriteProperties,
    selectedRegion: state.selectedRegion,
    user: state.user,
  };
}

_FavoritesPage.defaultProps = {
  favorites: [],
  selectedSorting: '',
  favoriteProperties: {},
};

_FavoritesPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.object),
  favoriteProperties: PropTypes.objectOf(PropTypes.any),
  selectedSorting: PropTypes.string,
  selectedViewType: PropTypes.string.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  user: PropTypes.objectOf({
    token: PropTypes.string,
    profile: PropTypes.objectOf(PropTypes.any),
  }),
};

export default connect(mapStateToProps)(_FavoritesPage);
