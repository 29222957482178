// copied from mlsn-ui/panorama
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Loader from 'react-loader';
import cn from './YandexPanorama.css';

function loadScript(url, onLoad) {
  const head = document.querySelector('head');
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  if (onLoad) {
    script.onload = onLoad;
  }
  head.appendChild(script);
}

export default class YandexPanorama extends Component {
  state = {
    loadFailed: false,
  };

  componentDidMount() {
    window.initPanorama = this.initPanorama.bind(this);
    if (!window.ymaps) {
      loadScript(
        'https://api-maps.yandex.ru/2.1/?lang=ru_RU&onload=initPanorama&mode=debug&load=panorama.Player,panorama.locate'
      );
    } else {
      this.initPanorama();
    }
  }

  initPanorama() {
    const { coordinates } = this.props;
    delete window.initPanorama;

    const panorama = findDOMNode(this);
    this.ymaps = window.ymaps;

    this.ymaps.modules.require(['panorama.Player', 'panorama.locate'], (Player, locate) => {
      this.ymaps.panorama = { Player, locate };
      const locateRequest = this.ymaps.panorama.locate(coordinates);
      locateRequest.then((panoramas) => {
        if (panoramas.length) {
          // eslint-disable-next-line no-unused-vars
          const player = new this.ymaps.panorama.Player(panorama, panoramas[0], {
            controls: ['zoomControl'],
            suppressMapOpenBlock: true,
          });
        } else {
          this.setState({ loadFailed: true });
        }
      });
    });
  }

  render() {
    const { loadFailed } = this.state;
    return (
      <div className={cn.base}>
        {!loadFailed && <Loader zIndex={0} color="white" />}
        {loadFailed && <div className={cn.body}>Неудалось загрузить панораму</div>}
      </div>
    );
  }
}

YandexPanorama.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.any).isRequired,
};

YandexPanorama.defaultProps = {};
