import GtagEvent from './gtagEvent';
import {
  getCianObjectType,
  getCustomUrlCian,
  getExtraCategory,
  getParentId,
  getCianDealType,
} from './gtagUtils';
import { getNewFormat } from '../../utils'

class GtagEventBuilder extends GtagEvent {
  setEvent(event) {
    this.event = event;
    return this;
  }
  setEventCategory(eventCategory) {
    this.eventCategory = eventCategory;
    return this;
  }
  setEventAction(eventAction) {
    this.eventAction = eventAction;
    return this;
  }
  setEventLabel(eventLabel) {
    this.eventLabel = eventLabel;
    return this;
  }
  setUser(user) {
    if (user.token !== null && user.token !== 'undefined' && user.profile && user.profile.id) {
      this.user = {
        userId: user.profile && user.profile.id,
        type: user.profile && user.profile.isRealtor ? 'realtor' : 'owner'
      };
      return this;
    }
    this.user = { type: 'not_authorized' };
    return this;
  }
  setPage(options) {
    this.page = {
      ...options
    };
    return this;
  }
  setProducts(products) {
    if (!products.length) {
      this.products = [];
      return this;
    }

    this.products = products.map(item => {
      const isDailyType = item.period == 2;
      const realtyType = isDailyType ? 'rentDaily' : item.realtyType;
      return {
        id: item.id,
        brand: item.userId === null || item.userId === undefined ? null :
         `${item.userId}/${item.userType === 0 ? 'realtor' : 'owner'}`,
        dealType: getCianDealType(getNewFormat(realtyType).dealType),
        objectType: getCianObjectType(getNewFormat(realtyType).realtyType, Number(item.refType)),
        category: `${getCianDealType(getNewFormat(realtyType).dealType)}/${getCianObjectType(getNewFormat(realtyType).realtyType,
          Number(item.refType))}`,
        region: item.regionName.replace(/\.$/, ''),
        owner: item.userType === 3,
        parentId: getParentId(item),
        customURL: getCustomUrlCian(item),
        extra: {
          category: getExtraCategory(getNewFormat(realtyType).dealType,
            getNewFormat(realtyType).realtyType, Number(item.refType))
        }
      };
    });
    return this;
  }
  getEvent() {
    if (!this.event) {
      throw new Error(`${this.constructor.name} получил некорректное значение event`);
    } else if (!this.eventCategory) {
      throw new Error(`${this.constructor.name} получил некорректное значение eventCategory`);
    } else if (!this.eventAction) {
      throw new Error(`${this.constructor.name} получил некорректное значение eventAction`);
    } else if (!this.eventLabel) {
      throw new Error(`${this.constructor.name} получил некорректное значение eventLabel`);
    } else if (!this.user.type) {
      throw new Error(`${this.constructor.name} получил некорректный объект user`);
    } else if (!this.page.pageType && !this.page.siteType) {
      throw new Error(`${this.constructor.name} получил некорректный объект page`);
    }
    const obj = new GtagEventBuilder(this);
    return Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  }
}
export default GtagEventBuilder;
