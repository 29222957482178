import PropTypes from 'prop-types';
import React from 'react';

const sectionStyle = {
  minHeight: 350,
  // paddingTop: '56px',
};

const MainSection = ({ style, children }) => {
  const propsStyle = style;
  return <div style={{ ...propsStyle, ...sectionStyle }}>{children}</div>;
};

MainSection.defaultProps = {
  style: {},
};

MainSection.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

export default MainSection;
