import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RangeField } from '../ui';
import ModalButton from '../ui/ModalButton';
import { FormControlLabel } from '../ui/material';
import Switch from '../ui/Switch';
import PropertyTypeSelect from '../PropertyTypeSelect';
import TabsSelect from '../TabsSelect/TabsSelect';
import FormPaper from './FormPaper/FormPaper';
import { SearchDivider } from '../Divider/Divider';
import { deadlinesBuilding, authorFilter, authorItems } from '../../utils';
import apiMappings from '../../api/mappings/index';
import {
  lists as listsFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  realty as realtyFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../lib/PropTypeValues';

export default class SaleFields extends Component {
  handleSelectDistrict() {
    const { onSelectDistrict } = this.props;
    onSelectDistrict(this.refs.district.getValue());
  }

  mapSaleTypes = (item) => {
    const mappings = {
      '1-комн.': { sort: 1, displayName: '1', ...item },
      '2-комн.': { sort: 2, displayName: '2', ...item },
      '3-комн.': { sort: 3, displayName: '3', ...item },
      '4-комн.': { sort: 4, displayName: '4', ...item },
      '5+ комн.': { sort: 5, displayName: '5+', ...item },
      'комн.': { sort: 6, displayName: 'Комната', ...item },
      доля: { sort: 7, displayName: 'Доля в квартире', ...item },
    };
    return mappings[item.shortName] || item;
  };

  compareBySort = (a, b) => {
    if (a.sort < b.sort) {
      return -1;
    }
    if (a.sort > b.sort) {
      return 1;
    }
    return 0;
  };

  render() {
    const {
      filter,
      rubrics,
      rubric,
      lists,
      searchPeriod,
      microdistricts,
      onClearSelectedValue,
      dictionaries,
      realty,
      locality,
      handleShowModal,
      onSelectLocality,
      onChangeFilter,
      districts,
      layout,
      sanitary,
      condition,
      building,
      buildingMaterial,
    } = this.props;
    const isNewBuildingRubric = rubric.id === 10;

    const propertyTypes = isNewBuildingRubric
      ? realty.sales
          .filter((item) => [0, 100, 102].indexOf(item.id) === -1)
          .map((item) => this.mapSaleTypes(item))
      : realty.sales.map((item) => this.mapSaleTypes(item)).sort(this.compareBySort);

    const floorItems =
      dictionaries.floorPosition && apiMappings.floorPositions(dictionaries.floorPosition.items);

    return (
      <div>
        <FormPaper>
          <ModalButton
            label="Населенный пункт"
            valueLabel={locality.name || filter.locality_fias_id}
            value={locality.id || filter.locality_fias_id}
            name="locality_fias_id"
            fieldRef={(input) => (this.locality_fias_id = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_LOCALITY_MODAL', {
                filterType: 'sale',
                title: 'Населенный пункт',
                filter,
                name: 'locality_fias_id',
                onSelectLocality,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Район"
            valueLabel={filter.o_d}
            value={filter.o_d}
            name="o_d"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.o_d = input;
            }}
            list={districts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: districts,
                title: 'Район',
                value: filter.o_d,
                filter,
                name: 'o_d',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Микрорайон"
            valueLabel={filter.m_m}
            value={filter.m_m}
            name="m_m"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.m_m = input;
            }}
            list={microdistricts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: microdistricts,
                title: 'Микрорайон',
                value: filter.m_m,
                filter,
                name: 'm_m',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Улица, дом"
            valueLabel={filter.addr}
            value={filter.addr}
            name="addr"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.addr = input;
            }}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_STREET_MODAL', {
                filterType: 'sale',
                title: 'Улица, дом',
                filter,
                name: 'addr',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Тип недвижимости"
            valueLabel={rubric.name}
            showModal={() => {
              handleShowModal('CHANGE_RUBRIC', {
                filterType: 'sale',
                rubrics,
                value: rubric.id,
                filter,
              });
            }}
          />
          <SearchDivider />
          <TabsSelect
            filter={filter}
            buttons={[
              {
                label: 'Все',
                name: '',
                value: '',
              },
              {
                label: 'Вторичка',
                name: 'saled',
                value: '1',
              },
              {
                label: 'Новостройки',
                name: 'new',
                value: '2',
              },
            ]}
            typeRealtyToggle={(input) => {
              this.typeRealtyToggle = input;
            }}
            onChangeFilter={onChangeFilter}
          />
          <PropertyTypeSelect
            value={filter.ref_type}
            name="ref_type"
            items={propertyTypes}
            label="Количество комнат"
            onChangeFilter={onChangeFilter}
          />
          <SearchDivider />
          <input type="hidden" defaultValue={1} name="is_normal_price" />
          <RangeField
            minName="ref_minprice"
            maxName="ref_maxprice"
            mainLabel="Цена, ₽"
            min={filter.ref_minprice}
            max={filter.ref_maxprice}
            label=""
            measureLabel=""
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.photo}
                name="photo"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.photo = input;
                }}
              />
            }
            label="Только с фото"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <RangeField
            mainLabel="Общая площадь, м²"
            minName="sq_total_min"
            maxName="sq_total_max"
            min={filter.sq_total_min}
            max={filter.sq_total_max}
            measureLabel=""
            label=""
            onChangeFilter={onChangeFilter}
          />
          <RangeField
            mainLabel="Жилая площадь, м²"
            minName="sq_living_min"
            maxName="sq_living_max"
            min={filter.sq_living_min}
            max={filter.sq_living_max}
            measureLabel=""
            label=""
            onChangeFilter={onChangeFilter}
          />
          <RangeField
            mainLabel="Площадь кухни, м²"
            minName="sq_kitchen_min"
            maxName="sq_kitchen_max"
            min={filter.sq_kitchen_min}
            max={filter.sq_kitchen_max}
            measureLabel=""
            label=""
            onChangeFilter={onChangeFilter}
          />
        </FormPaper>

        <input type="hidden" name="has_cords" defaultValue={filter.has_cords} />

        <div>
          <input type="hidden" name="left_lat" defaultValue={filter.left_lat} />
          <input type="hidden" name="left_lon" defaultValue={filter.left_lon} />
          <input type="hidden" name="right_lat" defaultValue={filter.right_lat} />
          <input type="hidden" name="right_lon" defaultValue={filter.right_lon} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[0]} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[1]} />
          <input type="hidden" name="zoom" defaultValue={filter.zoom} />
        </div>

        <FormPaper>
          <ModalButton
            label="Этаж"
            valueLabel={
              filter.floor &&
              floorItems.find((type) => Number(type.id) === Number(filter.floor)).name
            }
            value={filter.floor}
            name="floor"
            fieldRef={(input) => {
              this.floor = input;
            }}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: floorItems,
                title: 'Этаж',
                value: filter.floor,
                filter,
                name: 'floor',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <RangeField
            minName="floor_min"
            maxName="floor_max"
            min={filter.floor_min}
            max={filter.floor_max}
            measureLabel=""
            label=""
            onChangeFilter={onChangeFilter}
          />
        </FormPaper>
        <FormPaper>
          <ModalButton
            label="Планировка"
            valueLabel={filter.planning_id}
            value={filter.planning_id}
            name="planning_id"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.planning_id = input;
            }}
            list={layout}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: layout,
                title: 'Планировка',
                value: filter.planning_id,
                filter,
                name: 'planning_id',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Санузел"
            valueLabel={filter.sanitary_id}
            value={filter.sanitary_id}
            name="sanitary_id"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.sanitary_id = input;
            }}
            list={sanitary}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: sanitary,
                title: 'Санузел',
                value: filter.sanitary_id,
                filter,
                name: 'sanitary_id',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Состояние/ремонт"
            valueLabel={filter.renovation_type}
            value={filter.renovation_type}
            name="renovation_type"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.renovation_type = input;
            }}
            list={condition.sales}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: condition.sales,
                title: 'Состояние/ремонт',
                value: filter.renovation_type,
                filter,
                name: 'renovation_type',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.balcony}
                name="balcony"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.balcony = input;
                }}
              />
            }
            label="Есть балкон или лоджия"
            labelPlacement="start"
          />
          <SearchDivider />
          <ModalButton
            label="Материал окон"
            valueLabel={
              filter.window_type &&
              lists.windowMaterial.find((type) => Number(type.id) === Number(filter.window_type))
                .name
            }
            value={filter.window_type}
            name="window_type"
            fieldRef={(input) => {
              this.window_type = input;
            }}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: lists.windowMaterial,
                title: 'Материал окон',
                value: filter.window_type,
                filter,
                name: 'window_type',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Вид из окна"
            valueLabel={
              filter.window_view &&
              lists.viewFromWindow.find((type) => Number(type.id) === Number(filter.window_view))
                .name
            }
            value={filter.window_view}
            name="window_view"
            fieldRef={(input) => {
              this.window_view = input;
            }}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: lists.viewFromWindow,
                title: 'Вид из окна',
                value: filter.window_view,
                filter,
                name: 'window_view',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Тип дома"
            valueLabel={filter.building_type_id}
            value={filter.building_type_id}
            name="building_type_id"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.building_type_id = input;
            }}
            list={building}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: building,
                title: 'Тип дома',
                value: filter.building_type_id,
                filter,
                name: 'building_type_id',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Материал дома"
            valueLabel={filter.material_id}
            value={filter.material_id}
            name="material_id"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => {
              this.material_id = input;
            }}
            list={buildingMaterial}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: buildingMaterial,
                title: 'Материал дома',
                value: filter.material_id,
                filter,
                name: 'material_id',
              });
            }}
          />
          <SearchDivider />
          <RangeField
            mainLabel="Этажей в доме"
            label=""
            measureLabel=""
            minName="min_floor_number"
            maxName="max_floor_number"
            min={filter.min_floor_number}
            max={filter.max_floor_number}
            onChangeFilter={onChangeFilter}
          />
          <SearchDivider />
          <RangeField
            mainLabel="Год постройки"
            label=""
            measureLabel=""
            minName="year_built_from"
            maxName="year_built_to"
            min={filter.year_built_from}
            max={filter.year_built_to}
            onChangeFilter={onChangeFilter}
          />

          {(isNewBuildingRubric || filter.new) && (
            <>
              <SearchDivider />
              <ModalButton
                label="Срок сдачи"
                valueLabel={
                  filter.max_deadline &&
                  deadlinesBuilding.find((type) => Number(type.id) === Number(filter.max_deadline))
                    .name
                }
                value={filter.max_deadline}
                name="max_deadline"
                fieldRef={(input) => {
                  this.max_deadline = input;
                }}
                showModal={() => {
                  handleShowModal('RADIO_GROUP_MODAL', {
                    filterType: 'sale',
                    list: deadlinesBuilding,
                    title: 'Срок сдачи',
                    value: filter.max_deadline,
                    filter,
                    name: 'max_deadline',
                    defaultValue: true,
                  });
                }}
              />
            </>
          )}
        </FormPaper>

        <FormPaper>
          <FormControlLabel
            control={
              <Switch
                name="is_mortgage"
                checkedValue={filter.is_mortgage}
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => {
                  this.is_mortgage = input;
                }}
              />
            }
            label="Ипотека"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                name="is_clear_sale"
                checkedValue={filter.is_clear_sale && Number(filter.is_clear_sale) === 1}
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_clear_sale = input)}
              />
            }
            label="Чистая продажа"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                name="is_clear_sale"
                value="2"
                checkedValue={filter.is_clear_sale && Number(filter.is_clear_sale) === 2}
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_clear_sale = input)}
              />
            }
            label="Обменный вариант"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Дата публикации"
            valueLabel={
              filter.interval &&
              filter.interval < 31 &&
              [{ id: '', name: 'за все время' }, ...searchPeriod.sales].find(
                (type) => Number(type.id) === Number(filter.interval)
              ).name
            }
            value={filter.interval}
            name="interval"
            fieldRef={(input) => {
              this.interval = input;
            }}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: [{ id: '', name: 'за все время' }, ...searchPeriod.sales],
                title: 'Дата публикации',
                value: filter.interval,
                filter,
                name: 'interval',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Автор объявления"
            valueLabel={authorItems.find((type) => type.id === authorFilter(filter).value).name}
            value={authorFilter(filter).value}
            name="only"
            fieldRef={(input) => (this.only = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: authorItems,
                title: 'Автор объявления',
                value: authorFilter(filter).value,
                filter,
                name: 'only',
              });
            }}
          />
        </FormPaper>
      </div>
    );
  }
}

SaleFields.defaultProps = {
  microdistricts: [],
  districts: [],
};

SaleFields.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  condition: PropTypes.objectOf(PropTypes.any).isRequired,
  searchPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  realty: realtyFromPropTypes.isRequired,
  lists: listsFromPropTypes.isRequired,
  rubric: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  onSelectDistrict: PropTypes.func.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  districts: PropTypes.arrayOf(PropTypes.object),
  onClearSelectedValue: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  buildingMaterial: PropTypes.arrayOf(PropTypes.object).isRequired,
  building: PropTypes.arrayOf(PropTypes.object).isRequired,
  sanitary: PropTypes.arrayOf(PropTypes.object).isRequired,
  layout: PropTypes.arrayOf(PropTypes.object).isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
};
