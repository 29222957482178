class factoryTableInterface {
  constructor (tariff) {
    this.tariff = tariff;
  }

  getTable() {
    return null;
  }
  getTableBuilder(rubric) {
    return null;
  }
  getTableHeader (rubricSlug) {
    return '';
  }
  getRubrics (sort) {
    return [];
  }
  getRubricLength (rubric) {
    return 0;
  }
}

export default factoryTableInterface;