import { Api2 } from '../../api';
import { getNewFormat } from '../../utils';
import { SIMILAR_OBJECTS_LOAD, SIMILAR_OBJECTS_IGNORE } from '../../actions/actionTypes';
import { START, SUCCESS } from '../../actions/consts';

function loadSimilarObjects() {
  return (dispatch, getState) => {
    dispatch({
      type: SIMILAR_OBJECTS_LOAD + START,
    });

    const { object } = getState();

    if (!object.loaded) {
      return dispatch({
        type: SIMILAR_OBJECTS_IGNORE,
      });
    }

    const type = object.data.realtyType + (object.data.period === 2 ? 'Daily' : '');
    const path = {
      ...getNewFormat(type),
      id: object.data.id,
    };

    return Api2.getSimilarObjects(path).then((data) => {
      dispatch({
        type: SIMILAR_OBJECTS_LOAD + SUCCESS,
        payload: data,
      });
    });
  };
}

export default loadSimilarObjects;
