/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
/* TODO: удалить после миграции MUI темы v0 на v4 */

import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import theme from './themes/main';
import store from './stores';
import history from './history';
import App from './pages/App';
import ErrorBoundary from './ErrorBoundary';
import WrappedRoute from './WrappedRoute';
import NotFoundPage from './pages/NotFoundPage';

export default class Root extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <Switch>
                <WrappedRoute path="/:region" component={App} />
                <WrappedRoute path="/" component={App} />
                <WrappedRoute path="*" component={NotFoundPage} />
              </Switch>
            </Provider>
          </ThemeProvider>
        </Router>
      </ErrorBoundary>
    );
  }
}
