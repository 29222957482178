/* eslint-disable react/no-string-refs */
import PropTypes from 'prop-types';
import React from 'react';
import { debounce } from 'lodash';
import ReactDOM from 'react-dom';
import styles from './assets/RangeField.css';

export default class RangeField extends React.Component {
  constructor(...props) {
    super(...props);
    const { max, min } = this.props;
    this.state = {
      max: max || '',
      min: min || '',
    };
    this.debouncedChangeFilter = debounce(this.debouncedChangeFilter.bind(this), 1100);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { onNotification } = this.props;
    const { min, max } = this.state;

    // @TODO Move {4} (length of value for notification) to props
    if (
      (min.length === 4 && nextState.min.length === 5) ||
      (max.length === 4 && nextState.max.length === 5)
    ) {
      onNotification();
    }
    return true;
  }

  onChange(field) {
    const { onChangeFilter } = this.props;
    this.setState(
      {
        [field]: ReactDOM.findDOMNode(this.refs[field]).value,
      },
      () => {
        if (onChangeFilter) {
          this.debouncedChangeFilter();
        }
      }
    );
  }

  getMin() {
    return ReactDOM.findDOMNode(this.refs.min).value;
  }

  getMax() {
    return ReactDOM.findDOMNode(this.refs.max).value;
  }

  debouncedChangeFilter() {
    const { onChangeFilter } = this.props;
    onChangeFilter();
  }

  render() {
    const { mainLabel, label, measureLabel, minLabel, minName, maxLabel, maxName } = this.props;
    const { min, max } = this.state;
    return (
      <div className={styles.root}>
        {!!mainLabel && <div className={styles.mainLabel}>{mainLabel}</div>}
        <div className={styles.fieldsWrapper}>
          <div className={`${styles.field} ${styles.min}`}>
            <span className={styles.label}>{label}</span>
            <span className={styles.measureLabel}>{measureLabel}</span>
            <span className={styles.placeholder}>{minLabel}</span>
            <input
              className={styles.input}
              type="number"
              ref="min"
              autoComplete="off"
              step="0.1"
              name={minName}
              value={min}
              onChange={() => {
                this.onChange('min');
              }}
            />
          </div>
          <div className={`${styles.field} ${styles.max}`}>
            <span className={styles.measureLabel}>{measureLabel}</span>
            <span className={styles.placeholder}>{maxLabel}</span>
            <input
              className={styles.input}
              type="number"
              ref="max"
              autoComplete="off"
              step="0.1"
              name={maxName}
              value={max}
              onChange={() => {
                this.onChange('max');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

RangeField.propTypes = {
  label: PropTypes.string.isRequired,
  measureLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  minName: PropTypes.string,
  maxName: PropTypes.string,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onNotification: PropTypes.func,
  onChangeFilter: PropTypes.func,
  mainLabel: PropTypes.string,
};

RangeField.defaultProps = {
  minLabel: 'От',
  maxLabel: 'До',
  onNotification: () => {},
  onChangeFilter: () => {},
  mainLabel: '',
  min: 0,
  max: 0,
  minName: '',
  maxName: '',
};
