import FactoryTableInterface from './factoryTableInterface';
import TariffDiscountTableBuilder from '../builders/tariffDiscountTableBuilder';
import TariffTableBuilder from '../builders/tariffTableBuilder';

const sortOrder = {
  sale_apartments: 1,
  sale_lands: 2,
  sale_commercials: 3,
  sale_garages: 4,
  rent_apartments: 5,
  rentDaily_apartments: 6,
  rent_commercials: 7,
}

class FactoryTable extends FactoryTableInterface {

  getTable () {
    return (
      this.getRubrics(true).map(rubric => (
        this.getTableBuilder(rubric).buildTable().renderTable()
      ))
    )
  }

  getTableBuilder (rubric) {
    if (this.getRubricLength(rubric) > 2) {
      return new TariffDiscountTableBuilder(rubric, this.getTableHeader);
    }
    return new TariffTableBuilder(rubric, this.getTableHeader);
  }

  getTableHeader (rubricSlug) {
    switch (rubricSlug) {
      case 'rent_apartments': return 'Аренда. Длительная';
      case 'rent_commercials': return 'Аренда. Коммерческая';
      case 'rentDaily_apartments': return 'Аренда. Посуточная';

      case 'sale_apartments': return 'Продажа. Квартиры, комнаты';
      case 'sale_commercials': return 'Продажа. Коммерческая';
      case 'sale_garages': return 'Продажа. Гаражи';
      case 'sale_lands': return 'Продажа. Дома, участки, дачи';
    }
    return '';
  }

  getRubrics (sort = false) {
    if (sort) {
      return this.tariff.rubrics.sort((a, b) => {
        if(sortOrder[a.slug] < sortOrder[b.slug]) { return -1; }
        if(sortOrder[a.slug] > sortOrder[b.slug]) { return 1; }
        return 0;
      });
    }
    return this.tariff.rubrics;
  }

  getRubricLength (rubric) {
    return rubric.pricing.length || 0;
  }
}

export default FactoryTable;