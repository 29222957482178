export class TableBuilder {
  constructor(rubric, getTableHeader) {
    this.rubric = rubric;
    this.getTableHeader = getTableHeader;
  }
  buildTable () {
    return null;
  }
  renderTable () {
    return null;
  }
  addHeader () {
    return null;
  }
  addColumn () {
    return null;
  }
  renderHint () {
    return null;
  }
}