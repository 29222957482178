/* eslint-disable import/prefer-default-export */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Root from './Root';
import config from './config';

Sentry.init({
  dsn: 'https://eeaff28e6fde42e3958d21dcccebcbbe@sentry.n1.ru/49',
  release: config.release,
  integrations: [
    new Sentry.Integrations.InboundFilters({
      ignoreErrors: [
        "Cannot read property 'setTimeout' of null",
        "Cannot read property 'ownerDocument' of null",
        'Permission denied',
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
      ],
      denyUrls: [
        /\/(gtm|ga|analytics)\.js/i,
        /leaflet\.js/i,
        /freedraw\.js/i,
        /banners\.adfox\.ru/i,
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /127\.0\.0\.1:4001\/isrunning/i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /googlesyndication\.com/i,
      ],
    }),
  ],
});

try {
  ReactDOM.render(<Root />, document.getElementById('app'));
} catch (error) {
  Sentry.captureException(error, {
    tags: {
      errorCode: 500,
    },
  });
}
