/* eslint-disable react/no-string-refs */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  loadProperties,
  loadGarageProperties,
  loadMapProperties,
  invalidateMapProperties,
  updateSalesFilter,
  updateRentsFilter,
  setCurrentPageType,
  invalidateProperties,
} from '../actions';
import { Map, Cluster } from '../components/YandexMaps';

import PropertiesListMap from '../components/PropertiesListMap';
import styles from './assets/MapPage.css';
import { convertFilter } from '../utils';
import GtagBuilder from '../classes/analytics/gtagEventBuilder';
import GtagBuilderV2 from '../classes/analytics/gtagEventBuilderV2';
import {
  gtagPushEventNewPage,
  getCianDealType,
  getCianObjectType,
  getExtraCategory,
} from '../classes/analytics/gtagUtils';
import {
  selectedRegion as selectedRegionFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
  lists as listsFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
} from '../lib/PropTypeValues';

class _MapPage extends React.Component {
  state = {
    mapLoaded: false,
    bounds: [],
    center: [],
    zoom: null,
    showPanel: false,
  };

  componentDidMount() {
    const { selectedProperties, dispatch } = this.props;
    if (selectedProperties.apartments) {
      this.setState({ showPanel: true });
    }
    dispatch(setCurrentPageType('map'));
    // eslint-disable-next-line react/no-find-dom-node
    const mapNode = ReactDOM.findDOMNode(this.refs.map);
    const height = window.innerHeight - 104;
    mapNode.style.height = `${height}px`;
  }

  handleSendGA = () => {
    const {
      user,
    } = this.props;
    const cianGtagEvent = new GtagBuilder({})
      .setEvent('newpage')
      .setEventCategory('page')
      .setEventAction('newpage')
      .setEventLabel('open')
      .setUser(user)
      .setPage({
        pageType: 'Map',
        siteType: 'mobile',
      })
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleSendMapListingGA = () => {
    const {
      user,
      currentFilterType,
      selectedRegion,
      selectedProperties,
    } = this.props;
    const cianGtagEvent = new GtagBuilderV2({})
      .setEvent('newpage')
      .setEventCategory('page')
      .setEventAction('newpage')
      .setEventLabel('open')
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: 'map',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: Number(selectedProperties.apartments.items.length),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts(selectedProperties.apartments.items)
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleSendClickAnnouncementGA = (announcement) => {
    const {
      user,
      currentFilterType,
      selectedProperties,
    } = this.props;
    const cianGtagEvent = new GtagBuilderV2({})
      .setEvent('oldevent')
      .setEventCategory('Listing')
      .setEventAction('to_card')
      .setEventLabel('from_listing')
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: 'map',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        region: announcement.location && announcement.location.region && announcement.location.region.fullName.replace(/\.$/, ''),
        offersQty: Number(selectedProperties.apartments.items.length),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleClearCords = (filter) => {
    const clearedFilter = { ...filter };
    delete clearedFilter.has_cords;
    delete clearedFilter.left_lat;
    delete clearedFilter.left_lon;
    delete clearedFilter.right_lat;
    delete clearedFilter.right_lon;
    delete clearedFilter.center;
    delete clearedFilter.zoom;
    return clearedFilter;
  };

  handleMapLoad = (ymaps, map) => {
    const { salesFilter, rentsFilter, route, dispatch, selectedLocality } = this.props;
    map.controls.add('zoomControl', { size: 'small' });

    let center = null;
    let zoom = null;
    let addr = null;
    let bounds = null;
    let locality = '';

    const section = route.path.replace(/\/.*/, '');

    if (section === 'sales' || section === 'saleBusiness' || section === 'lands') {
      center = salesFilter.center;
      zoom = salesFilter.zoom;
      addr = salesFilter.addr;
      locality = selectedLocality.name;
    } else {
      center = rentsFilter.center;
      zoom = rentsFilter.zoom;
      addr = rentsFilter.addr;
      locality = selectedLocality.name;
    }

    if (!center && typeof addr !== 'undefined') {
      ymaps
        .geocode(`Россия, ${locality}, ${addr}`, {
          results: 1,
        })
        .then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          bounds = firstGeoObject.properties.get('boundedBy');
          map.setBounds(bounds, {
            checkZoomRange: true,
          });
        }, this);
    }

    if (center && zoom) {
      map.setCenter(center, zoom, { checkZoomRange: true });
    }

    map.events.add('click', () => {
      this.setState({ showPanel: false });
      dispatch(invalidateMapProperties());
    });

    this.setState(
      {
        mapLoaded: true,
        bounds: map.getBounds(),
        center: map.getCenter(),
        zoom: map.getZoom(),
      },
      this.loadProperty
    );
  };

  setBalloonData = (ids) => {
    const { dispatch, route, rentsFilter } = this.props;
    const path = route.path.replace(/\/.*/, '');
    const sectionMap = {
      sales: 'sale/apartments',
      rents: 'rent/apartments',
      lands: 'sale/lands',
      rentBusiness: 'rent/commercial',
      saleBusiness: 'sale/commercial',
      garages: 'sale/garages',
    };

    let section = '';
    if (rentsFilter.period && rentsFilter.period === '2') {
      section = 'rentDaily/apartments';
    } else {
      section = sectionMap[path];
    }
    // fields=''
    dispatch(loadMapProperties({ id: ids.join(',') }, section));
    this.setState({
      showPanel: true,
    });
  };

  boundschange(event) {
    this.setState(
      {
        bounds: event.originalEvent.newBounds,
        center: event.originalEvent.newCenter,
        zoom: event.originalEvent.newZoom,
      },
      this.loadProperty
    );
  }

  loadProperty() {
    const {
      route,
      rentsFilter,
      salesFilter,
      selectedRegion,
      dispatch,
      lists,
      dictionaries,
      selectedLocality,
      selectedProperties,
    } = this.props;
    const { bounds, center, zoom } = this.state;
    let boundsLatLon = {};
    let selfCenter = [];
    let selfZoom = null;

    if (bounds) {
      boundsLatLon = {
        left_lat: bounds[0][0],
        left_lon: bounds[0][1],
        right_lat: bounds[1][0],
        right_lon: bounds[1][1],
      };
      selfCenter = center;
      selfZoom = zoom;
    }
    const path = route.path.replace(/\/.*/, '');
    const query = path === 'rents' ? rentsFilter : salesFilter;

    const filter = {
      region_fias: selectedRegion.code,
      pageSize: 300,
      has_cords: 1,
      ...query,
      ...boundsLatLon,
      fields: 'lat,lon',
    };

    // TODO fix garages/map
    if (route.path === 'garages/map') {
      dispatch(
        loadGarageProperties(convertFilter(filter, selectedLocality), 'sale/garages', dictionaries)
      );
    } else {
      const section = route.path.replace(/\/.*/, '');

      if (section === 'sales' || section === 'saleBusiness' || section === 'lands') {
        dispatch(loadProperties({ ...filter }, section, lists)).then(() => {
          if (selectedProperties.apartments !== null && selectedProperties.apartments.items.length) {
            this.handleSendMapListingGA()
          } else {
            this.handleSendGA();
          }
        });

        delete filter.fields;

        filter.center = selfCenter;
        filter.zoom = selfZoom;

        dispatch(updateSalesFilter(filter));
      } else {
        dispatch(loadProperties({ ...filter }, section, lists)).then(() => {
          if (selectedProperties.apartments !== null && selectedProperties.apartments.items.length) {
          this.handleSendMapListingGA()
          } else {
            this.handleSendGA();
          }
        });

        delete filter.fields;

        filter.center = selfCenter;
        filter.zoom = selfZoom;
        dispatch(updateRentsFilter(filter));
      }
    }
  }

  renderToListButton() {
    const { route, rentsFilter, salesFilter, selectedRegion, dispatch } = this.props;
    const path = route.path.replace(/\/.*/, '');
    const filter = path === 'rents' ? rentsFilter : salesFilter;
    const clearedFilter = this.handleClearCords(filter);
    delete clearedFilter.pageSize;
    const query = Object.keys(clearedFilter)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(clearedFilter[key])}`;
      })
      .join('&');
    const toListView = `${[`/${selectedRegion.domain}`, path].join('/')}?${query}`;
    return (
      <div
        style={{
          textAlign: 'center',
          background: 'white',
        }}
      >
        <Link
          to={toListView}
          onClick={() => {
            if (path === 'rents') {
              dispatch(updateRentsFilter(clearedFilter));
            } else {
              dispatch(updateSalesFilter(clearedFilter));
            }
            dispatch(invalidateProperties());
          }}
          className={styles.linkToggle}
        >
          <div className={styles.toggleContainer}>
            <div className={styles.toggleButton}>
              <span>Список</span>
            </div>
            <div className={styles.toggleButtonActive}>
              <span>На карте</span>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  render() {
    const { regions, selectedRegion, properties, selectedProperties, dictionaries } = this.props;
    const { mapLoaded, showPanel } = this.state;
    const center = regions.filter((item) => item.id === selectedRegion.id)[0].coordinates;

    return (
      <section>
        <Loader loaded={mapLoaded} />
        {this.renderToListButton()}
        <div style={{ width: '100%' }} ref="map">
          <Map
            mapState={{ center, zoom: 12, controls: [] }}
            onLoad={this.handleMapLoad}
            eventHandlers={{ boundschange: this.boundschange.bind(this) }}
          >
            <Cluster properties={properties} onClick={this.setBalloonData} />
          </Map>

          {showPanel && (
            <div style={{ position: 'relative' }}>
              <PropertiesListMap
                properties={selectedProperties}
                dictionaries={dictionaries}
                domain={selectedRegion.domain}
                eventClickAnnouncement={(announcement) => this.handleSendClickAnnouncementGA(announcement)}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    regions: state.regions.items,
    selectedRegion: state.selectedRegion,
    lists: state.lists,
    dictionaries: state.dictionaries,
    properties: state.properties,
    selectedProperties: state.mapProperties,
    salesFilter: state.salesFilter,
    rentsFilter: state.rentsFilter,
    selectedSection: state.selectedSection,
    selectedLocality: state.selectedLocality,
    user: state.user,
    currentFilterType: state.filterType,
  };
}

_MapPage.defaultProps = {
  salesFilter: {},
  rentsFilter: {
    period: 1,
  },
  selectedProperties: {},
  properties: {},
};

_MapPage.propTypes = {
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  lists: listsFromPropTypes.isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      loaded: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.object),
      filter: PropTypes.func,
    })
  ).isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  rentsFilter: PropTypes.objectOf(PropTypes.any),
  salesFilter: PropTypes.objectOf(PropTypes.any),
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  selectedProperties: PropTypes.shape({
    apartments: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  properties: PropTypes.objectOf(PropTypes.any),
};

export default connect(mapStateToProps)(_MapPage);
