import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { UserPaymentsFilter } from '../../components/User';
import { setCurrentPageType } from '../../actions';

class _PaymentsFilterPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('empty'));
  }

  render() {
    return <UserPaymentsFilter />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

_PaymentsFilterPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_PaymentsFilterPage);
