import React from 'react';
import { formatPrice } from '../../utils';
import Line from './Line';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';
import { AccountDivider } from '../Divider/Divider';
import FormPaper from '../SearchForm/FormPaper/FormPaper';
import cn from './User.css';

const UserProfile = ({ user: { profile } }) => {
  return (
    <div className={cn.userProfile}>
      <FormPaper>
        <Line name="ID" value={profile.id} />
      </FormPaper>
      <FormPaper>
        <Line name="Баланс" value={formatPrice(profile.balance)} />
        <AccountDivider />
        <Line name="Имя" value={profile.fullName} />
        <AccountDivider />
        <Line name="Логин" value={profile.name} />
        <AccountDivider />
        <Line name="e-mail" value={profile.email} />
        <AccountDivider />
        {profile.contactPhones.map((phone) => (
          <Line name="Телефон" value={phone.formatted} />
        ))}
      </FormPaper>
    </div>
  );
};

UserProfile.propTypes = {
  user: userFromPropTypes.isRequired,
};

export default UserProfile;
