import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import BannerIcon from './bannerIcon.svg';
import styles from './CianBanner.css';

const CianBanner = ({ handleOnClick }) => {
  return (
    <div className={classNames(styles.container)} onClick={handleOnClick}>
      <img
        className={classNames(styles.icon)}
        src={BannerIcon}
        alt="cian.ru"
      />
      <div className={classNames(styles.text)}>
        Циан и МЛСН одна компания! Попробуйте поиск в Циан
      </div>
    </div>
  )
};

CianBanner.propTypes = {
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  handleOnClick: PropTypes.func,
};

CianBanner.defaultProps = {
  handleOnClick: () => {}
};

export default CianBanner;
