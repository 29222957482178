import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import {
  hideModal,
  updateSalesFilter,
  updateRentsFilter,
  loadProperties,
  loadGarageProperties,
} from '../../../actions';
import { FontIcon } from '../../ui/material';
import styles from './Modals.css';
import StyledDialog from '../ModalWithStyles';
import { AutocompleteDialogContent } from '../DialogContentWithStyles';
import StreetSelect from '../../StreetSelect';
import { convertFilter } from '../../../utils';
import {
  lists as listsFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../../lib/PropTypeValues';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AutocompleteStreetModal extends Component {
  state = {
    hideDescription: false,
  };

  handleSave = (selected) => {
    const {
      filterType,
      filter,
      dispatch,
      lists,
      dictionaries,
      selectedLocality,
      selectedSalesRubric,
      selectedRentsRubric,
      name,
    } = this.props;

    const rubricRoute =
      filterType === 'sale' ? selectedSalesRubric.route : selectedRentsRubric.route;

    const localityName = rubricRoute === 'garages' ? 'localityId' : 'locality_fias_id';

    const currentFilter = {
      ...filter,
      [localityName]: selectedLocality.id,
      [name]: selected.value,
    };

    if (filterType === 'sale') {
      dispatch(updateSalesFilter(currentFilter));
    } else {
      dispatch(updateRentsFilter(currentFilter));
    }

    if (rubricRoute === 'garages') {
      dispatch(
        loadGarageProperties(
          convertFilter({ ...currentFilter }, selectedLocality),
          `sale/${rubricRoute}`,
          dictionaries
        )
      );
    } else {
      dispatch(loadProperties({ ...currentFilter }, rubricRoute, lists));
    }

    dispatch(hideModal());
  };

  hideDescription = () => {
    this.setState({
      hideDescription: true,
    });
  };

  render() {
    const { hideDescription } = this.state;

    const { dispatch, title, filter, selectedLocality, selectedRegion, name } = this.props;

    return (
      <div>
        <StyledDialog open onClose={() => dispatch(hideModal())} TransitionComponent={Transition}>
          <div className={styles.header}>
            <div className={styles.headerName}>{title}</div>
            <div className={styles.headerClose}>
              <FontIcon
                className="material-icons"
                onClick={() => dispatch(hideModal())}
                style={{ color: 'black', fontSize: '19px' }}
              >
                close
              </FontIcon>
            </div>
          </div>
          <AutocompleteDialogContent>
            <StreetSelect
              onSelectEvent={this.handleSave}
              onChangeList={this.hideDescription}
              name={name}
              value={filter[name]}
              regionId={selectedRegion.code}
              localityId={Number(selectedLocality.id)}
            />
            {!hideDescription && (
              <div className={styles.description}>
                <span className={styles.descriptionRow}>
                  Начните вводить название улицы, конкретный адрес.
                </span>
              </div>
            )}
          </AutocompleteDialogContent>
        </StyledDialog>
      </div>
    );
  }
}

AutocompleteStreetModal.propTypes = {
  filterType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedSalesRubric: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRentsRubric: PropTypes.objectOf(PropTypes.any).isRequired,
  lists: listsFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect((state, ownProps) => ({
  filterType: ownProps.filterType,
  filter: ownProps.filter,
  title: ownProps.title,
  name: ownProps.name,
  value: ownProps.value,

  lists: state.lists,
  selectedRegion: state.selectedRegion,
  selectedSalesRubric: state.selectedSalesRubric,
  selectedRentsRubric: state.selectedRentsRubric,
  selectedLocality: state.selectedLocality,
  dictionaries: state.dictionaries,
}))(AutocompleteStreetModal);
