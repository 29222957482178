import PropTypes from 'prop-types';
import React from 'react';
import { find } from 'lodash';
import cn from './ComplexView.css';
import { lists as listsFromPropTypes } from '../../lib/PropTypeValues';

export default class ComplexInfo extends React.Component {
  renderNotFilled = () => {
    return <span style={{ color: '#bbb' }}>не заполнено</span>;
  };

  renderRow(param) {
    const boolText = (val) => (val ? 'есть' : 'нет');
    const value =
      param.value === 0 || param.value === 1
        ? boolText(param.value)
        : param.value || this.renderNotFilled();

    return (
      <tr key={param.name}>
        <td className={cn.paramName}>{param.name}</td>
        <td className={cn.paramValue}>{value}</td>
      </tr>
    );
  }

  render() {
    const {
      declaration,
      lists,
      material,
      ceilingHeightMax,
      ceilingHeightMin,
      deadline,
      floor,
      apartmentCount,
      squareMin,
      squareMax,
      repairState,
      developerName,
      sellerName,
    } = this.props;
    const declarationComplex = /http(|s):\/\//.test(declaration) ? (
      <a href={declaration} rel="noreferrer" target="_blank" style={{ color: '#8dc63f' }}>
        {declaration.replace(/http(|s):\/\//, '').split('/')[0]}
      </a>
    ) : (
      declaration
    );

    const materialComplex = (find(lists.buildingMaterial, { id: material }) || {}).nameShort;

    const ceilingHeight =
      ceilingHeightMax === ceilingHeightMin
        ? `${ceilingHeightMin} см`
        : `${[ceilingHeightMin, ceilingHeightMax].filter(Number).join(' - ')} см`;

    const params = [
      { name: 'Срок сдачи', value: deadline },
      { name: 'Этажность домов', value: floor },
      { name: 'Количество квартир', value: apartmentCount },
      { name: 'Площадь квартир', value: `${squareMin} - ${squareMax} м²` },
      { name: 'Высота потолков', value: ceilingHeight },
      { name: 'Материал стен', value: materialComplex },
      { name: 'Отделка', value: repairState },
      { name: 'Застройщик', value: developerName },
      { name: 'Продавец', value: sellerName },
      { name: 'Проектные декларации', value: declarationComplex },
    ];

    return (
      <table className={cn.infoTable}>
        {params.filter((param) => param.value).map((param) => this.renderRow(param))}
      </table>
    );
  }
}

ComplexInfo.defaultProps = {
  apartmentCount: null,
  sellerName: null,
};

ComplexInfo.propTypes = {
  declaration: PropTypes.string.isRequired,
  lists: listsFromPropTypes.isRequired,
  material: PropTypes.number.isRequired,
  ceilingHeightMax: PropTypes.number.isRequired,
  ceilingHeightMin: PropTypes.number.isRequired,
  deadline: PropTypes.string.isRequired,
  floor: PropTypes.string.isRequired,
  apartmentCount: PropTypes.number,
  squareMin: PropTypes.number.isRequired,
  squareMax: PropTypes.number.isRequired,
  repairState: PropTypes.string.isRequired,
  developerName: PropTypes.string.isRequired,
  sellerName: PropTypes.string,
};
