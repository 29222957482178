/**
 * Ads config file
 * @type {{Adfox: {domain: {params: {pp: string, ps: string, p2: string}}, default: {params: {pp: string, ps: string, p2: string}}}}}
 */

const config = {
  Adfox: {
    omsk: {
      params: {
        pp: 'h',
        ps: 'cjhn',
        p2: 'fmxb',
      },
    },
    tatarstan: {
      params: {
        pp: 'h',
        ps: 'cjoz',
        p2: 'fmxb',
      },
    },
    default: {
      params: {
        pp: 'h',
        ps: 'cjzq',
        p2: 'fmxb',
      },
    },
  },
};

export default config;
