/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import {
  hideModal,
  updateSalesFilter,
  updateRentsFilter,
  loadProperties,
  loadGarageProperties,
} from '../../../actions';
import { RadioButtonGroup, FontIcon, FormControlLabel } from '../../ui/material';
import styles from './Modals.css';
import StyledDialog from '../ModalWithStyles';
import { StyledDialogContent } from '../DialogContentWithStyles';
import { SearchDivider } from '../../Divider/Divider';
import StyledRadioButton from '../../RadioButton';
import { capitalizeFirstLetter, convertFilter } from '../../../utils';
import {
  lists as listsFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../../lib/PropTypeValues';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class RadioGroupModal extends Component {
  constructor(...props) {
    super(...props);
    const { value } = this.props;
    this.state = {
      selected: value || '',
    };
  }

  handleChange = (e) => {
    this.setState({
      selected: e.target.value,
    });
  };

  handleSave = () => {
    const { selected } = this.state;
    const {
      filterType,
      filter,
      dispatch,
      lists,
      selectedLocality,
      dictionaries,
      name,
      selectedSalesRubric,
      selectedRentsRubric,
    } = this.props;

    const rubricRoute =
      filterType === 'sale' ? selectedSalesRubric.route : selectedRentsRubric.route;

    const localityName = rubricRoute === 'garages' ? 'localityId' : 'locality_fias_id';

    const currentFilter = {
      ...filter,
      [localityName]: selectedLocality.id,
      [name]: Number(selected),
    };

    // convert inner values to real values
    if (currentFilter.only === 'owners') {
      currentFilter.only_owners = 1;
      delete currentFilter.only_builders;
    }

    if (currentFilter.only === 'builders') {
      currentFilter.only_builders = 1;
      delete currentFilter.only_owners;
    }

    if (currentFilter.only === 'all') {
      delete currentFilter.only_builders;
      delete currentFilter.only_owners;
    }

    if (filterType === 'sale') {
      dispatch(updateSalesFilter(currentFilter));
    } else {
      dispatch(updateRentsFilter(currentFilter));
    }

    if (rubricRoute === 'garages') {
      dispatch(
        loadGarageProperties(
          convertFilter({ ...currentFilter }, selectedLocality),
          `sale/${rubricRoute}`,
          dictionaries
        )
      );
    } else {
      dispatch(loadProperties({ ...currentFilter }, rubricRoute, lists));
    }

    dispatch(hideModal());
  };

  render() {
    const { selected } = this.state;
    const { dispatch, title, list, defaultValue } = this.props;
    return (
      <div>
        <StyledDialog open onClose={() => dispatch(hideModal())} TransitionComponent={Transition}>
          <div className={styles.header}>
            <div className={styles.headerName}>{title}</div>
            <div className={styles.headerClose}>
              <FontIcon
                className="material-icons"
                onClick={() => dispatch(hideModal())}
                style={{ color: 'black', fontSize: '19px' }}
              >
                close
              </FontIcon>
            </div>
          </div>
          <StyledDialogContent>
            <RadioButtonGroup value={selected} onChange={this.handleChange}>
              {defaultValue && (
                <>
                  <FormControlLabel
                    control={<StyledRadioButton checked={selected === ''} />}
                    value=""
                    label="Неважно"
                    labelPlacement="start"
                  />
                  <SearchDivider />
                </>
              )}
              {list.map((item) => {
                return (
                  <div key={item.id}>
                    <FormControlLabel
                      control={<StyledRadioButton checked={selected == item.id} />}
                      value={item.id}
                      label={capitalizeFirstLetter(item.name)}
                      labelPlacement="start"
                    />
                    <SearchDivider />
                  </div>
                );
              })}
            </RadioButtonGroup>
          </StyledDialogContent>

          <div />
          <button className={styles.saveButton} type="button" onClick={() => this.handleSave()}>
            Выбрать
          </button>
        </StyledDialog>
      </div>
    );
  }
}

RadioGroupModal.defaultProps = {};

RadioGroupModal.propTypes = {
  filterType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    .isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedSalesRubric: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRentsRubric: PropTypes.objectOf(PropTypes.any).isRequired,
  lists: listsFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect((state, ownProps) => ({
  filterType: ownProps.filterType,
  filter: ownProps.filter,
  title: ownProps.title,
  list: ownProps.list,
  name: ownProps.name,
  defaultValue: ownProps.defaultValue,

  lists: state.lists,
  selectedRegion: state.selectedRegion,
  selectedSalesRubric: state.selectedSalesRubric,
  selectedRentsRubric: state.selectedRentsRubric,
  selectedLocality: state.selectedLocality,
  dictionaries: state.dictionaries,
}))(RadioGroupModal);
