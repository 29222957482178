/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-string-refs */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { map, filter, includes, isEmpty, head, isArray } from 'lodash';
import cx from 'classnames';
import { FontIcon } from './material';
import styles from './assets/Select.css';

export default class Select extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      selected: isArray(props.value)
        ? props.value
        : typeof props.value === 'undefined'
        ? []
        : [props.value],
    };
  }

  handleChange(e) {
    const { onSelect, onChangeFilter } = this.props;
    const value = this.getValue();
    this.setState({
      selected: isArray(value) ? value : [value],
    });
    if (onSelect) {
      onSelect(e);
    }
    if (onChangeFilter) {
      onChangeFilter();
    }
  }

  getValue() {
    const { multiple } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.select);
    const selectedOptions = filter(node.options, (option) => option.selected);

    const selected = map(selectedOptions, (option) =>
      option.value !== '' ? Number(option.value) : option.value
    );
    return multiple ? selected : selected[0];
  }

  renderOptions() {
    const { items } = this.props;
    return items.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
  }

  renderSelected() {
    const { multiple, items, emptyString, selectedStyle } = this.props;
    const { selected } = this.state;
    const selectedToNum = selected.map((item) => {
      if (item === '') {
        return item;
      }
      return +item;
    });

    let selectedItems = [];
    if (multiple) {
      selectedItems = items.filter((item) => {
        return includes(selectedToNum, item.id) || includes(selectedToNum, +item.id);
      });
    } else {
      selectedItems = items.filter((item) => {
        return includes(selectedToNum, item.id);
      });
    }

    const string = isEmpty(selectedItems)
      ? emptyString || head(items).name
      : selectedItems.length > 1
      ? `Выбрано: ${selectedItems.length}`
      : selectedItems.map((item) => item.name).join(', ');

    return (
      <div className={styles.selected} style={selectedStyle}>
        {string}
      </div>
    );
  }

  render() {
    const { multiple, className, label, arrow, name, selectStyle } = this.props;
    const { selected } = this.state;
    const value = multiple ? selected : head(selected);
    const selectClassName = cx(styles.root, {
      [className]: className,
    });
    return (
      <div className={selectClassName}>
        <div className={styles.label}>{label}</div>
        <div className={styles.wrapper}>
          {this.renderSelected()}
          {arrow && (
            <FontIcon
              className="material-icons"
              style={{ position: 'absolute', right: 11, top: 15 }}
            >
              keyboard_arrow_down
            </FontIcon>
          )}
        </div>

        <select
          name={name}
          className={styles.element}
          ref="select"
          multiple={multiple}
          style={selectStyle}
          value={value}
          onChange={this.handleChange.bind(this)}
        >
          {this.renderOptions()}
        </select>
      </div>
    );
  }
}

Select.defaultProps = {
  arrow: true,
  emptyString: 'не важно',
  value: 0,
  multiple: false,
  onSelect: () => {},
  onChangeFilter: () => {},
  name: '',
  className: '',
  selectStyle: {},
  selectedStyle: {},
};

Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  multiple: PropTypes.bool,
  arrow: PropTypes.bool,
  onSelect: PropTypes.func,
  onChangeFilter: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  emptyString: PropTypes.string,
  selectStyle: PropTypes.objectOf(PropTypes.any),
  selectedStyle: PropTypes.objectOf(PropTypes.any),
};
