/* eslint-disable max-classes-per-file */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { withStyles } from '@material-ui/core';
import { IconButton } from './ui/material';
import { addToFavorites, removeFromFavorites } from '../actions';

class _ButtonFavorite extends React.Component {
  constructor(...props) {
    super(...props);
    const { object, uid, favorites } = this.props;
    let objectFavorite = { ...object };
    if (!object) {
      const uidFavorite = uid.split('_');
      objectFavorite = {
        id: Number(uidFavorite[1]),
        type: 'old',
        realtyType: uidFavorite[0],
      };
    }

    if (objectFavorite.type === 'old') {
      objectFavorite = this.converToFormat(objectFavorite);
    }
    const section = [objectFavorite.dealType, objectFavorite.realtyType].join('_');
    this.state = {
      checked: !!find(favorites, (o) => {
        if (o.id !== objectFavorite.id) {
          return false;
        }
        if (
          o.section === section ||
          (o.section === 'rentDaily_apartments' && section === 'rent_apartments')
        ) {
          return true;
        }
        return false;
      }),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { checked } = this.state;
    return checked !== nextState.checked;
  }

  componentDidUpdate(prevProps, prevState) {
    const { object, uid, dispatch } = this.props;
    const { checked } = this.state;
    if (prevState.checked !== checked) {
      let objectFavorite = { ...object };
      if (!object) {
        const uidFavorite = uid.split('_');
        objectFavorite = {
          id: Number(uidFavorite[1]),
          type: 'old',
          realtyType: uidFavorite[0],
        };
      }
      if (objectFavorite.type === 'old') {
        objectFavorite = this.converToFormat(objectFavorite);
      }
      if (checked) {
        dispatch(addToFavorites(objectFavorite));
      } else {
        dispatch(removeFromFavorites(objectFavorite));
      }
    }
  }

  converToFormat = (object) => {
    const buffer = { ...object };
    switch (object.realtyType) {
      case 'sales':
        buffer.dealType = 'sale';
        buffer.realtyType = 'apartments';
        break;
      case 'lands':
        buffer.dealType = 'sale';
        buffer.realtyType = 'lands';
        break;
      case 'saleBusiness':
        buffer.dealType = 'sale';
        buffer.realtyType = 'commercials';
        break;
      case 'garages':
        buffer.dealType = 'sale';
        buffer.realtyType = 'garages';
        break;
      case 'rents':
        buffer.dealType = 'rent';
        buffer.realtyType = 'apartments';
        break;
      case 'rentBusiness':
        buffer.dealType = 'rent';
        buffer.realtyType = 'commercials';
        break;
      default:
        break;
    }
    return buffer;
  };

  toggleChecked(e) {
    e.preventDefault();
    e.stopPropagation();
    const { ymClick, eventAddFavorite, eventRemoveFavorite } = this.props;
    const { checked } = this.state;
    if (ymClick) {
      ymClick();
    }

    if (!checked && eventAddFavorite) {
      eventAddFavorite();
    }

    if (checked && eventRemoveFavorite) {
      eventRemoveFavorite();
    }

    this.setState({
      checked: !checked,
    });
  }

  render() {
    const { smallPadding, alternativeView, iconColor, iconSize } = this.props;
    const { checked } = this.state;
    // eslint-disable-next-line no-use-before-define
    const IconButtonComponent = smallPadding ? IconButtonReducedArea : IconButton;
    return (
      <div {...this.props} role="button" onClick={this.toggleChecked.bind(this)}>
        <IconButtonComponent>
          {checked && <Favorite color={iconColor} size={iconSize} />}
          {!checked && alternativeView && (
            <FavoriteWithoutColorBorder color={iconColor} size={iconSize} />
          )}
          {!checked && !alternativeView && (
            <FavoriteColorBorder color={iconColor} size={iconSize} />
          )}
        </IconButtonComponent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    favorites: state.favorites,
    user: state.user,
  };
}

_ButtonFavorite.defaultProps = {
  iconColor: '#FF7F29',
  iconSize: '24',
  smallPadding: false,
  alternativeView: false,
  object: null,
  ymClick: null,
  eventAddFavorite: null,
  eventRemoveFavorite: null,
};

_ButtonFavorite.propTypes = {
  object: PropTypes.objectOf(PropTypes.any),
  uid: PropTypes.string.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  smallPadding: PropTypes.bool,
  alternativeView: PropTypes.bool,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  ymClick: PropTypes.func,
  eventAddFavorite: PropTypes.func,
  eventRemoveFavorite: PropTypes.func,
};

export default connect(mapStateToProps)(_ButtonFavorite);

const IconButtonReducedArea = withStyles(() => ({
  root: {
    padding: '3px',
  },
}))((props) => <IconButton {...props} />);

const FavoriteColorBorder = ({ color, size }) => {
  const fillColor = color || '#FF7F29';
  const iconSize = size || '24';

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 9.66667C22 5.55856 19.612 3 15.8889 3C14.1914 3 12.8781 3.58157 12 4.72859C11.1219 3.58157 9.80856 3 8.11111 3C4.38799 3 2 5.55856 2 9.66667C2 12.8822 5.25376 16.5187 11.6982 20.6887C11.8819 20.8075 12.1181 20.8075 12.3018 20.6887C18.7462 16.5187 22 12.8822 22 9.66667ZM15.8889 4.11121C18.9806 4.11121 20.8889 6.15582 20.8889 9.66676C20.8889 12.3325 17.9459 15.6656 12 19.5594C6.05407 15.6656 3.11111 12.3325 3.11111 9.66676C3.11111 6.15582 5.01942 4.11121 8.1111 4.11121C9.75949 4.11121 10.8599 4.74004 11.5031 6.02632C11.7078 6.4358 12.2922 6.4358 12.4969 6.02632C13.14 4.74004 14.2405 4.11121 15.8889 4.11121Z"
        fill={fillColor}
      />
    </svg>
  );
};

FavoriteColorBorder.defaultProps = {
  size: '24',
  color: '#FF7F29',
};

FavoriteColorBorder.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

const FavoriteWithoutColorBorder = ({ size }) => {
  const iconSize = size || '24';
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 25.017C14 25.017 17.5018 22.6191 19.3057 20.9759C21.0108 19.4228 22.7951 18.2873 24.051 16.5221C24.6443 15.6882 25.4664 14.7889 25.4664 13.5C25.4664 8.68164 23.447 5.59351 19.3057 5.00027C17.5136 4.74355 16.0852 6.37574 14 6.37574C11.7319 6.37574 9.81377 4.67976 8.05242 5.00027C4.4527 5.65531 2.40045 8.50067 2.40045 13.1361C2.40045 16.8746 14 25.017 14 25.017Z"
        fill="black"
        fillOpacity="0.298022"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 12C26 7.07027 23.1344 4 18.6667 4C16.6297 4 15.0538 4.69788 14 6.07431C12.9462 4.69788 11.3703 4 9.33333 4C4.86558 4 2 7.07027 2 12C2 15.8586 5.90451 20.2225 13.6378 25.2264C13.8582 25.369 14.1418 25.369 14.3622 25.2264C22.0955 20.2225 26 15.8586 26 12ZM18.6667 5.33331C22.3767 5.33331 24.6667 7.78685 24.6667 12C24.6667 15.1988 21.1351 19.1986 14 23.8712C6.8649 19.1986 3.33334 15.1988 3.33334 12C3.33334 7.78685 5.62332 5.33331 9.33334 5.33331C11.3114 5.33331 12.632 6.08791 13.4037 7.63146C13.6494 8.12282 14.3506 8.12282 14.5963 7.63146C15.3681 6.08791 16.6886 5.33331 18.6667 5.33331Z"
        fill="white"
      />
    </svg>
  );
};

FavoriteWithoutColorBorder.defaultProps = {
  size: '24',
};

FavoriteWithoutColorBorder.propTypes = {
  size: PropTypes.string,
};

const Favorite = ({ color, size }) => {
  const fillColor = color || '#FF7F29';
  const iconSize = size || '24';

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8889 3C19.612 3 22 5.55856 22 9.66667C22 12.8822 18.7462 16.5187 12.3018 20.6887C12.1181 20.8075 11.8819 20.8075 11.6982 20.6887C5.25376 16.5187 2 12.8822 2 9.66667C2 5.55856 4.38799 3 8.11111 3C9.80856 3 11.1219 3.58157 12 4.72859C12.8781 3.58157 14.1914 3 15.8889 3Z"
        fill={fillColor}
      />
    </svg>
  );
};

Favorite.defaultProps = {
  size: '24',
  color: '#FF7F29',
};

Favorite.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};
