import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import UserAccount from '../../components/User/UserAccount';
import { setCurrentPageType } from '../../actions';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';

class _AccountPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('account'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        <UserAccount user={user} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

_AccountPage.propTypes = {
  user: userFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_AccountPage);
