/* eslint-disable react/static-property-placement */
import React from 'react';
import ReactDOM from 'react-dom';

const { script } = ReactDOM;

export default class extends React.Component {
  static displayName = 'YMInitializer';

  componentDidMount() {
    const insertPoint = document.getElementsByTagName('script')[0];
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.src = 'https://mc.yandex.ru/metrika/watch.js';
    insertPoint.parentNode.insertBefore(el, insertPoint);
  }

  render() {
    return script(null);
  }
}
