import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '../../ui/material';
import { styles, PaymentPaperStyle, ModalPaperStyle } from './styles';

const FormPaper = (props) => {
  const { classes, children } = props;
  return (
    <Paper square className={classes.root}>
      {children}
    </Paper>
  );
};

FormPaper.defaultProps = {
  classes: {},
};

FormPaper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

export const PaymentPaper = withStyles(PaymentPaperStyle)(FormPaper);
export const ModalPaper = withStyles(ModalPaperStyle)(FormPaper);
export default withStyles(styles)(FormPaper);
