import './assets/normalize.css';
import './assets/index.css';
// import './assets/smartbanner.css';

import PropTypes, { object } from 'prop-types';
import React, { Component } from 'react';
import Cookies from 'cookies-js';
import { withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { find, isEmpty } from 'lodash';
import ym, { YMInitializer } from 'react-yandex-metrika';
// import SmartBanner from 'react-smartbanner';
import { Dialog, DialogActions, Button } from '../components/ui/material';
import RLink from '../components/RLink';
import MainMenu from '../components/MainMenu';
import MainSection from '../components/MainSection';
import Header from '../components/Header';
import ButtonBack from '../components/ButtonBack';
import ButtonFavorite from '../components/ButtonFavorite';
import ButtonFilter from '../components/ButtonFilter';
import ButtonSearch from '../components/ButtonSearch';
import ButtonResetFilter from '../components/ButtonResetFilter';
import { SnackBar } from '../components/ui';

import {
  loadLists,
  loadRegions,
  detectRegion,
  setRegion,
  setLocality,
  removeNotification,
  setCurrentViewType,
  setScrollPosition,
  showModal,
  loadConfig,
} from '../actions';
import WrappedRoute from '../WrappedRoute';
import ButtonChangeView from '../components/ButtonChangeView';
import ModalRoot from '../components/ModalRoot';
import { SavedSearchPage } from './SavedSearchPage';
import RegionPage from './RegionPage';
import FullVersionPage from './FullVersionPage';
import FavoritesPage from './FavoritesPage';
import TinkoffPage from './TinkoffPage';
import RegionContainer from './RegionContainer';
import SignOutIcon from '../components/ui/assets/sign-out.svg';
import {
  lists as listsFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  userObjects as userObjectsFromPropTypes,
  user as userFromPropTypes,
} from '../lib/PropTypeValues';

import {
  gtagPushEventNewPage,
  getCianDealType,
  getCianObjectType,
  getExtraCategory,
  getCustomUrlCian,
  getCustomUrlCianForApi2,
  getParentId,
} from '../classes/analytics/gtagUtils';
import GtagBuilder from '../classes/analytics/gtagEventBuilder';
import GtagBuilderV2 from '../classes/analytics/gtagEventBuilderV2';
import { getNewFormat } from '../utils';

class _App extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isConfirmRegionDialog: false,
      isOpenMenu: false,
    };

    this.showConfirmRegionDialog = this.showConfirmRegionDialog.bind(this);
    this.closeConfirmRegionDialog = this.closeConfirmRegionDialog.bind(this);

    this.handleCancelRegion = this.handleCancelRegion.bind(this);
    this.handleConfirmRegion = this.handleConfirmRegion.bind(this);
    this.closeConfirmRegionDialog = this.closeConfirmRegionDialog.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  componentDidMount() {
    const {
      selectedRegion,
      match: {
        params: { region },
      },
      dispatch,
      location,
      history,
    } = this.props;
    if (Cookies.get('fullVersion')) {
      const domain = document.location.hostname.split('.').slice(1).join('.');
      Cookies.expire('fullVersion', { domain: `.${domain}` });
    }
    dispatch(loadRegions()).then((reg) => {
      // set region from url
      if (region || !isEmpty(selectedRegion)) {
        const findedRegion = find(reg, 'domain', region);
        return dispatch(setRegion(findedRegion || selectedRegion));
      }

      // detect region by ip and show prompt if there is no region in url and cookie
      if (isEmpty(selectedRegion)) {
        this.showConfirmRegionDialog();
        return dispatch(detectRegion());
      }

      // else if there is region in cookie then load lists and set locality
      dispatch(loadLists(selectedRegion.code));
      dispatch(
        setLocality({
          id: selectedRegion.capitalId,
          name: selectedRegion.capitalName,
        })
      );
      return false;
    });

    if (location.pathname === '/' && selectedRegion.domain) {
      history.push(`/${selectedRegion.domain}/`);
    }
  }

  componentDidUpdate(prevProps) {
    const { location, dispatch, params, regions } = this.props;
    const { pathname } = location;

    if (prevProps.location.pathname !== pathname && ym) {
      ym('hit', pathname);
    }
    if (prevProps.params && prevProps.params.region !== params.region && params.region) {
      const region = find(regions.items, 'domain', params.region);
      dispatch(setRegion(region));
    }
  }

  handleChangeType = (type) => {
    this.props.dispatch(setCurrentViewType(type));
    this.props.dispatch(setScrollPosition(0));
  };

  handleToggleMenu() {
    this.setState((state) => {
      return {
        isOpenMenu: !state.isOpenMenu,
      };
    });
  }

  handleCancelRegion() {
    const { history } = this.props;
    this.closeConfirmRegionDialog();
    history.push('/region');
  }

  handleConfirmRegion() {
    const { regions, detectedRegion, dispatch, history } = this.props;
    this.closeConfirmRegionDialog();
    const regionItems = regions.items;

    if (regionItems.length) {
      const region = find(regionItems, { code: detectedRegion.code });
      dispatch(setRegion(region));
      history.push(`/${region.domain}/`);
    }
  }

  handleRemoveNotification = () => {
    const { dispatch } = this.props;
    dispatch(removeNotification());
  };

  handleClickBack = () => {
    const { routes, selectedRegion, history } = this.props;
    if (routes instanceof Array && routes.length) {
      const last = routes[routes.length - 1];
      if (last.prev) {
        return history.push(`/${selectedRegion.domain}/${last.prev}`);
      }
    }
    return history.goBack();
  };

  handleOpenUnionCianModal = () => {
    const { cianModalContext } = this.props;
    this.props.dispatch(
      showModal('UNION_CIAN_MODAL', {
        title: '',
        history: this.props.history,
        timer: 10,
        header: cianModalContext.title,
        description: cianModalContext.description,
      })
    );
  };

  closeConfirmRegionDialog() {
    this.setState({
      isConfirmRegionDialog: false,
    });
  }

  showConfirmRegionDialog() {
    this.setState({
      isConfirmRegionDialog: true,
    });
  }

  handleEventAddFavoriteGA(apiVersion = 'v1') {
    const {
      user,
      selectedRegion,
      property,
    } = this.props;
    if (property.loaded) {
      const isDailyType = property.data && property.data.period == 2;
      const realtyType = isDailyType ? 'rentDaily' : property.data.realtyType;
      const data = {
        v1: {
          dealType: getCianDealType(getNewFormat(property.data && realtyType).dealType),
          dealTypeExtraCategory: getNewFormat(property.data && realtyType).dealType,
          objectType: getCianObjectType(getNewFormat(property.data && realtyType).realtyType, property.data && property.data.refType),
          realtyType: getNewFormat(property.data && realtyType).realtyType,
          objectTypeId: property.data && property.data.refType,
        },
        v2: {
          dealType: getCianDealType(property.data && property.data.dealType),
          dealTypeExtraCategory: property.data && property.data.dealType,
          objectType: getCianObjectType(property.data && property.data.realtyType, property.data && property.data.objectTypeId),
          realtyType: property.data && property.data.realtyType,
          objectTypeId: property.data && property.data.objectTypeId,
        }
      }
      const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
      const cianGtagEvent = GtagBuilderClass
        .setEvent('oldevent')
        .setEventCategory('favorite')
        .setEventAction('add_from_card')
        .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(property.data) : getCustomUrlCianForApi2(property.data))
        .setUser(user)
        .setPage({
          pageType: 'Card',
          siteType: 'mobile',
          dealType: data[apiVersion].dealType,
          objectType: data[apiVersion].objectType,
          region: selectedRegion.name.replace(/\.$/, ''),
          offerID: property.data.id,
          parentId: getParentId(property.data),
          extra: {
            category: getExtraCategory(data[apiVersion].dealTypeExtraCategory, data[apiVersion].realtyType, data[apiVersion].objectTypeId)
          }
        })
        .setProducts([property.data])
        .getEvent();
      gtagPushEventNewPage(cianGtagEvent);
    }
  }

  handleEventRemoveFavoriteGA(apiVersion = 'v1') {
    const {
      user,
      selectedRegion,
      property,
    } = this.props;
    if (property.loaded) {
      const isDailyType = property.data && property.data.period == 2;
      const realtyType = isDailyType ? 'rentDaily' : property.data.realtyType;
      const data = {
        v1: {
          dealType: getCianDealType(getNewFormat(property.data && realtyType).dealType),
          dealTypeExtraCategory: getNewFormat(property.data && realtyType).dealType,
          objectType: getCianObjectType(getNewFormat(property.data && realtyType).realtyType, property.data && property.data.refType),
          realtyType: getNewFormat(property.data && realtyType).realtyType,
          objectTypeId: property.data && property.data.refType,
        },
        v2: {
          dealType: getCianDealType(property.data && property.data.dealType),
          dealTypeExtraCategory: property.data && property.data.dealType,
          objectType: getCianObjectType(property.data && property.data.realtyType, property.data && property.data.objectTypeId),
          realtyType: property.data && property.data.realtyType,
          objectTypeId: property.data && property.data.objectTypeId,
        }
      }
      const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
      const cianGtagEvent = GtagBuilderClass
        .setEvent('oldevent')
        .setEventCategory('favorite')
        .setEventAction('remove_from_card')
        .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(property.data) : getCustomUrlCianForApi2(property.data))
        .setUser(user)
        .setPage({
          pageType: 'Card',
          siteType: 'mobile',
          dealType: data[apiVersion].dealType,
          objectType: data[apiVersion].objectType,
          region: selectedRegion.name.replace(/\.$/, ''),
          offerID: property.data.id,
          parentId: getParentId(property.data),
          extra: {
            category: getExtraCategory(data[apiVersion].dealTypeExtraCategory, data[apiVersion].realtyType, data[apiVersion].objectTypeId)
          }
        })
        .setProducts([property.data])
        .getEvent();
      gtagPushEventNewPage(cianGtagEvent);
    }
  }

  render() {
    const {
      detectedRegion,
      properties,
      userObjects,
      favoriteProperties,
      currentPageType,
      location,
      selectedRegion,
      complex,
      user,
      regions,
      favorites,
      lists,
      selectedLocality,
      notification,
      routes,
      visibleHeader,
      property,
      route,
    } = this.props;
    const { isConfirmRegionDialog, isOpenMenu } = this.state;
    let leftElement = '';
    let rightElement = '';
    const uid = location.pathname.split('/').slice(2).join('_');


    switch (currentPageType) {
      case 'view':
        leftElement = (properties.loaded || userObjects.loaded || favoriteProperties.loaded) && (
          <ButtonBack onClick={this.handleClickBack} />
        );
        rightElement = (
          <ButtonFavorite
            uid={uid}
            ymClick={() => ym('reachGoal', 'favorites_button_property_page')}
            eventAddFavorite={() => this.handleEventAddFavoriteGA(location.pathname.includes('garages') ? 'v2' : 'v1')}
            eventRemoveFavorite={() => this.handleEventRemoveFavoriteGA(location.pathname.includes('garages') ? 'v2' : 'v1')}
          />
        );
        break;
      case 'search':
        rightElement = <ButtonResetFilter />;
        break;
      case 'map':
        rightElement = <ButtonSearch link={`/${selectedRegion.domain}/`} />;
        break;
      case 'complex':
        leftElement = complex.loaded && <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'user':
        leftElement = user.loaded && <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'contacts':
        leftElement = <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'account':
        // leftElement = !!prevRoute && <ButtonBack onClick={this.handleClickBack} />;
        rightElement = (
          <div style={{ marginTop: 3, marginRight: 12, width: 24, marginLeft: 'auto' }}>
            <RLink to="user/logout">
              <img alt="signOutIcon" src={SignOutIcon} />
            </RLink>
          </div>
        );
        break;
      case 'transactions':
        leftElement = user.loaded && <ButtonBack onClick={this.handleClickBack} />;
        rightElement = (
          <div style={{ display: 'flex' }}>
            <ButtonFilter link={`/${selectedRegion.domain}/user/payments/filter`} />
          </div>
        );
        break;
      case 'userObjects':
        leftElement = userObjects.loaded && <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'priceHistory':
        leftElement = <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'userInvoices':
        leftElement = <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'userInvoiceCreate':
        leftElement = <ButtonBack onClick={this.handleClickBack} />;
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'favorites':
        rightElement = (
          <ButtonChangeView
            currentType={this.props.selectedViewType}
            onChangeType={this.handleChangeType}
          />
        );
        break;
      case 'savedSearch':
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'empty':
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'register':
        rightElement = <div style={{ marginTop: 12, marginRight: 24, width: 24 }}>&nbsp;</div>;
        break;
      case 'region':
      default:
        break;
    }

    const isDetectedRegionCorrect =
      find(regions.items, { code: detectedRegion.code }) !== undefined;
    const dialogText = isDetectedRegionCorrect
      ? `Ваш регион ${detectedRegion && detectedRegion.name}?`
      : detectedRegion && `Не удалось определить регион`;

    const favoritesCount = favorites.filter((item) => !!item);
    return (
      <div>
        {/* <SmartBanner
          title="МЛСН - Недвижимость"
          author={`ООО "МЛСН"`}
          button="ПЕРЕЙТИ"
          storeText={{
            android: 'знать, где искать!',
            ios: 'знать, где искать!',
          }}
          price={{
            android: 'Главное',
            ios: 'Главное',
          }}
        /> */}
        <MainMenu
          region={selectedRegion.domain}
          regionName={selectedRegion.name}
          handleOpenUnionCianModal={this.handleOpenUnionCianModal}
          favorites={favoritesCount.length}
          user={user}
          isOpenMenu={isOpenMenu}
          toggleMenu={this.handleToggleMenu}
          savedSearchTotalCount={this.props.savedSearchTotalCount}
        />
        <Dialog open={isConfirmRegionDialog}>
          {dialogText}
          <DialogActions>
            {isDetectedRegionCorrect ? (
              <>
                <Button onClick={this.handleCancelRegion} color="primary">
                  Нет
                </Button>
                <Button onClick={this.handleConfirmRegion} color="primary" autoFocus>
                  Да
                </Button>
              </>
            ) : (
              <Button onClick={this.handleCancelRegion} color="primary" autoFocus>
                Выбрать вручную
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Header
          iconElementLeft={leftElement}
          iconElementRight={rightElement}
          onMenuButtonTap={this.handleToggleMenu}
        />
        <MainSection style={{ paddingTop: `${visibleHeader ? '56px' : '0px'}` }}>
          {((lists.loaded && selectedLocality.name) ||
            location.pathname === '/region' ||
            location.pathname === '/fullVersion') && (
            <Switch>
              <WrappedRoute path="/region" component={RegionPage} />
              <WrappedRoute path="/full-version" component={FullVersionPage} />
              <WrappedRoute path="/favorites" component={FavoritesPage} />
              <WrappedRoute path="/saved-search" component={SavedSearchPage} />
              <WrappedRoute path="/tinkoff/success" component={TinkoffPage} statusType="success" />
              <WrappedRoute path="/tinkoff/fail" component={TinkoffPage} statusType="fail" />
              <WrappedRoute path="/:region/" component={RegionContainer} />
            </Switch>
          )}
        </MainSection>
        <ModalRoot />
        <YMInitializer accounts={[32755740]} />
        <div>
          {notification && (
            <SnackBar removeNotification={this.handleRemoveNotification} message={notification} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentPageType: state.currentPageType,
    selectedRegion: state.selectedRegion,
    selectedLocality: state.selectedLocality,
    detectedRegion: state.detectedRegion,
    lists: state.lists,
    dictionaries: state.dictionaries,
    regions: state.regions,
    counters: state.counters,
    favorites: state.favorites,
    favoriteProperties: state.favoriteProperties,
    notification: state.notification,
    properties: state.properties,
    complex: state.complex,
    userObjects: state.userObjects,
    user: state.user,
    savedSearchTotalCount: state.savedSearch.totalCount,
    selectedViewType: state.selectedViewType,
    visibleHeader: state.visibleHeader,
    property: state.object,
    cianModalContext: state.appConfig.integrationSettings && state.appConfig.integrationSettings.common_popup,
  };
}

_App.defaultProps = {
  favorites: [],
  properties: {},
  notification: '',
  favoriteProperties: {},
  routes: [],
  params: {},
  savedSearchTotalCount: null,
  property: {
    loaded: false,
    data: {},
  },
};

_App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  lists: listsFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  regions: PropTypes.shape({
    loaded: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  detectedRegion: PropTypes.shape({
    code: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  favorites: PropTypes.arrayOf(PropTypes.object),
  userObjects: userObjectsFromPropTypes.isRequired,
  user: userFromPropTypes.isRequired,
  complex: PropTypes.shape({
    loaded: PropTypes.bool,
    history: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.objectOf(PropTypes.any),
    historyLoaded: PropTypes.bool,
  }).isRequired,
  properties: PropTypes.shape({
    loaded: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
  }),
  notification: PropTypes.string,
  favoriteProperties: PropTypes.shape({
    loaded: PropTypes.bool,
  }),
  currentPageType: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.shape({
    params: PropTypes.shape({
      region: PropTypes.string,
    }),
  }).isRequired,
  savedSearchTotalCount: PropTypes.number,
  selectedViewType: PropTypes.string,
  property: PropTypes.shape({
    loaded: PropTypes.bool,
    data: PropTypes.objectOf(PropTypes.any),
  }),
  cianModalContext: PropTypes.objectOf(PropTypes.any),
};

export default compose(withRouter, connect(mapStateToProps))(_App);
