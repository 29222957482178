import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import moment from 'moment';
import { formatPrice, isLandDacha } from '../utils';
import { loadPriceHistory, setCurrentPageType } from '../actions';
import { Panel } from '../components/ui';
import 'moment/locale/ru';
import styles from '../components/PropertyView/PropertyView.css';
import { UpArrowIcon, DownArrowIcon } from '../components/ui/Icons';
import { dictionaries as dictionariesFromPropTypes } from '../lib/PropTypeValues';

class _PriceHistoryPage extends React.Component {
  state = {
    fullMode: false,
  };

  componentDidMount() {
    const { route, dispatch, dictionaries, match } = this.props;
    const path = route.path.replace(/\/.*/, '');
    const sectionMap = {
      sales: 'sale/apartments',
      rents: 'rent/apartments',
      lands: 'sale/lands',
      rentBusiness: 'rent/commercial',
      saleBusiness: 'sale/commercial',
      garages: 'sale/garages',
    };

    const section = sectionMap[path];

    dispatch(loadPriceHistory(match.params.id, section, dictionaries));
    dispatch(setCurrentPageType('priceHistory'));
  }

  handleFullMode = () => {
    this.setState({ fullMode: true });
  };

  getDate = (timestamp) => {
    return moment(timestamp * 1000).format('DD MMMM YYYY');
  };

  getDeltaPercent = (item) => {
    const prevPrice = item.newPrice - item.deltaPrice;
    if (!prevPrice) {
      return '+100';
    }
    const percent = ((item.deltaPrice / prevPrice) * 100).toFixed(1);
    const sign = percent > 0 ? '+' : ' - ';

    return sign + percent.replace('-', '');
  };

  getPercent = (percent) => {
    return percent > 0 ? (
      <span style={{ color: '#94c133' }}>{percent}%</span>
    ) : (
      <span style={{ color: '#ff3232' }}>{percent}%</span>
    );
  };

  getPrice = (item) => {
    return item.newPrice !== 0 ? formatPrice(item.newPrice) : 'договорная';
  };

  getStartPrice = (history) => {
    const price = history[history.length - 1].newPrice - history[history.length - 1].deltaPrice;
    return price !== 0 ? formatPrice(price) : 'договорная';
  };

  getArrow = (deltaPrice) => {
    return deltaPrice > 0 ? (
      <UpArrowIcon color="#94c133" size="15" />
    ) : (
      <DownArrowIcon color="#ff3232" size="15" />
    );
  };

  render() {
    const { property } = this.props;
    const { fullMode } = this.state;
    const propertyData = property.loaded && property.data;
    const history = property.loaded && property.data.priceHistory;
    let startPrice = 0;

    if (history) {
      history.sort((a, b) => b.timestamp - a.timestamp);
      startPrice = this.getStartPrice(history);
    }

    const landArea = propertyData.refSquare ? `${propertyData.refSquare} сот.` : '';
    const houseArea = propertyData.areaTotal ? `${propertyData.areaTotal} м²` : '';
    const square = isLandDacha(propertyData) ? landArea : houseArea;
    const floor = [propertyData.floor || '-', propertyData.floorTotal || '-'].join('/');

    return (
      <Loader loaded={property.loaded}>
        <section className={styles.objMainInfo}>
          <div>{propertyData.price ? formatPrice(propertyData.price) : 'Цена договорная'}</div>
          <div>
            {[
              propertyData.realtyTypeShortName,
              square,
              propertyData.floor && propertyData.floorTotal ? `${floor} эт.` : '',
            ]
              .filter((n) => n)
              .join(', ')}
          </div>
          <div>{[propertyData.localityFias, propertyData.refStreet].join(', ')}</div>
        </section>
        <Panel
          title="История изменения цены"
          name="history"
          titleClassName={styles.panelTitle}
          id="seller"
          containerStyle={{ padding: '10px 15px' }}
        >
          <table style={{ fontSize: 13, width: '100%' }}>
            {property.loaded &&
              history &&
              history.map((item, i) => {
                return (
                  <tr
                    key={`${this.getDate(item.timestamp)}_${this.getPrice(item)}`}
                    className={i > 5 && !fullMode ? styles.hide : null}
                  >
                    <td style={{ paddingRight: 15, whiteSpace: 'nowrap' }} width="45%">
                      {this.getDate(item.timestamp)}
                    </td>
                    <td style={{ paddingRight: 15, whiteSpace: 'nowrap' }} width="40%">
                      {this.getPrice(item)}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }} width="10%">
                      {this.getPercent(this.getDeltaPercent(item))}
                    </td>
                    <td width="5%">{this.getArrow(this.getDeltaPercent(item))}</td>
                  </tr>
                );
              })}
            <tr className={!fullMode && history.length > 5 ? null : styles.hide}>
              <td colSpan={4} style={{ textAlign: 'center', position: 'relative' }}>
                <div
                  role="button"
                  style={{
                    color: '#ff7f29',
                    fontSize: 16,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '20px 0 0 0',
                    background:
                      'linear-gradient(0deg,#fff,hsla(0,0%,100%,.8) 80%,hsla(0,0%,100%,0))',
                  }}
                  onClick={this.handleFullMode}
                  onKeyDown={this.handleFullMode}
                >
                  подробнее
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <hr
                  style={{
                    margin: 0,
                    marginTop: 8,
                    border: 0,
                    borderBottom: '1px solid rgba(0,0,0,.12)',
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: 10, whiteSpace: 'nowrap' }} width="45%">
                Начальная цена
              </td>
              <td colSpan={3} style={{ paddingTop: 10, whiteSpace: 'nowrap' }} width="55%">
                {startPrice}
              </td>
            </tr>
          </table>
        </Panel>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    dictionaries: state.dictionaries,
    property: state.priceHistory,
  };
}

_PriceHistoryPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  property: PropTypes.shape({
    loaded: PropTypes.bool,
    data: PropTypes.shape({
      priceHistory: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export default connect(mapStateToProps)(_PriceHistoryPage);
