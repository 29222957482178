import PropTypes from 'prop-types';
import React from 'react';

import ReactDOM from 'react-dom';

export default class StaticMap extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = { src: '' };
  }

  componentDidMount() {
    const { lat, lon, zoom } = this.props;
    const { clientWidth } = ReactDOM.findDOMNode(this);
    this.setState({
      src: `https://static-maps.yandex.ru/1.x/?ll=${lon},${lat}&size=${clientWidth},${
        clientWidth / 1.5
      }&z=${zoom}&l=map&pt=${lon},${lat},org`,
    });
  }

  render() {
    const { src } = this.state;
    return (
      <div>
        <img src={src} alt="Карта" />
      </div>
    );
  }
}

StaticMap.defaultProps = {};

StaticMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  zoom: PropTypes.string.isRequired,
};
