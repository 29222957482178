import GtagEvent from './gtagEvent';
import {
  getCianObjectType,
  getCustomUrlCianForApi2,
  getExtraCategory,
  getParentId,
  getCianDealType,
} from './gtagUtils';

class GtagEventBuilderV2 extends GtagEvent {
  setEvent(event) {
    this.event = event;
    return this;
  }
  setEventCategory(eventCategory) {
    this.eventCategory = eventCategory;
    return this;
  }
  setEventAction(eventAction) {
    this.eventAction = eventAction;
    return this;
  }
  setEventLabel(eventLabel) {
    this.eventLabel = eventLabel;
    return this;
  }
  setUser(user) {
    if (user.token) {
      this.user = {
        userId: user.profile.id,
        type: user.profile.isRealtor ? 'realtor' : 'owner'
      };
      return this;
    }
    this.user = { type: 'not_authorized' };
    return this;
  }
  setPage(options) {
    this.page = {
      ...options
    };
    return this;
  }
  setProducts(products) {
    if (!products.length) {
      this.products = [];
      return this;
    }

    this.products = products.map(item => {
      return {
        id: item.id,
        brand: item.author === null || (item.author && item.author.id) === undefined ? null :
         `${item.author.id}/${item.author.isRealtor ? 'realtor' : 'owner'}`,
        dealType: getCianDealType(item.dealType),
        objectType: getCianObjectType(item.realtyType, Number(item.objectTypeId)),
        category: `${getCianDealType(item.dealType)}/${getCianObjectType(item.realtyType,
          Number(item.objectTypeId))}`,
        region: item.location && item.location.region && item.location.region.fullName.replace(/\.$/, ''),
        owner: item.author && item.author.userTypeId === 3,
        parentId: getParentId(item),
        customURL: getCustomUrlCianForApi2(item),
        extra: {
          category: getExtraCategory(item.dealType, item.realtyType, Number(item.objectTypeId))
        }
      };
    });
    return this;
  }
  getEvent() {
    if (!this.event) {
      throw new Error(`${this.constructor.name} получил некорректное значение event`);
    } else if (!this.eventCategory) {
      throw new Error(`${this.constructor.name} получил некорректное значение eventCategory`);
    } else if (!this.eventAction) {
      throw new Error(`${this.constructor.name} получил некорректное значение eventAction`);
    } else if (!this.eventLabel) {
      throw new Error(`${this.constructor.name} получил некорректное значение eventLabel`);
    } else if (!this.user.type) {
      throw new Error(`${this.constructor.name} получил некорректный объект user`);
    } else if (!this.page.pageType && !this.page.siteType) {
      throw new Error(`${this.constructor.name} получил некорректный объект page`);
    }
    const obj = new GtagEventBuilderV2(this);
    return Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  }
}
export default GtagEventBuilderV2;
