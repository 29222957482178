import PropTypes from 'prop-types';
import React from 'react';
import RLink from '../components/RLink';
import cn from './assets/tinkoff.css';

const TinkoffPage = ({ statusType }) => {
  return (
    <div style={{ padding: '20px 10px', textAlign: 'center' }}>
      {statusType === 'success' ? (
        <div className={cn.success}>
          <h3 className={cn.title}>Платеж успешно выполнен.</h3>
          <span>
            В&nbsp;ближайшее время средства поступят на ваш счет в&nbsp;личном&nbsp;кабинете.
          </span>
          <RLink className={cn.button} to="user/account">
            Перейти в профиль
          </RLink>
        </div>
      ) : (
        <div className={cn.fail}>
          <h3 className={cn.title}>Платеж отклонен.</h3>
          <RLink className={cn.button} to="user/account">
            Перейти в профиль
          </RLink>
        </div>
      )}
    </div>
  );
};

TinkoffPage.propTypes = {
  statusType: PropTypes.string.isRequired,
};

export default TinkoffPage;
