import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import LogoCianIcon from './logo_cian.svg';
import WhiteLogoCianIcon from './logo_cian_white.svg';
import styles from './LogoCian.css';

const LogoCian = ({ style, whiteIcon }) => {
  return (whiteIcon ?
    <img
      className={classNames(styles.root)}
      style={style}
      src={WhiteLogoCianIcon}
      alt="cian.ru"
    />
    :
    <img
      className={classNames(styles.root)}
      style={style}
      src={LogoCianIcon}
      alt="cian.ru"
    />
  );
};

LogoCian.propTypes = {
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  whiteIcon: PropTypes.bool
};

LogoCian.defaultProps = {
  whiteIcon: false
};

export default LogoCian;
