import { getNewFormat } from '../../utils'

export function getCianObjectType(type, objectTypeId) {
  switch (type) {
    case 'apartments':
      if (objectTypeId === 101 || objectTypeId === 103 || objectTypeId === 104) {
        return 'suburban';
      }
      return 'flat_old,flat_new';
    case 'lands':
      return 'suburban';
    case 'commercials':
      return 'commercial';
    case 'garages':
      return 'commercial';
    default:
      return null;
  }
}

export function getUrlObjectType(type, objectTypeId) {
  switch (type) {
    case 'apartments':
      if (objectTypeId === 101 || objectTypeId === 103 || objectTypeId === 104) {
        return 'suburban';
      }
      return 'flat';
    case 'lands':
      return 'suburban';
    case 'commercials':
      return 'commercial';
    case 'garages':
      return 'commercial';
    default:
      return null;
  }
}

export function getExtraCategory(dealType, realtyType, objectType) {
  if (dealType === 'sale' && realtyType === 'apartments') {
    if (objectType === 0) {
      return 'roomSale';
    }
    if (objectType === 1 ||
        objectType === 2 ||
        objectType === 3 ||
        objectType === 4 ||
        objectType === 5) {
      return 'flatSale';
    }
    return 'flatSale';
  }
  if (dealType === 'sale' && realtyType === 'lands') {
    if (objectType === 36) {
      return 'countryHouseSale';
    }
    if (objectType === 101) {
      return 'houseSale';
    }
    if (objectType === 103) {
      return 'cottageSale';
    }
    if (objectType === 104) {
      return 'townhouseSale';
    }
    return 'landSale';
  }
  if (dealType === 'sale' && realtyType === 'commercials') {
    if (objectType === 35) {
      return 'commercialLandSale';
    }
    if (objectType === 37) {
      return 'officeSale';
    }
    if (objectType === 38) {
      return 'shoppingAreaSale';
    }
    if (objectType === 39) {
      return 'warehouseSale';
    }
    if (objectType === 42) {
      return 'businessSale';
    }
    if (objectType === 43) {
      return 'freeAppointmentObjectSale';
    }
    return 'officeSale';
  }
  if (dealType === 'sale' && realtyType === 'garages') {
    if (objectType === 311) {
      return 'metalGarageSale';
    }
    if (objectType === 264) {
      return 'capitalGarageSale';
    }
    if (objectType === 306) {
      return 'parkingSale';
    }
    return 'capitalGarageSale';
  }
  if (dealType === 'rent' && realtyType === 'apartments') {
    if (objectType === 0) {
      return 'roomRent';
    }
    if (objectType === 1 ||
        objectType === 2 ||
        objectType === 3 ||
        objectType === 4 ||
        objectType === 5) {
      return 'flatRent';
    }
    if (objectType === 101) {
      return 'houseRent';
    }
    if (objectType === 103) {
      return 'cottageRent';
    }
    if (objectType === 104) {
      return 'townhouseRent';
    }
    return 'flatRent';
  }
  if (dealType === 'rent' && realtyType === 'commercials') {
    if (objectType === 35) {
      return 'commercialLandRent';
    }
    if (objectType === 37) {
      return 'officeRent';
    }
    if (objectType === 38) {
      return 'shoppingAreaRent';
    }
    if (objectType === 39) {
      return 'warehouseRent';
    }
    if (objectType === 42) {
      return 'businessRent';
    }
    if (objectType === 43) {
      return 'freeAppointmentObjectRent';
    }
    return 'officeRent';
  }
  if (dealType === 'rentDaily' && realtyType === 'apartments') {
    if (objectType === 1 ||
        objectType === 2 ||
        objectType === 3 ||
        objectType === 4 ||
        objectType === 5) {
      return 'dailyFlatRent';
    }
    if (objectType === 101 || objectType === 103 || objectType === 104) {
      return 'dailyHouseRent';
    }
    return 'dailyFlatRent';
  }
  return null;
}

export function getCustomUrlCian(obj) {
  const dealType = getNewFormat(obj.realtyType).dealType.includes('rent');
  const isDailyType = obj.period == 2;
  const realtyType = getUrlObjectType(getNewFormat(isDailyType ? 'rentDaily' : obj.realtyType).realtyType, obj.refType);
  return `/${dealType ? 'rent' : 'sale'}/${realtyType}/object_type=/spec=${obj.userType === 0 ? 'realtor' : 'none'}${dealType ?
    isDailyType ? '/rent_type=daily/' : '/rent_type=long/'
    : '/'}${obj.id}/from_developer=0/repres=0/owner=${obj.userType === 3 ? 1 : 0}`;
}

export function getCustomUrlCianForApi2(obj) {
  const dealType = obj.dealType.includes('rent');
  const dailyRentType = obj.dealType.includes('Daily');
  const realtyType = getUrlObjectType(obj.realtyType, obj.objectTypeId);
  return `/${dealType ? 'rent' : 'sale'}/${realtyType}/object_type=/spec=${obj.author && obj.author.userTypeId === 0 ? 'realtor' : 'none'}${dealType ?
    dailyRentType ? '/rent_type=daily/' : '/rent_type=long/'
    : '/'}${obj.id}/from_developer=0/repres=0/owner=${obj.author && obj.author.userTypeId === 3 ? 1 : 0}`;
}

export function getCianDealType(dealType) {
  switch (dealType) {
    case 'sale':
      return 'sale';
    case 'rent':
      return 'rent_long';
    case 'rentDaily':
      return 'rent_day';
    default:
      return 'other';
  }
}

export function getParentId(property) {
  if (property.residentialComplex && property.residentialComplex.id) {
    return property.residentialComplex.id;
  }
  if (property.township && property.township.id) {
    return property.township.id;
  }
  if (property.rcid) {
    return property.rcid;
  }
  return null;
}

export function gtagPushEventNewPage(options) {
  window.dataLayer && window.dataLayer.push({ ...options });
}
