import PropTypes from 'prop-types';
import React from 'react';

const FormRow = ({ isGroup, children }) => {
  return <div style={{ marginBottom: isGroup ? '0px' : '15px' }}>{children}</div>;
};

FormRow.defaultProps = {
  isGroup: false,
};

FormRow.propTypes = {
  isGroup: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default FormRow;
