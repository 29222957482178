import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '../../components/ui';
import SubmitButton from '../../components/SubmitButton';
import FormRow from '../../components/SearchForm/FormRow';
import cn from '../../components/User/UserLogin.css';
import config from '../../config';
import { setCurrentPageType } from '../../actions';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';
import FormPaper from '../../components/SearchForm/FormPaper/FormPaper';


class _RechargeBalancePage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('user'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  render() {
    const { selectedRegion } = this.props;
    const region = selectedRegion;
    const domain = region && region.domain ? region.domain : 'omsk';

    return (
      <div className={cn.paymentPage}>
        <FormPaper>
          <div className={cn.paymentForm}>
            <div className={cn.title}>Пополнить баланс</div>
            <form action={config.recharge_url} method="GET">
              <FormRow>
                <TextField
                  required="required"
                  min="0"
                  step="1"
                  pattern="\d+"
                  placeholder="Сумма пополнения"
                  name="amount"
                  type="number"
                />
              </FormRow>
              <input type="hidden" name="region" value={domain} />
              <div style={{ position: 'relative', minHeight: '57px' }}>
                <SubmitButton value="Пополнить" />
              </div>
            </form>
          </div>
        </FormPaper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
  };
}

_RechargeBalancePage.propTypes = {
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_RechargeBalancePage);
