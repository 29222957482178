import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import serialize from 'form-serialize';
import { TextField } from '../ui';
import { Checkbox, FormControlLabel } from '../ui/material';
import SubmitButton from '../SubmitButton';
import FormRow from '../SearchForm/FormRow';
import cn from './UserRegisterRestore.css';
import {
  registerSMS as actionRegisterSMS,
  userRegister as actionUserRegister,
} from '../../actions';
import {
  selectedRegion as selectedRegionFromPropTypes,
  user as userFromPropTypes,
} from '../../lib/PropTypeValues';

class UserRegister extends React.Component {
  state = {
    timer: 0,
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleSubmit(e) {
    const { dispatch, user, history, selectedRegion } = this.props;
    e.preventDefault();
    const formdata = new FormData(ReactDOM.findDOMNode(this.form));

    dispatch(actionUserRegister(formdata)).then(() => {
      if (user.token) {
        history.push(`/${selectedRegion.domain}/user/account`);
      }
    });
  }

  handleSMS = (e) => {
    const { dispatch, registerSMS } = this.props;
    e.preventDefault();
    const formdata = serialize(ReactDOM.findDOMNode(this.form), { hash: true });
    dispatch(actionRegisterSMS(formdata.phone1)).then(() => {
      if (registerSMS.loaded && !registerSMS.error) {
        this.runTimer();
      }
    });
  };

  getError(field) {
    const {
      userRegister: { errors },
    } = this.props;
    if (errors[field]) {
      return errors[field][0];
    }
    return null;
  }

  runTimer() {
    const { timer } = this.state;
    this.setState({
      timer: 30,
    });
    this.timer = setInterval(() => {
      if (timer) {
        this.setState({
          timer: timer - 1,
        });
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  render() {
    const { registerSMS, userRegister, type, selectedRegion, city } = this.props;
    const { timer } = this.state;

    return (
      <div className={cn.register}>
        <form
          onSubmit={this.handleSubmit.bind(this)}
          ref={(n) => {
            this.form = n;
          }}
          action=""
        >
          <input type="hidden" name="auser_type_id" value={type === 'realtor' ? 0 : 3} />
          <input type="hidden" name="city" value={city} />
          <FormRow>
            <TextField
              placeholder="Ф.И.О."
              name="full_name"
              type="text"
              isError={this.getError('full_name')}
              errorText={this.getError('full_name')}
            />
          </FormRow>
          {type === 'realtor' && (
            <FormRow>
              <TextField
                placeholder="Агентство"
                name="agencyName"
                type="text"
                isError={this.getError('agencyName')}
                errorText={this.getError('agencyName')}
              />
            </FormRow>
          )}
          <div className={cn.phoneBlock}>
            <div className={cn.phone}>
              <div className={cn.phoneLabel}>
                <span>+7</span>
              </div>
              <div className={cn.phoneField}>
                <TextField
                  ref={(n) => {
                    this.phone = n;
                  }}
                  placeholder="Телефон"
                  name="phone1"
                  type="text"
                  isError={registerSMS.error || this.getError('phone1')}
                  errorText={registerSMS.error || this.getError('phone1')}
                />
              </div>
            </div>
            <div style={{ position: 'relative', minHeight: 40, marginBottom: 15 }}>
              <Loader loaded={!registerSMS.loading}>
                <button
                  className={cn.smsButton}
                  onClick={this.handleSMS}
                  disabled={!!timer}
                  type="button"
                >
                  {registerSMS.loaded && !registerSMS.error ? (
                    <span>Отправить код повторно{timer ? ` (${timer})` : ''}</span>
                  ) : (
                    <span>Отправить код подтверждения</span>
                  )}
                </button>
              </Loader>
            </div>
          </div>
          <FormRow>
            <TextField
              placeholder="Код из СМС"
              name="phone1_approve_code"
              type="text"
              isError={this.getError('phone1_approve_code')}
              errorText={this.getError('phone1_approve_code')}
            />
          </FormRow>
          {type === 'realtor' && (
            <FormRow>
              <TextField
                placeholder="Адрес"
                name="address"
                type="text"
                isError={this.getError('address')}
                errorText={this.getError('address')}
              />
            </FormRow>
          )}
          <FormRow>
            <TextField
              placeholder="E-mail"
              name="email"
              type="email"
              isError={this.getError('email')}
              errorText={this.getError('email')}
            />
          </FormRow>
          <FormRow>
            <TextField
              placeholder="Логин"
              name="name"
              type="text"
              isError={this.getError('name')}
              errorText={this.getError('name')}
            />
          </FormRow>
          <FormRow>
            <TextField
              placeholder="Пароль"
              name="passwd"
              type="password"
              isError={this.getError('passwd')}
              errorText={this.getError('passwd')}
            />
          </FormRow>
          <FormRow>
            <TextField
              placeholder="Повторите пароль"
              name="passwd_repeat"
              type="password"
              isError={this.getError('passwd_repeat')}
              errorText={this.getError('passwd_repeat')}
            />
          </FormRow>
          <FormRow>
            <FormControlLabel
              control={<Checkbox name="confirm" value={1} />}
              label="Я соглашаюсь с правилами использования сайта MLSN.RU"
            />
            {this.getError('confirm') && (
              <div style={{ color: '#F44336', padding: 3 }}>{this.getError('confirm')}</div>
            )}
          </FormRow>
          <FormRow>
            <a
              className={cn.rulesLink}
              href={`https://${selectedRegion.domain}.mlsn.ru/page/default/rules/`}
              target="_blank"
              rel="noreferrer"
            >
              Правила использования сайта MLSN.RU
            </a>
          </FormRow>
          <div style={{ position: 'relative', minHeight: '57px' }}>
            <Loader loaded={!userRegister.loading}>
              <SubmitButton value="Зарегистрироваться" />
            </Loader>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
    userRegister: state.userRegister,
    registerSMS: state.registerSMS,
    user: state.user,
  };
}

UserRegister.defaultProps = {
  city: 55,
  type: 'owner',
  user: {},
  registerSMS: {},
  userRegister: {},
};

UserRegister.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  city: PropTypes.number,
  type: PropTypes.oneOf(['realtor', 'owner']),
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  user: userFromPropTypes,
  registerSMS: PropTypes.shape({
    error: PropTypes.string,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
  userRegister: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
  }),
};

export default connect(mapStateToProps)(UserRegister);
