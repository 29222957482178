export const TextFieldStyle = {
  root: {
    width: '100%',
    fontSize: '15px',
    '& label': {
      fontSize: '15px',
    },
    '& label.Mui-focused': {
      color: '#EE7422',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D8D8D8',
      borderWidth: '1px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#D8D8D8',
      borderWidth: '1px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#EE7422',
      borderWidth: '1px',
    },
  }
}