import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontIcon, IconButton } from './ui/material';

const ButtonFilter = ({ link }) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <IconButton>
        <FontIcon className="material-icons" style={{ color: '#FF7F29' }}>
          tune
        </FontIcon>
      </IconButton>
    </Link>
  );
};

ButtonFilter.propTypes = {
  link: PropTypes.string.isRequired,
};

export default ButtonFilter;
