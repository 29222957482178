import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TabToggle from '../../components/ui/TabToggle';
import TabsToggle from '../../components/ui/TabsToggle';
import cn from '../../components/User/UserRegister.css';
import { UserRegister } from '../../components/User';
import { setSection, setCurrentPageType } from '../../actions';
import {
  selectedRegion as selectedRegionFromPropTypes,
  user as userFromPropTypes,
} from '../../lib/PropTypeValues';
import IntegrationCianImage from '../../components/ui/assets/integration_register_image.svg';


class _RegisterPage extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      section: 1,
    };
    this.handleSelectSection = this.handleSelectSection.bind(this);
    this.registerRef = React.createRef();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { user, history, selectedRegion } = this.props;
    if (user.token) {
      history.push(`/${selectedRegion.domain}/user/account`);
    }
  }

  componentDidMount() {
    this.props.dispatch(setCurrentPageType('register'));
  }

  handleSelectSection(event, sectionId) {
    const { dispatch } = this.props;
    this.setState({
      section: sectionId,
    });
    dispatch(setSection(sectionId));
  }

  handleExecuteScroll = () => this.registerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  render() {
    const {
      selectedRegion,
      history,
      isRegistrationClosed,
      integrationBlockConfig,
    } = this.props;
    const { section } = this.state;
    return (
      <div style={{ height: window.innerHeight - 56, overflow: 'auto' }}>
        {integrationBlockConfig && integrationBlockConfig.is_visible &&
          <div className={cn.integrationContainer}>
            <div className={cn.title}>
              {integrationBlockConfig && integrationBlockConfig.title}
            </div>
            <div className={cn.description}>
              {integrationBlockConfig && integrationBlockConfig.description}
            </div>
            <div className={cn.buttons}>
              <a className={cn.successBtn} href={integrationBlockConfig && integrationBlockConfig.link}>Перейти на Циан</a>
              {!isRegistrationClosed &&
                <div className={cn.closeBtn} onClick={this.handleExecuteScroll}>Зарегистрироваться на МЛСН</div>
              }
            </div>
            <img className={cn.integrationImage} src={IntegrationCianImage} />
          </div>
        }
        {!isRegistrationClosed &&
          <div ref={this.registerRef}>
            <TabsToggle
              onChange={this.handleSelectSection}
              variant="fullWidth"
              value={section}
              style={{ margin: '0 10px 10px' }}
            >
              <TabToggle label="Специалист" />
              <TabToggle label="Частное лицо" />
            </TabsToggle>
            <UserRegister
              type={section ? 'owner' : 'realtor'}
              city={selectedRegion.code}
              history={history}
            />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
    user: state.user,
    integrationBlockConfig: state.appConfig.integrationSettings && state.appConfig.integrationSettings.registration_popup,
    isRegistrationClosed: state.appConfig.integrationSettings && state.appConfig.integrationSettings.is_registration_closed,
  };
}

_RegisterPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: userFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_RegisterPage);
