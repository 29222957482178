import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './assets/TextField.css';

export default class TextField extends Component {
  render() {
    const { isError, multiLine, inputRef, errorText } = this.props;
    return (
      <div>
        <div className={styles[isError ? 'rootError' : 'root']}>
          {multiLine ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <textarea className={styles.input} {...this.props} />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <input ref={inputRef} type="text" className={styles.input} {...this.props} />
          )}
        </div>

        {isError && <div className={styles.errorText}>{errorText}</div>}
      </div>
    );
  }
}

TextField.defaultProps = {
  inputRef: () => {},
  multiLine: false,
  errorText: '',
  isError: false,
};

TextField.propTypes = {
  inputRef: PropTypes.func,
  errorText: PropTypes.string,
  isError: PropTypes.bool,
  multiLine: PropTypes.bool,
};
