import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import qs from 'query-string';
import { ComplexListView } from '../components/ComplexView';
import {
  loadComplexApartments,
  loadMoreComplexApartments,
  setCurrentPageType,
  setScrollPosition,
} from '../actions';
import { pluralize } from '../utils';
import { selectedRegion as selectedRegionFromPropTypes } from '../lib/PropTypeValues';
import {
  gtagPushEventNewPage,
  getCianDealType,
  getCianObjectType,
  getExtraCategory,
  getCustomUrlCian,
  getCustomUrlCianForApi2
} from '../classes/analytics/gtagUtils';
import GtagBuilder from '../classes/analytics/gtagEventBuilder';
import GtagBuilderV2 from '../classes/analytics/gtagEventBuilderV2';

class _ComplexApartments extends Component {
  constructor(...args) {
    super(...args);
    const { location } = this.props;
    this.state = {
      page: Number(qs.parse(location.search).page) || 1,
    };
  }

  componentDidMount() {
    const { dispatch, match, location, selectedRegion } = this.props;
    const query = qs.parse(location.search);

    const filter = {
      regionId: selectedRegion.code,
      residentialComplexId: match.params.id,
      'per-page': 10,
      ...query,
    };
    dispatch(loadComplexApartments(filter));
    dispatch(setCurrentPageType('complex'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  handleClickLoadMore = () => {
    const { apartments, location, selectedRegion, match, dispatch } = this.props;
    const { page } = this.state;
    const nextPage = page + 1;
    const query = qs.parse(location.search);
    delete query.page;

    // eslint-disable-next-line no-underscore-dangle
    const meta = apartments.data._meta || {};
    if (meta.currentPage === meta.pageCount) {
      return;
    }

    const filter = {
      regionId: selectedRegion.code,
      residentialComplexId: match.params.id,
      page: nextPage,
      'per-page': 10,
      ...query,
    };

    this.setState({
      loading: true,
      page: nextPage,
    });

    dispatch(loadMoreComplexApartments(filter)).then(() => this.setState({ loading: false }));
  };

  handleEventAddFavoriteGA(apiVersion = 'v1', announcement) {
    const {
      user,
      selectedViewType,
      currentFilterType,
      selectedSorting,
      selectedRegion,
      apartments
    } = this.props;
    const { page } = this.state;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('favorite')
      .setEventAction(selectedViewType === 'photo' ? 'add_from_list': 'add_from_table')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(announcement) : getCustomUrlCianForApi2(announcement))
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        sortType: selectedSorting ? selectedSorting : 'default',
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: apartments.data ? Number(apartments.data.items.length) : 0,
        pageNumber: Number(page),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleEventRemoveFavoriteGA(apiVersion = 'v1', announcement) {
    const {
      user,
      selectedViewType,
      currentFilterType,
      selectedSorting,
      selectedRegion,
      apartments
    } = this.props;
    const { page } = this.state;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('favorite')
      .setEventAction(selectedViewType === 'photo' ? 'remove_from_list': 'remove_from_table')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(announcement) : getCustomUrlCianForApi2(announcement))
      .setUser(user)
      .setPage({
        pageType: 'Listing',
        siteType: 'mobile',
        listingType: selectedViewType === 'photo' ? 'list' : 'table',
        dealType: getCianDealType(currentFilterType.dealType),
        objectType: getCianObjectType(currentFilterType.realtyType),
        sortType: selectedSorting ? selectedSorting : 'default',
        region: selectedRegion.name.replace(/\.$/, ''),
        offersQty: apartments.data ? Number(apartments.data.items.length) : 0,
        pageNumber: Number(page),
        extra: {
          category: getExtraCategory(currentFilterType.dealType, currentFilterType.realtyType)
        }
      })
      .setProducts([announcement])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  render() {
    const { apartments, dispatch, selectedRegion } = this.props;
    const { loading } = this.state;
    const items = apartments.data ? apartments.data.items : [];
    // eslint-disable-next-line no-underscore-dangle
    const meta = apartments.data ? apartments.data._meta || {} : {};
    return (
      <Loader loaded={apartments.loaded}>
        <div
          style={{
            padding: '5px 8px 5px',
            fontSize: 14,
            color: '#666',
            textAlign: 'center',
          }}
        >
          {meta.totalCount} {pluralize(meta.totalCount, 'объявление', 'объявления', 'объявлений')}
        </div>
        <ComplexListView
          items={items}
          loading={loading}
          canLoadMore={meta.currentPage !== meta.pageCount}
          updateScrollPosition={(scrollPosition) => {
            dispatch(setScrollPosition(scrollPosition));
          }}
          onLoad={this.handleClickLoadMore}
          domain={selectedRegion.domain}
          eventAddFavorite={(announcement) => this.handleEventAddFavoriteGA('v2', announcement)}
          eventRemoveFavorite={(announcement) => this.handleEventRemoveFavoriteGA('v2', announcement)}
        />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    apartments: state.complexApartments,
    selectedRegion: state.selectedRegion,
    lists: state.lists,
    user: state.user,
    selectedViewType: state.selectedViewType,
    selectedSorting: state.selectedSorting,
    currentFilterType: state.filterType,
  };
}

_ComplexApartments.defaultProps = {
  apartments: {},
};

_ComplexApartments.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  apartments: PropTypes.objectOf(PropTypes.any),
};

export default connect(mapStateToProps)(_ComplexApartments);
