/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from '../ui';
import cn from './UserTariff.css';
import FormPaper from '../SearchForm/FormPaper/FormPaper';
import { getTableFactory } from '../../classes/builder/tariffs/builder';


export default class UserTariff extends React.Component {
  constructor(...props) {
    super(...props);
    const { tariff } = this.props;
    this.factoryTable = getTableFactory(tariff);
  }
  render () {
    const { tariff } = this.props;
    return (
      <div className={cn.userTariff}>
        <Panel
          title="Ваш тариф"
          containerStyle={{ padding: '0' }}
          titleStyle={{ padding: '16px 16px 0 16px' }}
        >
          <FormPaper>
            <div className={cn.base}>
              <div className={cn.tariffName}>
                {`${tariff.name}:`}
              </div>
              <div className={cn.tariffDescription}>
                {tariff.description}
              </div>
            </div>
            <div className={cn.tariffTable}>
              {this.factoryTable.getTable()}
            </div>
          </FormPaper>
        </Panel>
      </div>
    );
  }
};

UserTariff.defaultProps = {
  tariff: null,
};

UserTariff.propTypes = {
  tariff: PropTypes.string,
};
