import PropTypes from 'prop-types';
import React from 'react';
import ym from 'react-yandex-metrika';
import { FontIcon, IconButton, MenuItem, Menu } from './ui/material';
import SwapVert from './ui/assets/swap_vert.svg';

export default class ButtonSort extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.handleTouch();
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleChange = (param) => {
    const { onChangeSort } = this.props;
    setTimeout(() => {
      onChangeSort(param.id, param.name);
    }, 300);
    this.handleClose();
  };

  handleTouch = () => {
    ym('reachGoal', 'sort_button');
  };

  renderIconButton() {
    return (
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
        <img src={SwapVert} alt="swap-vert" />
      </IconButton>
    );
  }

  render() {
    const { sortParams, currentSort } = this.props;
    const { anchorEl } = this.state;
    const itemStyle = {
      base: {
        fontSize: 14,
      },
      innerDivStyle: {
        color: 'black',
      },
    };

    if (!sortParams || !sortParams.length) {
      return null;
    }

    return (
      <>
        {this.renderIconButton()}
        <Menu
          id="sort-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {sortParams.map((param, i) => {
            const rightIcon =
              currentSort === param.id ? (
                <FontIcon
                  style={{ position: 'relative', color: '#9d9d9d' }}
                  className="material-icons"
                >
                  done
                </FontIcon>
              ) : null;

            return (
              <MenuItem
                index={i}
                key={param.id}
                value={param.id}
                rightIcon={rightIcon}
                primaryText={param.name}
                style={itemStyle.base}
                innerDivStyle={itemStyle.innerDivStyle}
                onClick={() => this.handleChange(param)}
              >
                {param.name} {rightIcon}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

ButtonSort.defaultProps = {
  sortParams: [],
};

ButtonSort.propTypes = {
  currentSort: PropTypes.string.isRequired,
  sortParams: PropTypes.arrayOf(PropTypes.any),
  onChangeSort: PropTypes.func.isRequired,
};
