import PropTypes from 'prop-types';
import React from 'react';
import { find } from 'lodash';
import styles from './PropertyView.css';
import { lists as listsFromPropTypes } from '../../lib/PropTypeValues';

export default class SalesInfo extends React.Component {
  renderArea = (area) => {
    return area > 0 ? <span>{area} м²</span> : 0;
  };

  renderNotFilled = () => {
    return <span style={{ color: '#bbb' }}>&mdash;</span>;
  };

  renderRow(param) {
    let value = '';
    switch (param.value) {
      case 0:
        value = this.renderNotFilled();
        break;
      case 1:
        value = 'есть';
        break;
      case 2:
        value = 'нет';
        break;
      default:
        value = param.value || this.renderNotFilled();
    }

    return (
      <tr key={param.name}>
        <td className={styles.paramName}>{param.name}</td>
        <td className={styles.paramValue}>{value}</td>
      </tr>
    );
  }

  render() {
    const {
      lists,
      renovationType,
      numDeadline,
      yearBuilt,
      type,
      buildingMaterial,
      floor,
      floorTotal,
      sqTotal,
      sqTiving,
      sqKitchen,
      layout,
      apartmentPosition,
      internet,
      alarm,
      elevator,
      rubbishChute,
      aquacount,
      ceilingHeight,
    } = this.props;
    const stringValue = (list, strType) => {
      // eslint-disable-next-line react/destructuring-assignment
      const val = find(lists[list], (item) => Number(item.id) === Number(this.props[strType]));
      return val && val.name;
    };

    const condition = find(
      lists.condition.sales,
      (item) => Number(item.id) === Number(renovationType)
    );
    let deadline;
    if (numDeadline) {
      deadline =
        numDeadline === 255
          ? 'Дом сдан'
          : `${numDeadline.toString().slice(-1)} квартал ${numDeadline.toString().slice(0, 4)} г.`;
    }

    const params = [
      { name: 'Срок сдачи', value: deadline },
      { name: 'Год постройки', value: numDeadline === '255' && yearBuilt },
      { name: 'Тип дома', value: type },
      { name: 'Материал', value: buildingMaterial },
      { name: 'Этаж', value: [floor, floorTotal].filter(Number).join('/') },
      { name: 'Общая площадь', value: this.renderArea(sqTotal) },
      { name: 'Жилая площадь', value: this.renderArea(sqTiving) },
      { name: 'Площадь кухни', value: this.renderArea(sqKitchen) },
      { name: 'Планировка', value: layout },
      { name: 'Санузел', value: stringValue('sanitary', 'sanitaryId') },
      { name: 'Балкон/лоджия', value: stringValue('balcony', 'balcony') },
      { name: 'Состояние', value: condition && condition.name },
      { name: 'Покрытие пола', value: stringValue('floorCover', 'floorCovering') },
      { name: 'Угловая', value: apartmentPosition === 1 ? 'да' : 'нет' },
      { name: 'Окна', value: stringValue('windowMaterial', 'windowType') },
      { name: 'Вид из окон', value: stringValue('viewFromWindow', 'windowView') },
      { name: 'Интернет', value: internet },
      { name: 'Домофон', value: alarm },
      { name: 'Лифт', value: elevator },
      { name: 'Мусоропровод', value: rubbishChute },
      { name: 'Cчётчики воды', value: aquacount },
      {
        name: 'Высота потолков',
        value: ceilingHeight && `${ceilingHeight} см`,
      },
      { name: 'Входная дверь', value: stringValue('door', 'outerDoorType') },
    ];

    return (
      <table className={styles.infoTable}>
        {params.filter((param) => param.value).map((param) => this.renderRow(param))}
      </table>
    );
  }
}

SalesInfo.defaultProps = {
  sqKitchen: 0,
  yearBuilt: null,
  layout: null,
  aquacount: null,
  sqTiving: null,
  sqTotal: null,
  type: null,
  buildingMaterial: null,
};

SalesInfo.propTypes = {
  buildingMaterial: PropTypes.string,
  lists: listsFromPropTypes.isRequired,
  type: PropTypes.string,
  floor: PropTypes.number.isRequired,
  floorTotal: PropTypes.number.isRequired,
  sqTotal: PropTypes.number,
  sqTiving: PropTypes.number,
  apartmentPosition: PropTypes.number.isRequired,
  internet: PropTypes.number.isRequired,
  alarm: PropTypes.number.isRequired,
  elevator: PropTypes.number.isRequired,
  rubbishChute: PropTypes.number.isRequired,
  aquacount: PropTypes.number,
  ceilingHeight: PropTypes.number.isRequired,
  sqKitchen: PropTypes.number,
  layout: PropTypes.arrayOf(PropTypes.object),
  numDeadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  yearBuilt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renovationType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
