import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

export default function WrappedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location, history, match }) => (
        <Component {...rest} location={location} history={history} match={match} />
      )}
    />
  );
}

WrappedRoute.defaultProps = {
  component: null,
};

WrappedRoute.propTypes = {
  component: PropTypes.node,
};
