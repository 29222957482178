import PropTypes from 'prop-types';
import React from 'react';
import styles from './PropertyView.css';

export default class GaragesInfo extends React.Component {
  renderArea = (area) => {
    return area > 0 ? <span>{area} м²</span> : 0;
  };

  renderNotFilled = () => {
    return <span style={{ color: '#bbb' }}>&mdash;</span>;
  };

  renderRow(param) {
    let value = '';
    switch (param.value) {
      case 0:
        value = this.renderNotFilled();
        break;
      case 1:
        value = 'есть';
        break;
      case 2:
        value = 'нет';
        break;
      case null:
        value = this.renderNotFilled();
        break;
      case true:
        value = 'есть';
        break;
      case false:
        value = 'нет';
        break;
      default:
        value = param.value || this.renderNotFilled();
    }

    return (
      <tr key={param.name}>
        <td className={styles.paramName}>{param.name}</td>
        <td className={styles.paramValue}>{value}</td>
      </tr>
    );
  }

  render() {
    const {
      size,
      squareTotal,
      hasSecurityProtection,
      hasRepairPit,
      hasCellar,
      hasElectricity,
      hasHeating,
      hasWater,
      hasCanalization,
    } = this.props;

    let garageSize;
    if (size) {
      garageSize = [size.length, size.width, size.height].filter((n) => n).join(' x ');
    }

    const params = [
      { name: 'Площадь гаража', value: this.renderArea(squareTotal) },
      { name: 'Размеры гаража, м', value: garageSize || this.renderNotFilled() },
      { name: 'Охрана', value: hasSecurityProtection },
      { name: 'Смотровая яма', value: hasRepairPit },
      { name: 'Погреб', value: hasCellar },
      { name: 'Электроснабжение', value: hasElectricity },
      { name: 'Отопление', value: hasHeating },
      { name: 'Водоснабжение', value: hasWater },
      { name: 'Канализация', value: hasCanalization },
    ];

    return (
      <table className={styles.infoTable}>
        {params.filter((param) => param.value).map((param) => this.renderRow(param))}
      </table>
    );
  }
}

GaragesInfo.defaultProps = {
  size: null,
  squareTotal: null,
  hasSecurityProtection: null,
  hasRepairPit: null,
  hasCellar: null,
  hasElectricity: null,
  hasHeating: null,
  hasWater: null,
  hasCanalization: null,
};

GaragesInfo.propTypes = {
  size: PropTypes.shape({
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  squareTotal: PropTypes.number,
  hasSecurityProtection: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  hasRepairPit: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  hasCellar: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  hasElectricity: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  hasHeating: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  hasWater: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  hasCanalization: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};
