import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Panel, Select } from '../ui';
import Line from './Line';
import cn from './UserPayments.css';
import RLink from '../RLink';
import { toYMD } from '../../utils';
import { initTransactionsFilter, updateTransactionsFilter } from '../../actions';
import { dictionaries as dictionariesFromPropTypes } from '../../lib/PropTypeValues';

class UserPaymentsFilter extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(initTransactionsFilter());
  }

  onChange = (field) => {
    const { dispatch, transactionsFilter } = this.props;
    let time = ReactDOM.findDOMNode(this[field]).value
      ? new Date(ReactDOM.findDOMNode(this[field]).value)
      : new Date();
    if (field === 'dateFrom') {
      const dateTo = new Date(transactionsFilter.dateTo);
      if (time > dateTo) {
        time = dateTo;
      }
    } else {
      const dateFrom = new Date(transactionsFilter.dateFrom);
      if (time < dateFrom) {
        time = dateFrom;
      }
      if (time > new Date()) {
        time = new Date();
      }
    }

    dispatch(
      updateTransactionsFilter({
        ...transactionsFilter,
        [field]: toYMD(time),
      })
    );
  };

  onSelectSection = () => {
    const { dispatch, dictionaries, transactionsFilter } = this.props;
    const id = this.section.getValue();
    const section = dictionaries.section || {};
    const sectionItems = section.items || [];
    const selected = find(sectionItems, { sectionId: id }) || {};

    dispatch(
      updateTransactionsFilter({
        ...transactionsFilter,
        section: selected,
        dealType: selected.dealType,
        realtyType: selected.realtyType,
      })
    );
  };

  onSelectTransaction = () => {
    const { dispatch, transactionsFilter } = this.props;
    const id = this.transaction.getValue();

    dispatch(
      updateTransactionsFilter({
        ...transactionsFilter,
        transaction: id,
      })
    );
  };

  toLocaleDateString = (field) => {
    const { transactionsFilter } = this.props;
    const date = new Date(transactionsFilter[field]);
    const options = { month: 'long', day: 'numeric' };
    const dayMonth = date.toLocaleDateString('ru-RU', options);
    return `${dayMonth} ${date.getFullYear()}`;
  };

  render() {
    const { dictionaries, transactionsFilter } = this.props;
    const transactionSelected = transactionsFilter.transaction;
    const transactionTypeItems =
      (dictionaries.billing && dictionaries.billing.transactionType.items) || [];
    const transactionTypes = [
      {
        id: '',
        name: 'Все',
      },
      ...transactionTypeItems,
    ];

    const section = dictionaries.section || {};
    const sectionSelected = transactionsFilter.section ? transactionsFilter.section.sectionId : 0;
    const sectionItems = (section.items || []).map((item) => {
      return {
        id: item.sectionId,
        name: item.name,
      };
    });
    sectionItems.unshift({
      id: '',
      name: 'Все',
    });

    return (
      <div>
        <Panel
          title="Фильтры"
          containerStyle={{ padding: '0' }}
          titleStyle={{ textAlign: 'center' }}
        >
          <Line name="Дата с">
            <label htmlFor="dateFrom">{this.toLocaleDateString('dateFrom')}</label>
            <input
              className={cn.paymentsInput}
              ref={(n) => {
                this.dateFrom = n;
              }}
              onChange={() => {
                this.onChange('dateFrom');
              }}
              id="dateFrom"
              type="date"
              name="dateFrom"
              value={transactionsFilter.dateFrom}
            />
          </Line>
          <Line name="Дата по">
            <label htmlFor="dateTo">{this.toLocaleDateString('dateTo')}</label>
            <input
              className={cn.paymentsInput}
              ref={(n) => {
                this.dateTo = n;
              }}
              onChange={() => {
                this.onChange('dateTo');
              }}
              id="dateTo"
              type="date"
              name="dateTo"
              value={transactionsFilter.dateTo}
            />
          </Line>
          <Select
            ref={(n) => {
              this.section = n;
            }}
            items={sectionItems}
            label="Раздел"
            emptyString="Все"
            className={cn.select}
            layoutStyle={{ background: 'none', height: '40px', lineHeight: '40px' }}
            selectedStyle={{ width: '100%' }}
            value={sectionSelected}
            onSelect={this.onSelectSection}
          />
          <Select
            ref={(n) => {
              this.transaction = n;
            }}
            items={transactionTypes}
            label="Опция"
            emptyString="Все"
            className={cn.select}
            layoutStyle={{ background: 'none', height: '40px', lineHeight: '40px' }}
            selectedStyle={{ width: '100%' }}
            value={transactionSelected}
            onSelect={this.onSelectTransaction}
          />
          <div style={{ padding: '20px' }}>
            <RLink to="user/payments" className={cn.button}>
              <span>Применить</span>
            </RLink>
          </div>
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsFilter: state.transactionsFilter,
    dictionaries: state.dictionaries,
    user: state.user,
  };
}

UserPaymentsFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  transactionsFilter: PropTypes.shape({
    dateTo: PropTypes.string,
    dateFrom: PropTypes.string,
    transaction: PropTypes.objectOf(PropTypes.any),
    section: PropTypes.shape({
      sectionId: PropTypes.number,
    }),
  }).isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(UserPaymentsFilter);
