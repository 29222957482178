import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import {
  hideModal,
  updateSalesFilter,
  updateRentsFilter,
  setRentsRubric,
  setSalesRubric,
  loadProperties,
  loadGarageProperties,
} from '../../../actions';
import { RadioButtonGroup, FontIcon, FormControlLabel } from '../../ui/material';
import styles from './Modals.css';
import StyledDialog from '../ModalWithStyles';
import { SearchDivider } from '../../Divider/Divider';
import StyledRadioButton from '../../RadioButton';
import { convertFilter } from '../../../utils';
import {
  lists as listsFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../../lib/PropTypeValues';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class RubricsModal extends Component {
  constructor(...props) {
    super(...props);
    const { value } = this.props;
    this.state = {
      selected: value || '1',
    };
  }

  handleChange = (e) => {
    this.setState({
      selected: e.target.value,
    });
  };

  handleSave = () => {
    const { selected } = this.state;
    const {
      filterType,
      filter,
      dispatch,
      lists,
      rubrics,
      selectedLocality,
      dictionaries,
    } = this.props;

    const rubricRoute =
      rubrics.find((rubric) => Number(rubric.id) === Number(selected)).route === 'garages'
        ? 'garages'
        : rubrics.find((rubric) => Number(rubric.id) === Number(selected)).route;

    const localityName = rubricRoute === 'garages' ? 'localityId' : 'locality_fias_id';

    let currentFilter = {
      ...filter,
      [localityName]: selectedLocality.id,
    };

    if (Number(selected) !== 10 && currentFilter.new) {
      delete currentFilter.new;
      dispatch(updateSalesFilter(currentFilter));
    }

    if (filterType === 'rent' && Number(selected) !== 5) {
      delete currentFilter.rooms;
      dispatch(updateRentsFilter(currentFilter));
    }

    if (filterType === 'rent' && Number(selected) === 5) {
      currentFilter = { ...currentFilter, rooms: 101 };
      dispatch(updateRentsFilter(currentFilter));
    }

    delete currentFilter.ref_type;

    if (rubricRoute === 'garages') {
      dispatch(
        loadGarageProperties(
          convertFilter({ ...currentFilter }, selectedLocality),
          `sale/${rubricRoute}`,
          dictionaries
        )
      );
    } else {
      dispatch(loadProperties({ ...currentFilter }, rubricRoute, lists));
    }

    dispatch(filterType === 'sale' ? setSalesRubric(selected) : setRentsRubric(selected));
    dispatch(hideModal());
  };

  render() {
    const { selected } = this.state;
    const { dispatch, rubrics } = this.props;
    return (
      <div>
        <StyledDialog open onClose={() => dispatch(hideModal())} TransitionComponent={Transition}>
          <div className={styles.header}>
            <div className={styles.headerName}>Тип недвижимости</div>
            <div className={styles.headerClose}>
              <FontIcon
                className="material-icons"
                onClick={() => dispatch(hideModal())}
                style={{ color: 'black', fontSize: '19px' }}
              >
                close
              </FontIcon>
            </div>
          </div>

          <RadioButtonGroup value={selected} onChange={this.handleChange}>
            {rubrics.map((item) => {
              return (
                <div key={item.id}>
                  <FormControlLabel
                    control={<StyledRadioButton checked={Number(selected) === Number(item.id)} />}
                    value={item.id}
                    label={item.name}
                    labelPlacement="start"
                  />
                  <SearchDivider />
                </div>
              );
            })}
          </RadioButtonGroup>

          <div />
          <button className={styles.saveButton} type="button" onClick={() => this.handleSave()}>
            Выбрать
          </button>
        </StyledDialog>
      </div>
    );
  }
}

RubricsModal.defaultProps = {};

RubricsModal.propTypes = {
  filterType: PropTypes.string.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  lists: listsFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect((state, ownProps) => ({
  filterType: ownProps.filterType,
  rubrics: ownProps.rubrics,
  filter: ownProps.filter,
  lists: state.lists,
  selectedRegion: state.selectedRegion,
  selectedLocality: state.selectedLocality,
  dictionaries: state.dictionaries,
}))(RubricsModal);
