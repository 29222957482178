import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import TabsToggle from '../../components/ui/TabsToggle';
import TabToggle from '../../components/ui/TabToggle';
import { UserListView } from '../../components/User';
import TabPanel from '../../components/ui/TabPanel';
import {
  user as userFromPropTypes,
  userObjects as userObjectsFromPropTypes,
} from '../../lib/PropTypeValues';
import {
  setScrollPosition,
  loadUserObjects,
  loadMoreUserObjects,
  setCurrentPageType,
} from '../../actions';

class _ListingPage extends React.Component {
  state = {
    selectedSection: 0,
  };

  componentDidMount() {
    const { user, dispatch, dealType, realtyType } = this.props;

    const params = {
      userId: user.profile.id,
      token: user.token,
      dealType,
      realtyType,
      'per-page': 10,
    };

    dispatch(
      loadUserObjects([
        {
          ...params,
          realtyStatus: 'active',
        },
        {
          ...params,
          realtyStatus: 'archived',
        },
      ])
    );

    dispatch(setCurrentPageType('userObjects'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  handleClickLoadMore = (section) => {
    const { user, userObjects, dealType, realtyType, dispatch } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const currentPage = this.state[`page_${section}`] || 1;
    const nextPage = currentPage + 1;

    const data = userObjects.list[section];

    // eslint-disable-next-line no-underscore-dangle
    const meta = data._meta || {};
    if (!meta.pageCount || currentPage === meta.pageCount) {
      return;
    }

    const filter = {
      userId: user.profile.id,
      token: user.token,
      dealType,
      realtyType,
      realtyStatus: section,
      page: nextPage,
      'per-page': 10,
    };
    this.setState({
      [`page_${section}`]: nextPage,
    });

    dispatch(loadMoreUserObjects(filter));
  };

  handleSelectSection = (event, sectionId) => {
    this.setState({
      selectedSection: sectionId,
    });
  };

  render() {
    const { userObjects, dispatch } = this.props;
    const { selectedSection } = this.state;

    const active = userObjects.list ? userObjects.list.active : {};
    // eslint-disable-next-line no-underscore-dangle
    const activeCount = (active._meta || {}).totalCount || 0;
    const archive = userObjects.list ? userObjects.list.archived : {};
    // eslint-disable-next-line no-underscore-dangle
    const archiveCount = (archive._meta || {}).totalCount || 0;

    return (
      <Loader loaded={!userObjects.loading} zIndex={1}>
        <TabsToggle
          onChange={this.handleSelectSection}
          variant="fullWidth"
          value={selectedSection}
          style={{ margin: '0 5px 10px' }}
        >
          <TabToggle label={`Активные - ${activeCount}`} />
          <TabToggle label={`Архив - ${archiveCount}`} />
        </TabsToggle>
        <TabPanel value={selectedSection} index={0}>
          <UserListView
            data={active}
            updateScrollPosition={(scrollPosition) => {
              dispatch(setScrollPosition(scrollPosition));
            }}
            onLoad={() => this.handleClickLoadMore('active')}
          />
        </TabPanel>
        <TabPanel value={selectedSection} index={1}>
          <UserListView
            data={archive}
            updateScrollPosition={(scrollPosition) => {
              dispatch(setScrollPosition(scrollPosition));
            }}
            onLoad={() => this.handleClickLoadMore('archived')}
          />
        </TabPanel>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    userObjects: state.userObjects,
    user: state.user,
  };
}

_ListingPage.propTypes = {
  user: userFromPropTypes.isRequired,
  userObjects: userObjectsFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  dealType: PropTypes.string.isRequired,
  realtyType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(_ListingPage);
