import React from 'react';
import cn from '../../../../components/User/UserTariff.css'
import { TableBuilder } from "./tableBuilder";

export default class TariffTableBuilder extends TableBuilder {
  constructor(...props) {
    super(...props);
    this.table = [];
  }
  renderHint (maxCount, minCount, price, firstPrice) {
    let limitations = [];
    if (firstPrice === 0 && price !== firstPrice) {
      limitations.push(`бесплатно ${minCount - 1}`);
    }
    if (maxCount !== null) {
      limitations.push(`не более ${maxCount}`);
    }
    return (!!limitations.length &&
      <div className={cn.hint}>
        {`(${limitations.join(', ')})`}
      </div>
    );
  }
  addColumn (type) {
    switch (type) {
      case 'header': {
        this.table.push(<td width="65%" className={cn.smTableHeader}>{this.getTableHeader(this.rubric.slug)}</td>);
        return this;
      }
      case 'price': {
        const lastRow = this.rubric.pricing[this.rubric.pricing.length - 1];
        const price = lastRow.costPerAd;
        const firstPrice = this.rubric.pricing[0].costPerAd;
        this.table.push(
          <td width="35%">
            {(price / 100).toFixed(2)} ₽
            {this.renderHint(lastRow.maxCount, lastRow.minCount, price, firstPrice)}
          </td>
        );
        return this;
      }
    }
    return this;
  }
  buildTable () {
    this.addColumn('header').addColumn('price');
    return this;
  }
  renderTable () {
    return <table className={cn.subTable}><tr>{this.table}</tr></table>
  }
}