import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RangeField } from '../ui';
import ModalButton from '../ui/ModalButton';
import { FormControlLabel } from '../ui/material';
import FormPaper from './FormPaper/FormPaper';
import { SearchDivider } from '../Divider/Divider';
import Switch from '../ui/Switch';
import { convert } from '../../api/mappings/utils';
import {
  selectedLocality as selectedLocalityFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../lib/PropTypeValues';

export default class GarageFields extends Component {
  render() {
    const {
      filter,
      dictionaries,
      dictionariesV2,
      rubrics,
      rubric,
      microdistricts,
      onClearSelectedValue,
      locality,
      handleShowModal,
      onSelectLocality,
      districts,
      onChangeFilter,
    } = this.props;

    const propertyTypes = dictionaries.sale.garages.objectType.items.map((item) => ({
      id: item.id,
      name: item.name,
      shortName: item.shortName,
    }));
    const intervals = convert(dictionariesV2)
      .filter((i) => i.slug === 'interval')[0]
      .options.map((item) => ({
        id: item.id,
        name: item.shortName,
      }));

    return (
      <div>
        <FormPaper>
          <ModalButton
            label="Населенный пункт"
            valueLabel={locality.name || filter.localityId}
            value={locality.id || filter.localityId}
            name="localityId"
            fieldRef={(input) => (this.localityId = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_LOCALITY_MODAL', {
                filterType: 'sale',
                title: 'Населенный пункт',
                filter,
                name: 'localityId',
                onSelectLocality,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Район"
            valueLabel={
              filter.district &&
              this.props.districts.find((district) => district.id == filter.district).name
            }
            value={filter.district}
            name="district"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.district = input)}
            list={districts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: districts,
                title: 'Район',
                value: filter.district,
                filter,
                name: 'district',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Микрорайон"
            valueLabel={
              filter.microdistrict &&
              microdistricts.find((microdistrict) => microdistrict.id == filter.microdistrict).name
            }
            value={filter.microdistrict}
            name="microdistrict"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.microdistrict = input)}
            list={microdistricts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: microdistricts,
                title: 'Микрорайон',
                value: filter.microdistrict,
                filter,
                name: 'microdistrict',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Улица, дом"
            valueLabel={filter.address}
            value={filter.address}
            name="address"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.address = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_STREET_MODAL', {
                filterType: 'sale',
                title: 'Улица, дом',
                filter,
                name: 'address',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Тип недвижимости"
            valueLabel={rubric.name}
            showModal={() => {
              handleShowModal('CHANGE_RUBRIC', {
                filterType: 'sale',
                rubrics,
                value: rubric.id,
                filter,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Тип объекта"
            valueLabel={
              filter.objectTypeId &&
              propertyTypes.find((type) => type.id == filter.objectTypeId).name
            }
            value={filter.objectTypeId}
            name="objectTypeId"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.objectTypeId = input)}
            list={propertyTypes}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: 'sale',
                list: propertyTypes,
                title: 'Тип объекта',
                value: filter.objectTypeId,
                filter,
                name: 'objectTypeId',
              });
            }}
          />
          <SearchDivider />
          <RangeField
            minName="priceMin"
            maxName="priceMax"
            mainLabel="Цена, ₽"
            label=""
            measureLabel=""
            min={filter.priceMin}
            max={filter.priceMax}
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasPhoto}
                name="hasPhoto"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasPhoto = input)}
              />
            }
            label="Только с фото"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <RangeField
            mainLabel="Площадь гаража, м²"
            label=""
            measureLabel=""
            minName="squareTotalMin"
            maxName="squareTotalMax"
            min={filter.squareTotalMin}
            max={filter.squareTotalMax}
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasSecurityProtection}
                name="hasSecurityProtection"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasSecurityProtection = input)}
              />
            }
            label="Охрана"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasRepairPit}
                name="hasRepairPit"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasRepairPit = input)}
              />
            }
            label="Смотровая яма"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasCellar}
                name="hasCellar"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasCellar = input)}
              />
            }
            label="Погреб"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasElectricity}
                name="hasElectricity"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasElectricity = input)}
              />
            }
            label="Электроснабжение"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasHeating}
                name="hasHeating"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasHeating = input)}
              />
            }
            label="Отопление"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasWater}
                name="hasWater"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasWater = input)}
              />
            }
            label="Водоснабжение"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.hasCanalization}
                name="hasCanalization"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.hasCanalization = input)}
              />
            }
            label="Канализация"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Дата публикации"
            valueLabel={
              (filter.interval &&
                !!intervals.length &&
                [{ id: '', name: 'за все время' }, ...intervals].find(
                  (type) => Number(type.id) === Number(filter.interval)
                ).name) ||
              ''
            }
            value={filter.interval}
            name="interval"
            fieldRef={(input) => (this.interval = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: 'sale',
                list: [{ id: '', name: 'за все время' }, ...intervals],
                title: 'Дата публикации',
                value: filter.interval,
                filter,
                name: 'interval',
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.onlyOwners}
                name="onlyOwners"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.onlyOwners = input)}
              />
            }
            label="Только от частных лиц"
            labelPlacement="start"
          />
        </FormPaper>
      </div>
    );
  }
}

GarageFields.defaultProps = {
  microdistricts: [],
  districts: [],
};

GarageFields.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  searchPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  rubric: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  dictionariesV2: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  districts: PropTypes.arrayOf(PropTypes.object),
  onClearSelectedValue: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};
