import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { words } from 'lodash';
import serialize from 'form-serialize';
import { connect } from 'react-redux';
import styles from './PropertyView/PropertyView.css';
import { Panel, TextField } from './ui';
import SubmitButton from './SubmitButton';
import { bidProperty } from '../actions';
import { getNewFormat } from '../utils';
import { ContactsDivider } from './Divider/Divider';

class _ContactsView extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      inputPhoneBidError: false,
      inputMessageBidError: false,
    };
  }

  handleBidObject(object) {
    const { dispatch } = this.props;
    dispatch(bidProperty(object));
  }

  handleBidSubmit(e) {
    const {
      object: { data },
    } = this.props;
    const { inputMessageBidError, inputPhoneBidError } = this.state;
    e.preventDefault();
    const formdata = serialize(ReactDOM.findDOMNode(this.refs.bidForm), { hash: true });

    this.setState({
      inputPhoneBidError: !formdata.auser_phone,
      inputMessageBidError: !formdata.auser_comment,
    });

    if (!inputMessageBidError && !inputPhoneBidError) {
      let object = {};
      if (data.dealType) {
        object = {
          id: data.id,
          dealType: data.dealType,
          realtyType: data.realtyType,
        };
      } else {
        object = {
          id: data.id,
          ...getNewFormat(data.realtyType),
        };
      }
      this.handleBidObject({
        ...object,
        phone: formdata.auser_phone || '',
        comment: formdata.auser_comment || '',
      });
    }
  }

  render() {
    const {
      object: { data },
      bids,
      path,
    } = this.props;

    const { inputPhoneBidError, inputMessageBidError } = this.state;

    if (!data) return null;

    const bidAction = words(path).slice(1).join('/');
    const alreadyBid = bids.indexOf(Number(data.id)) !== -1;

    return (
      <div>
        <Panel
          title="Контактные данные продавца"
          name="seller"
          titleClassName={styles.panelTitle}
          id="seller"
          containerStyle={{ padding: '5px 0' }}
        >
          <section className={styles.contactsBlock}>
            {data.contactName && (
              <div>
                <div>Контактное лицо</div>
                <div>{data.contactName}</div>
              </div>
            )}
            {data.agencyName && (
              <div>
                <div>Агентство</div>
                <div>{data.agencyName}</div>
              </div>
            )}
            {!!data.hasCallTrackingPhones && (
              <>
                <ContactsDivider />
                <div className={styles.callTrackingDescription}>
                  <span>Сообщения в мессенджерах и СМС не доставляются</span>
                </div>
              </>
            )}
            {data.userPhones &&
              data.userPhones.map((phone, i) => {
                return (
                  <div>
                    <div>Телефон {i + 1}</div>
                    <div>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                  </div>
                );
              })}
            {!data.userPhones && data.phone1 && (
              <div>
                <div>Телефон</div>
                <div>
                  <a href={`tel:${`${data.phone1}`.replace(/\D+/g, '')}`}>
                    {`${data.phone1}`.replace(/[^\d+-]/g, '')}
                  </a>
                </div>
              </div>
            )}
          </section>
        </Panel>

        {/* <Panel title="Написать продавцу" titleClassName={styles.panelTitle}>
          <div
            style={{ padding: '0 20px 10px', fontWeight: 300, color: '#252525', fontSize: '14px' }}
          >
            {!alreadyBid && (
              <form action={bidAction} onSubmit={this.handleBidSubmit.bind(this)} ref="bidForm">
                <TextField
                  hintText="Ваш номер телефона"
                  type="tel"
                  fullWidth
                  inputStyle={{ color: 'black' }}
                  isError={inputPhoneBidError}
                  errorText="укажите ваш номер телефона"
                  placeholder="* Ваш номер телефона"
                  name="auser_phone"
                />
                <p />
                <TextField
                  hintText="Текст сообщения"
                  type="text"
                  multiLine
                  fullWidth
                  inputStyle={{ color: 'black' }}
                  isError={inputMessageBidError}
                  errorText="заполните текст сообщения"
                  placeholder="* Текст сообщения..."
                  rows="5"
                  name="auser_comment"
                />

                <p style={{ paddingLeft: '5px', color: '#797878' }}>* - обязательные поля</p>
                <SubmitButton value="Отправить сообщение" />
              </form>
            )}
            {alreadyBid && 'Ваш запрос отправлен'}
          </div>
        </Panel> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    object: state.object,
    bids: state.bids,
  };
}

_ContactsView.defaultProps = {
  path: null,
};

_ContactsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  object: PropTypes.shape({
    data: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  bids: PropTypes.arrayOf(PropTypes.any).isRequired,
  path: PropTypes.string,
};

export default connect(mapStateToProps)(_ContactsView);
