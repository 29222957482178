/* eslint-disable max-classes-per-file */
import React from 'react';

export const Premium = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FF7F29"
      />
      <path
        d="M7.00256 16.3978C7.15051 16.4224 7.30024 16.4348 7.45023 16.4348C8.95417 16.4348 10.1734 15.2156 10.1734 13.7117V11.7988C10.1734 11.584 9.99918 11.4098 9.78433 11.4098H7.27378C7.2147 11.4098 7.15755 11.3888 7.11253 11.3505C7.00776 11.2615 6.99502 11.1043 7.08408 10.9996L11.7368 5.52579C11.7721 5.4842 11.8108 5.44554 11.8524 5.41019C12.278 5.04841 12.9163 5.10017 13.2781 5.52579L17.9308 10.9996C17.9691 11.0446 17.9901 11.1017 17.9901 11.1608C17.9901 11.2983 17.8786 11.4098 17.7411 11.4098H15.2306C15.0157 11.4098 14.8416 11.584 14.8416 11.7988V14.1329C14.8416 16.9259 12.5774 19.1901 9.78433 19.1901C7.5674 19.1901 6.20743 18.2048 5.63064 16.1691L7.00256 16.3978Z"
        fill="white"
      />
    </svg>
  );
};

export const Promo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#94C133"
      />
      <path
        d="M7.00256 16.3978C7.15051 16.4224 7.30024 16.4348 7.45023 16.4348C8.95417 16.4348 10.1734 15.2156 10.1734 13.7117V11.7988C10.1734 11.584 9.99918 11.4098 9.78433 11.4098H7.27378C7.2147 11.4098 7.15755 11.3888 7.11253 11.3505C7.00776 11.2615 6.99502 11.1043 7.08408 10.9996L11.7368 5.52579C11.7721 5.4842 11.8108 5.44554 11.8524 5.41019C12.278 5.04841 12.9163 5.10017 13.2781 5.52579L17.9308 10.9996C17.9691 11.0446 17.9901 11.1017 17.9901 11.1608C17.9901 11.2983 17.8786 11.4098 17.7411 11.4098H15.2306C15.0157 11.4098 14.8416 11.584 14.8416 11.7988V14.1329C14.8416 16.9259 12.5774 19.1901 9.78433 19.1901C7.5674 19.1901 6.20743 18.2048 5.63064 16.1691L7.00256 16.3978Z"
        fill="white"
      />
    </svg>
  );
};

export const Trusted = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000941533 4.70219C4.62535 3.84355 10.9048 0 10.9048 0C10.9048 0 18.609 4.19492 21.7792 4.6812C21.7792 4.6812 21.9232 10.6533 20.1725 15.7955C19.5758 17.5483 17.4652 20.0668 15.6613 21.5437C14.1796 22.7567 10.9048 24 10.9048 24C10.9048 24 3.83572 21.5437 1.6523 15.637C-0.0950813 10.9099 0.000941533 4.70219 0.000941533 4.70219ZM5.54416 10.9082L7.50448 8.75867L10.4137 12.5756C12.7937 8.21899 17.2147 3.1467 17.2147 3.1467L19.3852 4.00951C19.3852 4.00951 15.2491 8.22908 10.5794 18.4336C10.561 18.474 10.4185 18.1977 10.1715 17.7186C9.4177 16.2569 7.69059 12.9076 5.54416 10.9082Z"
        fill="#94C133"
      />
    </svg>
  );
};

export default {
  Premium,
  Promo,
  Trusted,
};
