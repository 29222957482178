import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import ym from 'react-yandex-metrika';
import Carousel from '../Carousel';
import { FontIcon } from '../ui/material';
import styles from './PropertyView.css';

const sliderStyles = {
  leftArrowStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '50px',
    textAlign: 'left',
    verticalAlign: 'middle',
    zIndex: '1',
  },
  rightArrowStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '50px',
    textAlign: 'left',
    verticalAlign: 'middle',
    zIndex: '0',
  },

  arrowLinkStyle: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
};

const modalStyles = {
  overlay: {
    background: 'rgba(0,0,0,0.9)',
    zIndex: 5,
  },
  content: {
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    padding: 0,
    border: 'none',
    borderRadius: 'none',
    background: 'transparent',
    overflowY: 'hidden',
  },
};

export default class PhotoSlider extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      modalIsOpen: false,
      currentPhoto: 0,
    };

    const { modalIsOpen } = this.state;

    window.addEventListener('popstate', () => {
      if (modalIsOpen) {
        document.querySelector('body').className = '';
      }
    });
    this.closeModal = this.closeModal.bind(this);
    this.closeModalPhoto = this.closeModalPhoto.bind(this);
  }

  updatePhotoIndex = (index) => {
    this.setState({
      currentPhoto: index,
    });
  };

  showPrevious(e) {
    const { photos } = this.props;
    const { currentPhoto } = this.state;
    e.preventDefault();
    const currentSelectedPhoto = currentPhoto < 1 ? photos.length - 1 : currentPhoto - 1;

    this.setState({ currentPhoto: currentSelectedPhoto });
  }

  showNext(e) {
    const { photos } = this.props;
    const { currentPhoto } = this.state;
    e.preventDefault();
    const currentSelectedPhoto = currentPhoto > photos.length - 2 ? 0 : currentPhoto + 1;
    this.setState({ currentPhoto: currentSelectedPhoto });
  }

  closeModal(e) {
    e.preventDefault();
    this.closeModalPhoto();
  }

  closeModalPhoto() {
    const { onClose } = this.props;
    this.setState(
      {
        modalIsOpen: false,
      },
      onClose()
    );
  }

  openModal(e) {
    const { onOpen } = this.props;
    ym('reachGoal', 'fullscreen_photo');
    e.preventDefault();
    this.setState(
      {
        modalIsOpen: true,
      },
      onOpen()
    );
  }

  renderLeftArrow() {
    return (
      <div style={sliderStyles.leftArrowStyle}>
        <div
          onClick={this.showPrevious.bind(this)}
          role="button"
          style={sliderStyles.arrowLinkStyle}
        >
          <FontIcon
            className="material-icons"
            style={{ marginTop: '110px', background: 'rgba(0,0,0,.4)' }}
          >
            chevron_left
          </FontIcon>
        </div>
      </div>
    );
  }

  renderRightArrow() {
    return (
      <div style={sliderStyles.rightArrowStyle}>
        <div onClick={this.showNext.bind(this)} role="button" style={sliderStyles.arrowLinkStyle}>
          <FontIcon
            className="material-icons"
            style={{ marginTop: '105px', background: 'rgba(0,0,0,.4)' }}
          >
            chevron_right
          </FontIcon>
        </div>
      </div>
    );
  }

  render() {
    const { photos, favoriteBtn } = this.props;
    const { currentPhoto, modalIsOpen } = this.state;
    const photoCount = photos.length;

    const badgeText =
      photoCount > 1 ? `${currentPhoto + 1} из ${photoCount}` : `${photoCount} фото`;

    return (
      <div>
        <a href="#" onClick={this.openModal.bind(this)}>
          <Carousel
            height={170}
            initialIndex={currentPhoto}
            photos={photos}
            afterSelect={this.updatePhotoIndex}
            mode="thumbnail"
          />
        </a>

        {photos.length > 1 && (
          <div className={styles.photoCounter}>
            {currentPhoto + 1}/{photos.length}
          </div>
        )}

        <Modal isOpen={modalIsOpen} style={modalStyles}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 6,
              background: 'rgba(0, 0, 0, 0.30)',
              height: 50,
            }}
          >
            <div style={{ left: 20, top: 13, position: 'fixed', color: 'white' }}>
              <a href="#" onClick={this.closeModal}>
                <FontIcon className="material-icons">close</FontIcon>
              </a>
            </div>
            <div
              style={{
                left: '45%',
                top: 15,
                position: 'fixed',
                color: 'white',
                textShadow: '0 0 1px #000',
              }}
            >
              {badgeText}
            </div>
            {favoriteBtn}
          </div>
          <Carousel
            height={window.innerHeight}
            initialIndex={currentPhoto}
            photos={photos}
            afterSelect={this.updatePhotoIndex}
            swipeUpDown={this.closeModalPhoto}
            mode="fullscreen"
          />
        </Modal>
      </div>
    );
  }
}

PhotoSlider.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.any),
  favoriteBtn: PropTypes.node,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

PhotoSlider.defaultProps = {
  photos: [],
  favoriteBtn: null,
  onOpen() {},
  onClose() {},
};
