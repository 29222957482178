/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isArray, isEmpty } from 'lodash';
import styles from './assets/ModalButton.css';
import { capitalizeFirstLetter, truncateString } from '../../utils';
import { RightArrowIcon } from './Icons';
import { IconButton, FontIcon } from './material';

class _ModalButton extends PureComponent {
  handleClear = (name) => {
    const { onClearSelectedValue } = this.props;
    onClearSelectedValue(name);
  };

  render() {
    const {
      label,
      value,
      name,
      showModal,
      fieldRef,
      valueLabel,
      multi,
      list,
      onClearSelectedValue,
    } = this.props;

    const nameFromArray =
      isArray(valueLabel) && valueLabel.length === 1 && multi && !isEmpty(list)
        ? list.find((i) => i.id == valueLabel[0]).name
        : '';

    const displayedLabel = isArray(valueLabel)
      ? valueLabel.length === 1
        ? nameFromArray
        : valueLabel.length > 1
        ? `Выбрано ${valueLabel.length}`
        : 'Неважно'
      : valueLabel;

    const classNameValue = cx(styles.value, {
      [styles.selected]: displayedLabel === 'Неважно' ? false : !!valueLabel,
    });

    return (
      <div className={styles.root}>
        <div className={styles.wrapper} onKeyDown={showModal} role="button" onClick={showModal}>
          <div className={styles.label}>{label}</div>
          <div className={classNameValue}>
            {valueLabel ? capitalizeFirstLetter(truncateString(displayedLabel, 14)) : 'Неважно'}
          </div>
        </div>
        {valueLabel && !isEmpty(valueLabel) && onClearSelectedValue ? (
          <IconButton onClick={() => this.handleClear(name)}>
            <FontIcon
              style={{ color: '#FF7F29', fontSize: 17, marginRight: 8 }}
              className="material-icons"
            >
              close
            </FontIcon>
          </IconButton>
        ) : (
          <div
            className={styles.defaultIcon}
            onKeyDown={showModal}
            role="button"
            onClick={showModal}
          >
            <RightArrowIcon />
          </div>
        )}
        {!!name && !!fieldRef && !multi && (
          <input type="hidden" ref={fieldRef} value={value || ''} name={name} />
        )}
        {isArray(value) &&
          multi &&
          !!fieldRef &&
          !!name &&
          value.map((item) => (
            <input key={item} type="hidden" ref={fieldRef} value={item} name={`${name}[]`} />
          ))}
      </div>
    );
  }
}

_ModalButton.propTypes = {
  showModal: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valueLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string,
  fieldRef: PropTypes.func,
  multi: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any),
  onClearSelectedValue: PropTypes.func,
};

_ModalButton.defaultProps = {
  fieldRef: null,
  onClearSelectedValue: null,
  multi: false,
  list: null,
  value: null,
  valueLabel: null,
  name: null,
};

export default _ModalButton;
