import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ym from 'react-yandex-metrika';
import PropertyImage from './PropertyImage';
import styles from './PropertyItem.css';
import ButtonFavorite from '../../ButtonFavorite';
import { Paper } from '../../ui/material';
import TrustedIcon from '../../ui/assets/trusted_icon.svg';
import PropertyItemInfo from './PropertyItemInfo';
import { selectedRegion as selectedRegionFromPropTypes } from '../../../lib/PropTypeValues';

class PropertyItem extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  getUid() {
    const { realtyType, id } = this.props;
    return `${realtyType}_${id}`;
  }

  render() {
    const { selectedRegion, dealType, realtyType, id, heightImg, eventAddFavorite, eventRemoveFavorite } = this.props;
    let section = realtyType;
    if (dealType) {
      if (dealType === 'sale') {
        const sections = {
          apartments: 'sales',
          lands: 'lands',
          commercials: 'saleBusiness',
          garages: 'garages',
        };
        section = sections[realtyType];
      } else if (dealType === 'rent') {
        section = realtyType === 'commercials' ? 'rentBusiness' : 'rents';
      } else if (dealType === 'rentDaily') {
        section = 'rents';
      }
    }
    const href = [`/${selectedRegion.domain}`, section, id].join('/');

    const imageSrc = window.innerWidth < 600 ? 'images320X240' : 'images';

    return (
      <div className={styles.root}>
        <Paper square>
          <div className={styles.trusted}>
            <img alt="trusted" src={TrustedIcon} />
          </div>
          <ButtonFavorite
            uid={this.getUid()}
            smallPadding
            object={{
              type: !dealType ? 'old' : 'new',
              id,
              dealType: dealType || null,
              realtyType,
            }}
            alternativeView
            ymClick={() => ym('reachGoal', 'favorite_button_from_list')}
            eventAddFavorite={eventAddFavorite}
            eventRemoveFavorite={eventRemoveFavorite}
            className={styles.buttonFavoriteContainer}
            iconSize="30"
          />
          <Link to={href} style={{ textDecoration: 'none' }}>
            <PropertyImage
              heightImg={heightImg}
              // eslint-disable-next-line react/destructuring-assignment
              src={this.props[imageSrc] && this.props[imageSrc].length > 0 && this.props[imageSrc]}
            />
            <PropertyItemInfo {...this.props} />
          </Link>
        </Paper>
      </div>
    );
  }
}

export default connect((state) => state)(PropertyItem);

PropertyItem.defaultProps = {
  dealType: null,
};

PropertyItem.propTypes = {
  id: PropTypes.number.isRequired,
  heightImg: PropTypes.number.isRequired,
  dealType: PropTypes.string,
  realtyType: PropTypes.string.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired,
};
