const initialState = [
  { regionCode: 55, counterId: 144326 },
  { regionCode: 16, counterId: 2001400 },
  { regionCode: 38, counterId: 25729259 },
  { regionCode: 22, counterId: 27377348 },
  { regionCode: 42, counterId: 27377504 },
  { regionCode: 23, counterId: 27377516 },
  { regionCode: 24, counterId: 27377546 },
  { regionCode: 77, counterId: 27377585 },
  { regionCode: 54, counterId: 27377600 },
  { regionCode: 61, counterId: 27377618 },
  { regionCode: 63, counterId: 27377639 },
  { regionCode: 78, counterId: 27377669 },
  { regionCode: 93, counterId: 27377699 },
  { regionCode: 72, counterId: 27377720 },
  { regionCode: 74, counterId: 27377744 },
];

export default function counters(state = initialState) {
  return state;
}
