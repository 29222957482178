import PropTypes from 'prop-types';
import React from 'react';
import styles from './PropertyView.css';

export default class BusinessRentInfo extends React.Component {
  renderNotFilled = () => {
    return <span style={{ color: '#bbb' }}>&mdash;</span>;
  };

  renderSquareMeter = (value) => {
    return value ? (
      <span>
        {value} м<sup>2</sup>
      </span>
    ) : (
      <span>-</span>
    );
  };

  renderRow(param) {
    let value = '';
    switch (param.value) {
      case 0:
        value = this.renderNotFilled();
        break;
      case 1:
        value = 'есть';
        break;
      case 2:
        value = 'нет';
        break;
      default:
        value = param.value || this.renderNotFilled();
    }

    return (
      <tr key={param.name}>
        <td className={styles.paramName}>{param.name}</td>
        <td className={styles.paramValue}>{value}</td>
      </tr>
    );
  }

  render() {
    const { floor, floorsTotal, areaTotal, hasPrivateEntrance } = this.props;
    const params = [
      { name: 'Этаж', value: `${floor}/${floorsTotal}` },
      { name: 'Площадь', value: this.renderSquareMeter(areaTotal) },
      { name: 'Отдельный вход', value: hasPrivateEntrance },
    ];

    return (
      <table className={styles.infoTable}>{params.map((param) => this.renderRow(param))}</table>
    );
  }
}

BusinessRentInfo.defaultProps = {
  hasPrivateEntrance: 0,
};

BusinessRentInfo.propTypes = {
  floor: PropTypes.number.isRequired,
  floorsTotal: PropTypes.number.isRequired,
  areaTotal: PropTypes.number.isRequired,
  hasPrivateEntrance: PropTypes.number,
};
