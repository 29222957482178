import PropTypes from 'prop-types';
import React from 'react';
import Autocomplete from './ui/Autocomplete';
import Api from '../api';

export default class StreetSelect extends React.Component {
  getSuggestions = (input, callback) => {
    const { onChangeList, regionId, localityId } = this.props;
    if (onChangeList) {
      onChangeList();
    }
    if (this.t) {
      clearTimeout(this.t);
    }

    this.t = setTimeout(() => {
      Api.getStreets(regionId, input, localityId).then((streets) => {
        callback(null, streets);
      });
    }, 100);
  };

  handleOnSelectStreet = (selected) => {
    const { onSelectEvent } = this.props;
    if (onSelectEvent) {
      onSelectEvent(selected);
    }
  };

  render() {
    const { name, value } = this.props;
    const inputProps = {
      placeholder: 'Улица',
      name,
      value: value || '',
      autoFocus: true,
    };

    return (
      <Autocomplete
        data={this.getSuggestions}
        onSelect={this.handleOnSelectStreet}
        inputProps={inputProps}
      />
    );
  }
}

StreetSelect.defaultProps = {
  regionId: 55,
  localityId: 94379,
  onChangeList: null,
  onSelectEvent: null,
  value: '',
};

StreetSelect.propTypes = {
  regionId: PropTypes.number,
  localityId: PropTypes.number,
  onChangeList: PropTypes.func,
  onSelectEvent: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};
