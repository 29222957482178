import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  RubricsModal,
  RadioGroupModal,
  CheckboxesModal,
  AutocompleteLocalityModal,
  AutocompleteStreetModal,
  UnauthorizedModal,
  SavedSearchModal,
  UnionCianModal,
} from './modals';

const MODAL_COMPONENTS = {
  CHANGE_RUBRIC: RubricsModal,
  RADIO_GROUP_MODAL: RadioGroupModal,
  CHECBOXES_MODAL: CheckboxesModal,
  AUTOCOMPLITE_LOCALITY_MODAL: AutocompleteLocalityModal,
  AUTOCOMPLITE_STREET_MODAL: AutocompleteStreetModal,
  UNAUTHORIZED_MODAL: UnauthorizedModal,
  SAVED_SEARCH_MODAL: SavedSearchModal,
  UNION_CIAN_MODAL: UnionCianModal,
};

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

function mapStateToProps(state) {
  return {
    modalType: state.modal.modalType,
    modalProps: state.modal.modalProps,
  };
}

ModalRoot.defaultProps = {
  modalType: null,
};

ModalRoot.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(ModalRoot);
