import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { submit } from 'redux-form';
import Slide from '@material-ui/core/Slide';
import { hideModal, filtersAdd } from '../../../actions';
import { FontIcon } from '../../ui/material';
import styles from './Modals.css';
import StyledDialog from '../ModalWithStyles';
import { StyledDialogContent } from '../DialogContentWithStyles';
import SavedSearchForm from '../forms/SavedSearchForm';
import { convertFilter } from '../../../utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SavedSearchModal extends Component {
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSave = (form) => {
    const {
      filterType,
      dispatch,
      salesFilter,
      rentsFilter,
      selectedRegion,
      selectedLocality,
    } = this.props;

    let currentSalesFilter = { ...salesFilter };

    let object = {
      name: form.name,
      dealType: filterType.dealType,
      realtyType: filterType.realtyType,
      filter: {},
      formatVersionId: filterType.realtyType === "garages" ? 383 : 382,
      regionId: selectedRegion.id,
    };

    if (filterType.realtyType === "garages") {
      currentSalesFilter.localityId = selectedLocality.id;
      currentSalesFilter.region_fias = selectedRegion.id;
      currentSalesFilter = convertFilter(currentSalesFilter, selectedLocality);
    }

    if (filterType.dealType.includes('sale')) {
      object = { ...object, filter: JSON.stringify({regionId: selectedRegion.id, ...currentSalesFilter}) };
    } else {
      object = { ...object, filter: JSON.stringify({regionId: selectedRegion.id, ...rentsFilter}) };
    }

    if (form.subscribe) {
      object = { ...object, subscribe: 1, email: form.email, interval: 376 };
    }
    let form_data = new FormData();
    for ( let key in object ) {
      form_data.append(key, object[key]);
    }
    dispatch(filtersAdd({data: form_data}));
    dispatch(hideModal());
  }

  handleSubmitForm = () => {
    this.props.dispatch(submit('SavedSearchForm'));
  }

  render() {
    const {
      dispatch,
      title,
    } = this.props;
    return (
      <div>
        <StyledDialog
          open={true}
          onClose={() => dispatch(hideModal())}
          TransitionComponent={Transition}
        >
          <div className={styles.header}>
          <div className={styles.headerName}>{title}</div>
            <div className={styles.headerClose}>
              <FontIcon className="material-icons" onClick={() => dispatch(hideModal())} style={{color: 'black', fontSize: '19px'}}>close</FontIcon>
            </div>
          </div>
          <StyledDialogContent>
            <SavedSearchForm onSubmit={this.handleSave} />
          </StyledDialogContent>
            <button className={styles.saveButton} onClick={() => this.handleSubmitForm()}>
              Сохранить
            </button>
        </StyledDialog>
      </div>
    )
  }
}

SavedSearchModal.propTypes = {
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,

  selectedRegion: PropTypes.object.isRequired,
};

export default connect(
  (state, ownProps) => ({
    title: ownProps.title,
    history: ownProps.history,

    selectedRegion: state.selectedRegion,
    filterType: state.filterType,
    salesFilter: state.salesFilter,
    rentsFilter: state.rentsFilter,
    selectedLocality: state.selectedLocality,
  })
)(SavedSearchModal)