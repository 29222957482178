import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tab } from './material';

const TabToggle = withStyles(() => ({
  root: {
    color: '#757575',
    fontSize: '15px',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    fontWeight: 'normal',
    textTransform: 'Initial',
    boxSizing: 'border-box',
    minHeight: '34px',
    lineHeight: 1,
    opacity: 1,
    '&$selected': {
      color: 'white',
    },
  },
  selected: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

TabToggle.defaultProps = {
  classes: {},
};

TabToggle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
};

export default TabToggle;
