/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import Infinite from 'react-infinite';
import { Card } from '../ui/material';
import RLink from '../RLink';
import { formatPrice } from '../../utils';
import cn from './UserList.css';
import { lists as listsFromPropTypes } from '../../lib/PropTypeValues';

class UserListView extends React.Component {
  componentDidMount() {
    const { scrollPosition } = this.props;
    ReactDOM.findDOMNode(this.refs.list).scrollTop = scrollPosition;
  }

  handleClickItem = () => {
    const { updateScrollPosition } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.list);
    updateScrollPosition(node.scrollTop);
  };

  render() {
    const { data, canLoadMore, onLoad, lists } = this.props;
    const items = data.items || [];
    const meta = data._meta || {};
    return (
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
          paddingTop: 5,
          background: '#f9f9f9',
        }}
      >
        <Infinite
          ref="list"
          elementHeight={120}
          isInfiniteLoading={meta.currentPage === meta.pageCount}
          infiniteLoadBeginEdgeOffset={250}
          containerHeight={window.innerHeight - 101}
          loadingSpinnerDelegate={
            canLoadMore ? (
              <div style={{ textAlign: 'center', margin: 20 }}>Загрузка ...</div>
            ) : (
              <div />
            )
          }
          onInfiniteLoad={onLoad}
        >
          {items.map((item) => (
            <ListItem {...item} onClick={this.handleClickItem} lists={lists} />
          ))}
        </Infinite>
      </ul>
    );
  }
}

UserListView.defaultProps = {
  data: {},
  canLoadMore: false,
};

UserListView.propTypes = {
  scrollPosition: PropTypes.number.isRequired,
  updateScrollPosition: PropTypes.func.isRequired,
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    _meta: PropTypes.objectOf(PropTypes.any),
  }),
  canLoadMore: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  lists: listsFromPropTypes.isRequired,
};

class ListItem extends React.Component {
  getUrl = (dealType, realtyType) => {
    const key = `${dealType}/${realtyType}`;
    const map = {
      'sale/apartments': 'sales',
      'sale/lands': 'lands',
      'sale/commercials': 'saleBusiness',
      'sale/garages': 'garages',
      'rent/apartments': 'rents',
      'rentDaily/apartments': 'rents',
      'rent/commercials': 'rentBusiness',
    };
    return map[key];
  };

  renderBadge = (type) => {
    if (type === 'premium') {
      return <div className={cn.itemBadgePremium}>премиум</div>;
    }
    if (type === 'liftedUp') {
      return <div className={cn.itemBadgeLifted}>поднято</div>;
    }
    return null;
  };

  render() {
    const {
      dealType,
      realtyType,
      photos,
      squareTotal,
      location,
      price,
      id,
      floorTotal,
      floor,
      lists,
      isPremium,
      isPromo,
      objectTypeId,
      onClick,
    } = this.props;
    const size = 'square120';
    const url = this.getUrl(dealType, realtyType);
    const floorItem = [floor || '-', floorTotal || '-'].join('/');
    const floorString = floor && floorTotal ? `, ${floorItem} эт.` : '';
    const district = location.localityDistrict && `${location.localityDistrict.name} р-н`;
    const realtyTypeShortName = (find(lists.realty.sales, { id: objectTypeId }) || {}).shortName;
    const badgeType = isPremium ? 'premium' : isPromo !== false && 'liftedUp';

    let address = '';
    if (location.house) {
      address = location.house.fullName;
    } else if (location.street) {
      address = location.street.fullName;
    }

    return (
      <li className={cn.item} key={id} onClick={onClick}>
        <Card>
          <RLink to={`${url}/${id}`} className={cn.itemLink}>
            <div className={cn.itemContainer}>
              <div className={cn.itemPhotoWrapper}>
                {photos.main && photos.main.url ? (
                  <img
                    src={photos.main.url.replace('{{ size }}', size)}
                    alt=""
                    style={{ width: 100 }}
                  />
                ) : (
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      lineHeight: '100px',
                      color: '#aaa',
                    }}
                  >
                    нет фото
                  </div>
                )}
                {badgeType && this.renderBadge(badgeType)}
              </div>
              <div style={{ maxHeight: 140, float: 'left', maxWidth: 180 }}>
                <p
                  style={{
                    color: 'rgb(141, 198, 63)',
                    fontSize: 20,
                    marginTop: 7,
                    marginBottom: 5,
                  }}
                >
                  {price ? formatPrice(Math.floor(price)) : 'Цена договорная'}
                </p>

                <p style={{ marginTop: 12, marginBottom: 0, color: '#323232', lineHeight: '20px' }}>
                  <span style={{ fontSize: '14px' }}>
                    {realtyTypeShortName} {squareTotal} м<sup>2</sup> {floorString}
                  </span>
                  <br />
                  <span style={{ fontSize: '12px' }}>{address}</span>
                  <br />
                  <span style={{ fontSize: '14px', color: 'grey' }}>{district}</span>
                </p>
              </div>
            </div>
          </RLink>
        </Card>
      </li>
    );
  }
}

ListItem.defaultProps = {
  photos: {},
  price: 0,
  isPremium: false,
  isPromo: false,
};

ListItem.propTypes = {
  lists: listsFromPropTypes.isRequired,
  dealType: PropTypes.string.isRequired,
  realtyType: PropTypes.string.isRequired,
  photos: PropTypes.objectOf(PropTypes.any),
  squareTotal: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  price: PropTypes.number,
  id: PropTypes.number.isRequired,
  floorTotal: PropTypes.number.isRequired,
  floor: PropTypes.number.isRequired,
  isPremium: PropTypes.bool,
  isPromo: PropTypes.bool,
  objectTypeId: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default connect((state) => state)(UserListView);
