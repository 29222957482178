import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '../ui/material';
import { withStyles } from '@material-ui/core/styles';
import { SwitchStyle } from './styles';

function _Switch(props) {
    const { classes, input, ...other } = props;
    return (
      <Switch
        className={classes.root}
        checked={input.value ? true : false}
        onChange={input.onChange}
        {...other}
      />
    );
  }

  _Switch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const StyledSwitch = withStyles(SwitchStyle)(_Switch);
