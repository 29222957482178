import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import UserTariff from '../../components/User/UserTariff';
import { loadUserTariff, setCurrentPageType, disableHeader } from '../../actions';
import qs from 'query-string';

class _TariffPage extends React.Component {
  componentDidMount() {
    const { dispatch, user, location, isDisableHeader } = this.props;
    const { profile } = user;
    const query = qs.parse(location.search);
    if ( user.token && profile.id ) {
      dispatch(loadUserTariff({ userId: profile.id, token: user.token }));
    }
    if ( query.userId && query.token ) {
      dispatch(loadUserTariff({ userId: query.userId, token: query.token }));
    }
    if (isDisableHeader) {
      dispatch(disableHeader());
    }
    dispatch(setCurrentPageType('user'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  render() {
    const { userTariff } = this.props;
    return (
      <Loader loaded={!userTariff.loading}>
        {userTariff.tariff === null ? null :
          <UserTariff tariff={userTariff.tariff} />
        }
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    userTariff: state.userTariff,
    user: state.user,
  };
}

_TariffPage.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.string,
    loaded: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  userTariff: PropTypes.shape({
    loading: PropTypes.bool,
    tariff: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(_TariffPage);
