import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch } from './material';

export default class Switch extends PureComponent {
  constructor(...props) {
    super(...props);
    const { checkedValue } = this.props;
    this.state = {
      checkedValue: checkedValue || '',
    };
  }

  handleChange = (event) => {
    const { onChangeFilter } = this.props;
    this.setState({ checkedValue: event.target.checked });
    if (onChangeFilter) {
      onChangeFilter(100);
    }
  };

  render() {
    const { inputRef, value, name } = this.props;
    const { checkedValue } = this.state;
    return (
      <MuiSwitch
        name={name}
        inputRef={inputRef}
        checked={!!checkedValue}
        onChange={this.handleChange}
        value={value}
      />
    );
  }
}

Switch.defaultProps = {
  value: '1',
  inputRef: () => {},
  onChangeFilter: () => {},
  checkedValue: false,
  name: '',
};

Switch.propTypes = {
  inputRef: PropTypes.func,
  value: PropTypes.string,
  checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChangeFilter: PropTypes.func,
  name: PropTypes.string,
};
