const initialState = [
  {
    id: 1,
    name: 'Квартиры, комнаты',
    route: 'sales',
    saleType: 'living',
    type: 'sale',
    dealType: 'sale',
    realtyType: 'apartments',
  },
  {
    id: 2,
    name: 'Дома, участки',
    route: 'lands',
    type: 'sale',
    dealType: 'sale',
    realtyType: 'lands',
  },
  {
    id: 3,
    name: 'Коммерческая недвижимость',
    route: 'saleBusiness',
    saleType: 'commerce',
    type: 'sale',
    dealType: 'sale',
    realtyType: 'commercials',
  },
  {
    id: 11,
    name: 'Гаражи',
    route: 'garages',
    saleType: 'garages',
    type: 'sale',
    dealType: 'sale',
    realtyType: 'garages',
  },
  {
    id: 4,
    name: 'Квартиры, комнаты',
    route: 'rents',
    rentType: 'rentApartments',
    type: 'rent',
    dealType: 'rent',
    realtyType: 'apartments',
  },
  {
    id: 5,
    name: 'Дома, участки',
    route: 'rents',
    rentType: 'rentLands',
    type: 'rent',
    dealType: 'rent',
    realtyType: 'apartments'
  },
  {
    id: 6,
    name: 'Коммерческая недвижимость',
    route: 'rentBusiness',
    rentType: 'rentBusiness',
    type: 'rent',
    dealType: 'rent',
    realtyType: 'commercials',
  },
];

export default function rubrics(state = initialState) {
  return state;
}
