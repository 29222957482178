import Api from '../../api';
import { MICRODISTRICT_RATING_LOAD, MICRODISTRICT_RATING_IGNORE } from '../../actions/actionTypes';
import { START, SUCCESS } from '../../actions/consts';

export default function loadMicrodistrictRating() {
  return (dispatch, getState) => {
    dispatch({
      type: MICRODISTRICT_RATING_LOAD + START,
    });

    const microdistrictMlsnId =
      getState().object.data && getState().object.data.microdistrictMlsnId;
    if (!microdistrictMlsnId) {
      return dispatch({
        type: MICRODISTRICT_RATING_IGNORE,
      });
    }

    const mid = microdistrictMlsnId || null;
    if (!mid) {
      return dispatch({
        type: MICRODISTRICT_RATING_IGNORE,
      });
    }

    return Api.fetchMicrodistrictRating(mid).then((data) => {
      dispatch({
        type: MICRODISTRICT_RATING_LOAD + SUCCESS,
        payload: data,
      });
    });
  };
}
