import PropTypes from 'prop-types';
import React from 'react';
import PhotoSlider from '../PropertyView/PhotoSlider';
import cn from './ComplexListHistory.css';

const ComplexListHistory = ({ complexHistory }) => {
  return (
    <div>
      {complexHistory.items.map((item) =>
        item.stages && item.stages.length ? (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px',
                fontSize: 14,
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  padding: '2px 0',
                  fontSize: 14,
                  color: '#666',
                }}
              >
                {item.name}
              </div>
              <div
                style={{
                  display: 'inline-block',
                  padding: '2px 12px',
                  fontSize: 14,
                  color: '#fff',
                  maxHeight: 17,
                  background: item.stage ? '#8dc63f' : '#FF7F29',
                }}
              >
                {item.stage ? 'Cдан' : 'Cтроится'}
              </div>
            </div>
            {item.stages.map((stage) =>
              stage.images && stage.images.length ? (
                <div className={cn.root}>
                  <PhotoSlider
                    photos={stage.images.map((photo) => photo.replace('{{ size }}', 'medium'))}
                    sliderClassName={cn.sliderBox}
                    badgeOf
                  />
                  <div className={cn.date}>
                    {stage.quarter} квартал {stage.year}
                  </div>
                </div>
              ) : (
                <div />
              )
            )}
          </div>
        ) : (
          <div />
        )
      )}
    </div>
  );
};

ComplexListHistory.propTypes = {
  complexHistory: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};

export default ComplexListHistory;
