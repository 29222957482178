import PropTypes from 'prop-types';
import React from 'react';
import { Snackbar as MuiSnackBar } from './material';

const style = {
  width: '100%',
  marginLeft: 0,
  bottom: 0,
  left: 0,
};

export default class SnackBar extends React.Component {
  constructor(...props) {
    super(...props);
    this.state = {
      open: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { message } = this.props;
    return nextProps.message !== message;
  }

  handleClose(event, reason) {
    const { removeNotification } = this.props;
    if (reason === 'clickaway') {
      return;
    }

    removeNotification();
    this.setState({
      open: false,
    });
  }

  render() {
    const { open } = this.state;
    const { message } = this.props;
    return (
      <MuiSnackBar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        message={message}
        autoHideDuration={3000}
        style={style}
        onClick={this.handleClose}
      />
    );
  }
}

SnackBar.defaultProps = {
  message: '',
  removeNotification: () => {},
};

SnackBar.propTypes = {
  message: PropTypes.string,
  removeNotification: PropTypes.func,
};
