/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import React from 'react';

export const IosIcon = () => {
  return (
    <svg width="17px" height="23px" viewBox="0 0 17 23" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-781.000000, -4944.000000)" fill="#999999">
          <g transform="translate(-130.000000, 4838.000000)">
            <g transform="translate(911.000000, 106.000000)">
              <path d="M16.1113933,15.1736111 C15.4185414,17.1028274 13.7019231,20.5927579 11.7474601,20.7119792 C10.3720972,20.7119792 10.0205007,19.8665923 8.53138607,19.8665923 C7.04227141,19.8665923 6.57692308,20.7119792 5.31531205,20.7119792 C3.13334543,20.8312004 0.0310232221,15.650496 0.0310232221,11.1959573 C0.0310232221,7.09908234 2.55424528,5.05064484 4.9740566,5.05064484 C6.23566763,5.05064484 7.49727866,5.89603175 8.19013062,5.89603175 C8.9967344,5.89603175 10.4858491,4.81220238 11.9853048,4.93142361 C12.6781567,4.93142361 14.394775,5.16986607 15.5426343,6.97986111 C12.5437228,9.14751984 12.7815675,13.6128968 16.1113933,15.1736111 L16.1113933,15.1736111 Z M11.9749637,0 C9.67924528,0.11922123 7.72478229,2.64454365 8.06603774,4.81220238 C10.1342525,4.9422619 12.2024673,2.40610119 11.9749637,0 L11.9749637,0 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const AndroidIcon = () => {
  return (
    <svg width="18px" height="23px" viewBox="0 0 18 23" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-815.000000, -4945.000000)" fill="#999999">
          <g transform="translate(-130.000000, 4838.000000)">
            <g transform="translate(945.000000, 107.000000)">
              <path d="M17.23114,8.66889466 L17.23114,8.66889466 C17.23114,7.92783954 16.7539559,7.44117647 16.0273347,7.44117647 C15.3007135,7.44117647 14.8235294,7.92783954 14.8235294,8.66889466 L14.8235294,14.8185461 C14.8235294,15.5596012 15.3007135,16.0462643 16.0273347,16.0462643 C16.7539559,16.0462643 17.23114,15.5596012 17.23114,14.8185461 L17.23114,14.8185461 L17.23114,8.66889466 L17.23114,8.66889466 Z" />
              <path d="M2.40761059,8.66889466 L2.40761059,8.66889466 C2.40761059,7.92783954 1.93042651,7.44117647 1.2038053,7.44117647 C0.477184081,7.44117647 0,7.92783954 0,8.66889466 L0,14.8185461 C0,15.5596012 0.477184081,16.0462643 1.2038053,16.0462643 C1.93042651,16.0462643 2.40761059,15.5596012 2.40761059,14.8185461 L2.40761059,14.8185461 L2.40761059,8.66889466 L2.40761059,8.66889466 Z" />
              <path d="M3.17647059,16.0462643 C3.17647059,16.7873194 3.65365467,17.2739825 4.38027588,17.2739825 L5.58408118,17.2739825 L5.58408118,20.9571371 C5.58408118,21.6981922 6.06126526,22.1848552 6.78788648,22.1848552 C7.51450769,22.1848552 7.99169177,21.6981922 7.99169177,20.9571371 L7.99169177,17.2739825 L9.19549707,17.2739825 L9.19549707,20.9571371 C9.19549707,21.6981922 9.67268115,22.1848552 10.3993024,22.1848552 C11.1259236,22.1848552 11.6031077,21.6981922 11.6031077,20.9571371 L11.6031077,17.2739825 L12.806913,17.2739825 C13.5335342,17.2739825 14.0107182,16.7873194 14.0107182,16.0462643 L14.0107182,7.44117647 L9.19549707,7.44117647 L3.17647059,7.44117647 L3.17647059,16.0462643 Z" />
              <path d="M11.6139528,1.84710754 L12.45987,0.497723589 C12.579166,0.376057823 12.45987,0.132726291 12.340574,0.132726291 C12.2212779,0.0110605242 11.9826859,0.132726291 11.9826859,0.254392057 L11.1367687,1.60377601 C10.2908514,1.36044448 9.57507531,1.23877871 8.60986206,1.23877871 C7.6446488,1.23877871 6.91802759,1.36044448 6.08295545,1.60377601 L5.22619312,0.121665766 C5.1068971,0 4.98760108,0 4.86830506,0 C4.74900904,0.121665766 4.62971302,0.364997299 4.74900904,0.486663065 L5.59492627,1.83604702 C4.14168384,2.57710214 3.17647059,3.80482033 3.17647059,6.14965146 L14.0215633,6.14965146 C14.0215633,3.80482033 13.0563501,2.57710214 11.6139528,1.84710754 L11.6139528,1.84710754 Z M5.58408118,4.91087275 C5.22619312,4.91087275 4.97675599,4.66754122 4.97675599,4.29148339 C4.97675599,3.92648609 5.21534803,3.67209404 5.58408118,3.67209404 C5.94196924,3.67209404 6.19140637,3.91542557 6.19140637,4.29148339 C6.19140637,4.66754122 5.95281433,4.91087275 5.58408118,4.91087275 L5.58408118,4.91087275 Z M11.6139528,4.91087275 C11.2560647,4.91087275 11.0066276,4.66754122 11.0066276,4.29148339 C11.0066276,3.92648609 11.2452196,3.67209404 11.6139528,3.67209404 C11.9826859,3.67209404 12.2212779,3.91542557 12.2212779,4.29148339 C12.2104329,4.66754122 11.9718408,4.91087275 11.6139528,4.91087275 L11.6139528,4.91087275 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 132 132">
      <path
        fill="#FFFFFF"
        fillOpacity=".4"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth="4"
        d="M10 2h112c4.4 0 8 3.6 8 8v112c0 4.4-3.6 8-8 8H10c-4.4 0-8-3.6-8-8V10c0-4.4 3.6-8 8-8zm55.8 25C51 27 39 39.3 39 54.6c0 20.7 26.8 51.2 26.8 51.2s27-30.5 27-51.2c0-15.3-12-27.6-27-27.6zm0 37.4c-5.3 0-9.5-4.4-9.5-9.8s4.2-10 9.5-10 9.6 4.6 9.6 10-4.3 9.8-9.6 9.8z"
      />
    </svg>
  );
};

export const PanoramaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 104 103">
      <g fill="none" fillRule="evenodd" transform="translate(2.000000, 2.000000)">
        <rect
          width="99.3"
          height="99.3"
          x=".4"
          fill="#000"
          fillOpacity=".5"
          stroke="#fff"
          strokeWidth="5"
          rx="8"
        />
        <text fill="#fff" fontFamily="Avenir-Heavy, Avenir" fontSize="45" fontWeight="600">
          <tspan x="69.7" y="45.6">
            ˚
          </tspan>
        </text>
        <text fill="#fff" fontFamily="Avenir-Heavy, Avenir" fontSize="23" fontWeight="600">
          <tspan x="30.1" y="36.3">
            360
          </tspan>
        </text>
        <path
          stroke="#fff"
          strokeWidth="4"
          d="M25.6 47.3c.3-10.8-.2-16-1.5-15.4-2.2.8-11.4 4-11.4 9.5 0 1-.4 32.2 0 35.2.5 3 8.5 6.6 14.8 7.8 6.4 1.3 3 1 13.2 1.5 6.7.3 13.4.3 20.2 0 6.5-.7 12-1.7 16.6-3 6.7-1.8 10.4-3.3 10.4-8.4v-33c0-5.7-9-10.2-11.7-9.7-.7 0-1 5.2-1 15.3"
        />
        <path
          stroke="#fff"
          strokeWidth="4"
          d="M12.8 43c3.5 6.2 12.3 10 26.4 11l12 .5c2.7 0 7.2 0 10.8-.4 3.6-.4 18.6-2.8 25.8-10.6"
        />
      </g>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9">
      <path
        fill="#FFFFFF"
        stroke="#595959"
        strokeWidth="1"
        d="M14.1,11.3c-0.2-0.2-0.2-0.5,0-0.7l7.5-7.5c0.2-0.2,0.3-0.5,0.3-0.7s-0.1-0.5-0.3-0.7l-1.4-1.4C20,0.1,19.7,0,19.5,0  c-0.3,0-0.5,0.1-0.7,0.3l-7.5,7.5c-0.2,0.2-0.5,0.2-0.7,0L3.1,0.3C2.9,0.1,2.6,0,2.4,0S1.9,0.1,1.7,0.3L0.3,1.7C0.1,1.9,0,2.2,0,2.4  s0.1,0.5,0.3,0.7l7.5,7.5c0.2,0.2,0.2,0.5,0,0.7l-7.5,7.5C0.1,19,0,19.3,0,19.5s0.1,0.5,0.3,0.7l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3  s0.5-0.1,0.7-0.3l7.5-7.5c0.2-0.2,0.5-0.2,0.7,0l7.5,7.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4c0.2-0.2,0.3-0.5,0.3-0.7  s-0.1-0.5-0.3-0.7L14.1,11.3z"
      />
    </svg>
  );
};

export const UpArrowIcon = ({ color, size, stroke }) => {
  const fill = color || '#000000';
  const sizeArrow = size || 24;
  const strokeArrow = stroke || 0;

  return (
    <svg
      fill={fill}
      height={sizeArrow}
      viewBox="0 0 24 24"
      width={sizeArrow}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
        strokeWidth={strokeArrow}
        stroke={fill}
      />
    </svg>
  );
};

export const DownArrowIcon = ({ color, size, stroke }) => {
  const fill = color || '#000000';
  const sizeArrow = size || 24;
  const strokeArrow = stroke || 0;

  return (
    <svg
      fill={fill}
      height={sizeArrow}
      viewBox="0 0 24 24"
      width={sizeArrow}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
        fill={fill}
        strokeWidth={strokeArrow}
        stroke={fill}
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35">
      <path fill="#757575" d="M19,9h-4V3H9v6H5l7,7 7,-7zM5,18v2h14v-2H5z" />
    </svg>
  );
};

export const RightArrowIcon = () => {
  return (
    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.37054L1.39309 0L8 6.5L1.39309 13L0 11.6295L5.21383 6.5L0 1.37054Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};

export const SavedSearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2858 7.74859C22.2858 5.08653 20.812 3.42859 18.5143 3.42859C17.4668 3.42859 16.6563 3.80545 16.1143 4.54872C15.5724 3.80545 14.7619 3.42859 13.7144 3.42859C11.4167 3.42859 9.94293 5.08653 9.94293 7.74859C9.94293 9.83223 11.951 12.1887 15.9281 14.8908C16.0414 14.9678 16.1873 14.9678 16.3006 14.8908C20.2777 12.1887 22.2858 9.83223 22.2858 7.74859ZM9.06044 4.14563C9.39912 4.14563 9.73243 4.16855 10.0589 4.21292C9.86961 4.47475 9.70671 4.76581 9.57228 5.08402C9.40348 5.07073 9.23285 5.06395 9.06064 5.06395C5.51065 5.06395 2.63281 7.94179 2.63281 11.4918C2.63281 15.0418 5.51065 17.9196 9.06064 17.9196C11.4131 17.9196 13.4703 16.6559 14.5906 14.7703C14.8315 14.9492 15.0802 15.1294 15.3368 15.311C15.1115 15.6806 14.8545 16.0286 14.5695 16.3514L18.1086 19.8905C18.2879 20.0698 18.2879 20.3605 18.1086 20.5398C17.9293 20.7192 17.6386 20.7192 17.4593 20.5398L13.9202 17.0007C12.6251 18.144 10.9238 18.8378 9.06044 18.8378C5.00331 18.8378 1.71436 15.5488 1.71436 11.4917C1.71436 7.43458 5.00331 4.14563 9.06044 4.14563Z"
        fill="#FF7F29"
      />
    </svg>
  );
};

export default {
  IosIcon,
  AndroidIcon,
  MapIcon,
  PanoramaIcon,
  CloseIcon,
  UpArrowIcon,
  DownArrowIcon,
  DownloadIcon,
  RightArrowIcon,
  SavedSearchIcon,
};
