import PropTypes from 'prop-types';
import React from 'react';
import styles from './PropertyView.css';

export default class LandsInfo extends React.Component {
  renderNotFilled = () => {
    return <span style={{ color: '#bbb' }}>&mdash;</span>;
  };

  renderRow(param) {
    let value = '';
    switch (param.value) {
      case 0:
        value = this.renderNotFilled();
        break;
      case 1:
        value = 'есть';
        break;
      case 2:
        value = 'нет';
        break;
      default:
        value = param.value || this.renderNotFilled();
    }

    return (
      <tr key={param.name}>
        <td className={styles.paramName}>{param.key}</td>
        <td className={styles.paramValue}>{value}</td>
      </tr>
    );
  }

  render() {
    const { info } = this.props;

    return (
      <table className={styles.infoTable}>
        {info.filter((param) => param.value).map((param) => this.renderRow(param))}
      </table>
    );
  }
}

LandsInfo.defaultProps = {
  info: [],
};

LandsInfo.propTypes = {
  info: PropTypes.shape({
    params: PropTypes.arrayOf(PropTypes.any),
  }),
};
