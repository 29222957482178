const AutocompleteTheme = {
  container: 'Autocomplete__react-autosuggest__container',
  containerOpen: 'Autocomplete__react-autosuggest__container--open',
  input: 'Autocomplete__react-autosuggest__input',
  inputOpen: 'Autocomplete__react-autosuggest__input--open',
  inputFocused: 'Autocomplete__react-autosuggest__input--focused',
  suggestionsContainer: 'Autocomplete__react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'Autocomplete__react-autosuggest__suggestions-container--open',
  suggestionsList: 'Autocomplete__react-autosuggest__suggestions',
  suggestion: 'Autocomplete__react-autosuggest__suggestion',
  suggestionFirst: 'Autocomplete__react-autosuggest__suggestion--first',
  suggestionHighlighted: 'Autocomplete__react-autosuggest__suggestion--highlighted',
  sectionContainer: 'Autocomplete__react-autosuggest__section-container',
  sectionContainerFirst: 'Autocomplete__react-autosuggest__section-container--first',
  sectionTitle: 'Autocomplete__react-autosuggest__section-title',
};

export default AutocompleteTheme;
