/* eslint-disable react/no-array-index-key */
/* eslint-disable max-classes-per-file */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Infinite from 'react-infinite';
import styles from './ListView.css';
import { Card } from '../ui/material';
import { formatPrice, isLandDacha } from '../../utils';
import ButtonFavorite from '../ButtonFavorite';
import Banner from '../Banner';
import BadgeLabel from '../ui/BadgeLabel';
import { UpArrowIcon, DownArrowIcon } from '../ui/Icons';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';

const inlineStyles = {
  item: {
    link: {
      textDecoration: 'none',
    },
    badgePremium: {
      background: '#FF7F29',
      position: 'absolute',
      color: 'white',
      bottom: 7,
      textAlign: 'center',
      width: 100,
      padding: '3px 0',
      fontSize: 14,
    },
    badgeLifted: {
      background: 'rgb(141, 198, 63)',
      position: 'absolute',
      color: 'white',
      bottom: 7,
      textAlign: 'center',
      width: 100,
      padding: '3px 0',
      fontSize: 14,
    },
  },
};

class ListView extends React.Component {
  adStorage = {};

  componentDidMount() {
    const { scrollPosition } = this.props;
    ReactDOM.findDOMNode(this.refs.list).scrollTop = scrollPosition;
  }

  handleClickItem = () => {
    const { updateScrollPosition } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.list);
    updateScrollPosition(node.scrollTop);
  };

  render() {
    const {
      items,
      loading,
      additionalHeight,
      canLoadMore,
      onLoad,
      handleScroll,
      districts,
      selectedRegion,
      eventClickAnnouncement,
      eventAddFavorite,
      eventRemoveFavorite
    } = this.props;

    return (
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, background: '#F5F6F8' }}>
        <Infinite
          ref="list"
          elementHeight={120}
          isInfiniteLoading={loading}
          infiniteLoadBeginEdgeOffset={250}
          containerHeight={window.innerHeight + additionalHeight - 130}
          loadingSpinnerDelegate={
            canLoadMore ? (
              <div style={{ textAlign: 'center', margin: 20 }}>Загрузка ...</div>
            ) : (
              <div />
            )
          }
          onInfiniteLoad={onLoad}
          handleScroll={handleScroll}
        >
          {items.map((item, id) =>
            !item.adItem ? (
              <ListItem
                {...item}
                rawItem={item}
                key={id}
                onClick={this.handleClickItem}
                districts={districts}
                domain={selectedRegion.domain}
                eventClickAnnouncement={eventClickAnnouncement}
                eventAddFavorite={() => eventAddFavorite(item)}
                eventRemoveFavorite={() => eventRemoveFavorite(item)}
              />
            ) : (
              <AdItem key={id} id={id} domain={selectedRegion.domain} storage={this.adStorage} />
            )
          )}
        </Infinite>
      </ul>
    );
  }
}

ListView.defaultProps = {
  loading: false,
  canLoadMore: false,
  items: [],
  districts: [],
};
ListView.propTypes = {
  scrollPosition: PropTypes.number.isRequired,
  updateScrollPosition: PropTypes.func.isRequired,

  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  additionalHeight: PropTypes.number.isRequired,
  canLoadMore: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired,
  districts: PropTypes.objectOf(PropTypes.any),
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  eventClickAnnouncement: PropTypes.func.isRequired,
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired
};

class ListItem extends React.Component {
  getUid() {
    const { realtyType, id } = this.props;
    return `${realtyType}_${id}`;
  }

  renderBadge = (type) => {
    if (type === 'premium') {
      return <div style={inlineStyles.item.badgePremium}>премиум</div>;
    }
    if (type === 'liftedUp') {
      return <div style={inlineStyles.item.badgeLifted}>поднято</div>;
    }
    return null;
  };

  render() {
    const {
      districts,
      images120X120,
      realtyTypeShortName,
      area,
      refSquare,
      refStreet,
      refPriceRub,
      dealType,
      realtyType,
      id,
      floor,
      status,
      floorTotal,
      districtId,
      domain,
      isSpecial,
      isPromo,
      deltaPrice,
      onClick,
      isTrusted,
      eventClickAnnouncement,
      rawItem,
      eventAddFavorite,
      eventRemoveFavorite,
    } = this.props;

    if (status === 'deleted') {
      return null;
    }

    const floorItem = [floor || '-', floorTotal || '-'].join('/');
    const floorString = floor && floorTotal ? `${floorItem} эт.` : '';
    const district = districts && districts[districtId] && `${districts[districtId].name} р-н`;
    let section = realtyType;
    if (dealType) {
      if (dealType === 'sale') {
        const sections = {
          apartments: 'sales',
          lands: 'lands',
          commercials: 'saleBusiness',
          garages: 'garages',
        };
        section = sections[realtyType];
      } else if (dealType === 'rent') {
        section = realtyType === 'commercials' ? 'rentBusiness' : 'rents';
      } else if (dealType === 'rentDaily') {
        section = 'rents';
      }
    }
    const href = [`/${domain}`, section, id].join('/');
    const badgeType = isSpecial ? 'premium' : isPromo !== 0 && 'liftedUp';

    const landArea = refSquare ? `${refSquare} сот.` : '';
    const houseArea = area ? `${area} м²` : '';
    const square = isLandDacha(this.props) ? landArea : houseArea;
    const arrow =
      deltaPrice > 0 ? (
        <UpArrowIcon color="#94c133" size="17" />
      ) : (
        <DownArrowIcon color="#ff3232" size="17" />
      );

    return (
      <li className={styles.item} onClick={onClick}>
        <Card>
          <Link to={href} onClick={() => eventClickAnnouncement(rawItem)} style={inlineStyles.item.link}>
            <div className={styles.itemContainer}>
              <div className={styles.itemPhotoWrapper}>
                {images120X120 && images120X120[0] ? (
                  <img src={images120X120[0]} alt="" style={{ width: 100 }} />
                ) : (
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      lineHeight: '100px',
                      color: '#aaa',
                    }}
                  >
                    нет фото
                  </div>
                )}
                {badgeType && this.renderBadge(badgeType)}
              </div>
              <div style={{ maxHeight: 140, float: 'left', maxWidth: 190 }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '3px',
                    right: '5px',
                    width: '35px',
                    height: '95%',
                    background: 'white',
                    boxShadow: '-6px 0px 4px #fff',
                  }}
                >
                  <ButtonFavorite
                    uid={this.getUid()}
                    object={{
                      type: !dealType ? 'old' : 'new',
                      id,
                      dealType: dealType || null,
                      realtyType,
                    }}
                    style={{ textAlign: 'center', position: 'absolute' }}
                    iconColor="#FF7F29"
                    iconStyle={{ width: 24, height: 24 }}
                    eventAddFavorite={eventAddFavorite}
                    eventRemoveFavorite={eventRemoveFavorite}
                  />
                  {isTrusted > 0 && (
                    <div
                      style={{ width: '22px', position: 'absolute', bottom: '5px', right: '6px' }}
                    >
                      <BadgeLabel.Trusted />
                    </div>
                  )}
                </div>
                <p
                  style={{
                    color: 'rgb(141, 198, 63)',
                    fontSize: 20,
                    marginTop: 7,
                    marginBottom: 5,
                  }}
                >
                  {deltaPrice && arrow}
                  {refPriceRub ? formatPrice(Math.floor(refPriceRub)) : 'Цена договорная'}
                </p>

                <p style={{ marginTop: 12, marginBottom: 0, color: '#323232', lineHeight: '20px' }}>
                  <span style={{ fontSize: '14px' }}>
                    {[realtyTypeShortName, square, floorString].filter((n) => n).join(', ')}
                  </span>
                  <br />
                  <span style={{ fontSize: '12px' }}>{refStreet}</span>
                  <br />
                  <span style={{ fontSize: '14px', color: 'grey' }}>{district}</span>
                </p>
              </div>
            </div>
          </Link>
        </Card>
      </li>
    );
  }
}

ListItem.defaultProps = {
  districts: null,
  districtId: null,
  isSpecial: null,
  isPromo: null,
  deltaPrice: null,
  isTrusted: null,
  status: null,
  dealType: null,
  realtyType: null,
};
ListItem.propTypes = {
  districts: PropTypes.objectOf(PropTypes.any),
  images120X120: PropTypes.arrayOf(PropTypes.any).isRequired,
  realtyTypeShortName: PropTypes.string.isRequired,
  area: PropTypes.number.isRequired,
  refSquare: PropTypes.number.isRequired,
  refStreet: PropTypes.string.isRequired,
  refPriceRub: PropTypes.number.isRequired,
  dealType: PropTypes.string,
  realtyType: PropTypes.string,
  id: PropTypes.number.isRequired,
  floor: PropTypes.number.isRequired,
  status: PropTypes.string,
  floorTotal: PropTypes.number.isRequired,
  districtId: PropTypes.number,
  domain: PropTypes.string.isRequired,
  isSpecial: PropTypes.number,
  isPromo: PropTypes.number,
  deltaPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired,
  isTrusted: PropTypes.number,
};

class AdItem extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { id, domain, storage } = this.props;
    return (
      <li className={styles.item} key={id}>
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '12px',
            fontSize: '10px',
            color: '#949494',
            zIndex: 3,
          }}
        >
          Реклама
        </div>
        <Card className={styles.adItem}>
          <Banner id={id} domain={domain} storage={storage} type="list" />
        </Card>
      </li>
    );
  }
}

AdItem.propTypes = {
  id: PropTypes.number.isRequired,
  domain: PropTypes.string.isRequired,
  storage: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect((state) => state)(ListView);
