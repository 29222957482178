import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';
import { find } from 'lodash';
import cn from './ComplexView.css';
import RLink from '../RLink';
import { lists as listsFromPropTypes } from '../../lib/PropTypeValues';

export default class ComplexApartments extends React.Component {
  renderPrice = (apartment) => {
    const language = {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'тыс.',
        million: 'млн',
      },
      currency: {
        symbol: '',
      },
    };

    numeral.language('ru', language);
    numeral.language('ru');

    const { priceMin, priceMax, pricePerSquareMeterMin, pricePerSquareMeterMax } = apartment;
    const formattedPrice = (price) => numeral(Math.floor(price)).format('0,0[.]00$');

    return priceMin ? (
      <span>
        <span className={cn.price} style={{ fontSize: '18px' }}>
          {formattedPrice(priceMin)} - {formattedPrice(priceMax)} руб.
        </span>

        {pricePerSquareMeterMin > 0 && (
          <div className={cn.priceSquare}>
            {formattedPrice(pricePerSquareMeterMin)} - {formattedPrice(pricePerSquareMeterMax)}{' '}
            руб./м²
          </div>
        )}
      </span>
    ) : (
      'Цена договорная'
    );
  };

  renderType(apartment) {
    const { lists } = this.props;
    const name = (find(lists.realty.sales, { id: apartment.objectTypeId }) || {}).shortName;
    return (
      <div style={{ paddingBottom: '7px' }}>
        {name}, {apartment.squareTotalMin} - {apartment.squareTotalMax} м²
      </div>
    );
  }

  render() {
    const { apartmentType, id, objectCount } = this.props;
    const apartments = apartmentType.slice();
    apartments.sort((a, b) => a.objectTypeId - b.objectTypeId);
    return (
      <div>
        <RLink
          to={`complex/${id}/apartments`}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 20px',
            color: '#252525',
            textDecoration: 'none',
          }}
        >
          <div>
            <div style={{ fontSize: '18px' }}>Объявлений: {objectCount}</div>
            <span style={{ fontSize: '14px', color: '#666' }}>Смотреть все объекты на продажу</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={cn.arrow}>&nbsp;</span>
          </div>
        </RLink>
        {apartments.map((apartment) => (
          <RLink
            to={`complex/${id}/apartments`}
            query={{
              objectTypeId: apartment.objectTypeId,
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: '1px solid #eee',
              padding: '10px 20px',
              color: '#252525',
              textDecoration: 'none',
            }}
          >
            <div>
              {this.renderType(apartment)}
              {this.renderPrice(apartment)}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={cn.arrow}>{apartment.total}</span>
            </div>
          </RLink>
        ))}
      </div>
    );
  }
}

ComplexApartments.defaultProps = {};

ComplexApartments.propTypes = {
  lists: listsFromPropTypes.isRequired,
  id: PropTypes.number.isRequired,
  apartmentType: PropTypes.arrayOf(PropTypes.any).isRequired,
  objectCount: PropTypes.number.isRequired,
};
