/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/no-webpack-loader-syntax */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { loadScript } from '../../utils';
import cn from './Adfox.css';

export default class Adfox extends Component {
  componentDidMount() {
    const { storage, id } = this.props;
    if (!storage[id]) {
      if (window.Ya && window.Ya.adfoxCode) {
        this.createAdFox();
      } else {
        loadScript('https://yastatic.net/pcode/adfox/loader.js', this.createAdFox);
      }
    }

    require('!style-loader!css-loader!./AdComplex.css');
  }

  componentWillUnmount() {
    const { storage, id } = this.props;
    if (!storage[id] && ReactDOM.findDOMNode(this.container).firstChild) {
      storage[id] = ReactDOM.findDOMNode(this.container).firstChild.innerHTML;
    }
  }

  getContainerId = () => {
    const { id } = this.props;
    return `adfox_${id}`;
  };

  createAdFox = () => {
    const { ownerId, params, onRender, onStub, onError } = this.props;
    window.Ya.adfoxCode.create({
      ownerId,
      containerId: this.getContainerId(),
      params,
      onRender,
      onStub,
      onError,
    });
  };

  render() {
    const { storage, id } = this.props;
    return (
      <div
        id={this.getContainerId()}
        className={cn.base}
        ref={(n) => {
          this.container = n;
        }}
        dangerouslySetInnerHTML={{ __html: storage[id] ? storage[id] : null }}
      />
    );
  }
}

Adfox.propTypes = {
  id: PropTypes.number.isRequired,
  ownerId: PropTypes.number,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  onRender: PropTypes.func.isRequired,
  onStub: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  storage: PropTypes.objectOf(PropTypes.any).isRequired,
};

Adfox.defaultProps = {
  ownerId: 252085,
};

//
// class AdTest extends React.Component {
//   render() {
//     return (
//       <div className="mlsn-mobile-list_item">
//         <div className="mlsn-mobile-complexad">
//           <a
//             href="https://ads.adfox.ru/252085/goLink?hash=bbd11f7826e94594&amp;pr=mxekcyp&amp;p5=ecrez&amp;rand=faquqib&amp;p1=bwdsj&amp;p2=fmix"
//             className="mlsn-mobile-complexad__link"
//             target="_blank"
//           >
//             <img
//               src="https://fs.mlsn.ru/photo/sale/457/4578705/1-komnatnaya-kvartira-rebyata-ne-udalyat-22-584a81ff2b651.jpg"
//               alt="жилой комплекс"
//               className="mlsn-mobile-complexad__image mlsn-ib"
//             />
//             <div className="mlsn-mobile-ib mlsn-mobile-complexad__info">
//               <div className="mlsn-mobile-complexad__title">ЖК «Серебряный берег»</div>
//               <div className="mlsn-mobile-complexad__deadline">4 квартал 2017</div>
//               <div className="mlsn-mobile-complexad__price">от 45 000 за кв.м.</div>
//             </div>
//           </a>
//         </div>
//       </div>
//     );
//   }
// }
