/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import 'whatwg-fetch';
import { values } from 'lodash';
import Api, { Api2 } from '../api';
import { toYMD } from '../utils';
import config from '../config';
import mappings from '../api/mappings';
import history from '../history';
import qs from 'query-string';
import {
  gtagPushEventNewPage,
  getCianDealType,
  getCianObjectType,
  getExtraCategory
} from '../classes/analytics/gtagUtils';
import GtagBuilderV2 from '../classes/analytics/gtagEventBuilderV2';


const API_ROOT = config.api_url.v1;

export const UPDATE_SALES_FILTER = 'UPDATE_SALES_FILTER';
export const UPDATE_RENTS_FILTER = 'UPDATE_RENTS_FILTER';
export const UPDATE_TRANSACTIONS_FILTER = 'UPDATE_TRANSACTIONS_FILTER';
export const SET_REGION = 'SET_REGION';

export const SET_SALES_RUBRIC = 'SET_SALES_RUBRIC';
export const SET_RENTS_RUBRIC = 'SET_RENTS_RUBRIC';

export const SET_RENTS_INTERVAL = 'SET_RENTS_INTERVAL';

export const SET_LOCALITY = 'SET_LOCALITY';
export const SET_SECTION = 'SET_SECTION';
export const LOAD_LISTS = 'LOAD_LISTS';
export const LOAD_DICTIONARIES = 'LOAD_DICTIONARIES';
export const LOAD_DICTIONARIES_V2 = 'LOAD_DICTIONARIES_V2';
export const LOAD_REGIONS = 'LOAD_REGIONS';
export const LOAD_DISTRICTS = 'LOAD_DISTRICTS';
export const SET_DISTRICTS = 'SET_DISTRICTS';

export const LOAD_PROPERTIES = 'LOAD_PROPERTIES';
export const LOAD_PROPERTIES_SUCCESS = 'LOAD_PROPERTIES_SUCCESS';
export const LOAD_PROPERTIES_FAILURE = 'LOAD_PROPERTIES_FAILURE';
export const INVALIDATE_PROPERTIES = 'INVALIDATE_PROPERTIES';
export const CLEAR_TOTAL_COUNT = 'CLEAR_TOTAL_COUNT';
export const CLEAR_TOTAL_COUNT_SUCCESS = 'CLEAR_TOTAL_COUNT_SUCCESS';

export const LOAD_MORE_PROPERTIES = 'LOAD_MORE_PROPERTIES';
export const LOAD_MORE_PROPERTIES_SUCCESS = 'LOAD_MORE_PROPERTIES_SUCCESS';
export const LOAD_MORE_PROPERTIES_FAILURE = 'LOAD_MORE_PROPERTIES_FAILURE';

export const LOAD_PROPERTY = 'LOAD_PROPERTY';
export const LOAD_PROPERTY_SUCCESS = 'LOAD_PROPERTY_SUCCESS';
export const LOAD_PROPERTY_FAILURE = 'LOAD_PROPERTY_FAILURE';

export const LOAD_COMPLEX = 'LOAD_COMPLEX';
export const LOAD_COMPLEX_SUCCESS = 'LOAD_COMPLEX_SUCCESS';
export const LOAD_COMPLEX_FAILURE = 'LOAD_COMPLEX_FAILURE';

export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';

export const REGISTER_SMS = 'REGISTER_SMS';
export const REGISTER_SMS_SUCCESS = 'REGISTER_SMS_SUCCESS';
export const REGISTER_SMS_FAILURE = 'REGISTER_SMS_FAILURE';

export const RESTORE_SMS = 'RESTORE_SMS';
export const RESTORE_SMS_SUCCESS = 'RESTORE_SMS_SUCCESS';
export const RESTORE_SMS_FAILURE = 'RESTORE_SMS_FAILURE';

export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAILURE = 'RESTORE_PASSWORD_FAILURE';

export const USER_PROFILE = 'USER_PROFILE';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const USER_TRANSACTIONS = 'USER_TRANSACTIONS';
export const USER_TRANSACTIONS_SUCCESS = 'USER_TRANSACTIONS_SUCCESS';
export const USER_TRANSACTIONS_FAILURE = 'USER_TRANSACTIONS_FAILURE';
export const USER_MORE_TRANSACTIONS_SUCCESS = 'USER_MORE_TRANSACTIONS_SUCCESS';

export const LOAD_COMPLEX_HISTORY_SUCCESS = 'LOAD_COMPLEX_HISTORY_SUCCESS';

export const LOAD_COMPLEX_APARTMENTS = 'LOAD_COMPLEX_APARTMENTS';
export const LOAD_COMPLEX_APARTMENTS_SUCCESS = 'LOAD_COMPLEX_APARTMENTS_SUCCESS';
export const LOAD_COMPLEX_APARTMENTS_FAILURE = 'LOAD_COMPLEX_APARTMENTS_FAILURE';

export const LOAD_USER_OBJECTS = 'LOAD_USER_OBJECTS';
export const LOAD_USER_OBJECTS_SUCCESS = 'LOAD_USER_OBJECTS_SUCCESS';
export const LOAD_USER_OBJECTS_FAILURE = 'LOAD_USER_OBJECTS_FAILURE';

export const LOAD_MORE_USER_OBJECTS = 'LOAD_MORE_USER_OBJECTS';
export const LOAD_MORE_USER_OBJECTS_SUCCESS = 'LOAD_MORE_USER_OBJECTS_SUCCESS';

export const LOAD_USER_TARIFF = 'LOAD_USER_TARIFF';
export const LOAD_USER_TARIFF_SUCCESS = 'LOAD_USER_TARIFF_SUCCESS';
export const LOAD_USER_TARIFF_FAILURE = 'LOAD_USER_TARIFF_FAILURE';

export const LOAD_MORE_COMPLEX_APARTMENTS = 'LOAD_MORE_COMPLEX_APARTMENTS';
export const LOAD_MORE_COMPLEX_APARTMENTS_SUCCESS = 'LOAD_MORE_COMPLEX_APARTMENTS_SUCCESS';

export const DETECT_REGION = 'DETECT_REGION';
export const SET_CURRENT_PAGE_TYPE = 'SET_CURRENT_PAGE_TYPE';
export const SET_CURRENT_VIEW_TYPE = 'SET_CURRENT_VIEW_TYPE';
export const SET_MICRODISTRICTS = 'SET_MICRODISTRICTS';

export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES';
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES';
export const LOAD_FAVORITES = 'LOAD_FAVORITES';
export const LOAD_FAVORITES_ITEM = 'LOAD_FAVORITES_ITEM';
export const CLEAR_FAVORITES = 'CLEAR_FAVORITES';

export const BID_PROPERTY = 'BID_PROPERTY';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_SORTING = 'SET_SORTING';
export const SET_SORTING_SECTION = 'SET_SORTING_SECTION';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const LOAD_MAP_PROPERTIES = 'LOAD_MAP_PROPERTIES';
export const LOAD_MAP_PROPERTIES_SUCCESS = 'LOAD_MAP_PROPERTIES_SUCCESS';
export const INVALIDATE_MAP_PROPERTIES = 'INVALIDATE_MAP_PROPERTIES';
export const LOAD_MAP_PROPERTIES_FAILURE = 'LOAD_MAP_PROPERTIES_FAILURE';

export const LOAD_PRICE_HISTORY = 'LOAD_PRICE_HISTORY';
export const LOAD_PRICE_HISTORY_SUCCESS = 'LOAD_PRICE_HISTORY_SUCCESS';
export const INVALIDATE_PRICE_HISTORY = 'INVALIDATE_PRICE_HISTORY';
export const LOAD_PRICE_HISTORY_FAILURE = 'LOAD_PRICE_HISTORY_FAILURE';

export const USER_INVOICES_LIST_LOAD = '@@userInvoices/USER_INVOICES_LIST_LOAD';
export const USER_INVOICES_LIST_SUCCESS = '@@userInvoices/USER_INVOICES_LIST_SUCCESS';
export const USER_MORE_INVOICES_LIST_SUCCESS = '@@userInvoices/USER_MORE_INVOICES_LIST_SUCCESS';
export const USER_INVOICES_LIST_FAILURE = '@@userInvoices/USER_INVOICES_LIST_FAILURE';
export const USER_INVOICE_CREATE = '@@userInvoices/USER_INVOICE_CREATE';
export const USER_INVOICE_CREATE_SUCCESS = '@@userInvoices/USER_INVOICE_CREATE_SUCCESS';
export const USER_INVOICE_CREATE_FAILURE = '@@userInvoices/USER_INVOICE_CREATE_FAILURE';

export const LOAD_MORTGAGE_LINK = 'LOAD_MORTGAGE_LINK';
export const LOAD_MORTGAGE_LINK_ERROR = 'LOAD_MORTGAGE_LINK_ERROR';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const LOAD_SAVED_SEARCHES_LIST = 'LOAD_SAVED_SEARCHES_LIST';
export const LOAD_SAVED_SEARCHES_LIST_SUCCESS = 'LOAD_SAVED_SEARCHES_LIST_SUCCESS';
export const LOAD_SAVED_SEARCHES_LIST_FAILURE = 'LOAD_SAVED_SEARCHES_LIST_FAILURE';

export const REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH = 'REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH';
export const REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_SUCCESS =
  'REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_SUCCESS';
export const REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_FAILURE =
  'REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_FAILURE';

export const REMOVE_SAVED_SEARCH = 'REMOVE_SAVED_SEARCH';
export const REMOVE_SAVED_SEARCH_SUCCESS = 'REMOVE_SAVED_SEARCH_SUCCESS';
export const REMOVE_SAVED_SEARCH_FAILURE = 'REMOVE_SAVED_SEARCH_FAILURE';

export const UNSUBSCRIBE_SAVED_SEARCH = 'UNSUBSCRIBE_SAVED_SEARCH';
export const UNSUBSCRIBE_SAVED_SEARCH_SUCCESS = 'UNSUBSCRIBE_SAVED_SEARCH_SUCCESS';
export const UNSUBSCRIBE_SAVED_SEARCH_FAILURE = 'UNSUBSCRIBE_SAVED_SEARCH_FAILURE';
export const UPDATE_FILTER_TYPE = 'UPDATE_FILTER_TYPE';

export const ADD_SAVED_SEARCH = 'ADD_SAVED_SEARCH';
export const ADD_SAVED_SEARCH_SUCCESS = 'ADD_SAVED_SEARCH_SUCCESS';
export const ADD_SAVED_SEARCH_FAILURE = 'ADD_SAVED_SEARCH_FAILURE';

export const LOAD_LOCALITY_BY_ID = 'LOAD_LOCALITY_BY_ID';
export const LOAD_LOCALITY_BY_ID_SUCCESS = 'LOAD_LOCALITY_BY_ID_SUCCESS';
export const LOAD_LOCALITY_BY_ID_FAILURE = 'LOAD_LOCALITY_BY_ID_FAILURE';

export const DISABLE_HEADER = 'DISABLE_HEADER';

export const LOAD_CONFIG = 'LOAD_CONFIG';
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_FAILURE = 'LOAD_CONFIG_FAILURE';

let timeout;

export function userLogin(auth) {
  return (dispatch) => {
    dispatch({ type: USER_LOGIN });

    return Api2.userLogin(auth).then((data) => {
      if (data.token) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          token: data.token,
        });
      } else {
        dispatch({
          type: USER_LOGIN_FAILURE,
          error: data.error,
        });
      }
    });
  };
}

export function userLogout() {
  return (dispatch) => {
    return dispatch({ type: USER_LOGOUT });
  };
}

export function userRegister(user) {
  return (dispatch) => {
    dispatch({ type: USER_REGISTER });

    return Api.userRegister(user).then((data) => {
      if (data.status === 'success') {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          token: data.token,
        });
        dispatch({
          type: USER_REGISTER_SUCCESS,
          userId: data.userId,
          token: data.token,
        });
      } else {
        dispatch({
          type: USER_REGISTER_FAILURE,
          errors: data.errors,
        });
      }
    });
  };
}

export function restorePassword(params) {
  return (dispatch) => {
    dispatch({ type: RESTORE_PASSWORD });

    return Api.restorePassword(params).then((data) => {
      if (data.status === 'success') {
        dispatch({
          type: RESTORE_PASSWORD_SUCCESS,
        });
      } else {
        dispatch({
          type: RESTORE_PASSWORD_FAILURE,
          errors: data.error,
        });
      }
    });
  };
}

export function removeNotification() {
  return { type: REMOVE_NOTIFICATION };
}

export function addNotification(message) {
  return (dispatch) => {
    dispatch({ type: ADD_NOTIFICATION, message });
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      dispatch(removeNotification());
    }, 3100);
  };
}

export function registerSMS(number) {
  return (dispatch) => {
    dispatch({ type: REGISTER_SMS });

    return Api.sendSMS(number).then((data) => {
      if (data.status === 'success') {
        dispatch(addNotification('SMS отправлено на ваш номер'));
        dispatch({
          type: REGISTER_SMS_SUCCESS,
        });
      } else {
        dispatch({
          type: REGISTER_SMS_FAILURE,
          error: data.errorsTxt,
        });
      }
    });
  };
}

export function restoreSMS(number) {
  return (dispatch) => {
    dispatch({ type: RESTORE_SMS });

    return Api.sendRestoreSMS(number).then((data) => {
      if (data.status === 'success') {
        dispatch(addNotification('SMS отправлено на ваш номер'));
        dispatch({
          type: RESTORE_SMS_SUCCESS,
          userId: data.uid,
        });
      } else {
        dispatch({
          type: RESTORE_SMS_FAILURE,
          error: data.error,
        });
      }
    });
  };
}

export function loadProfile(token) {
  return (dispatch) => {
    dispatch({ type: USER_PROFILE });

    return Api2.loadProfile(token).then((data) => {
      if (data.id) {
        dispatch({
          type: USER_PROFILE_SUCCESS,
          profile: data,
        });
      } else {
        dispatch({
          type: USER_PROFILE_FAILURE,
        });
      }
    });
  };
}

export function loadUserTariff(params) {
  return (dispatch) => {
    dispatch({ type: LOAD_USER_TARIFF });

    return Api2.getUserTariff(params).then((data) => {
      if (data.status >= 200 && data.status < 300) {
        return dispatch({
          type: LOAD_USER_TARIFF_SUCCESS,
          tariff: data.response,
        });
      }
      if (data.status == 401 || data.status == 404) {
        return dispatch({
          type: LOAD_USER_TARIFF_FAILURE,
          tariff: null,
        });
      }
    });
  };
}

export function loadConfig() {
  return (dispatch, getState) => {
    const { selectedRegion } = getState();
    dispatch({ type: LOAD_CONFIG });

    return Api2.getConfig({ subdomain: selectedRegion.domain || 'omsk' }).then((data) => {
      if (data.status >= 200 && data.status < 300) {
        return dispatch({
          type: LOAD_CONFIG_SUCCESS,
          payload: data.response,
        });
      }
      if (data.status == 401 || data.status == 404) {
        return dispatch({
          type: LOAD_CONFIG_FAILURE,
          payload: null,
        });
      }
    });
  };
}

export function loadUserTransactions({ more = false, page = 1 } = {}) {
  return (dispatch, getState) => {
    const { user, transactionsFilter } = getState();
    if (!more) {
      dispatch({ type: USER_TRANSACTIONS });
    }

    const object = {
      token: user.token,
      userId: user.profile.id,
      data: {
        dateFrom: transactionsFilter.dateFrom,
        dateTo: transactionsFilter.dateTo,
        dealType: transactionsFilter.dealType || '',
        realtyType: transactionsFilter.realtyType || '',
        transactionTypeId: transactionsFilter.transaction || '',
        'per-page': 25,
        page,
      },
    };

    return Api2.billing(object).then((data) => {
      dispatch({
        type: more ? USER_MORE_TRANSACTIONS_SUCCESS : USER_TRANSACTIONS_SUCCESS,
        items: data.items,
        meta: data._meta,
      });
    });
  };
}

export function setSorting(sorting) {
  return (dispatch) => {
    dispatch({ type: SET_SORTING, sorting });
  };
}

export function setSortingSection(section) {
  return (dispatch) => {
    dispatch({ type: SET_SORTING_SECTION, section });
  };
}

export function setScrollPosition(position) {
  return (dispatch) => {
    dispatch({ type: SET_SCROLL_POSITION, position });
  };
}

export function setCurrentViewType(type) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_VIEW_TYPE, viewType: type });
  };
}

export function bidProperty(object) {
  return (dispatch) => {
    Api2.bidObject(object).then((status) => {
      if (status) {
        dispatch({ type: BID_PROPERTY, bid: object.id });
      }
    });
  };
}

export function updateSalesFilter(filter) {
  return { type: UPDATE_SALES_FILTER, filter };
}

export function updateRentsFilter(filter) {
  return { type: UPDATE_RENTS_FILTER, filter };
}

export function updateTransactionsFilter(filter) {
  return { type: UPDATE_TRANSACTIONS_FILTER, filter };
}

export function initTransactionsFilter() {
  return (dispatch, getState) => {
    const filter = getState().transactionsFilter;
    if (!filter.dateTo) {
      const month = 30 * 24 * 60 * 60 * 1000;
      return dispatch({
        type: UPDATE_TRANSACTIONS_FILTER,
        filter: {
          dateFrom: toYMD(new Date() - month),
          dateTo: toYMD(new Date()),
          dealType: '',
          realtyType: '',
          transactionTypeId: '',
        },
      });
    }
    return new Promise((resolve) => resolve());
  };
}

export function selectDistricts(selected) {
  return (dispatch, getState) => {
    const districts = values(getState().districts);

    const microdistricts = districts
      .filter((dist) => {
        return selected.indexOf(+dist.id) !== -1;
      })
      .map((dist) => dist.microdistricts)
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []);

    dispatch({ type: SET_DISTRICTS, data: microdistricts });
    dispatch({ type: SET_MICRODISTRICTS, data: microdistricts });
  };
}

export function loadLists(regionId) {
  return (dispatch, getState) => {
    if (
      !getState().lists.loaded ||
      !getState().lists.heating ||
      !getState().lists.directionsList ||
      !getState().lists.rentLease
    ) {
      Api.fetchLists(regionId).then((lists) => dispatch({ type: LOAD_LISTS, lists }));
    }

    if (!getState().dictionaries.loaded) {
      Api2.fetchDictionaries().then((lists) => dispatch({ type: LOAD_DICTIONARIES, lists }));
    }

    if (!getState().dictionariesV2.loaded) {
      Api2.fetchDictionariesV2().then((lists) => dispatch({ type: LOAD_DICTIONARIES_V2, lists }));
    }
  };
}

export function loadDistricts(localityId) {
  return (dispatch) => {
    return fetch(`${API_ROOT}/lists/okatoDistricts?localityId=${localityId}`)
      .then((response) => response.json())
      .then((json) => json.data)
      .then((districts) => {
        dispatch({
          type: LOAD_DISTRICTS,
          districts,
        });
      });
  };
}

export function setLocality(locality) {
  return (dispatch) => {
    dispatch({ type: SET_LOCALITY, locality });
    dispatch(loadDistricts(locality.id));
  };
}

export function setRegion(region) {
  return (dispatch) => {
    dispatch({ type: SET_REGION, region });
    dispatch(setLocality({ id: region.capitalId, name: region.capitalName }));
    dispatch(loadLists(region.code));
    dispatch(loadConfig(region.code));
  };
}

export function setRegionBySearch(region) {
  return (dispatch) => {
    dispatch({ type: SET_REGION, region });
    dispatch(setLocality({ id: region.capitalId, name: region.capitalName }));
  };
}

export function setSalesRubric(rubricId) {
  return (dispatch) => {
    dispatch({ type: SET_SALES_RUBRIC, rubricId });
  };
}

export function setRentsRubric(rubricId) {
  return { type: SET_RENTS_RUBRIC, rubricId };
}

export function setRentsInterval(intervalId) {
  return { type: SET_RENTS_INTERVAL, intervalId };
}

export function setLocalityById(localityId) {
  return (dispatch) => {
    dispatch({ type: LOAD_LOCALITY_BY_ID });

    return Api2.getLocalityById(localityId).then((data) => {
      dispatch({
        type: LOAD_LOCALITY_BY_ID_SUCCESS,
        locality: data,
      });
    });
  };
}

export function setSection(sectionId) {
  return { type: SET_SECTION, sectionId };
}

export function setCurrentPageType(type) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_PAGE_TYPE, currentPageType: type });
  };
}

export function loadProperties(filter, type, lists) {
  return (dispatch) => {
    dispatch({ type: LOAD_PROPERTIES });
    return Api.fetchProperties(filter, type, lists).then((properties) => {
      dispatch({
        type: LOAD_PROPERTIES_SUCCESS,
        objects: properties.data,
        noMore: properties.data.length < 10,
        count: properties.count,
      });
    });
  };
}

export function loadGarageProperties(filter, type, lists) {
  return (dispatch) => {
    dispatch({ type: LOAD_PROPERTIES });
    return Api2.fetchGarageProperties(filter, type, lists).then((properties) => {
      dispatch({
        type: LOAD_PROPERTIES_SUCCESS,
        objects: properties.items,
        noMore: properties.items.length < 10,
        count: properties._meta.totalCount,
      });
    });
  };
}

export function invalidateProperties() {
  return { type: INVALIDATE_PROPERTIES };
}

export function loadMoreObjects(filter, type, lists) {
  return (dispatch, getState) => {
    const { properties } = getState();
    dispatch({ type: LOAD_MORE_PROPERTIES });

    return Api.fetchProperties(filter, type, lists).then((fetchedProperties) => {
      dispatch({
        type: LOAD_PROPERTIES_SUCCESS,
        objects: [...properties.items, ...fetchedProperties.data],
        noMore: fetchedProperties.data.length < 10,
        count: properties.count,
      });
    });
  };
}

export function loadMoreGarageObjects(filter, type, lists) {
  return (dispatch, getState) => {
    const { properties } = getState();
    dispatch({ type: LOAD_MORE_PROPERTIES });

    return Api2.fetchGarageProperties(filter, type, lists).then((fetchedProperties) => {
      dispatch({
        type: LOAD_PROPERTIES_SUCCESS,
        objects: [...properties.items, ...fetchedProperties.items],
        noMore: fetchedProperties.items.length < 10,
        count: properties.count,
      });
    });
  };
}

export function loadObject(id, type, lists = {}) {
  return (dispatch, getState) => {
    const { selectedRegion } = getState();
    dispatch({ type: LOAD_PROPERTY });

    return Api.getObject(id, type)
      .then((response) => {
        if (response.status === 404) {
          history.push(`/${selectedRegion.domain}/notFound/`);
          return dispatch({
            type: LOAD_PROPERTY_FAILURE,
            error: 'Response code 404',
          });
        }
        return response.json();
      })
      .then((respData) => {
        if (respData.data) {
          return dispatch({
            type: LOAD_PROPERTY_SUCCESS,
            object: mappings.property[type](respData.data, lists),
          });
        }
        return false;
      });
  };
}

export function loadMortgage(realtyId, type) {
  let realtyType = type;
  if (realtyType === 'sales') {
    realtyType = 'apartments';
  } else if (realtyType !== 'lands') {
    return (dispatch) =>
      dispatch({
        type: LOAD_MORTGAGE_LINK_ERROR,
      });
  }
  return (dispatch) => {
    return Api2.getMortgage(realtyId, realtyType)
      .then((object) => {
        dispatch({
          type: LOAD_MORTGAGE_LINK,
          object,
        });

        return object;
      })
      .catch(() => {
        return dispatch({
          type: LOAD_MORTGAGE_LINK_ERROR,
        });
      });
  };
}

export function loadGarageObject(id, lists) {
  return (dispatch) => {
    dispatch({ type: LOAD_PROPERTY });

    return Api2.getGarageObject(id, lists).then((object) => {
      dispatch({
        type: LOAD_PROPERTY_SUCCESS,
        object,
      });
    });
  };
}

export function loadComplex(id) {
  return (dispatch) => {
    dispatch({ type: LOAD_COMPLEX });

    return Api2.getComplex(id).then((complex) => {
      dispatch({
        type: LOAD_COMPLEX_SUCCESS,
        complex,
      });
    });
  };
}

export function loadComplexHistory(id) {
  return (dispatch) => {
    return Api2.getComplexHistory(id).then((data) => {
      dispatch({
        type: LOAD_COMPLEX_HISTORY_SUCCESS,
        history: data,
      });
    });
  };
}

export function loadComplexApartments(filter) {
  return (dispatch) => {
    dispatch({ type: LOAD_COMPLEX_APARTMENTS });

    return Api2.fetchProperties(filter, 'sale/apartments').then((apartments) => {
      dispatch({
        type: LOAD_COMPLEX_APARTMENTS_SUCCESS,
        apartments,
      });
    });
  };
}

export function loadMoreComplexApartments(filter) {
  return (dispatch, getState) => {
    const { complexApartments } = getState();
    dispatch({ type: LOAD_MORE_COMPLEX_APARTMENTS });

    return Api2.fetchProperties(filter, 'sale/apartments').then((apartments) => {
      // eslint-disable-next-line no-param-reassign
      apartments.items = [...complexApartments.data.items, ...apartments.items];
      dispatch({
        type: LOAD_COMPLEX_APARTMENTS_SUCCESS,
        apartments,
      });
    });
  };
}

export function loadUserObjects(objects) {
  return (dispatch) => {
    dispatch({ type: LOAD_USER_OBJECTS });

    const Promises = [];

    objects.forEach((item) => {
      Promises.push(
        new Promise((resolve) => {
          Api2.getUserObjects(item).then((items) => {
            resolve({
              section: item.realtyStatus,
              items,
            });
          });
        })
      );
    });
    return Promise.all(Promises).then((items) => {
      const list = {};
      items.forEach((item) => {
        list[item.section] = item.items;
      });
      dispatch({
        type: LOAD_USER_OBJECTS_SUCCESS,
        list,
      });
    });
  };
}

export function loadMoreUserObjects(filter) {
  return (dispatch) => {
    dispatch({ type: LOAD_MORE_USER_OBJECTS });

    return Api2.getUserObjects(filter).then((data) => {
      dispatch({
        type: LOAD_MORE_USER_OBJECTS_SUCCESS,
        section: filter.realtyStatus,
        data,
      });
    });
  };
}

export function loadRegions() {
  return (dispatch, getState) => {
    if (getState().regions.loaded) {
      return new Promise((resolve) => {
        resolve(getState().regions.items);
      });
    }

    return Api.fetchRegions().then((regions) => {
      dispatch({ type: LOAD_REGIONS, regions });
      return new Promise((resolve) => {
        resolve(regions);
      });
    });
  };
}

export function loadFavorites() {
  return (dispatch, getState) => {
    const { favorites, dictionaries } = getState();
    const query = favorites
      .filter((item) => item.id && item.section)
      .map((item) => ({
        id: item.id,
        section: item.section,
      }));
    return Api2.getObjects(query, dictionaries).then((payload) => {
      dispatch({
        type: LOAD_FAVORITES_ITEM,
        payload,
      });
    });
  };
}

export function loadFavoriteProperties(section, query) {
  return (dispatch, getState) => {
    const { dictionaries } = getState();
    return Api2.getObjects(query, dictionaries).then((items) => {
      dispatch({
        type: LOAD_FAVORITES_ITEM,
        payload: {
          section,
          items,
        },
      });
    });
  };
}

export function syncFavorites() {
  return (dispatch, getState) => {
    const {
      favorites,
      user: { token, profile },
    } = getState();

    if (!token) {
      return dispatch({
        type: LOAD_FAVORITES,
        payload: favorites.filter((item) => item.status !== 'deleted'),
      });
    }

    if (!!token && !!profile) {
      Api2.syncFavorites({
        userId: profile.id,
        token,
        favorites: favorites.filter((item) => item.id && item.section),
      }).then((payload) => {
        dispatch({
          type: LOAD_FAVORITES,
          payload: payload.items,
        });
      });
    }
  };
}

export function addToFavorites(object) {
  return (dispatch) => {
    dispatch(addNotification('Объект добавлен в избранное'));
    dispatch({
      type: ADD_TO_FAVORITES,
      payload: {
        id: object.id,
        section: [object.dealType, object.realtyType].join('_'),
      },
    });
    dispatch(syncFavorites());
  };
}

export function removeFromFavorites(object) {
  return (dispatch) => {
    dispatch(addNotification('Объект удален из избранного'));
    dispatch({
      type: REMOVE_FROM_FAVORITES,
      payload: {
        id: object.id,
        section: [object.dealType, object.realtyType].join('_'),
      },
    });
    dispatch(syncFavorites());
  };
}

export function clearFavorites() {
  return { type: CLEAR_FAVORITES };
}

export function detectRegion() {
  return (dispatch) => {
    return Api.getCurrentRegionByIp().then((region) => {
      dispatch({
        type: DETECT_REGION,
        region,
      });
    });
  };
}

export function loadMapProperties(filter, type) {
  return (dispatch) => {
    dispatch({ type: LOAD_MAP_PROPERTIES });
    return Api2.fetchProperties(filter, type).then((apartments) => {
      dispatch({
        type: LOAD_MAP_PROPERTIES_SUCCESS,
        apartments,
      });
    });
  };
}

export function invalidateMapProperties() {
  return { type: INVALIDATE_MAP_PROPERTIES };
}

export function loadPriceHistory(id, type, lists) {
  return (dispatch) => {
    dispatch({ type: LOAD_PRICE_HISTORY });

    return Api2.fetchPriceHistory(id, type, lists).then((property) => {
      dispatch({
        type: LOAD_PRICE_HISTORY_SUCCESS,
        property,
      });
    });
  };
}

export function loadUserInvoicesList({ more = false, page = 1 } = {}) {
  return (dispatch, getState) => {
    const { user, sort } = getState();
    if (!more) {
      dispatch({ type: USER_INVOICES_LIST_LOAD });
    }

    const object = {
      token: user.token,
      userId: user.profile.id,
      data: {
        sort,
        'per-page': 25,
        page,
      },
    };

    return Api2.invoices(object).then((data) => {
      dispatch({
        type: more ? USER_MORE_INVOICES_LIST_SUCCESS : USER_INVOICES_LIST_SUCCESS,
        items: data.items,
        meta: data._meta,
      });
    });
  };
}

export function createUserInvoice(sum) {
  return (dispatch, getState) => {
    const { user } = getState();

    dispatch({ type: USER_INVOICE_CREATE });

    return Api2.invoiceCreate({ userId: user.profile.id, sum, token: user.token })
      .then((resp) => {
        dispatch({
          type: USER_INVOICE_CREATE_SUCCESS,
        });

        return Promise.all([
          resp.json(),
          new Promise((resolve) => {
            return resolve(resp.status);
          }),
        ]);
      })
      .then((data) => {
        const message = data[0];
        const status = data[1];

        if (status >= 200 && status < 300) {
          return message;
        }
        const error = new Error('error');
        error.errorList = message;
        throw error;
      });
  };
}

export function hitContacts(object) {
  Api2.hitContact(object);
}

export function clearTotalCount() {
  return { type: CLEAR_TOTAL_COUNT };
}

export function stopReload() {
  return { type: CLEAR_TOTAL_COUNT_SUCCESS };
}

export function showModal(modalType, modalProps) {
  return {
    type: SHOW_MODAL,
    modalType,
    modalProps,
  };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function loadSavedSearchList(filterVersion = 1) {
  return (dispatch, getState) => {
    const {
      user: { token, profile },
      selectedRegion,
    } = getState();
    dispatch({
      type: LOAD_SAVED_SEARCHES_LIST,
    });
    return Api2.getSavedSearchList({
      token,
      userId: (!!profile && profile.id) || '',
      filterVersion,
    })
      .then((data) => {
        if (data.status >= 200 && data.status < 300) {
          return data.payload;
        }
        if (data.status == 401 || data.status == 404) {
          dispatch({ type: USER_LOGOUT });
          dispatch({
            type: LOAD_SAVED_SEARCHES_LIST_FAILURE,
          });
          history.push(`/${selectedRegion.domain}/user/login`);
          dispatch(addNotification('Пожалуйста авторизуйтесь'));
        } else {
          dispatch({
            type: LOAD_SAVED_SEARCHES_LIST_FAILURE,
          });
          throw new Error('Saved search list error');
        }
        return data.response;
      })
      .then((data) => {
        const garagesList =
          data && !!data.items ? data.items.filter((item) => item.realtyType === 'garages') : [];
        if (garagesList.length) {
          return Api2.getSavedSearchList({
            token,
            userId: (!!profile && profile.id) || '',
            filterVersion: 2,
          })
            .then((dataVersion2) => {
              if (dataVersion2.status >= 200 && dataVersion2.status < 300) {
                return dataVersion2.payload;
              }
              if (dataVersion2.status == 401) {
                dispatch({ type: USER_LOGOUT });
                dispatch({
                  type: LOAD_SAVED_SEARCHES_LIST_FAILURE,
                });
                history.push(`/${selectedRegion.domain}/user/login`);
                dispatch(addNotification('Пожалуйста авторизуйтесь'));
              } else {
                dispatch({
                  type: LOAD_SAVED_SEARCHES_LIST_FAILURE,
                });
                throw new Error('Saved search list error');
              }
              return dataVersion2.response;
            })
            .then((dataV2) => {
              const unionData = {
                ...data,
                items: [
                  ...data.items.filter((item) => item.realtyType !== 'garages'),
                  ...dataV2.items.filter((item) => item.realtyType === 'garages'),
                ],
              };
              dispatch({
                type: LOAD_SAVED_SEARCHES_LIST_SUCCESS,
                payload: unionData,
              });
              return unionData;
            });
        }
        dispatch({
          type: LOAD_SAVED_SEARCHES_LIST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function removeAndUnsubscribeSavedSearch(filterId) {
  return (dispatch, getState) => {
    const {
      user: { token, profile },
    } = getState();
    dispatch({
      type: REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH,
    });
    return Api2.unsubscribeSavedSearch({
      token,
      userId: (!!profile && profile.id) || '',
      filterId,
    })
      .then((data) => {
        if (data.status >= 200 && data.status < 300) {
          dispatch({
            type: UNSUBSCRIBE_SAVED_SEARCH_SUCCESS,
          });
          return Api2.removeSavedSearch({
            token,
            userId: (!!profile && profile.id) || '',
            filterId,
          }).then((removeData) => {
            if (removeData.status >= 200 && removeData.status < 300) {
              dispatch({
                type: REMOVE_SAVED_SEARCH_SUCCESS,
              });
              return true;
            }
            dispatch({
              type: REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_FAILURE,
            });
            throw new Error('Saved search remove error');
          });
        }
        dispatch({
          type: REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_FAILURE,
        });
        throw new Error('Saved search unsubscribe error');
      })
      .then(() => {
        dispatch({
          type: REMOVE_AND_UNSUBSCRIBE_SAVED_SEARCH_SUCCESS,
        });
        dispatch(loadSavedSearchList());
        dispatch(addNotification('Сохранённый поиск удален'));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function removeSavedSearch(filterId) {
  return (dispatch, getState) => {
    const {
      user: { token, profile },
    } = getState();
    dispatch({
      type: REMOVE_SAVED_SEARCH,
    });
    return Api2.removeSavedSearch({
      token,
      userId: (!!profile && profile.id) || '',
      filterId,
    })
      .then((data) => {
        if (data.status >= 200 && data.status < 300) {
          dispatch({
            type: REMOVE_SAVED_SEARCH_SUCCESS,
          });
          dispatch(loadSavedSearchList());
          dispatch(addNotification('Сохранённый поиск удален'));
        } else {
          dispatch({
            type: REMOVE_SAVED_SEARCH_FAILURE,
          });
          throw new Error('Saved search remove error');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function updateFilterType(dealType, realtyType) {
  return {
    type: UPDATE_FILTER_TYPE,
    dealType,
    realtyType,
  };
}

export function filtersAdd(object) {
  return (dispatch, getState) => {
    const {
      user,
      selectedRegion,
      filterType,
      properties,
      sortParams,
      selectedViewType,
      modal
    } = getState();

    dispatch({ type: ADD_SAVED_SEARCH });

    return Api2.filtersAdd({ userId: user.profile.id, token: user.token, ...object })
    .then((data) => {
      if (data.status >= 200 && data.status < 300) {
        dispatch({
          type: ADD_SAVED_SEARCH_SUCCESS,
        });
        dispatch(addNotification('Поиск сохранен'));
        if (object.data.get('subscribe')) {
          try {
            const page = qs.parse(modal.modalProps.history.location.search).page
            const cianGtagEvent = new GtagBuilderV2({})
              .setEvent('oldevent')
              .setEventCategory('subscription')
              .setEventAction('Send')
              .setEventLabel(properties.items && properties.items.length ? selectedViewType === 'photo' ?'From_listing' : 'From_table' : 'From_empty')
              .setUser(user)
              .setPage({
                pageType: 'Listing',
                siteType: 'mobile',
                listingType: selectedViewType === 'photo' ? 'list' : 'table',
                dealType: getCianDealType(filterType.dealType),
                objectType: getCianObjectType(filterType.realtyType),
                sortType: sortParams.selectedSorting ? sortParams.selectedSorting : 'default',
                region: selectedRegion.name.replace(/\.$/, ''),
                offersQty: Number(properties.count),
                pageNumber: Number(page),
                extra: {
                  category: getExtraCategory(filterType.dealType, filterType.realtyType)
                }
              })
              .getEvent();
            gtagPushEventNewPage(cianGtagEvent);
          } catch (e) {
            console.error('Ошибка при отправке subscription события', e);
          }
        }
      } else if (data.status == 401) {
        dispatch({ type: USER_LOGOUT });
        dispatch({
          type: ADD_SAVED_SEARCH_FAILURE,
        });
        dispatch(addNotification('Ошибка: Поиск не сохранен, пожалуйста авторизуйтесь'));
      } else {
        dispatch({
          type: ADD_SAVED_SEARCH_FAILURE,
        });
        throw new Error('Saved search error');
      }
      return data.response;
    })
    .catch((error) => {
      dispatch(addNotification('Ошибка: Поиск не сохранен'));
      throw error;
    });
  };
}

export function disableHeader() {
  return (dispatch) => {
    dispatch({ type: DISABLE_HEADER });
  };
}
