import PropTypes from 'prop-types';
import React from 'react';
import { isUndefined } from 'lodash';
import { RangeField } from '../ui';
import ModalButton from '../ui/ModalButton';
import Switch from '../ui/Switch';

import PropertyTypeSelect from '../PropertyTypeSelect';
import { FormControlLabel } from '../ui/material';
import FormPaper from './FormPaper/FormPaper';
import { SearchDivider } from '../Divider/Divider';
import TabsSelect from '../TabsSelect/TabsSelect';
import { genderItems, residentsItems } from '../../utils';
import {
  selectedLocality as selectedLocalityFromPropTypes,
  realty as realtyFromPropTypes,
} from '../../lib/PropTypeValues';

const rentLeaseSortOrder = {
  2: 3,
  3: 1,
  6: 2,
}

export default class RentApartmentsFields extends React.Component {
  mapRentTypes = (item) => {
    const mappings = {
      '1-комн.': { sort: 1, displayName: '1', ...item },
      '2-комн.': { sort: 2, displayName: '2', ...item },
      '3-комн.': { sort: 3, displayName: '3', ...item },
      '4-комн.': { sort: 4, displayName: '4', ...item },
      '5+ комн.': { sort: 5, displayName: '5+', ...item },
      'комн.': { sort: 6, displayName: 'Комната', ...item },
    };
    return mappings[item.shortName] || item;
  };

  compareBySort = (a, b) => {
    if (a.sort < b.sort) {
      return -1;
    }
    if (a.sort > b.sort) {
      return 1;
    }
    return 0;
  };

  render() {
    const {
      filter,
      rubrics,
      rubric,
      microdistricts,
      intervals,
      selectedRentsInterval,
      handleChangeInterval,
      isShowMls,
      onClearSelectedValue,
      rentLease,
      realty,
      locality,
      handleShowModal,
      type,
      onSelectLocality,
      districts,
      onChangeFilter,
      layout,
      condition,
      furniture,
      searchPeriod,
    } = this.props;

    const propertyTypes = realty.longtermRents
      .filter((item) => [101, 103, 104].indexOf(item.id) === -1)
      .map((item) => this.mapRentTypes(item))
      .sort(this.compareBySort);

    const rentLeaseItems = rentLease
      .filter((item) => [].indexOf(item.id) === -1)
      .sort((a, b) => {
        if(rentLeaseSortOrder[a.id] < rentLeaseSortOrder[b.id]) { return -1; }
        if(rentLeaseSortOrder[a.id] > rentLeaseSortOrder[b.id]) { return 1; }
        return 0;
      });

    return (
      <div>
        <FormPaper>
          <ModalButton
            label="Населенный пункт"
            valueLabel={locality.name || filter.locality_fias_id}
            value={locality.id || filter.locality_fias_id}
            name="locality_fias_id"
            fieldRef={(input) => (this.locality_fias_id = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_LOCALITY_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                title: 'Населенный пункт',
                filter,
                name: 'locality_fias_id',
                onSelectLocality,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Район"
            valueLabel={filter.o_d}
            value={filter.o_d}
            name="o_d"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.o_d = input)}
            list={districts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: districts,
                title: 'Район',
                value: filter.o_d,
                filter,
                name: 'o_d',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Микрорайон"
            valueLabel={filter.m_m}
            value={filter.m_m}
            name="m_m"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.m_m = input)}
            list={microdistricts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: microdistricts,
                title: 'Микрорайон',
                value: filter.m_m,
                filter,
                name: 'm_m',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Улица, дом"
            valueLabel={filter.addr}
            value={filter.addr}
            name="addr"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.addr = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_STREET_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                title: 'Улица, дом',
                filter,
                name: 'addr',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Тип недвижимости"
            valueLabel={rubric.name}
            showModal={() => {
              handleShowModal('CHANGE_RUBRIC', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                rubrics,
                value: rubric.id,
                filter,
              });
            }}
          />
          <SearchDivider />
          <TabsSelect
            filter={filter}
            buttons={intervals.map((item) => ({
              label: item.name,
              name: 'period',
              value: item.id,
            }))}
            defaultSelectedValue={
              !isUndefined(filter.period) ? filter.period : selectedRentsInterval.id
            }
            defaultSelectedName="period"
            onChangeInterval={handleChangeInterval}
            typeRealtyToggle={(input) => (this.period = input)}
            onChangeFilter={onChangeFilter}
          />
          <PropertyTypeSelect
            value={filter.ref_type}
            name="ref_type"
            items={propertyTypes}
            label="Комнатность"
            onChangeFilter={onChangeFilter}
          />
          <SearchDivider />
          <input type="hidden" defaultValue={1} name="is_normal_price" />
          <RangeField
            mainLabel="Цена, ₽"
            minName="ref_minprice"
            maxName="ref_maxprice"
            label=""
            measureLabel=""
            min={filter.ref_minprice}
            max={filter.ref_maxprice}
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.photo}
                name="photo"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.photo = input)}
              />
            }
            label="Только с фото"
            labelPlacement="start"
          />
        </FormPaper>

        <input type="hidden" name="has_cords" defaultValue={filter.has_cords} />

        <div>
          <input type="hidden" name="left_lat" defaultValue={filter.left_lat} />
          <input type="hidden" name="left_lon" defaultValue={filter.left_lon} />
          <input type="hidden" name="right_lat" defaultValue={filter.right_lat} />
          <input type="hidden" name="right_lon" defaultValue={filter.right_lon} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[0]} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[1]} />
          <input type="hidden" name="zoom" defaultValue={filter.zoom} />
        </div>

        {isShowMls && (
          <FormPaper>
            <TabsSelect
              filter={filter}
              buttons={[
                {
                  label: 'Реклама',
                  name: 'type_id',
                  value: '',
                },
                {
                  label: 'Мультилистинг',
                  name: 'type_id',
                  value: 1,
                },
              ]}
              defaultSelectedValue={!isUndefined(filter.type_id) ? filter.type_id : ''}
              defaultSelectedName="type_id"
              typeRealtyToggle={(input) => (this.type_id = input)}
              onChangeFilter={onChangeFilter}
            />
          </FormPaper>
        )}

        <FormPaper>
          <RangeField
            mainLabel="Общая площадь, м²"
            minName="sq_total_min"
            maxName="sq_total_max"
            min={filter.sq_total_min}
            max={filter.sq_total_max}
            measureLabel=""
            label=""
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.new}
                name="new"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.new = input)}
              />
            }
            label="Только новые дома"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Планировка"
            valueLabel={filter.planning_id}
            value={filter.planning_id}
            name="planning_id"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.planning_id = input)}
            list={layout}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: layout,
                title: 'Планировка',
                value: filter.planning_id,
                filter,
                name: 'planning_id',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Состояние/ремонт"
            valueLabel={filter.renovation_type}
            value={filter.renovation_type}
            name="renovation_type"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.renovation_type = input)}
            list={condition.sales}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: condition.sales,
                title: 'Состояние/ремонт',
                value: filter.renovation_type,
                filter,
                name: 'renovation_type',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Мебель"
            valueLabel={filter.furniture}
            value={filter.furniture}
            name="furniture"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.furniture = input)}
            list={furniture}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: furniture,
                title: 'Мебель',
                value: filter.furniture,
                filter,
                name: 'furniture',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.fridge}
                name="fridge"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.fridge = input)}
              />
            }
            label="Холодильник"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.tv}
                name="tv"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.tv = input)}
              />
            }
            label="Телевизор"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.washing}
                name="washing"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.washing = input)}
              />
            }
            label="Стиральная машина"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_conditioning}
                name="is_conditioning"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_conditioning = input)}
              />
            }
            label="Кондиционер"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.balcony}
                name="balcony"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.balcony = input)}
              />
            }
            label="Балкон / Лоджия"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Период сдачи"
            valueLabel={
              filter.lease_id &&
              rentLeaseItems.find((item) => Number(item.id) === Number(filter.lease_id)).name
            }
            value={filter.lease_id}
            name="lease_id"
            fieldRef={(input) => (this.lease_id = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: rentLeaseItems,
                title: 'Период сдачи',
                value: filter.lease_id,
                filter,
                name: 'lease_id',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.deposit}
                name="deposit"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.deposit = input)}
              />
            }
            label="Депозит"
            labelPlacement="start"
          />
          <SearchDivider />
          <ModalButton
            label="Предпочтение к жильцам"
            valueLabel={
              filter.gender &&
              genderItems.find((item) => Number(item.id) === Number(filter.gender)).name
            }
            value={filter.gender}
            name="gender"
            fieldRef={(input) => (this.gender = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: genderItems,
                title: 'Предпочтение к жильцам',
                value: filter.gender,
                filter,
                name: 'gender',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Количество жильцов"
            valueLabel={
              filter.num_of_person &&
              residentsItems.find((item) => Number(item.id) === Number(filter.num_of_person)).name
            }
            value={filter.num_of_person}
            name="num_of_person"
            fieldRef={(input) => (this.num_of_person = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: residentsItems,
                title: 'Количество жильцов',
                value: filter.num_of_person,
                filter,
                name: 'num_of_person',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_smoke_allowed}
                name="is_smoke_allowed"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_smoke_allowed = input)}
              />
            }
            label="Можно с детьми"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.pu_incl}
                name="pu_incl"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.pu_incl = input)}
              />
            }
            label="В том числе коммунальные услуги"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_animals_allowed}
                name="is_animals_allowed"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_animals_allowed = input)}
              />
            }
            label="Можно с животными"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Дата публикации"
            valueLabel={
              filter.added &&
              searchPeriod.rents.find((item) => Number(item.id) === Number(filter.added)).name
            }
            value={filter.added}
            name="added"
            fieldRef={(input) => (this.added = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: searchPeriod.rents,
                title: 'Дата публикации',
                value: filter.added,
                filter,
                name: 'added',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={+filter.agency === -1}
                name="agency"
                value="-1"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.agency = input)}
              />
            }
            label="Только от частных лиц"
            labelPlacement="start"
          />
        </FormPaper>
      </div>
    );
  }
}

RentApartmentsFields.defaultProps = {
  microdistricts: [],
  districts: [],
};

RentApartmentsFields.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  condition: PropTypes.objectOf(PropTypes.any).isRequired,
  searchPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  realty: realtyFromPropTypes.isRequired,
  rubric: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  handleChangeInterval: PropTypes.func.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  rentLease: PropTypes.arrayOf(PropTypes.object).isRequired,
  intervals: PropTypes.arrayOf(PropTypes.object).isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  districts: PropTypes.arrayOf(PropTypes.object),
  onClearSelectedValue: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  layout: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRentsInterval: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  furniture: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  isShowMls: PropTypes.bool.isRequired,
};
