import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import Item from './Item';
import styles from './ProperyList.css';
import AdItem from './AdItem/AdItem';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';

class PropertyList extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      portrait: window.innerHeight > window.innerWidth,
    };

    this.list = null;

    this.setListRef = (element) => {
      this.list = element;
    };
    this.adStorage = {};
  }

  componentDidMount() {
    const { scrollPosition } = this.props;
    this.list.scrollTo(scrollPosition);
    window.addEventListener('resize', this.setScreenOrientation);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { objects, additionalHeight } = this.props;
    const { portrait } = this.state;
    return (
      nextProps.objects.length !== objects.length ||
      portrait !== nextState.portrait ||
      additionalHeight !== nextProps.additionalHeight
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenOrientation);
  }

  handleClickItem = (item) => {
    const { updateScrollPosition, eventClickAnnouncement } = this.props;
    const node = ReactDOM.findDOMNode(this.list);
    updateScrollPosition(node.scrollTop);
    eventClickAnnouncement(item);
  };

  setScreenOrientation = () => {
    this.setState({
      portrait: window.innerHeight > window.innerWidth,
    });
    this.list.resetAfterIndex(0);
  };

  getItemSize = (index) => {
    const { objects } = this.props;
    const { portrait } = this.state;
    return objects.map((item) => {
      const maxHeight = 350;
      const heightInfo = 130;
      let itemHeight = portrait
        ? window.innerWidth * 0.5625 + heightInfo
        : window.innerWidth * 0.23125 + heightInfo;
      if (itemHeight > maxHeight + heightInfo) {
        itemHeight = 480;
      }
      if (!item.rcid) {
        itemHeight -= 21;
      }
      if (item.districts && !item.districts[item.districtId]) {
        itemHeight -= 21;
      }
      if (item.adItem) {
        itemHeight = 124;
      }
      return itemHeight;
    })[index];
  };

  isItemLoaded = (index) => {
    const { canLoadMore, objects } = this.props;
    return !canLoadMore || index < objects.length;
  };

  Item = ({ index, style, data }) => {
    let content;
    const { selectedRegion, eventAddFavorite, eventRemoveFavorite } = this.props;
    const item = data.items[index];
    const { imageHeight } = data;

    if (!this.isItemLoaded(index)) {
      content = 'Loading...';
    } else {
      content = !item.adItem ? (
        <div className={styles.item} role="button" onClick={() => this.handleClickItem(item)}>
          <Item
            {...item}
            heightImg={imageHeight - 5}
            route={item.route}
            eventAddFavorite={() => eventAddFavorite(item)}
            eventRemoveFavorite={() => eventRemoveFavorite(item)}
          />
        </div>
      ) : (
        <AdItem id={index} height={114} storage={this.adStorage} domain={selectedRegion.domain} />
      );
    }

    return <div style={style}>{content}</div>;
  };

  render() {
    const { objects, canLoadMore, onLoad, additionalHeight, handleScroll } = this.props;
    const { portrait } = this.state;
    const items = objects;
    const itemCount = canLoadMore ? items.length + 1 : items.length;
    return (
      <div className={styles.root}>
        <InfiniteLoader
          isItemLoaded={this.isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={onLoad}
        >
          {({ onItemsRendered, ref }) => (
            <List
              height={window.innerHeight + additionalHeight - 130}
              onItemsRendered={onItemsRendered}
              ref={(list) => {
                ref(list);
                this.setListRef(list);
              }}
              itemSize={this.getItemSize}
              width="100%"
              itemCount={itemCount}
              itemData={{
                items,
                imageHeight: portrait ? window.innerWidth * 0.5625 : window.innerWidth * 0.23125,
              }}
              onScroll={handleScroll}
            >
              {this.Item}
            </List>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

PropertyList.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.object),
  additionalHeight: PropTypes.number.isRequired,
  scrollPosition: PropTypes.number,
  updateScrollPosition: PropTypes.func,
  handleScroll: PropTypes.func,
  onLoad: PropTypes.func.isRequired,
  canLoadMore: PropTypes.bool,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  eventClickAnnouncement: PropTypes.func.isRequired,
  eventAddFavorite: PropTypes.func.isRequired,
  eventRemoveFavorite: PropTypes.func.isRequired,
};

PropertyList.defaultProps = {
  objects: [],
  canLoadMore: false,
  scrollPosition: 0,
  updateScrollPosition: () => {},
  handleScroll: () => {},
};

function mapStateToProps(state) {
  return {
    scrollPosition: state.scrollPosition,
    selectedRegion: state.selectedRegion,
  };
}

export default connect(mapStateToProps)(PropertyList);
