import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';
import { find, result } from 'lodash';
import { formatPrice } from '../utils';
import { UpArrowIcon, DownArrowIcon } from './ui/Icons';
import cn from './PropertiesListMap.css';
import { dictionaries as dictionariesFromPropTypes } from '../lib/PropTypeValues';

class PropertiesListMap extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { properties } = this.props;
    return nextProps.properties !== properties;
  }

  getFormattedPrice(item) {
    const arrow = this.getArrowPriceHistory(item);

    return item.price > 0 ? (
      <span>
        {arrow}
        {formatPrice(item.price)}
      </span>
    ) : (
      <span>{arrow}Цена договорная</span>
    );
  }

  getArrowPriceHistory = (item) => {
    const history = item.priceHistory;
    if (!history || item.dealType !== 'sale') {
      return null;
    }

    history.sort((a, b) => b.timestamp - a.timestamp);
    let arrow = null;
    if (history[0].deltaPrice !== 0) {
      arrow =
        history[0].deltaPrice > 0 ? (
          <UpArrowIcon color="#94c133" size="17" />
        ) : (
          <DownArrowIcon color="#ff3232" size="17" />
        );
    }

    return arrow;
  };

  getAddress = (item) => {
    if (item.location.house) {
      return item.location.house.fullName;
    }

    if (item.realtyType === 'lands' && item.objectTypeId === 4) {
      return item.gardenAssociation && `СТ ${item.gardenAssociation.name}`;
    }

    return item.location.street && item.location.street.fullName;
  };

  getTypeName(item) {
    const { dictionaries } = this.props;
    return result(
      find(dictionaries[item.dealType][item.realtyType].objectType.items, {
        id: item.objectTypeId,
      }),
      'middleName'
    );
  }

  getSquare = (item) => {
    if (item.realtyType === 'lands' && (item.objectTypeId === 1 || item.objectTypeId === 4)) {
      return item.squareLand > 0 ? `, ${item.squareLand} сот.` : null;
    }
    return item.squareTotal > 0 ? `, ${item.squareTotal} м²` : null;
  };

  getDistrict = (item) => {
    return item.location.localityDistrict && item.location.localityDistrict.fullName;
  };

  renderProperty(item) {
    const { domain, eventClickAnnouncement } = this.props;
    let section = item.realtyType;
    if (item.dealType) {
      if (item.dealType === 'sale') {
        const sections = {
          apartments: 'sales',
          lands: 'lands',
          commercials: 'saleBusiness',
          garages: 'garages',
        };
        section = sections[item.realtyType];
      } else if (item.dealType === 'rent') {
        section = item.realtyType === 'commercials' ? 'rentBusiness' : 'rents';
      } else if (item.dealType === 'rentDaily') {
        section = 'rents';
      }
    }
    const href = [`/${domain}`, section, item.id].join('/');

    return (
      <Link to={href} style={{ textDecoration: 'none' }} onClick={() => eventClickAnnouncement(item)}>
        <div className={cn.item}>
          <div style={{ padding: '7px', height: '88px', position: 'relative' }}>
            {item.photos.main ? (
              <img
                width={85}
                alt={`${section}_photo`}
                src={item.photos.main.url.replace('{{ size }}', '120x120')}
              />
            ) : (
              <div
                style={{
                  width: 85,
                  height: 85,
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  lineHeight: '100px',
                  color: '#aaa',
                }}
              >
                нет фото
              </div>
            )}
          </div>
          <div style={{ maxHeight: '140px', float: 'left', maxWidth: '190px' }}>
            <p
              style={{
                color: 'rgb(141, 198, 63)',
                fontSize: '20px',
                marginTop: '7px',
                marginBottom: '5px',
              }}
            >
              {this.getFormattedPrice(item)}
            </p>
            <p style={{ marginTop: 0, marginBottom: 0, color: '#323232', lineHeight: '20px' }}>
              <span style={{ fontSize: '14px' }}>
                {this.getTypeName(item)}
                {this.getSquare(item)}, {item.floor}/{item.floorTotal} эт.
              </span>
              <br />
              <span style={{ fontSize: '12px' }}>{this.getAddress(item)}</span>
              <br />
              <span style={{ fontSize: '14px', color: 'grey' }}>{this.getDistrict(item)}</span>
            </p>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { properties } = this.props;
    return (
      <Loader loaded={properties.loaded} color="black">
        <div className={cn.list}>
          {properties.loaded &&
            properties.apartments.items.map((item) => {
              return this.renderProperty(item);
            })}
        </div>
      </Loader>
    );
  }
}

PropertiesListMap.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  domain: PropTypes.string.isRequired,
  eventClickAnnouncement: PropTypes.func.isRequired,
};

export default PropertiesListMap;
