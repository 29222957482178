import React from 'react';

import cn from './style.css';

const MobileAppsView = () => {
  return (
    <div className={cn.bannerWrapper}>
      <div className={cn.header}>
        Разместите объявление
        <br/>
        сразу на двух сайтах
      </div>

      <p className={cn.text}>
        Разместите объявление на Циан —
        <br/>
        оно сразу появится на МЛСН
      </p>

      <div>
        <svg width="270" height="145" viewBox="0 0 270 145" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M181 115.569V143.5H131.909V133.175L136.372 131.486V76.7559L144.811 68.0791V38.1296L154.223 30.0547V10.0661L176.909 1.49963V55.8678V59.7067V112.127L181 115.569Z" fill="white"/>
          <path d="M144.811 68.0791V38.1296L154.223 30.0547V10.0661L176.909 1.49963V55.8678V59.7067V112.127L181 115.569V143.5H131.909V133.175L136.372 131.486V76.7559M144.811 68.0791L136.372 76.7559M144.811 68.0791C129.134 68.0791 136.223 68.0791 133.248 68.0791M136.372 76.7559H127" stroke="#152242" stroke-width="2"/>
          <mask id="path-3-inside-1_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 67)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 67)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-3-inside-1_3_1419)"/>
          <mask id="path-4-inside-2_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 67)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 67)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-4-inside-2_3_1419)"/>
          <mask id="path-5-inside-3_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 85)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 85)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-5-inside-3_3_1419)"/>
          <mask id="path-6-inside-4_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 157 67)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 157 67)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-6-inside-4_3_1419)"/>
          <mask id="path-7-inside-5_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 157 85)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 157 85)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-7-inside-5_3_1419)"/>
          <mask id="path-8-inside-6_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 49)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 49)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-8-inside-6_3_1419)"/>
          <mask id="path-9-inside-7_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 157 49)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 157 49)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-9-inside-7_3_1419)"/>
          <mask id="path-10-inside-8_3_1419" fill="white">
            <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 31)"/>
          </mask>
          <rect width="2" height="12" rx="0.983136" transform="matrix(-1 0 0 1 169 31)" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-10-inside-8_3_1419)"/>
          <path d="M154.212 10.0117H144.334M144.334 10.0117V30.0349M144.334 10.0117C144.589 9.47771 166.5 1 166.5 1H177.885M144.334 30.0349L133.819 38.0442M144.334 30.0349C145.099 30.0349 151.663 30.0349 154.212 30.0349M133.819 38.0442V67.4117L127.446 75.421V140.163M133.819 38.0442H145.298" stroke="#152242" stroke-width="2"/>
          <mask id="path-12-inside-9_3_1419" fill="white">
            <rect x="160" y="105" width="2" height="7" rx="0.983136"/>
          </mask>
          <rect x="160" y="105" width="2" height="7" rx="0.983136" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-12-inside-9_3_1419)"/>
          <mask id="path-13-inside-10_3_1419" fill="white">
            <rect x="180" y="105" width="2" height="7" rx="0.983136"/>
          </mask>
          <rect x="180" y="105" width="2" height="7" rx="0.983136" fill="white" stroke="#152242" stroke-width="2" mask="url(#path-13-inside-10_3_1419)"/>
          <rect x="205" y="96" width="32" height="48" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M214.983 134.5C214.983 131.453 217.453 128.983 220.5 128.983C223.547 128.983 226.017 131.453 226.017 134.5V144.017H214.983V134.5Z" fill="white" stroke="#0468FF" stroke-width="1.96627"/>
          <path d="M205.897 95.0205L214.212 70.9995H226.809L236.046 95.4773L205.897 95.0205Z" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M218.976 69.7168L212.5 93" stroke="#0468FF" stroke-width="2"/>
          <path d="M223 69.5005L227.5 92.001" stroke="#0468FF" stroke-width="2"/>
          <path d="M213 93.5C213 91.567 214.567 90 216.5 90C218.433 90 220 91.567 220 93.5V96H213V93.5Z" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M205 93.5C205 91.567 206.567 90 208.5 90C210.433 90 212 91.567 212 93.5V96H205V93.5Z" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M221 93.5C221 91.567 222.567 90 224.5 90C226.433 90 228 91.567 228 93.5V96H221V93.5Z" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M230 93.5C230 91.567 231.567 90 233.5 90C235.433 90 237 91.567 237 93.5V96H230V93.5Z" fill="white" stroke="#0468FF" stroke-width="2"/>
          <rect x="214" y="66" width="13" height="5" fill="white" stroke="#0468FF" stroke-width="2"/>
          <circle cx="220.388" cy="55.8881" r="9.38804" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M220.5 36.5C220.5 36.6298 220.5 37.1975 220.474 37.9933C220.448 40.1001 220.555 43.3618 222 45.5H220H219C220.111 44.3894 220.399 40.2633 220.474 37.9933C220.481 37.3782 220.5 36.8616 220.5 36.5Z" fill="white" stroke="#0468FF" stroke-width="2"/>
          <path d="M222 35.4375C222 36.266 221.329 36.9375 220.5 36.9375C219.672 36.9375 219 36.266 219 35.4375C219 34.6091 219.672 33.9375 220.5 33.9375C221.329 33.9375 222 34.6091 222 35.4375Z" fill="#0468FF" stroke="#0468FF"/>
          <path d="M220.5 35.5V26" stroke="#0468FF" stroke-width="1.96627"/>
          <path d="M217.5 28.5L223.5 28.5" stroke="#0468FF" stroke-width="1.96627"/>
          <path d="M76 143L143 143V82.9697C131.787 65.5737 84.6904 68.5141 76 82.9698V143Z" fill="white" stroke="#0468FF" stroke-width="1.96627"/>
          <path d="M99.1385 144.016H144.363V83.798C134.531 66.3477 106.758 69.2973 99.1385 83.798V144.016Z" fill="white" stroke="#0468FF" stroke-width="1.96627"/>
          <mask id="path-31-inside-11_3_1419" fill="white">
            <rect x="116.835" y="92.8935" width="8.84823" height="17.2049" rx="0.983136"/>
          </mask>
          <rect x="116.835" y="92.8935" width="8.84823" height="17.2049" rx="0.983136" stroke="#0468FF" stroke-width="3.93254" mask="url(#path-31-inside-11_3_1419)"/>
          <mask id="path-32-inside-12_3_1419" fill="white">
            <rect x="128.14" y="98.7913" width="8.84823" height="11.3061" rx="0.983136"/>
          </mask>
          <rect x="128.14" y="98.7913" width="8.84823" height="11.3061" rx="0.983136" stroke="#0468FF" stroke-width="3.93254" mask="url(#path-32-inside-12_3_1419)"/>
          <mask id="path-33-inside-13_3_1419" fill="white">
            <rect x="105.036" y="98.7913" width="8.84823" height="11.3061" rx="0.983136"/>
          </mask>
          <rect x="105.036" y="98.7913" width="8.84823" height="11.3061" rx="0.983136" stroke="#0468FF" stroke-width="3.93254" mask="url(#path-33-inside-13_3_1419)"/>
          <path d="M75.9831 83.9832H99.0169V144.017H75.9831V83.9832Z" fill="white" stroke="#0468FF" stroke-width="1.96627"/>
          <path d="M84.8829 132.465C84.8829 130.428 86.5335 128.778 88.5697 128.778C90.6058 128.778 92.2564 130.428 92.2564 132.465V143.033L84.8829 143.033L84.8829 132.465Z" stroke="#0468FF" stroke-width="1.96627"/>
          <circle cx="88.5697" cy="98.5464" r="3.68676" stroke="#0468FF" stroke-width="1.96627"/>
          <circle cx="88.5697" cy="112.801" r="3.68676" stroke="#0468FF" stroke-width="1.96627"/>
          <rect x="141.218" y="114.811" width="12.2175" height="29.2054" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M153.376 113.153H166.48V144.017H153.376V113.153Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <mask id="path-40-inside-14_3_1419" fill="white">
            <rect x="144.282" y="121.124" width="5.96904" height="8.62195" rx="0.983136"/>
          </mask>
          <rect x="144.282" y="121.124" width="5.96904" height="8.62195" rx="0.983136" fill="white" stroke="#152242" stroke-width="3.93254" mask="url(#path-40-inside-14_3_1419)"/>
          <path d="M138.866 115.395L146.531 104.365H147.226L155.161 115.395H138.866Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M147.242 104.417L160.923 104.207L169.192 115.196L155.173 115.405L147.242 104.417Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <rect x="8.67949" y="110.131" width="33.6018" height="33.8841" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M41.9151 112.064H76.3249V144.016H41.9151V112.064Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <mask id="path-45-inside-15_3_1419" fill="white">
            <rect x="49.4744" y="119.876" width="9.03317" height="12.9852" rx="0.983136"/>
          </mask>
          <rect x="49.4744" y="119.876" width="9.03317" height="12.9852" rx="0.983136" fill="white" stroke="#152242" stroke-width="3.93254" mask="url(#path-45-inside-15_3_1419)"/>
          <mask id="path-46-inside-16_3_1419" fill="white">
            <rect x="60.7658" y="119.876" width="9.03317" height="12.9852" rx="0.983136"/>
          </mask>
          <rect x="60.7658" y="119.876" width="9.03317" height="12.9852" rx="0.983136" fill="white" stroke="#152242" stroke-width="3.93254" mask="url(#path-46-inside-16_3_1419)"/>
          <path d="M2.76901 112.559L24.5485 94.8624H26.4522L47.448 112.559H2.76901Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M25.8197 94.8591H60.1079L82.2381 112.556L47.0554 112.556L25.8197 94.8591Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <rect x="8.67949" y="110.131" width="33.6018" height="33.8841" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M41.9151 112.064H76.3249V144.016H41.9151V112.064Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <mask id="path-51-inside-17_3_1419" fill="white">
            <rect x="49.4744" y="119.876" width="9.03317" height="12.9852" rx="0.983136"/>
          </mask>
          <rect x="49.4744" y="119.876" width="9.03317" height="12.9852" rx="0.983136" fill="white" stroke="#152242" stroke-width="3.93254" mask="url(#path-51-inside-17_3_1419)"/>
          <mask id="path-52-inside-18_3_1419" fill="white">
            <rect x="60.7658" y="119.876" width="9.03317" height="12.9852" rx="0.983136"/>
          </mask>
          <rect x="60.7658" y="119.876" width="9.03317" height="12.9852" rx="0.983136" fill="white" stroke="#152242" stroke-width="3.93254" mask="url(#path-52-inside-18_3_1419)"/>
          <path d="M2.76901 112.559L24.5485 94.8624H26.4522L47.448 112.559H2.76901Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M25.8197 94.8591H60.1079L82.2381 112.556L47.0554 112.556L25.8197 94.8591Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <rect x="224.267" y="121.368" width="22.4536" height="22.6474" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M246.769 121.983H264.39V144.017H246.769V121.983Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M231.983 132.5C231.983 130.558 233.558 128.983 235.5 128.983C237.442 128.983 239.017 130.558 239.017 132.5V144.017H231.983V132.5Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M231.586 99.9832H237.931L250.628 122.047H219.65L231.586 99.9832ZM231.725 99.7255C231.725 99.7256 231.725 99.7258 231.725 99.7259L231.725 99.7255L231.293 99.4916L231.725 99.7255Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <circle cx="234.938" cy="112.505" r="2.22306" stroke="#152242" stroke-width="1.96627"/>
          <path d="M238.048 99.9888L254.947 100.045L268.26 121.862L250.569 122.074L238.048 99.9888Z" fill="white" stroke="#152242" stroke-width="1.96627"/>
          <path d="M176.873 119.116C176.227 125.09 181.5 124.004 182.145 124.004L190.753 129C189.892 124.33 196.955 128.661 200.652 123.026C205.356 115.858 196.201 113.765 196.671 111.513C197.532 107.385 199.329 104.235 196.671 101.303C187.415 91.0925 179.886 101.846 180.746 104.996C181.489 107.712 175.474 109.232 175.044 111.513C174.613 113.794 177.519 113.142 176.873 119.116Z" fill="white" stroke="#152242" stroke-width="2"/>
          <path d="M182.647 120.472C181.133 126.821 178.272 138.591 177.281 142.79C177.134 143.414 177.617 144 178.258 144H192.75C193.391 144 193.866 143.405 193.725 142.78L190.049 126.472C189.991 126.219 190.035 125.953 190.171 125.731L194.051 119.374C194.584 118.5 193.62 117.492 192.727 117.993C190.556 119.211 188.375 120.468 188.092 120.748C187.789 121.049 186.837 115.681 186.001 110.435C185.821 109.305 184.164 109.298 183.982 110.427L183.688 112.251C183.564 113.017 182.595 113.278 182.103 112.679C181.472 111.91 180.244 112.581 180.551 113.527L182.625 119.937C182.681 120.112 182.69 120.294 182.647 120.472Z" fill="white" stroke="#152242" stroke-width="2"/>
          <line x1="166" y1="144" x2="265" y2="144" stroke="#152242" stroke-width="2"/>
          <path d="M265 144L8 144" stroke="#152242" stroke-width="2"/>
          <path d="M257.02 48.8941C258.947 48.4204 260.465 46.9442 260.997 45.0418C261.529 46.9427 263.045 48.4196 264.972 48.894C263.009 49.3932 261.483 50.9321 260.996 52.8895C260.508 50.9311 258.983 49.3931 257.02 48.8941Z" fill="#152242" stroke="#152242" stroke-width="0.449963"/>
          <path d="M201.02 7.89413C202.947 7.42035 204.465 5.94416 204.997 4.0418C205.529 5.94271 207.045 7.4196 208.972 7.89403C207.009 8.39316 205.483 9.9321 204.996 11.8895C204.508 9.93109 202.983 8.39305 201.02 7.89413Z" fill="#152242" stroke="#152242" stroke-width="0.449963"/>
          <path d="M68.0198 12.8941C69.9467 12.4204 71.4645 10.9442 71.9969 9.0418C72.5287 10.9427 74.0453 12.4196 75.9724 12.894C74.0087 13.3932 72.4834 14.9321 71.9961 16.8895C71.5085 14.9311 69.9826 13.3931 68.0198 12.8941Z" fill="#152242" stroke="#152242" stroke-width="0.449963"/>
          <path d="M16.0198 22.8941C17.9467 22.4204 19.4645 20.9442 19.9969 19.0418C20.5287 20.9427 22.0453 22.4196 23.9724 22.894C22.0087 23.3932 20.4834 24.9321 19.9961 26.8895C19.5085 24.9311 17.9826 23.3931 16.0198 22.8941Z" fill="#152242" stroke="#152242" stroke-width="0.449963"/>
          <path d="M105.02 49.8941C106.947 49.4204 108.465 47.9442 108.997 46.0418C109.529 47.9427 111.045 49.4196 112.972 49.894C111.009 50.3932 109.483 51.9321 108.996 53.8895C108.508 51.9311 106.983 50.3931 105.02 49.8941Z" fill="#152242" stroke="#152242" stroke-width="0.449963"/>
          <circle cx="61" cy="51" r="1.5" fill="#152242" stroke="#152242"/>
          <circle cx="114" cy="12" r="1.5" fill="#152242" stroke="#152242"/>
          <circle cx="16" cy="74" r="1.5" fill="#152242" stroke="#152242"/>
        </svg>

      </div>

      <a
        className={cn.link}
        href="https://omsk.cian.ru/razmestit-obyavlenie/?channel=mlsn"
      >
        Перейти на Циан
      </a>
    </div>
  );
};

export default MobileAppsView;
