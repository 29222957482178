const StylesRadioButton = {
  root: {
    padding: '14px 9px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px #757575',
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#FF7F29',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'none',
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      position: 'relative',
      bottom: '1px',
      right: '1px',
    },
  },
};

export default StylesRadioButton;
