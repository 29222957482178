import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentPageType } from '../actions';
import MobileAppsView from '../components/MobileAppsView';

class _CreateAnnouncementPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('empty'));
  }

  render() {
    return <MobileAppsView />;
  }
}

function mapStateToProps(state) {
  return {
    domain: state.selectedRegion.domain || 'omsk',
    user: state.user,
  };
}

_CreateAnnouncementPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_CreateAnnouncementPage);
