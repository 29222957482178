import PropTypes from 'prop-types';
import React from 'react';
import { isUndefined } from 'lodash';
import { RangeField } from '../ui';
import ModalButton from '../ui/ModalButton';
import Switch from '../ui/Switch';
import TabsSelect from '../TabsSelect/TabsSelect';
import PropertyTypeSelect from '../PropertyTypeSelect';
import { FormControlLabel } from '../ui/material';
import FormPaper from './FormPaper/FormPaper';
import { SearchDivider } from '../Divider/Divider';
import {
  selectedLocality as selectedLocalityFromPropTypes,
  realty as realtyFromPropTypes,
} from '../../lib/PropTypeValues';

export default class DailyRentApartmentsFields extends React.Component {
  mapDailyRentTypes = (item) => {
    const mappings = {
      '1-комн.': { sort: 1, displayName: '1', ...item },
      '2-комн.': { sort: 2, displayName: '2', ...item },
      '3-комн.': { sort: 3, displayName: '3', ...item },
      '4-комн.': { sort: 4, displayName: '4', ...item },
      хостел: { sort: 5, displayName: 'Хостел', ...item },
    };
    return mappings[item.shortName] || item;
  };

  compareBySort = (a, b) => {
    if (a.sort < b.sort) {
      return -1;
    }
    if (a.sort > b.sort) {
      return 1;
    }
    return 0;
  };

  render() {
    const {
      filter,
      rubrics,
      rubric,
      microdistricts,
      intervals,
      selectedRentsInterval,
      handleChangeInterval,
      onClearSelectedValue,
      realty,
      locality,
      handleShowModal,
      type,
      onSelectLocality,
      districts,
      onChangeFilter,
      condition,
      searchPeriod,
    } = this.props;

    const propertyTypes = realty.dailyRents
      .filter((item) => [103, 104].indexOf(item.id) === -1)
      .map((item) => this.mapDailyRentTypes(item))
      .sort(this.compareBySort);

    return (
      <div>
        <FormPaper>
          <ModalButton
            label="Населенный пункт"
            valueLabel={locality.name || filter.locality_fias_id}
            value={locality.id || filter.locality_fias_id}
            name="locality_fias_id"
            fieldRef={(input) => (this.locality_fias_id = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_LOCALITY_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                title: 'Населенный пункт',
                filter,
                name: 'locality_fias_id',
                onSelectLocality,
              })
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Район"
            valueLabel={filter.o_d}
            value={filter.o_d}
            name="o_d"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.o_d = input)}
            list={districts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: districts,
                title: 'Район',
                value: filter.o_d,
                filter,
                name: 'o_d',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Микрорайон"
            valueLabel={filter.m_m}
            value={filter.m_m}
            name="m_m"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.m_m = input)}
            list={microdistricts}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: microdistricts,
                title: 'Микрорайон',
                value: filter.m_m,
                filter,
                name: 'm_m',
              });
            }}
          />
          <SearchDivider />
          <ModalButton
            label="Улица, дом"
            valueLabel={filter.addr}
            value={filter.addr}
            name="addr"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.addr = input)}
            showModal={() => {
              handleShowModal('AUTOCOMPLITE_STREET_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                title: 'Улица, дом',
                filter,
                name: 'addr',
              });
            }}
          />
        </FormPaper>

        <FormPaper>
          <ModalButton
            label="Тип недвижимости"
            valueLabel={rubric.name}
            showModal={() => {
              handleShowModal('CHANGE_RUBRIC', {
                filterType: 'rent',
                rubrics,
                value: rubric.id,
                filter,
              });
            }}
          />
          <SearchDivider />
          <TabsSelect
            filter={filter}
            buttons={intervals.map((item) => ({
              label: item.name,
              name: 'period',
              value: item.id,
            }))}
            defaultSelectedValue={
              !isUndefined(filter.period) ? filter.period : selectedRentsInterval.id
            }
            defaultSelectedName="period"
            onChangeInterval={handleChangeInterval}
            typeRealtyToggle={(input) => (this.period = input)}
            onChangeFilter={onChangeFilter}
          />
          <PropertyTypeSelect
            value={filter.ref_type}
            name="ref_type"
            items={propertyTypes}
            label="Комнатность"
            onChangeFilter={onChangeFilter}
          />
          <SearchDivider />
          <input type="hidden" defaultValue={1} name="is_normal_price" />
          <RangeField
            mainLabel="Цена, ₽"
            minName="ref_minprice"
            maxName="ref_maxprice"
            label=""
            measureLabel=""
            min={filter.ref_minprice}
            max={filter.ref_maxprice}
            onChangeFilter={onChangeFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.photo}
                name="photo"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.photo = input)}
              />
            }
            label="Только с фото"
            labelPlacement="start"
          />
        </FormPaper>

        <FormPaper>
          <RangeField
            mainLabel="Количество спальных мест"
            minName="number_of_beds"
            maxName=""
            label=""
            measureLabel=""
            min={filter.number_of_beds}
            max={0}
          />
          <ModalButton
            label="Состояние/ремонт"
            valueLabel={filter.renovation_type}
            value={filter.renovation_type}
            name="renovation_type"
            onClearSelectedValue={onClearSelectedValue}
            fieldRef={(input) => (this.renovation_type = input)}
            list={condition.sales}
            multi
            showModal={() => {
              handleShowModal('CHECBOXES_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: condition.sales,
                title: 'Состояние/ремонт',
                value: filter.renovation_type,
                filter,
                name: 'renovation_type',
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.fridge}
                name="fridge"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.fridge = input)}
              />
            }
            label="Холодильник"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.tv}
                name="tv"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.tv = input)}
              />
            }
            label="Телевизор"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.washing}
                name="washing"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.washing = input)}
              />
            }
            label="Стиральная машина"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_conditioning}
                name="is_conditioning"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_conditioning = input)}
              />
            }
            label="Кондиционер"
            labelPlacement="start"
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={filter.is_internet}
                name="is_internet"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.is_internet = input)}
              />
            }
            label="Интернет"
            labelPlacement="start"
          />
        </FormPaper>

        <input type="hidden" name="has_cords" defaultValue={filter.has_cords} />

        <div>
          <input type="hidden" name="left_lat" defaultValue={filter.left_lat} />
          <input type="hidden" name="left_lon" defaultValue={filter.left_lon} />
          <input type="hidden" name="right_lat" defaultValue={filter.right_lat} />
          <input type="hidden" name="right_lon" defaultValue={filter.right_lon} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[0]} />
          <input type="hidden" name="center[]" defaultValue={filter.center && filter.center[1]} />
          <input type="hidden" name="zoom" defaultValue={filter.zoom} />
        </div>

        <FormPaper>
          <ModalButton
            label="Дата публикации"
            valueLabel={
              filter.added &&
              searchPeriod.rents.find((item) => Number(item.id) === Number(filter.added)).name
            }
            value={filter.added}
            name="added"
            fieldRef={(input) => (this.added = input)}
            showModal={() => {
              handleShowModal('RADIO_GROUP_MODAL', {
                filterType: type === 'sales' ? 'sale' : 'rent',
                list: searchPeriod.rents,
                title: 'Дата публикации',
                value: filter.added,
                filter,
                name: 'added',
                defaultValue: true,
              });
            }}
          />
          <SearchDivider />
          <FormControlLabel
            control={
              <Switch
                checkedValue={+filter.agency === -1}
                name="agency"
                value="-1"
                onChangeFilter={() => onChangeFilter()}
                inputRef={(input) => (this.agency = input)}
              />
            }
            label="Только от частных лиц"
            labelPlacement="start"
          />
        </FormPaper>
      </div>
    );
  }
}

DailyRentApartmentsFields.defaultProps = {
  microdistricts: [],
  districts: [],
};

DailyRentApartmentsFields.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  condition: PropTypes.objectOf(PropTypes.any).isRequired,
  searchPeriod: PropTypes.objectOf(PropTypes.any).isRequired,
  realty: realtyFromPropTypes.isRequired,
  rubric: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  districts: PropTypes.arrayOf(PropTypes.object),
  onClearSelectedValue: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  handleChangeInterval: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  intervals: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRentsInterval: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};
