import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Infinite from 'react-infinite';
import Modal from 'react-modal';
import Loader from 'react-loader';
import { FontIcon } from '../ui/material';
import { Panel } from '../ui';
import cn from './UserPayments.css';
import { formatPrice, toDotDMY, toDotDMYhm, pluralize } from '../../utils';
import { loadUserTransactions, initTransactionsFilter } from '../../actions';
import { ModalPaper, PaymentPaper }  from '../../components/SearchForm/FormPaper/FormPaper';

const cx = require('classnames/bind').bind(cn);

class UserPayments extends React.Component {
  state = {
    modalIsOpen: false,
    item: {},
    page: 1,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(initTransactionsFilter());
    dispatch(loadUserTransactions());
  }

  onLoadMore = () => {
    const { dispatch, userTransactions } = this.props;
    const { page } = this.state;
    const meta = userTransactions.meta || {};
    if (meta.pageCount === page) {
      return false;
    }

    const nextPage = page + 1;
    this.setState({
      page: nextPage,
    });
    dispatch(
      loadUserTransactions({
        more: true,
        page: nextPage,
      })
    );
    return false;
  };

  onModalLayoutClick = (e) => {
    const modalContent = ReactDOM.findDOMNode(this.modalContent);
    if (modalContent === e.target) {
      this.closeModal(e);
    }
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({
      modalIsOpen: false,
      item: {},
    });
  };

  openModal = (item) => {
    this.setState({
      modalIsOpen: true,
      item,
    });
  };

  render() {
    const { transactionsFilter, userTransactions } = this.props;
    const { page, modalIsOpen, item } = this.state;
    const items = userTransactions.items || [];
    const meta = userTransactions.meta || {};
    const credit = (meta.credit || 0) + (meta.bonus || 0);
    const debit = meta.debit || 0;

    const canLoadMore = meta.pageCount > page;
    const loadMore = meta.pageCount > page && meta.currentPage === page;

    const modalStyles = {
      overlay: {
        background: 'rgba(245,246,248,0.8)',
        zIndex: 5,
      },
      content: {
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        padding: 0,
        border: 'none',
        borderRadius: 'none',
        background: 'transparent',
        overflowY: 'hidden',
      },
    };

    return (
      <Loader loaded={userTransactions.loaded}>
        <Panel
          title=""
          containerStyle={{ padding: '16px' }}
          titleStyle={{ textAlign: 'center' }}
        >
          <div className={cn.paymentsPeriod}>
            За период с {toDotDMY(transactionsFilter.dateFrom)} по{' '}
            {toDotDMY(transactionsFilter.dateTo)}
          </div>
          <div className={cn.credit}>Приход: {formatPrice(credit)}</div>
          <div className={cn.debit}>Расход: {formatPrice(debit)}</div>
        </Panel>
        <Panel
          title={`${pluralize(meta.totalCount, 'Найдена', 'Найдено', 'Найдено')} ${
            meta.totalCount || '0'
          } ${pluralize(meta.totalCount, 'операция', 'операции', 'операций')}`}
          containerStyle={{ padding: '0' }}
          titleStyle={{ textAlign: 'center' }}
        >
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0, background: '#EFEFEF' }}>
            <Infinite
              ref={(n) => {
                this.list = n;
              }}
              elementHeight={90}
              isInfiniteLoading={!loadMore}
              infiniteLoadBeginEdgeOffset={250}
              containerHeight={window.innerHeight - 188}
              loadingSpinnerDelegate={
                canLoadMore ? (
                  <div style={{ textAlign: 'center', margin: 20 }}>Загрузка ...</div>
                ) : (
                  <div />
                )
              }
              onInfiniteLoad={this.onLoadMore}
            >
              {items.map((userTransaction) => (
                <li
                  key={`${userTransaction.sum}_${userTransaction.inserted}`}
                  className={cn.userTransaction}
                  onClick={() => {
                    this.openModal(userTransaction);
                  }}
                >
                  <PaymentPaper>
                    <div className={cn.params}>
                      <div className={cx({ sum: true, costs: userTransaction.sum < 0 })}>
                        {formatPrice(userTransaction.sum)}
                      </div>
                      <div className={cn.date}>{toDotDMYhm(userTransaction.inserted * 1000)}</div>
                    </div>
                    <div className={cn.description}>{userTransaction.description}</div>
                  </PaymentPaper>
                </li>
              ))}
            </Infinite>
          </ul>
        </Panel>
        <Modal isOpen={modalIsOpen} style={modalStyles}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 6,
              background: 'transparent',
              height: 50,
            }}
          >
            <div className={cn.modalClose}>
              <a href="#" onClick={this.closeModal}>
                <FontIcon className="material-icons">close</FontIcon>
              </a>
            </div>
          </div>
          <div
            ref={(n) => {
              this.modalContent = n;
            }}
            className={cn.modalContent}
            onClick={this.onModalLayoutClick}
            role="button"
          >
            <ModalPaper>
              <div className={cn.params}>
                <div className={cx({ sum: true, costs: item.sum < 0 })}>
                  <div className={cx({ label: true, alignLeft: true })}>Сумма</div>
                  {formatPrice(item.sum)}
                </div>
                <div className={cx({ date: true, alignRight: true })}>
                  <div className={cn.label}>Дата</div>
                  {toDotDMYhm(item.inserted * 1000)}
                </div>
              </div>
              <div className={cn.params}>
                <div className={cx({ paramsItem: true, alignLeft: true })}>
                  <div className={cn.label}>Новый баланс</div>
                  {formatPrice(item.balance)}
                </div>
                <div className={cx({ paramsItem: true, alignRight: true })}>
                  <div className={cn.label}>Тариф на тот момент</div>
                  {item.tariffName || ''}
                </div>
              </div>
              {(item.sectionName || item.adsNumber) && (
                <div className={cn.params}>
                  {item.sectionName && (
                    <div className={cx({ paramsItem: true, alignLeft: true })}>
                      <div className={cn.label}>Раздел</div>
                      {item.sectionName}
                    </div>
                  )}
                  {item.adsNumber && (
                    <div
                      className={cx({ paramsItem: true, alignRight: true })}
                      style={{ margin: '0 3px' }}
                    >
                      <div className={cn.label}>Строчек</div>
                      {item.adsNumber}
                    </div>
                  )}
                </div>
              )}
              {!!item.discount && (
                <div className={cn.params}>
                  <div className={cn.paramsItem}>
                    <div className={cx({ label: true, alignRight: true })}>Скидка</div>
                    {item.discount * 100}%
                  </div>
                </div>
              )}
              <div className={cn.description}>
                <div className={cx({ label: true, alignLeft: true })}>Описание</div>
                {item.description || ''}
              </div>
            </ModalPaper>
          </div>
        </Modal>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsFilter: state.transactionsFilter,
    userTransactions: state.userTransactions,
    dictionaries: state.dictionaries,
    user: state.user,
  };
}

UserPayments.defaultProps = {
  userTransactions: {},
};

UserPayments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  transactionsFilter: PropTypes.shape({
    dateTo: PropTypes.string,
    dateFrom: PropTypes.string,
    transaction: PropTypes.objectOf(PropTypes.any),
    section: PropTypes.shape({
      sectionId: PropTypes.number,
    }),
  }).isRequired,
  userTransactions: PropTypes.shape({
    dateTo: PropTypes.string,
    dateFrom: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.objectOf(PropTypes.any),
    loaded: PropTypes.bool,
  }),
};

export default connect(mapStateToProps)(UserPayments);
