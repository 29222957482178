import React from 'react';
import cn from '../../../../components/User/UserTariff.css'
import { factoryTariffTableCell } from '../factories/factoryTariffTableCell';

export class tariffRowBuilder {
  constructor (rows) {
    this.rows = rows;
    this.bufferRows = [];
  }

  buildRows (type) {
    switch (type) {
      case 'count': {
        this.bufferRows.push(<tr>{new factoryTariffTableCell().createCell('headerCount')}</tr>);
        this.rows.map(row => {
          this.bufferRows.push(<tr>{new factoryTariffTableCell(row).createCell('count')}</tr>);
        });
        return this;
      }
      case 'price': {
        this.bufferRows.push(<tr>{new factoryTariffTableCell().createCell('headerPrice')}</tr>);
        this.rows.map(row => {
          this.bufferRows.push(<tr>{new factoryTariffTableCell(row).createCell('price')}</tr>);
        });
        return this;
      }
      case 'discount': {
        this.bufferRows.push(<tr>{new factoryTariffTableCell().createCell('headerDiscount')}</tr>);
        this.rows.map(row => {
          this.bufferRows.push(<tr>{new factoryTariffTableCell(row, this.rows[0].costPerAd).createCell('discount')}</tr>);
        })
        return this;
      }
    }
  }

  renderRows () {
    return <table className={cn.subTable}>{this.bufferRows}</table>
  }

}