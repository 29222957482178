import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  hideModal,
} from '../../../actions'
import {
  FontIcon,
} from '../../ui/material';
import Slide from '@material-ui/core/Slide';
import styles from './Modals.css'
import StyledDialog from '../ModalWithStyles';
import { StyledDialogContent } from '../DialogContentWithStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UnauthorizedModal extends Component {

  handleSignIn = () => {
    const { history, selectedRegion } = this.props;
    history.push(`/${selectedRegion.domain}/user/login`);
    this.props.dispatch(hideModal());
  }

  handleSignUp = () => {
    const { history, selectedRegion } = this.props;
    history.push(`/${selectedRegion.domain}/user/register`);
    this.props.dispatch(hideModal());
  }

  render() {
    const {
      dispatch,
      title,
    } = this.props;
    return (
      <div>
        <StyledDialog
          open={true}
          onClose={() => dispatch(hideModal())}
          TransitionComponent={Transition}
        >
          <div className={styles.header}>
          <div className={styles.headerName}>{title}</div>
            <div className={styles.headerClose}>
              <FontIcon className="material-icons" onClick={() => dispatch(hideModal())} style={{color: 'black', fontSize: '19px'}}>close</FontIcon>
            </div>
          </div>
          <StyledDialogContent>
            <div className={styles.description}>
              <p>
                Для сохранения поиска необходимо авторизоваться или зарегистрироваться.
              </p>
              <p>
                Это позволит в любой момент и на любых Ваших устройствах вернуться к текущим результатам поиска.
              </p>
            </div>
          </StyledDialogContent>

          <div className={styles.inlineButtons}>
            <button className={styles.saveButton} onClick={() => this.handleSignIn()}>
              Войти
            </button>
            <button className={styles.secondSaveButton} onClick={() => this.handleSignUp()}>
              Зарегистрироваться
            </button>
          </div>
        </StyledDialog>
      </div>
    )
  }
}

UnauthorizedModal.propTypes = {
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,

  selectedRegion: PropTypes.object.isRequired,
};

export default connect(
  (state, ownProps) => ({
    title: ownProps.title,
    history: ownProps.history,

    selectedRegion: state.selectedRegion,
  })
)(UnauthorizedModal)