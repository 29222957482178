import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { isArray } from 'lodash';
import {
  hideModal,
  updateSalesFilter,
  updateRentsFilter,
  loadProperties,
  loadGarageProperties,
} from '../../../actions';
import { FontIcon, FormControlLabel } from '../../ui/material';
import styles from './Modals.css';
import StyledDialog from '../ModalWithStyles';
import { StyledDialogContent } from '../DialogContentWithStyles';
import { SearchDivider } from '../../Divider/Divider';
import { StyledCheckbox } from '../../Checkbox';
import { capitalizeFirstLetter, convertFilter } from '../../../utils';
import {
  lists as listsFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
} from '../../../lib/PropTypeValues';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CheckboxesModal extends Component {
  constructor(...props) {
    super(...props);
    const { value } = this.props;
    this.state = {
      // eslint-disable-next-line no-nested-ternary
      selected: isArray(value) ? value : typeof value === 'undefined' ? [] : [value],
    };
  }

  handleChange = (value) => {
    const { selected } = this.state;
    let currentSelected = selected;
    if (currentSelected.includes(value.toString())) {
      currentSelected = currentSelected.filter((item) => Number(item) !== Number(value));
    } else {
      currentSelected.push(value.toString());
    }
    const uniqueSelected = [...new Set(currentSelected)];
    this.setState({
      selected: uniqueSelected,
    });
  };

  handleSave = () => {
    const { selected } = this.state;
    const {
      filterType,
      filter,
      dispatch,
      lists,
      selectedLocality,
      dictionaries,
      name,
      selectedSalesRubric,
      selectedRentsRubric,
    } = this.props;

    const rubricRoute =
      filterType === 'sale' ? selectedSalesRubric.route : selectedRentsRubric.route;

    const localityName = rubricRoute === 'garages' ? 'localityId' : 'locality_fias_id';

    const currentFilter = {
      ...filter,
      [localityName]: selectedLocality.id,
      [name]: selected,
    };

    if (filterType === 'sale') {
      dispatch(updateSalesFilter(currentFilter));
    } else {
      dispatch(updateRentsFilter(currentFilter));
    }

    if (rubricRoute === 'garages') {
      dispatch(
        loadGarageProperties(
          convertFilter({ ...currentFilter }, selectedLocality),
          `sale/${rubricRoute}`,
          dictionaries
        )
      );
    } else {
      dispatch(loadProperties({ ...currentFilter }, rubricRoute, lists));
    }

    dispatch(hideModal());
  };

  render() {
    const { selected } = this.state;
    const { dispatch, title, list } = this.props;

    return (
      <div>
        <StyledDialog open onClose={() => dispatch(hideModal())} TransitionComponent={Transition}>
          <div className={styles.header}>
            <div className={styles.headerName}>{title}</div>
            <div className={styles.headerClose}>
              <FontIcon
                className="material-icons"
                onClick={() => dispatch(hideModal())}
                style={{ color: 'black', fontSize: '19px' }}
              >
                close
              </FontIcon>
            </div>
          </div>
          <StyledDialogContent>
            {list.map((item) => {
              return (
                <div key={item.id}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={
                          !!selected.find(
                            (selectedItem) => Number(selectedItem) === Number(item.id)
                          )
                        }
                        onChange={() => this.handleChange(item.id)}
                      />
                    }
                    value={item.id}
                    label={capitalizeFirstLetter(item.name)}
                    labelPlacement="start"
                  />
                  <SearchDivider />
                </div>
              );
            })}
          </StyledDialogContent>

          <div />
          <button className={styles.saveButton} type="button" onClick={() => this.handleSave()}>
            Выбрать
          </button>
        </StyledDialog>
      </div>
    );
  }
}

CheckboxesModal.defaultProps = {};

CheckboxesModal.propTypes = {
  filterType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedSalesRubric: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRentsRubric: PropTypes.objectOf(PropTypes.any).isRequired,
  lists: listsFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array]).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect((state, ownProps) => ({
  filterType: ownProps.filterType,
  filter: ownProps.filter,
  title: ownProps.title,
  list: ownProps.list,
  name: ownProps.name,
  value: ownProps.value,

  lists: state.lists,
  selectedRegion: state.selectedRegion,
  selectedSalesRubric: state.selectedSalesRubric,
  selectedRentsRubric: state.selectedRentsRubric,
  selectedLocality: state.selectedLocality,
  dictionaries: state.dictionaries,
}))(CheckboxesModal);
