/* eslint-disable react/no-unused-state */
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Carousel.css';

export default class Carousel extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      touchstartX: 0,
      touchstartY: 0,
      touchmoveX: 0,
      touchmoveY: 0,
      touchstartTime: 0,
      sensitivity: 10,
      deltaX: 0,
      deltaY: 0,
      percentageOffset: 0,
      activeSlide: 0,
      slideCount: 0,
      wrapper: {},
      playAnimation: false,
    };

    this.afterSelect = this.afterSelect.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
  }

  componentDidMount() {
    const { mode, photos } = this.props;
    const wrapper = document.querySelector(`.carousel__${mode}`);
    this.setState(
      {
        wrapper,
        slideCount: photos.length,
      },
      this.init
    );
  }

  touchStart(e) {
    this.setState({
      touchstartX: e.touches ? e.touches[0].pageX : e.clientX,
      touchstartY: e.touches ? e.touches[0].pageY : e.clientY,
      touchstartTime: new Date().getTime(),
    });
  }

  touchMove(e) {
    const { touchstartX, touchstartY, slideCount, activeSlide, wrapper } = this.state;
    e.preventDefault();
    e.stopPropagation();

    const touchmoveX = e.touches ? e.touches[0].pageX : e.clientX;
    const touchmoveY = e.touches ? e.touches[0].pageY : e.clientY;
    const deltaX = touchmoveX - touchstartX;
    const deltaY = touchmoveY - touchstartY;

    let percentageOffset = 0;

    if (Math.abs(deltaX) > Math.abs(deltaY) * 0.8) {
      percentageOffset = ((100 / slideCount) * deltaX) / window.innerWidth;
      const percentageCalculated = percentageOffset - (100 / slideCount) * activeSlide;
      wrapper.style.transform = `translateX( ${percentageCalculated}% )`;
    }

    this.setState({
      touchmoveX,
      touchmoveY,
      deltaX,
      deltaY,
      percentageOffset,
      playAnimation: true,
    });
  }

  touchEnd(e) {
    const {
      touchstartTime,
      touchstartX,
      deltaX,
      deltaY,
      activeSlide,
      percentageOffset,
      sensitivity,
      slideCount,
    } = this.state;
    const elapsedTime = new Date().getTime() - touchstartTime;
    const touchendX = e.changedTouches ? e.changedTouches[0].pageX : e.clientX;
    const velocityX = ((touchendX - touchstartX) / elapsedTime).toFixed(2);

    if (Math.abs(deltaX) > Math.abs(deltaY) * 0.8) {
      if (velocityX > 1) {
        this.select(activeSlide - 1);
      } else if (velocityX < -1) {
        this.select(activeSlide + 1);
      } else if (percentageOffset <= -(sensitivity / slideCount)) {
        this.select(activeSlide + 1);
      } else if (percentageOffset >= sensitivity / slideCount) {
        this.select(activeSlide - 1);
      } else {
        this.select(activeSlide);
      }
    }

    this.setState({
      deltaX: 0,
      deltaY: 0,
    });
  }

  select(number) {
    const { slideCount } = this.state;
    let { activeSlide } = this.state;
    if (number < 0) {
      activeSlide = 0;
    } else if (number > slideCount - 1) {
      activeSlide = slideCount - 1;
    } else {
      activeSlide = number;
    }

    this.setState(
      {
        activeSlide,
      },
      this.selectionAnimation
    );

    this.afterSelect(activeSlide);
  }

  selectionAnimation() {
    const { playAnimation, wrapper, slideCount, activeSlide } = this.state;
    if (playAnimation) {
      wrapper.style.transition = 'transform .3s';
    } else {
      wrapper.style.transition = 'none';
    }

    const translateX = -(100 / slideCount) * activeSlide;
    wrapper.style.transform = `translateX( ${translateX}% )`;
  }

  init() {
    const { initialIndex } = this.props;
    const { slideCount, wrapper } = this.state;
    if (slideCount === 1) {
      return;
    }

    wrapper.addEventListener('touchstart', this.touchStart, false);
    wrapper.addEventListener('touchmove', this.touchMove, false);
    wrapper.addEventListener('touchend', this.touchEnd, false);
    wrapper.addEventListener('transitionend', () => (wrapper.style.transition = 'none'));

    this.select(initialIndex);
  }

  afterSelect(index) {
    const { afterSelect } = this.props;
    afterSelect(index);
  }

  render() {
    const { photos, height, mode } = this.props;
    const { slideCount } = this.state;
    const className = `${styles.wrapper} carousel__${mode}`;
    const width = `${slideCount * 100}%`;

    return (
      <div className={styles.carousel} style={{ height }}>
        <div className={className} style={{ width }}>
          {photos.map((photo) => (
            <div className={styles.item} style={{ height }}>
              <img src={photo} alt="carousel__photo" className={styles.img} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Carousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.any).isRequired,
  mode: PropTypes.string.isRequired,
  initialIndex: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  afterSelect: PropTypes.func.isRequired,
};
