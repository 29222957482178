import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeral';
import ym from 'react-yandex-metrika';
import Modal from 'react-modal';
import Loader from 'react-loader';
import { FontIcon } from '../ui/material';
import RLink from '../RLink';
import { Panel } from '../ui';
import { Map, Placemark } from '../YandexMaps';
import YandexPanorama from '../YandexPanorama';
import PreviewText from '../PreviewText';
import ComplexInfo from './ComplexInfo';
import ComplexApartments from './ComplexApartments';
import PhotoSlider from '../PropertyView/PhotoSlider';
import cn from './ComplexView.css';

export default class ComplexView extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      mapIsOpen: false,
      mapLoaded: false,
      panoramaIsOpen: false,
    };
  }

  handleMapLoad = () => {
    this.setState({
      mapLoaded: true,
    });
  };

  openMap(e) {
    ym('reachGoal', 'show_map');
    e.preventDefault();
    this.setState({
      mapIsOpen: true,
    });
  }

  openPanorama(e) {
    e.preventDefault();
    this.setState({
      panoramaIsOpen: true,
    });
  }

  closeMap(e) {
    e.preventDefault();
    this.setState({
      mapIsOpen: false,
      mapLoaded: false,
    });
  }

  closePanorama(e) {
    e.preventDefault();
    this.setState({
      panoramaIsOpen: false,
    });
  }

  renderPrice() {
    const language = {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'тыс.',
        million: 'млн',
      },
      currency: {
        symbol: '',
      },
    };

    numeral.language('ru', language);
    numeral.language('ru');

    const { priceMin, priceMax, pricePerSquareMeterMin, pricePerSquareMeterMax } = this.props;
    const formattedPrice = (price) => numeral(Math.floor(price)).format('0,0[.]00$');

    return priceMin ? (
      <span>
        <span className={cn.price}>
          {formattedPrice(priceMin)} - {formattedPrice(priceMax)} руб.
        </span>

        {pricePerSquareMeterMin > 0 && (
          <div className={cn.priceSquare}>
            {formattedPrice(pricePerSquareMeterMin)} - {formattedPrice(pricePerSquareMeterMax)}{' '}
            руб./м²
          </div>
        )}
      </span>
    ) : (
      'Цена договорная'
    );
  }

  renderMainInfo() {
    const { name, location, complexHistory, id } = this.props;
    return (
      <div className={cn.mainInfo}>
        <p style={{ fontSize: 20, margin: '5px 0 15px' }}>{`ЖК "${name.trim()}"`}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{this.renderPrice()}</div>
        </div>
        <p style={{ margin: '5px 0 15px' }}>{location.trim()}</p>
        {complexHistory && (
          <p>
            <RLink
              to={`complex/${id}/history`}
              style={{ color: '#8dc63f', fontSize: 14, textDecoration: 'none' }}
            >
              <FontIcon
                className="material-icons"
                style={{
                  color: '#8dc63f',
                  fontSize: 20,
                  marginRight: 4,
                  verticalAlign: 'text-bottom',
                }}
              >
                restore
              </FontIcon>
              Смотреть ход строительства
            </RLink>
          </p>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            margin: '30px 0',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontIcon
              className="material-icons"
              style={{ color: '#8dc63f', fontSize: 24, marginRight: 4 }}
            >
              place
            </FontIcon>
            <a
              href="#"
              style={{ color: '#8dc63f', textDecoration: 'none' }}
              onClick={this.openMap.bind(this)}
            >
              Карта
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontIcon
              className="material-icons"
              style={{ color: '#8dc63f', fontSize: 24, marginRight: 4 }}
            >
              panorama_horizontal
            </FontIcon>
            <a
              href="#"
              style={{ color: '#8dc63f', textDecoration: 'none' }}
              onClick={this.openPanorama.bind(this)}
            >
              Панорама
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { photos, description, location, point } = this.props;
    const { mapIsOpen, mapLoaded, panoramaIsOpen } = this.state;
    const modalStyles = {
      overlay: {
        background: 'rgba(0,0,0,0.9)',
        zIndex: 5,
      },
      content: {
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        padding: 0,
        border: 'none',
        borderRadius: 'none',
        background: 'transparent',
        overflowY: 'hidden',
      },
    };

    return (
      <div>
        {photos.all.length > 0 && (
          <div className={cn.root}>
            <PhotoSlider photos={photos.all.map((photo) => photo.url.replace('{{ size }}/', ''))} />
          </div>
        )}
        {this.renderMainInfo()}
        <Panel title="Информация" titleClassName={cn.panelTitle}>
          <div style={{ padding: '0 20px' }}>
            <ComplexInfo {...this.props} />
          </div>
        </Panel>
        <Panel title="Описание" titleClassName={cn.panelTitle}>
          <div style={{ padding: '0 20px', fontWeight: 300, color: '#252525', fontSize: '16px' }}>
            <PreviewText text={description} />
          </div>
        </Panel>
        <Panel title="Объекты" titleClassName={cn.panelTitle} containerStyle={{ padding: '0px' }}>
          <div style={{ color: '#252525', fontSize: '16px' }}>
            <ComplexApartments {...this.props} />
          </div>
        </Panel>

        <Modal isOpen={mapIsOpen} style={modalStyles}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 6,
              background: 'black',
              height: 50,
            }}
          >
            <div style={{ left: 15, top: 15, position: 'fixed', color: 'white' }}>
              {location.trim()}
            </div>
            <div style={{ right: 15, top: 12, position: 'fixed', color: 'white' }}>
              <a href="#" onClick={this.closeMap.bind(this)}>
                <FontIcon className="material-icons">close</FontIcon>
              </a>
            </div>
          </div>
          <Loader loaded={mapLoaded} color="white" />
          <Map mapState={{ center: [point[0], point[1]], zoom: 17 }} onLoad={this.handleMapLoad}>
            <Placemark geometry={[point[0], point[1]]} />
          </Map>
        </Modal>

        <Modal isOpen={panoramaIsOpen} style={modalStyles}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 6,
              background: 'black',
              height: 50,
            }}
          >
            <div style={{ left: 15, top: 15, position: 'fixed', color: 'white' }}>
              {location.trim()}
            </div>
            <div style={{ right: 15, top: 12, position: 'fixed', color: 'white' }}>
              <a href="#" onClick={this.closePanorama.bind(this)}>
                <FontIcon className="material-icons">close</FontIcon>
              </a>
            </div>
          </div>
          {panoramaIsOpen && <YandexPanorama coordinates={[point[0], point[1]]} />}
        </Modal>
      </div>
    );
  }
}

ComplexView.defaultProps = {
  priceMin: 0,
  priceMax: 0,
  pricePerSquareMeterMin: 0,
  pricePerSquareMeterMax: 0,
  photos: {},
  description: null,
  location: null,
  name: null,
  point: [],
  complexHistory: null,
  id: null,
};

ComplexView.propTypes = {
  priceMin: PropTypes.number,
  priceMax: PropTypes.number,
  pricePerSquareMeterMin: PropTypes.number,
  pricePerSquareMeterMax: PropTypes.number,
  id: PropTypes.number,
  photos: PropTypes.objectOf(PropTypes.any),
  description: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  complexHistory: PropTypes.objectOf(PropTypes.any),
  point: PropTypes.arrayOf(PropTypes.any),
};
