import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import UserLogin from '../../components/User';
import { userLogin, setCurrentPageType } from '../../actions';
import {
  user as userFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
} from '../../lib/PropTypeValues';

const styles = {
  root: {
    padding: '30px 20px',
    color: '#6d6d6d',
    fontSize: 20,
    textAlign: 'center',
  },
};

class _LoginPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('empty'));
  }

  componentDidUpdate() {
    const { user, history, selectedRegion } = this.props;
    if (user.token) {
      history.push(`/${selectedRegion.domain}/user/account`);
    }
  }

  handleFormSubmit = (data) => {
    const { dispatch } = this.props;
    dispatch(userLogin(data));
  };

  render() {
    const { user } = this.props;
    return (
      <div style={styles.root}>
        <UserLogin onSubmit={this.handleFormSubmit} loading={user.loading} error={user.error} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
    user: state.user,
  };
}

_LoginPage.propTypes = {
  user: userFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_LoginPage);
