export const styles = {
  root: {
    background: '#fff',
    color: '#212121',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.0756058)',
    marginBottom: '10px',
  },
};

export const PaymentPaperStyle = {
  root: {
    background: '#fff',
    color: '#212121',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.0756058)',
    margin: '0 16px 10px 16px',
    borderRadius: '4px',
  },
};

export const ModalPaperStyle = {
  root: {
    background: '#fff',
    color: '#212121',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.0756058)',
    borderRadius: '4px',
  },
};
