import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './assets/Panel.css';

export default class Panel extends React.Component {
  constructor(...props) {
    super(...props);
    const { opened } = this.props;
    this.state = { opened };
  }

  toggle = () => {
    const { collapsible } = this.props;
    const { opened } = this.state;
    if (!collapsible) return;

    this.setState({
      opened: !opened,
    });
  };

  renderContent() {
    const { containerStyle, containerClassName, children } = this.props;
    const { opened } = this.state;
    return (
      opened && (
        <div className={classNames(styles.content, containerClassName)} style={containerStyle}>
          {children}
        </div>
      )
    );
  }

  renderTitle() {
    const { titleStyle, titleClassName, title } = this.props;
    return (
      <div
        className={classNames(styles.title, titleClassName)}
        style={titleStyle}
        onClick={this.toggle}
        onKeyDown={this.toggle}
        role="button"
      >
        {title}
      </div>
    );
  }

  render() {
    const { className, style, id, titlePosition } = this.props;
    return (
      <div className={classNames(styles.root, className)} styles={style} id={id}>
        {titlePosition === 'top' && this.renderTitle()}
        {this.renderContent()}
        {titlePosition === 'bottom' && this.renderTitle()}
      </div>
    );
  }
}

Panel.defaultProps = {
  opened: true,
  titlePosition: 'top',
  collapsible: false,
  style: {},
  titleStyle: {},
  containerStyle: {},
  className: '',
  titleClassName: '',
  containerClassName: '',
  children: null,
  title: '',
  id: Math.random(),
};

Panel.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  titleStyle: PropTypes.objectOf(PropTypes.any),
  containerStyle: PropTypes.objectOf(PropTypes.any),

  className: PropTypes.string,
  titleClassName: PropTypes.string,
  containerClassName: PropTypes.string,

  opened: PropTypes.bool,
  collapsible: PropTypes.bool,
  titlePosition: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node,
  title: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
