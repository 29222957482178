import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import Loader from 'react-loader';
import styles from './assets/SavedSearchPage.css';
import {
  loadSavedSearchList,
  addNotification,
  updateSalesFilter,
  updateRentsFilter,
  invalidateProperties,
  setRentsRubric,
  setSalesRubric,
  setRentsInterval,
  removeAndUnsubscribeSavedSearch,
  removeSavedSearch,
  setSection,
  setLocalityById,
  setCurrentPageType,
} from '../actions';
import { SearchDivider } from '../components/Divider/Divider';
import { getOldFormat, landsIds, getRubricIdByParams, getSectionIdByDealType } from '../utils';
import { FontIcon } from '../components/ui/material';
import {
  user as userFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
} from '../lib/PropTypeValues';

export class _SavedSearchPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('savedSearch'));
    this.handleLoadList();
  }

  componentDidUpdate() {
    const { user, dispatch } = this.props;
    if (!user.token) {
      dispatch(addNotification('Пожалуйста авторизуйтесь'));
      this.redirectToLogin();
    }
  }

  handleLoadList = () => {
    const { user, dispatch } = this.props;
    if (!!user.token) {
      dispatch(loadSavedSearchList());
    } else {
      dispatch(addNotification('Пожалуйста авторизуйтесь'));
      this.redirectToLogin();
    }
  };

  redirectToLogin = () => {
    const { history, selectedRegion } = this.props;
    history.push(`/${selectedRegion.domain}/user/login`);
  };

  getRealtyType = (type) => {
    switch (type) {
      case 'apartments':
        return 'квартир и комнат';
      case 'lands':
        return 'домов и участков';
      case 'commercials':
        return 'коммерческой';
      case 'garages':
        return 'гаражей';
      default:
        return '';
    }
  };

  getDealType = (type) => {
    switch (type) {
      case 'sale':
        return 'Продажа';
      case 'rent':
        return 'Аренда';
      case 'rentDaily':
        return 'Посуточная аренда';
      default:
        return '';
    }
  };

  handleOpenSavedSearch = (item) => {
    const { selectedRegion, dispatch, history, selectedLocality } = this.props;

    let currnetFilter = { ...item.filters };
    let currentRubricId = getRubricIdByParams(item.dealType, item.realtyType);
    if (
      item.dealType.includes('rent') &&
      item.filters.ref_type &&
      landsIds.some((r) => item.filters.ref_type[0] == r)
    ) {
      currentRubricId = 5;
    }

    if (
      !!currnetFilter.locality_fias_id &&
      Number(selectedLocality.id) !== currnetFilter.locality_fias_id[0]
    ) {
      dispatch(setLocalityById(currnetFilter.locality_fias_id[0]));
    }

    if (item.formatVersionId === 382 && !item.dealType.includes('rent')) {
      currnetFilter = { ...currnetFilter, is_normal_price: 1 };
    }

    if (item.dealType === 'rentDaily') {
      dispatch(setRentsInterval(2));
    }
    if (item.dealType === 'rent') {
      dispatch(setRentsInterval(1));
    }
    dispatch(setSection(getSectionIdByDealType(item.dealType)));

    if (item.dealType.includes('sale')) {
      dispatch(updateSalesFilter(currnetFilter));
      dispatch(setSalesRubric(currentRubricId));
    } else {
      dispatch(updateRentsFilter(currnetFilter));
      dispatch(setRentsRubric(currentRubricId));
    }
    dispatch(invalidateProperties());
    history.push({
      pathname: `/${selectedRegion.domain}/${getOldFormat(item.dealType, item.realtyType)}`,
      search: qs.stringify(currnetFilter),
    });
  };

  deleteSavedSearch = (e, savedSearchItem) => {
    const { dispatch } = this.props;
    e.stopPropagation();
    if (savedSearchItem.isSubscribed) {
      dispatch(removeAndUnsubscribeSavedSearch(savedSearchItem.id));
    } else {
      dispatch(removeSavedSearch(savedSearchItem.id));
    }
  };

  render() {
    const { savedSearch } = this.props;
    const items = savedSearch.items ? savedSearch.items.sort((a, b) => a.id - b.id).reverse() : [];
    return (
      <Loader loaded={savedSearch.loaded}>
        <div className={styles.title}>Сохраненные поиски</div>
        {items.map((item) => (
          <div key={item.id}>
            <div
              onClick={() => this.handleOpenSavedSearch(item)}
              className={styles.savedSearchItem}
              role="button"
            >
              <div className={styles.savedSearchField}>
                <span className={styles.savedSearchLink}>{item.name}</span>
                <div className={styles.deleteButton}>
                  <FontIcon
                    className="material-icons"
                    onClick={(e) => this.deleteSavedSearch(e, item)}
                    style={{ color: 'black', fontSize: '19px' }}
                  >
                    close
                  </FontIcon>
                </div>
              </div>
              <div className={styles.savedSearchField}>
                <span className={styles.savedSearchType}>{`${this.getDealType(
                  item.dealType
                )} ${this.getRealtyType(item.realtyType)}`}</span>
              </div>
            </div>
            <SearchDivider />
          </div>
        ))}
        {!items.length && (
          <>
            <div className={styles.emptyList}>Сохраненных поисков нет.</div>
            <div className={styles.emptyList}>
              Сохраненные поиски позволяют в любой момент и на любых Ваших устройствах вернуться к
              текущим результатам поиска.
            </div>
          </>
        )}
      </Loader>
    );
  }
}

_SavedSearchPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: userFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  savedSearch: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.any),
    loaded: PropTypes.bool,
  }).isRequired,
  selectedLocality: selectedLocalityFromPropTypes.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    savedSearch: state.savedSearch,
    selectedRegion: state.selectedRegion,
    rubrics: state.rubrics,
    selectedLocality: state.selectedLocality,
  };
}

export const SavedSearchPage = connect(mapStateToProps)(_SavedSearchPage);
