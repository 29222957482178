import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import styles from './Header.css';
import Logo from '../Logo';
import LogoCian from '../LogoCian';
import UnionIcon from '../UnionIcon';
import ButtonSort from '../ButtonSort';
import ButtonChangeView from '../ButtonChangeView';
import { AppBar, Toolbar, IconButton } from '../ui/material';
import {
  removeNotification,
  setCurrentViewType,
  setSorting,
  setScrollPosition,
  showModal,
} from '../../actions';
import MenuIcon from './hamburger-menu.svg';
import { SavedSearchIcon } from '../ui/Icons';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';

class Header extends Component {
  constructor(...props) {
    super(...props);

    this.handleLeftIconButtonTouchTap = this.handleLeftIconButtonTouchTap.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { currentPageType, iconElementLeft } = this.props;
    return (
      currentPageType !== nextProps.currentPageType || iconElementLeft !== nextProps.iconElementLeft
    );
  }

  handleChangeType = (type) => {
    const { dispatch } = this.props;
    dispatch(setCurrentViewType(type));
    dispatch(setScrollPosition(0));
  };

  handleChangeSort = (value) => {
    const { dispatch, location, history } = this.props;
    const query = qs.parse(location.search);

    dispatch(removeNotification());

    dispatch(setScrollPosition(0));
    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...query, sort: value, page: 1 }),
    });
    dispatch(setSorting(value));
  };

  handleLeftIconButtonTouchTap() {
    const { onMenuButtonTap } = this.props;
    onMenuButtonTap();
  }

  handleSavedSearch = () => {
    const { isAutherized } = this.props;

    if (!isAutherized) {
      this.props.dispatch(
        showModal('UNAUTHORIZED_MODAL', {
          title: 'Сохранение поиска',
          history: this.props.history,
        })
      );
    }
    if (isAutherized) {
      this.props.dispatch(
        showModal('SAVED_SEARCH_MODAL', {
          title: 'Сохранение поиска',
          history: this.props.history,
        })
      );
    }
  };

  handleOpenUnionCianModal = () => {
    const { cianModalContext } = this.props;
    this.props.dispatch(
      showModal('UNION_CIAN_MODAL', {
        title: '',
        history: this.props.history,
        timer: 10,
        header: cianModalContext.title,
        description: cianModalContext.description,
      })
    );
  };

  renderTitle() {
    const { selectedRegion } = this.props;
    return (
      <Fragment>
        <Link to={`/${selectedRegion.domain}/`} className={styles.titleLogo}>
          <Logo style={{ margin: '9px 6px 6px 8px', height: '20px' }} />
        </Link>
        <div onClick={() => this.handleOpenUnionCianModal()}>
          <UnionIcon style={{ margin: '8px 0px', height: '9px' }} />
          <LogoCian style={{ margin: '0px 5px 4px', height: '26px' }} />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      style,
      sortingSection,
      selectedViewType,
      selectedSorting,
      sortParams,
      iconElementLeft,
      iconElementRight,
      visibleHeader,
    } = this.props;

    const defaultRightElement = (
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        <ButtonChangeView currentType={selectedViewType} onChangeType={this.handleChangeType} />
        <ButtonSort
          onChangeSort={this.handleChangeSort}
          currentSort={selectedSorting}
          sortParams={sortParams[sortingSection]}
        />
        <IconButton onClick={this.handleSavedSearch}>
          <SavedSearchIcon />
        </IconButton>
      </div>
    );

    return (
      <AppBar className={styles.root} style={{ ...style, minHeight: 40, display: `${!visibleHeader ? 'none' : 'flex' }` }} position="fixed">
        <Toolbar>
          {!!iconElementLeft && iconElementLeft}
          {!iconElementLeft && (
            <IconButton
              edge="start"
              onClick={this.handleLeftIconButtonTouchTap}
              className={styles.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <img alt="menu-icon" src={MenuIcon} />
            </IconButton>
          )}
          {this.renderTitle()}
          <div className={styles.rightElement}>
            {iconElementRight || defaultRightElement}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
    selectedViewType: state.selectedViewType,
    sortParams: state.sortParams,
    currentPageType: state.currentPageType,
    selectedSorting: state.selectedSorting,
    sortingSection: state.sortingSection,
    isAutherized: !!state.user.token,
    visibleHeader: state.visibleHeader,
    cianModalContext: state.appConfig.integrationSettings && state.appConfig.integrationSettings.common_popup,
  };
}

Header.defaultProps = {
  iconElementLeft: null,
  iconElementRight: null,
  style: {},
};

Header.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  sortingSection: PropTypes.string.isRequired,
  selectedViewType: PropTypes.string.isRequired,
  selectedSorting: PropTypes.string.isRequired,
  sortParams: PropTypes.objectOf(PropTypes.any).isRequired,
  iconElementLeft: PropTypes.node,
  iconElementRight: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  onMenuButtonTap: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  currentPageType: PropTypes.string.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  isAutherized: PropTypes.bool.isRequired,
  cianModalContext: PropTypes.objectOf(PropTypes.any),
};

export default compose(withRouter, connect(mapStateToProps, null))(Header);
