import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { UserRestore } from '../../components/User';
import {
  selectedRegion as selectedRegionFromPropTypes,
  user as userFromPropTypes,
} from '../../lib/PropTypeValues';

class _RestorePage extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { user, history, selectedRegion } = this.props;
    if (user.token) {
      history.push(`/${selectedRegion.domain}/user/account`);
    }
  }

  render() {
    const { history } = this.props;
    return (
      <div style={{ height: window.innerHeight - 48, overflow: 'auto' }}>
        <UserRestore history={history} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
    user: state.user,
  };
}

_RestorePage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: userFromPropTypes.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_RestorePage);
