import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { loadProfile, syncFavorites, loadSavedSearchList } from '../../actions';
import WrappedRoute from '../../WrappedRoute';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';
import TariffPage from './TariffPage';
import RechargeBalancePage from './RechargeBalancePage';
import ProfilePage from './ProfilePage';
import PaymentsPage from './PaymentsPage';
import PaymentsFilterPage from './PaymentsFilterPage';
import LogoutPage from './LogoutPage';
import ListingPage from './ListingPage';
import InvoicesPage from './InvoicesPage';
import InvoiceCreatePage from './InvoiceCreatePage';
import BalancePage from './BalancePage';
import AccountPage from './AccountPage';

class _UserContainer extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { user, dispatch } = this.props;
    if (user.token) {
      dispatch(loadProfile(user.token)).then(() => {
        dispatch(syncFavorites());
        dispatch(loadSavedSearchList());
      });
    } else {
      this.redirectToLogin();
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (!user.token) {
      this.redirectToLogin();
    }
  }

  redirectToLogin() {
    const { history, selectedRegion } = this.props;
    history.push(`/${selectedRegion.domain}/user/login`);
  }

  render() {
    const { user, match } = this.props;
    return (
      <Loader loaded={user.loaded}>
        <Switch>
          <WrappedRoute path={`${match.path}/logout`} component={LogoutPage} />

          <WrappedRoute
            path={`${match.path}/recharge`}
            component={RechargeBalancePage}
            prev="user/account"
          />

          <WrappedRoute path={`${match.path}/account`} component={AccountPage} />
          <WrappedRoute
            path={`${match.path}/profile`}
            component={ProfilePage}
            prev={`${match.path}/user/account`}
          />
          <WrappedRoute
            path={`${match.path}/balance`}
            component={BalancePage}
            prev={`${match.path}/user/account`}
          />

          <WrappedRoute
            path={`${match.path}/payments`}
            component={PaymentsPage}
            prev={`${match.path}/user/balance`}
          />
          <WrappedRoute path={`${match.path}/payments/filter`} component={PaymentsFilterPage} />
          <WrappedRoute
            path={`${match.path}/tariff`}
            component={TariffPage}
            prev={`${match.path}/user/balance`}
          />
          <WrappedRoute
            path={`${match.path}/invoices`}
            component={InvoicesPage}
            prev={`${match.path}/user/account`}
          />
          <WrappedRoute
            path={`${match.path}/invoice`}
            component={InvoiceCreatePage}
            prev={`${match.path}/user/invoices`}
          />

          <WrappedRoute
            path={`${match.path}/sale/apartments`}
            component={ListingPage}
            dealType="sale"
            realtyType="apartments"
          />
          <WrappedRoute
            path={`${match.path}/sale/commercials`}
            component={ListingPage}
            dealType="sale"
            realtyType="commercials"
          />
          <WrappedRoute
            path={`${match.path}/sale/lands`}
            component={ListingPage}
            dealType="sale"
            realtyType="lands"
          />
          <WrappedRoute
            path={`${match.path}/sale/garages`}
            component={ListingPage}
            dealType="sale"
            realtyType="garages"
          />
          <WrappedRoute
            path={`${match.path}/rent/apartments`}
            component={ListingPage}
            dealType="rent"
            realtyType="apartments"
          />
          <WrappedRoute
            path={`${match.path}/rent/commercials`}
            component={ListingPage}
            dealType="rent"
            realtyType="commercials"
          />
        </Switch>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedRegion: state.selectedRegion,
  };
}

_UserContainer.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.string,
    loaded: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(_UserContainer);
