export const DialogStyles = {
  paper: {
    width: '100%',
    bottom: 0,
    position: 'fixed',
    borderRadius: '4px 4px 0 0',
    maxWidth: '1000px',
    margin: '0 auto',
  },
};

export const DialogContentStyles = {
  root: {
    padding: 0,
  },
};

export const AutocompleteDialogContentStyles = {
  root: {
    padding: 0,
    minHeight: '40vh',
    maxHeight: '40vh',
  },
};
