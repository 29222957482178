import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { StyledTextField } from '../../TextField/TextField';
import { StyledSwitch } from '../../Switch/Switch';
import styles from './styles.css';
import {
  FormControlLabel,
} from '../../ui/material';
import { validate } from './validate';


let SavedSearchForm = props => {
  const { handleSubmit, isSubscribe, onSubmit } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className={styles.textFieldWrapper}>
          <Field
            label="Название поиска"
            name="name"
            multiline
            autoComplete="false"
            component={StyledTextField}
          />
        </div>
        <FormControlLabel
          control={
            <Field name="subscribe" component={StyledSwitch}/>
          }
          label="Присылать новые объявления на эл. почту"
          labelPlacement="start"
        />
        {isSubscribe &&
          <div className={styles.textFieldWrapper}>
            <Field
              label="Эл. почта для уведомлений"
              name="email"
              autoComplete="false"
              component={StyledTextField}
            />
          </div>
        }
      </div>
    </form>
  )
}

SavedSearchForm = reduxForm({
  form: 'SavedSearchForm',
  validate,
})(SavedSearchForm);

const selector = formValueSelector('SavedSearchForm');
SavedSearchForm = connect(
  state => {
    const isSubscribe = selector(state, 'subscribe');
    return {
      isSubscribe,
      initialValues: {
        name: 'Мой поиск',
        email: !!state.user.profile && state.user.profile.email || '',
        subscribe: true,
      },
    }
  }
)(SavedSearchForm);

export default SavedSearchForm;