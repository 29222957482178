/* eslint-disable no-underscore-dangle */
import { find } from 'lodash';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import rubrics from './rubrics';
import intervals from './intervals';
import sortParams from './sortParams';
import counters from './yaMetrikaCounters';
import microdistrictRating from '../components/MicrodistrictRating/reducers';
import similarObjects from '../components/SimilarObjects/reducers';
import { getRandomElement } from '../utils';

import {
  UPDATE_SALES_FILTER,
  UPDATE_RENTS_FILTER,
  UPDATE_TRANSACTIONS_FILTER,
  SET_REGION,
  SET_SALES_RUBRIC,
  SET_RENTS_RUBRIC,
  SET_LOCALITY,
  SET_SECTION,
  SET_RENTS_INTERVAL,
  LOAD_LISTS,
  LOAD_DICTIONARIES,
  LOAD_DICTIONARIES_V2,
  LOAD_REGIONS,
  LOAD_DISTRICTS,
  SET_MICRODISTRICTS,
  LOAD_PROPERTIES,
  LOAD_PROPERTIES_SUCCESS,
  INVALIDATE_PROPERTIES,
  CLEAR_TOTAL_COUNT,
  CLEAR_TOTAL_COUNT_SUCCESS,
  LOAD_COMPLEX,
  LOAD_COMPLEX_SUCCESS,
  LOAD_COMPLEX_HISTORY_SUCCESS,
  LOAD_COMPLEX_APARTMENTS,
  LOAD_COMPLEX_APARTMENTS_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGOUT,
  REGISTER_SMS,
  REGISTER_SMS_SUCCESS,
  REGISTER_SMS_FAILURE,
  RESTORE_SMS,
  RESTORE_SMS_SUCCESS,
  RESTORE_SMS_FAILURE,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAILURE,
  LOAD_USER_OBJECTS,
  LOAD_USER_OBJECTS_SUCCESS,
  // LOAD_MORE_USER_OBJECTS,
  LOAD_MORE_USER_OBJECTS_SUCCESS,
  LOAD_USER_TARIFF,
  LOAD_USER_TARIFF_SUCCESS,
  LOAD_USER_TARIFF_FAILURE,
  USER_PROFILE,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_TRANSACTIONS,
  USER_TRANSACTIONS_SUCCESS,
  // USER_TRANSACTIONS_FAILURE,
  USER_MORE_TRANSACTIONS_SUCCESS,
  LOAD_PROPERTY,
  LOAD_PROPERTY_SUCCESS,
  LOAD_PROPERTY_FAILURE,
  DETECT_REGION,
  SET_CURRENT_PAGE_TYPE,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  LOAD_FAVORITES,
  LOAD_FAVORITES_ITEM,
  CLEAR_FAVORITES,
  BID_PROPERTY,
  SET_CURRENT_VIEW_TYPE,
  SET_SCROLL_POSITION,
  SET_SORTING,
  SET_SORTING_SECTION,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  LOAD_MAP_PROPERTIES,
  LOAD_MAP_PROPERTIES_SUCCESS,
  LOAD_MAP_PROPERTIES_FAILURE,
  INVALIDATE_MAP_PROPERTIES,
  LOAD_PRICE_HISTORY,
  LOAD_PRICE_HISTORY_SUCCESS,
  // LOAD_PRICE_HISTORY_FAILURE,
  // INVALIDATE_PRICE_HISTORY,
  USER_INVOICES_LIST_LOAD,
  USER_INVOICES_LIST_SUCCESS,
  // USER_INVOICES_LIST_FAILURE,
  USER_MORE_INVOICES_LIST_SUCCESS,
  LOAD_MORTGAGE_LINK,
  SHOW_MODAL,
  HIDE_MODAL,
  LOAD_SAVED_SEARCHES_LIST,
  LOAD_SAVED_SEARCHES_LIST_SUCCESS,
  LOAD_SAVED_SEARCHES_LIST_FAILURE,
  UPDATE_FILTER_TYPE,
  LOAD_LOCALITY_BY_ID_SUCCESS,
  DISABLE_HEADER,
  LOAD_CONFIG,
  LOAD_CONFIG_SUCCESS,
  LOAD_CONFIG_FAILURE,
} from '../actions';

const initialRentsRubric = {
  id: 4,
  name: 'Квартиры, комнаты',
  route: 'rents',
  rentType: 'rentApartments',
  type: 'rent',
  dealType: 'rent',
  realtyType: 'apartments',
};

const initialRentsInterval = {
  id: 1,
  name: 'Длительно',
  type: 'longTerm',
  dealType: 'rent',
};

const initialCurrentFilterType = {
  dealType: 'sale',
  realtyType: 'apartments',
};

export function user(state = { error: false }, action) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        error: false,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        error: false,
        loading: false,
        token: action.token,
      };
    case USER_LOGIN_FAILURE:
      return {
        error: action.error,
        loading: false,
      };

    case USER_LOGOUT:
      return {
        error: false,
        token: null,
      };

    case USER_PROFILE:
      return {
        ...state,
        loaded: false,
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        loaded: true,
        profile: action.profile,
      };
    case USER_PROFILE_FAILURE:
      return {
        loaded: false,
        token: null,
      };
    default:
      return state;
  }
}

export function userObjects(state = { loading: true, loaded: false }, action) {
  const list = { ...state.list };
  switch (action.type) {
    case LOAD_USER_OBJECTS:
      return {
        loading: true,
        loaded: false,
      };
    case LOAD_USER_OBJECTS_SUCCESS:
      return {
        loading: false,
        loaded: true,
        list: action.list,
      };
    case LOAD_MORE_USER_OBJECTS_SUCCESS:
      list[action.section] = {
        ...action.data,
        items: [...list[action.section].items, ...action.data.items],
      };
      return {
        ...state,
        list,
      };
    default:
      return state;
  }
}

export function registerSMS(state = { error: null, loading: false }, action) {
  switch (action.type) {
    case REGISTER_SMS:
      return {
        error: null,
        loading: true,
      };
    case REGISTER_SMS_SUCCESS:
      return {
        loading: false,
        loaded: true,
        error: null,
      };
    case REGISTER_SMS_FAILURE:
      return {
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export function restoreSMS(state = { error: null, loading: false }, action) {
  switch (action.type) {
    case RESTORE_SMS:
      return {
        error: null,
        loading: true,
      };
    case RESTORE_SMS_SUCCESS:
      return {
        loading: false,
        loaded: true,
        userId: action.userId,
        error: null,
      };
    case RESTORE_SMS_FAILURE:
      return {
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export function restorePassword(state = { errors: {}, loading: false }, action) {
  switch (action.type) {
    case RESTORE_PASSWORD:
      return {
        errors: {},
        loading: true,
      };
    case RESTORE_PASSWORD_SUCCESS:
      return {
        loading: false,
        loaded: true,
        status: true,
        errors: {},
      };
    case RESTORE_PASSWORD_FAILURE:
      return {
        loading: false,
        loaded: true,
        errors: action.errors,
      };
    default:
      return state;
  }
}

export function userRegister(state = { errors: {}, loading: false }, action) {
  switch (action.type) {
    case USER_REGISTER:
      return {
        errors: {},
        loading: true,
      };
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        loaded: true,
        errors: {},
      };
    case USER_REGISTER_FAILURE:
      return {
        loading: false,
        loaded: true,
        errors: action.errors,
      };
    default:
      return state;
  }
}

export function userTariff(state = { loading: false, loaded: false }, action) {
  switch (action.type) {
    case LOAD_USER_TARIFF:
      return {
        loading: true,
      };
    case LOAD_USER_TARIFF_SUCCESS:
      return {
        loading: false,
        loaded: true,
        tariff: action.tariff,
      };
    case LOAD_USER_TARIFF_FAILURE:
      return {
        loading: false,
        loaded: true,
        tariff: action.tariff,
      };
    default:
      return state;
  }
}

export function appConfig(state = { redirectCianUri: 'https://cian.ru/', integrationSettings: null }, action) {
  switch (action.type) {
    case LOAD_CONFIG:
      return {
        ...state,
        redirectCianUri: 'https://cian.ru/',
      };
    case LOAD_CONFIG_SUCCESS:
      return {
        ...state,
        redirectCianUri: action.payload.redirect_uri,
        integrationSettings: action.payload.integration_settings,
      };
    case LOAD_CONFIG_FAILURE:
      return {
        ...state,
        redirectCianUri: 'https://cian.ru/',
        integrationSettings: null,
      };
    default:
      return state;
  }
}

export function userTransactions(state = { loading: false }, action) {
  switch (action.type) {
    case USER_TRANSACTIONS:
      return {
        loading: true,
      };
    case USER_TRANSACTIONS_SUCCESS:
      return {
        loading: false,
        loaded: true,
        items: action.items,
        meta: action.meta,
      };
    case USER_MORE_TRANSACTIONS_SUCCESS:
      return {
        loading: false,
        loaded: true,
        items: [...state.items, ...action.items],
        meta: action.meta,
      };
    default:
      return state;
  }
}

export function scrollPosition(state = 0, action) {
  switch (action.type) {
    case SET_SCROLL_POSITION:
      return action.position;
    default:
      return state;
  }
}

export function selectedSorting(state = '', action) {
  switch (action.type) {
    case SET_SORTING:
      return action.sorting;
    default:
      return state;
  }
}

export function sortingSection(state = '', action) {
  switch (action.type) {
    case SET_SORTING_SECTION:
      return action.section;
    default:
      return state;
  }
}

export function bids(state = [], action) {
  switch (action.type) {
    case BID_PROPERTY:
      return [action.bid, ...state];
    default:
      return state;
  }
}

function mergeFavorites(prev, added, status) {
  let wasInFavorites = false;
  const merged = [];

  prev.forEach((fav) => {
    if (Number(fav.id) === Number(added.id) && fav.section === added.section) {
      merged.push({
        ...fav,
        timestamp: Math.floor(Date.now() / 1000),
        status,
      });
      wasInFavorites = true;
    } else {
      merged.push(fav);
    }
  });

  if (!wasInFavorites) {
    merged.push({
      id: added.id,
      section: added.section,
      timestamp: Math.floor(Date.now() / 1000),
      status,
    });
  }

  return merged;
}

export function favorites(state = [], action) {
  switch (action.type) {
    case ADD_TO_FAVORITES:
      return mergeFavorites(state, action.payload, 'added');
    case REMOVE_FROM_FAVORITES:
      return mergeFavorites(state, action.payload, 'deleted');
    case LOAD_FAVORITES:
      return action.payload;
    default:
      return state;
  }
}

const initialStateFavProps = {
  sale: {
    items: [],
    page: 1,
  },
  rent: {
    items: [],
    page: 1,
  },
};

export function favoriteProperties(state = initialStateFavProps, action) {
  switch (action.type) {
    case LOAD_FAVORITES_ITEM:
      return {
        ...state,
        [action.payload.section]: {
          items: [...state[action.payload.section].items, ...action.payload.items],
          page: state[action.payload.section].page + 1,
        },
        loaded: true,
      };
    case CLEAR_FAVORITES:
      return { ...initialStateFavProps };
    default:
      return state;
  }
}

export function selectedRegion(state = {}, action) {
  switch (action.type) {
    case SET_REGION:
      return { ...state, ...action.region };
    default:
      return state;
  }
}

export function detectedRegion(state = {}, action) {
  switch (action.type) {
    case DETECT_REGION:
      return action.region;
    default:
      return state;
  }
}

export function selectedSalesRubric(state = rubrics()[0], action) {
  switch (action.type) {
    case SET_SALES_RUBRIC:
      return find(rubrics(), (rubric) => Number(action.rubricId) === Number(rubric.id));
    default:
      return state;
  }
}

export function selectedRentsRubric(state = initialRentsRubric, action) {
  switch (action.type) {
    case SET_RENTS_RUBRIC:
      return find(rubrics(), (rubric) => Number(action.rubricId) === Number(rubric.id));
    default:
      return state;
  }
}

export function selectedRentsInterval(state = initialRentsInterval, action) {
  switch (action.type) {
    case SET_RENTS_INTERVAL:
      return find(intervals(), (interval) => Number(action.intervalId) === Number(interval.id));
    default:
      return state;
  }
}

export function selectedLocality(state = {}, action) {
  switch (action.type) {
    case SET_LOCALITY:
      return action.locality;
    case LOAD_LOCALITY_BY_ID_SUCCESS:
      return action.locality;
    default:
      return state;
  }
}

export function selectedSection(state = 0, action) {
  switch (action.type) {
    case SET_SECTION:
      return action.sectionId;
    default:
      return state;
  }
}

export function regions(state = { loaded: false, items: [] }, action) {
  switch (action.type) {
    case LOAD_REGIONS:
      return {
        loaded: true,
        items: action.regions,
      };
    default:
      return state;
  }
}

export function districts(state = {}, action) {
  switch (action.type) {
    case LOAD_DISTRICTS:
      return action.districts;
    default:
      return state;
  }
}

export function properties(state = { loaded: false, invalidated: true, reload: false }, action) {
  switch (action.type) {
    case LOAD_PROPERTIES:
      return {
        loaded: false,
        items: [],
        count: state.count || '',
      };
    case LOAD_PROPERTIES_SUCCESS:
      return {
        loaded: true,
        invalidated: false,
        noMore: action.noMore || false,
        items: action.objects,
        count: action.count,
      };
    case INVALIDATE_PROPERTIES:
      return {
        ...state,
        invalidated: true,
      };
    case CLEAR_TOTAL_COUNT:
      return {
        ...state,
        count: '',
        reload: true,
      };
    case CLEAR_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        reload: false,
      };
    default:
      return state;
  }
}

export function object(state = { loaded: false, error: null }, action) {
  switch (action.type) {
    case LOAD_PROPERTY:
      return {
        loaded: false,
      };
    case LOAD_PROPERTY_SUCCESS:
      return {
        ...state,
        loaded: true,
        data: action.object,
      };
    case LOAD_PROPERTY_FAILURE:
      return {
        ...state,
        loaded: false,
        error: action.error,
      };
    case LOAD_MORTGAGE_LINK:
      return {
        ...state,
        mortgage: getRandomElement(action.object.items),
      };
    default:
      return state;
  }
}

export function complex(state = { loaded: false, historyLoaded: false }, action) {
  switch (action.type) {
    case LOAD_COMPLEX:
      return {
        loaded: false,
        historyLoaded: false,
      };
    case LOAD_COMPLEX_SUCCESS:
      return {
        ...state,
        loaded: true,
        data: action.complex,
      };
    case LOAD_COMPLEX_HISTORY_SUCCESS:
      return {
        ...state,
        historyLoaded: true,
        history: action.history,
      };
    default:
      return state;
  }
}

export function complexApartments(state = { loaded: false }, action) {
  switch (action.type) {
    case LOAD_COMPLEX_APARTMENTS:
      return {
        loaded: false,
      };
    case LOAD_COMPLEX_APARTMENTS_SUCCESS:
      return {
        loaded: true,
        data: action.apartments,
      };
    default:
      return state;
  }
}

export function salesFilter(state = {}, action) {
  switch (action.type) {
    case UPDATE_SALES_FILTER:
      return action.filter;
    default:
      return state;
  }
}

export function rentsFilter(state = {}, action) {
  switch (action.type) {
    case UPDATE_RENTS_FILTER:
      return action.filter;
    default:
      return state;
  }
}

export function transactionsFilter(state = {}, action) {
  switch (action.type) {
    case UPDATE_TRANSACTIONS_FILTER:
      return action.filter;
    default:
      return state;
  }
}

export function lists(state = { loaded: false }, action) {
  switch (action.type) {
    case LOAD_LISTS:
      return { ...action.lists, loaded: true };
    default:
      return state;
  }
}

export function dictionaries(state = { loaded: false }, action) {
  switch (action.type) {
    case LOAD_DICTIONARIES:
      return { ...action.lists, loaded: true };
    default:
      return state;
  }
}

export function dictionariesV2(state = { loaded: false }, action) {
  switch (action.type) {
    case LOAD_DICTIONARIES_V2:
      const { lists } = action;
      return { ...lists, loaded: true };
    default:
      return state;
  }
}

export function currentPageType(state = 'search', action) {
  switch (action.type) {
    case SET_CURRENT_PAGE_TYPE:
      return action.currentPageType;
    default:
      return state;
  }
}

export function selectedViewType(state = 'photo', action) {
  switch (action.type) {
    case SET_CURRENT_VIEW_TYPE:
      return action.viewType;
    default:
      return state;
  }
}

export function notification(state = '', action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return action.message;
    case REMOVE_NOTIFICATION:
      return '';
    default:
      return state;
  }
}

export function microdistricts(state = [], action) {
  switch (action.type) {
    case SET_MICRODISTRICTS:
      return action.data;
    default:
      return state;
  }
}

export function mapProperties(state = { loading: false, loaded: false, apartments: null }, action) {
  switch (action.type) {
    case LOAD_MAP_PROPERTIES:
      return {
        loaded: false,
        loading: true,
        apartments: null,
      };
    case LOAD_MAP_PROPERTIES_SUCCESS:
      return {
        loaded: true,
        loading: false,
        apartments: action.apartments,
      };
    case LOAD_MAP_PROPERTIES_FAILURE:
    case INVALIDATE_MAP_PROPERTIES:
    case SET_SALES_RUBRIC:
    case SET_RENTS_RUBRIC:
      return {
        loaded: false,
        loading: false,
        apartments: null,
      };
    default:
      return state;
  }
}

export function priceHistory(state = { loaded: false }, action) {
  switch (action.type) {
    case LOAD_PRICE_HISTORY:
      return {
        loaded: false,
      };
    case LOAD_PRICE_HISTORY_SUCCESS:
      return {
        loaded: true,
        data: action.property,
      };
    default:
      return state;
  }
}

export function userInvoicesList(state = { loading: false }, action) {
  switch (action.type) {
    case USER_INVOICES_LIST_LOAD:
      return {
        loading: true,
      };
    case USER_INVOICES_LIST_SUCCESS:
      return {
        loading: false,
        loaded: true,
        items: action.items,
        meta: action.meta,
      };
    case USER_MORE_INVOICES_LIST_SUCCESS:
      return {
        loading: false,
        loaded: true,
        items: [...state.items, ...action.items],
        meta: action.meta,
      };
    default:
      return state;
  }
}

export function modal(state = { modalType: null, modalProps: {} }, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case HIDE_MODAL:
      return { modalType: null, modalProps: {} };
    default:
      return state;
  }
}

export function savedSearch(state = { items: [], loaded: false }, action) {
  switch (action.type) {
    case LOAD_SAVED_SEARCHES_LIST:
      return {
        items: [],
        totalCount: '',
        loaded: false,
      };
    case LOAD_SAVED_SEARCHES_LIST_SUCCESS:
      return {
        items: action.payload && action.payload.items ? action.payload.items : [],
        totalCount: action.payload && action.payload._meta ? action.payload._meta.totalCount : '',
        loaded: true,
      };
    case LOAD_SAVED_SEARCHES_LIST_FAILURE:
      return {
        loaded: true,
      };
    default:
      return state;
  }
}

export function filterType(state = initialCurrentFilterType, action) {
  switch (action.type) {
    case UPDATE_FILTER_TYPE:
      return {
        dealType: action.dealType,
        realtyType: action.realtyType,
      };
    default:
      return state;
  }
}
export function visibleHeader(state = true, action) {
  switch (action.type) {
    case DISABLE_HEADER:
      return false;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  bids,
  favorites,
  favoriteProperties,
  selectedRegion,
  detectedRegion,
  selectedSalesRubric,
  selectedRentsRubric,
  selectedLocality,
  selectedSection,
  selectedViewType,
  selectedRentsInterval,
  rubrics,
  intervals,
  regions,
  districts,
  properties,
  object,
  complex,
  complexApartments,
  salesFilter,
  rentsFilter,
  transactionsFilter,
  user,
  userObjects,
  registerSMS,
  restoreSMS,
  restorePassword,
  userRegister,
  userTariff,
  userTransactions,
  userInvoicesList,
  lists,
  dictionaries,
  dictionariesV2,
  currentPageType,
  counters,
  sortParams,
  notification,
  scrollPosition,
  selectedSorting,
  sortingSection,
  microdistricts,
  microdistrictRating,
  similarObjects,
  mapProperties,
  priceHistory,
  modal,
  savedSearch,
  filterType,
  visibleHeader,
  form: formReducer,
  appConfig,
});

export default rootReducer;
