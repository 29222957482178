export const MenuDividerStyle = {
  root: {
    background: 'rgba(255, 255, 255, 0.120247)',
  },
};
export const SearchDividerStyle = {
  root: {
    background: '#D8D8D8',
  },
};
export const ContactsDividerStyle = {
  root: {
    background: '#D8D8D8',
    marginLeft: '0px',
  },
};
export const AccountDividerStyle = {
  root: {
    background: '#D8D8D8',
  },
};
