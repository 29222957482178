import React from 'react';
import { percentage } from '../../../../utils';

export class factoryTariffTableCell {
  constructor (cell, firstPrice) {
    this.cell = cell || null;
    this.firstPrice = firstPrice || null;
  }

  createCell(type) {
    switch (type) {
      case 'headerCount': return <th>кол-во</th>
      case 'headerPrice': return <th>₽ / день за шт.</th>
      case 'headerDiscount': return <th>скидка</th>

      case 'count': return (
        <td>{this.cell.maxCount !== null ? `${this.cell.minCount} — ${this.cell.maxCount}` : `от ${this.cell.minCount}`}</td>
      )
      case 'price': return <td>{(this.cell.costPerAd / 100).toFixed(2)} ₽</td>
      case 'discount': return (
        <td>
          {this.cell.costPerAd !== this.firstPrice ?
          `${(Math.round(percentage(this.cell.costPerAd, this.firstPrice) - 100))}%` : ''}
        </td>
      )
    }
  }
}