import PropTypes from 'prop-types';
import React from 'react';
import { throttle } from 'lodash';

import cn from './Sticky.css';

export default class Sticky extends React.Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isSticky: true,
    };

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { bottomOffset } = this.props;
    throttle(() => {
      if (
        document.documentElement.scrollHeight - window.pageYOffset - window.innerHeight <
        bottomOffset
      ) {
        this.setState({ isSticky: false });
      } else {
        this.setState({ isSticky: true });
      }
    }, 50)();
  };

  render() {
    const { adaptive, children } = this.props;
    const { isSticky } = this.state;
    const isAdaptive = adaptive ? cn.adaptive : null;

    return (
      <div
        style={{ display: isSticky ? 'block' : 'none' }}
        className={`${cn.sticky} ${isAdaptive}`}
      >
        {children}
      </div>
    );
  }
}

Sticky.defaultProps = {
  adaptive: null,
  bottomOffset: null,
};

Sticky.propTypes = {
  adaptive: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node.isRequired,
  bottomOffset: PropTypes.number,
};
