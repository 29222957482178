import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import ViewPage from './ViewPage';
import WrappedRoute from '../WrappedRoute';
import UserContainer from './User/UserContainer';
import RestorePage from './User/RestorePage';
import RegisterPage from './User/RegisterPage';
import ProfilePage from './User/ProfilePage';
import LoginPage from './User/LoginPage';
import SearchPage from './SearchPage';
import ResultsPage from './ResultsPage/ResultsPage';
import PriceHistoryPage from './PriceHistoryPage';
import MapPage from './MapPage';
import CreateAnnouncementPage from './CreateAnnouncementPage';
import ContactsPage from './ContactsPage';
import ComplexPage from './ComplexPage';
import ComplexHistory from './ComplexHistory';
import ComplexApartments from './ComplexApartments';
import TariffPage from './User/TariffPage';

const RegionContainer = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from="/:region/" to="/:region/search" />
      <WrappedRoute exact path="/:region/search" component={SearchPage} />

      <WrappedRoute path={`${match.path}user/login`} component={LoginPage} />
      <WrappedRoute path={`${match.path}user/register`} component={RegisterPage} />
      <WrappedRoute path={`${match.path}user/restorepass`} component={RestorePage} />
      <WrappedRoute path={`${match.path}user`} component={UserContainer} />

      <WrappedRoute path={`${match.path}create-announcement`} component={CreateAnnouncementPage} />

      <WrappedRoute path={`${match.path}profile`} component={ProfilePage} />

      <WrappedRoute
        exact
        path={`${match.path}rents`}
        route={{ path: 'rents' }}
        component={ResultsPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}rents/map`}
        route={{ path: 'rents' }}
        component={MapPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}rents/:id`}
        route={{ path: 'rents' }}
        component={ViewPage}
      />
      <WrappedRoute path={`${match.path}rents/:id/contacts`} component={ContactsPage} />

      <WrappedRoute
        exact
        path={`${match.path}sales`}
        route={{ path: 'sales' }}
        component={ResultsPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}sales/map`}
        route={{ path: 'sales' }}
        component={MapPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}sales/:id`}
        route={{ path: 'sales' }}
        component={ViewPage}
      />
      <WrappedRoute path={`${match.path}sales/:id/contacts`} component={ContactsPage} />
      <WrappedRoute
        path={`${match.path}sales/:id/priceHistory`}
        route={{ path: 'sales' }}
        component={PriceHistoryPage}
      />

      <WrappedRoute
        exact
        path={`${match.path}saleBusiness`}
        route={{ path: 'saleBusiness' }}
        component={ResultsPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}saleBusiness/map`}
        route={{ path: 'saleBusiness' }}
        component={MapPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}saleBusiness/:id`}
        route={{ path: 'saleBusiness' }}
        component={ViewPage}
      />
      <WrappedRoute path={`${match.path}saleBusiness/:id/contacts`} component={ContactsPage} />

      <WrappedRoute
        exact
        path={`${match.path}rentBusiness`}
        route={{ path: 'rentBusiness' }}
        component={ResultsPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}rentBusiness/map`}
        route={{ path: 'rentBusiness' }}
        component={MapPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}rentBusiness/:id`}
        route={{ path: 'rentBusiness' }}
        component={ViewPage}
      />
      <WrappedRoute path={`${match.path}rentBusiness/:id/contacts`} component={ContactsPage} />

      <WrappedRoute
        exact
        path={`${match.path}lands`}
        route={{ path: 'lands' }}
        component={ResultsPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}lands/map`}
        route={{ path: 'lands' }}
        component={MapPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}lands/:id`}
        route={{ path: 'lands' }}
        component={ViewPage}
      />
      <WrappedRoute exact path={`${match.path}lands/:id/contacts`} component={ContactsPage} />
      <WrappedRoute
        path={`${match.path}lands/:id/priceHistory`}
        route={{ path: 'lands' }}
        component={PriceHistoryPage}
      />

      <WrappedRoute
        exact
        path={`${match.path}garages`}
        route={{ path: 'garages' }}
        component={ResultsPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}garages/map`}
        route={{ path: 'garages' }}
        component={MapPage}
      />
      <WrappedRoute
        exact
        path={`${match.path}garages/:id`}
        route={{ path: 'garages' }}
        component={ViewPage}
      />
      <WrappedRoute path={`${match.path}garages/:id/contacts`} component={ContactsPage} />

      <WrappedRoute
        exact
        path={`${match.path}complex/:id`}
        route={{ path: 'complex' }}
        component={ComplexPage}
      />
      <WrappedRoute exact path={`${match.path}complex/:id/history`} component={ComplexHistory} />
      <WrappedRoute path={`${match.path}complex/:id/apartments`} component={ComplexApartments} />
      <WrappedRoute
                path={`${match.path}tariff`}
                component={TariffPage}
                isDisableHeader={true}
              />
    </Switch>
  );
};

RegionContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default withRouter(RegionContainer);
