import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../ui/material';
import { withStyles } from '@material-ui/core/styles';
import { TextFieldStyle } from './styles';

function _TextField(props) {
    const {
      input,
      label,
      meta: { touched, error },
      classes,
      ...other
    } = props;
    return (
      <TextField
        label={label}
        className={classes.root}
        helperText={touched && error}
        error={!!touched && !!error}
        {...input}
        {...other}
      />
    );
  }

_TextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const StyledTextField = withStyles(TextFieldStyle)(_TextField);


