// import {Styles, Utils} from '../components/ui/material';
import { createMuiTheme } from '@material-ui/core/styles';
import { fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';
// import cyan from '@material-ui/core/colors/cyan';
// import pink from '@material-ui/core/colors/pink';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import common from '@material-ui/core/colors/common';
import { spacing } from '@material-ui/system';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

const Spacing = spacing;

// const fullBlack = 'rgba(0, 0, 0, 1)';
const darkBlack = 'rgba(0, 0, 0, 0.87)';
const lightBlack = 'rgba(0, 0, 0, 0.54)';
const minBlack = 'rgba(0, 0, 0, 0.26)';
const faintBlack = 'rgba(0, 0, 0, 0.12)';
// const fullWhite = 'rgba(255, 255, 255, 1)';
const darkWhite = 'rgba(255, 255, 255, 0.87)';
// const lightWhite = 'rgba(255, 255, 255, 0.54)';

const theme = createMuiTheme({
  spacing: 2,
  contentFontFamily: 'Roboto, sans-serif',
  palette: {
    primary: {
      main: '#8dc63f',
    },
    secondary: {
      main: '#FF7F29',
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        height: 56,
        minHeight: 40,
        '@media (min-width: 600px)': {
          minHeight: 56,
        },
      },
    },
    MuiIconButton: {
      edgeStart: {
        marginLeft: 0,
      },
      root: {
        color: '#fff',
        padding: '5px',
      },
    },
    MuiPaper: {
      root: {
        'background-color': '#000',
        color: '#fff',
      },
    },
    MuiMenu: {
      paper: {
        'background-color': '#fff',
        color: '#000',
      },
    },
    MuiDialog: {
      paper: {
        'background-color': '#fff',
        color: '#000',
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 5,
        boxShadow: 'none',
      },
      colorPrimary: {
        'background-color': '#fff',
      },
    },
    MuiIcon: {
      root: {
        color: 'white',
      },
    },
    MuiTab: {
      wrapper: {
        zIndex: 1,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#212121',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '0px',
        paddingRight: '16px',
      },
    },
    MuiDivider: {
      root: {
        marginLeft: '16px',
      },
    },
    MuiListItemText: {
      root: {
        fontSize: '15px',
        marginTop: '7px',
        marginBottom: '7px',
      },
    },
    MuiSwitch: {
      root: {
        marginLeft: 'auto',
      },
      colorSecondary: {
        '&$checked': {
          color: '#FAFAFA',
        },
        '&$checked + $track': {
          opacity: 1,
        },
      },
      track: {
        backgroundColor: '#C6C6C6',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 15,
      },
      labelPlacementStart: {
        width: 'calc(100% - 21px)',
        padding: '6px 0px',
      },
    },
    MuiRadio: {
      root: {
        marginLeft: 'auto',
      },
    },
  },
  getComponentThemes(palette, space) {
    spacing = space || Spacing;
    const obj = {
      appBar: {
        color: palette.primary1Color,
        textColor: darkWhite,
        height: 48,
      },
      button: {
        height: 36,
        minWidth: 88,
        iconButtonSize: spacing.iconSize * 2,
      },
      checkbox: {
        boxColor: '#6d6d6d',
        checkedColor: palette.primary1Color,
        requiredColor: palette.primary1Color,
        disabledColor: palette.disabledColor,
        labelColor: palette.darkBlack,
        labelDisabledColor: palette.disabledColor,
      },
      datePicker: {
        color: palette.primary1Color,
        textColor: common.white,
        calendarTextColor: palette.textColor,
        selectColor: palette.primary2Color,
        selectTextColor: common.white,
      },
      dropDownMenu: {
        accentColor: palette.borderColor,
      },

      flatButton: {
        color: palette.canvasColor,
        textColor: palette.textColor,
        primaryTextColor: palette.accent1Color,
        secondaryTextColor: palette.primary1Color,
      },
      floatingActionButton: {
        buttonSize: 56,
        miniSize: 40,
        color: palette.accent1Color,
        iconColor: common.white,
        secondaryColor: palette.primary1Color,
        secondaryIconColor: common.white,
      },
      leftNav: {
        width: spacing.desktopKeylineIncrement * 4,
        color: darkBlack,
      },
      listItem: {
        nestedLevelDepth: 18,
      },
      menu: {
        backgroundColor: darkBlack,
        containerBackgroundColor: darkBlack,
      },
      menuItem: {
        dataHeight: 32,
        height: 48,
        hoverColor: 'rgba(0, 0, 0, .035)',
        padding: spacing.desktopGutter,
        selectedTextColor: palette.accent1Color,
      },
      menuSubheader: {
        padding: spacing.desktopGutter,
        borderColor: palette.borderColor,
        textColor: palette.primary1Color,
      },
      paper: {
        backgroundColor: common.white,
      },
      radioButton: {
        borderColor: '#6d6d6d',
        backgroundColor: '#6d6d6d',
        checkedColor: palette.primary1Color,
        requiredColor: palette.primary1Color,
        disabledColor: palette.disabledColor,
        size: 24,
        labelColor: palette.darkBlack,
        labelDisabledColor: palette.disabledColor,
      },
      raisedButton: {
        color: common.white,
        textColor: palette.textColor,
        primaryColor: palette.accent1Color,
        primaryTextColor: common.white,
        secondaryColor: palette.primary1Color,
        secondaryTextColor: common.white,
      },
      slider: {
        trackSize: 2,
        trackColor: minBlack,
        trackColorSelected: grey['500'],
        handleSize: 12,
        handleSizeDisabled: 8,
        handleColorZero: grey['400'],
        handleFillColor: common.white,
        selectionColor: palette.primary3Color,
        rippleColor: palette.primary1Color,
      },
      snackbar: {
        textColor: common.white,
        backgroundColor: '#323232',
        actionColor: palette.accent1Color,
      },
      table: {
        backgroundColor: common.white,
      },
      tableHeader: {
        borderColor: palette.borderColor,
      },
      tableHeaderColumn: {
        textColor: lightBlack,
        height: 56,
        spacing: 28,
      },
      tableFooter: {
        borderColor: palette.borderColor,
        textColor: lightBlack,
      },
      tableRow: {
        hoverColor: grey['200'],
        stripeColor: lighten(palette.primary1Color, 0.55),
        selectedColor: grey['300'],
        textColor: darkBlack,
        borderColor: palette.borderColor,
      },
      tableRowColumn: {
        height: 48,
        spacing: 28,
      },
      timePicker: {
        color: common.white,
        textColor: grey['600'],
        accentColor: palette.primary1Color,
        clockColor: common.black,
        selectColor: palette.primary2Color,
        selectTextColor: common.white,
      },
      toggle: {
        thumbOnColor: palette.primary1Color,
        thumbOffColor: grey['50'],
        thumbDisabledColor: grey['400'],
        thumbRequiredColor: palette.primary1Color,
        trackOnColor: fade(palette.primary1Color, 0.5),
        trackOffColor: minBlack,
        trackDisabledColor: faintBlack,
        labelColor: palette.textColor,
        labelDisabledColor: palette.disabledColor,
      },
      toolbar: {
        backgroundColor: darken('#eeeeee', 0.05),
        height: 48,
        titleFontSize: 20,
        iconColor: 'rgba(0, 0, 0, .40)',
        separatorColor: 'rgba(0, 0, 0, .175)',
        menuHoverColor: 'rgba(0, 0, 0, .10)',
        minHeight: 40,
        '@media (min-width: 600px)': {
          minHeight: 48,
        },
      },
      tabs: {
        backgroundColor: palette.white,
      },
      textField: {
        textColor: palette.textColor,
        hintColor: palette.disabledColor,
        floatingLabelColor: palette.textColor,
        disabledTextColor: palette.disabledColor,
        errorColor: red['500'],
        focusColor: palette.primary1Color,
        backgroundColor: 'transparent',
        borderColor: palette.borderColor,
      },
    };

    // Properties based on previous properties
    obj.flatButton.disabledTextColor = fade(obj.flatButton.textColor, 0.3);
    obj.floatingActionButton.disabledColor = darken(common.white, 0.1);
    obj.floatingActionButton.disabledTextColor = fade(palette.textColor, 0.3);
    obj.raisedButton.disabledColor = darken(obj.raisedButton.color, 0.1);
    obj.raisedButton.disabledTextColor = fade(obj.raisedButton.textColor, 0.3);
    obj.slider.handleSizeActive = obj.slider.handleSize * 2;
    obj.toggle.trackRequiredColor = fade(obj.toggle.thumbRequiredColor, 0.5);

    return obj;
  },
});

export default theme;
