import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import UnionWhiteIconSvg from './union_white.svg';
import UnionIconSvg from './union.svg';
import styles from './UnionIcon.css';

const UnionIcon = ({ style, whiteIcon }) => {
  return (whiteIcon ?
    <img
      className={classNames(styles.root)}
      style={style}
      src={UnionWhiteIconSvg}
      alt="unionIcon"
    />
    :
    <img
      className={classNames(styles.root)}
      style={style}
      src={UnionIconSvg}
      alt="unionIcon"
    />
  );
};

UnionIcon.propTypes = {
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  whiteIcon: PropTypes.bool,
};

UnionIcon.defaultProps = {
  whiteIcon: false
};

export default UnionIcon;
