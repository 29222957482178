import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from './ui/material';
import SearchIcon from './ui/assets/search.svg';

const ButtonSearch = ({ link }) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <IconButton>
        <img src={SearchIcon} alt="search-icon" />
      </IconButton>
    </Link>
  );
};

ButtonSearch.propTypes = {
  link: PropTypes.string.isRequired,
};

export default ButtonSearch;
