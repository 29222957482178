import { MICRODISTRICT_RATING_LOAD, MICRODISTRICT_RATING_IGNORE } from '../../actions/actionTypes';
import { START, SUCCESS, ERROR } from '../../actions/consts';

const DEFAULT_STATE = {
  payload: null,
  loading: false,
  loaded: false,
};

export default function microdistrictRating(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case MICRODISTRICT_RATING_LOAD + START:
      return { ...state, loading: true, loaded: false };
    case MICRODISTRICT_RATING_LOAD + SUCCESS:
      return { ...state, loading: false, loaded: true, payload: action.payload };
    case MICRODISTRICT_RATING_LOAD + ERROR:
      return { ...state, loading: false, loaded: true, payload: null, error: action.error };
    case MICRODISTRICT_RATING_IGNORE:
      return { ...state, loading: false, loaded: false, payload: null };
    default:
      return state;
  }
}
