/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MuiCheckbox } from '../ui/material';
import CheckboxStyle from './styles';

function Checkbox(props) {
  const { classes, ...other } = props;
  return (
    <MuiCheckbox className={classes.root} icon={<span className={classes.icon} />} {...other} />
  );
}

Checkbox.defaultProps = {
  classes: {},
};

Checkbox.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
};

export const StyledCheckbox = withStyles(CheckboxStyle)(Checkbox);
