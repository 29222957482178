import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Banner from '../../Banner';
import styles from '../ProperyList.css';

class _AdItem extends PureComponent {
  render() {
    const { id, height, domain, storage } = this.props;
    return (
      <div className={styles.item} key={id}>
        <div
          className={styles.root}
          style={{
            height,
            padding: 0,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '6px',
              right: '5px',
              fontSize: '10px',
              color: '#949494',
              zIndex: 3,
            }}
          >
            Реклама
          </div>
          <Banner id={id} domain={domain} storage={storage} type="list" />
        </div>
      </div>
    );
  }
}

_AdItem.propTypes = {
  id: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  domain: PropTypes.string.isRequired,
  storage: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default _AdItem;
