import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { userLogout } from '../../actions';

class _LogoutPage extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(userLogout());
  }

  render() {
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

_LogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_LogoutPage);
