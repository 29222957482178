import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import AnimatedNumber from 'animated-number-react';
import styles from './SubmitFormButton.css';

class _SubmitFormButton extends PureComponent {
  renderTotalCount = (totalCount) => {
    return (
      <AnimatedNumber
        value={totalCount}
        formatValue={(value) => `Показать ${value.toFixed(0)} объявлений`}
        duration={300}
      />
    );
  };

  render() {
    const { totalCount } = this.props;
    return (
      <div className={styles.wrapper}>
        <button type="submit" className={styles.root}>
          {totalCount ? this.renderTotalCount(totalCount) : 'Показать'}
        </button>
      </div>
    );
  }
}

_SubmitFormButton.defaultProps = {
  totalCount: 0,
};

_SubmitFormButton.propTypes = {
  totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default _SubmitFormButton;
