import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { ComplexListHistory } from '../components/ComplexView';
import { loadComplexHistory, setCurrentPageType } from '../actions';
import { lists as listsFromPropTypes } from '../lib/PropTypeValues';

class _ComplexHistory extends Component {
  componentDidMount() {
    const { dispatch, params, complex } = this.props;
    if (!complex.historyLoaded) {
      dispatch(loadComplexHistory(params.id));
    }
    dispatch(setCurrentPageType('complex'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  render() {
    const { complex, lists } = this.props;

    return (
      <Loader loaded={complex.historyLoaded}>
        <ComplexListHistory lists={lists} complexHistory={complex.history} />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    complex: state.complex,
    lists: state.lists,
  };
}

_ComplexHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  complex: PropTypes.shape({
    loaded: PropTypes.bool,
    history: PropTypes.bool,
    data: PropTypes.objectOf(PropTypes.any),
    historyLoaded: PropTypes.bool,
  }).isRequired,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  lists: listsFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(_ComplexHistory);
