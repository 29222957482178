import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'react-loader';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import PropertyView from '../components/PropertyView/PropertyView';
import { loadObject, loadGarageObject, setCurrentPageType, loadMortgage } from '../actions';
import loadMicrodistrictRating from '../components/MicrodistrictRating/actions';
import loadSimilarObjects from '../components/SimilarObjects/actions';
import {
  lists as listsFromPropTypes,
  dictionaries as dictionariesFromPropTypes,
  selectedRegion as selectedRegionFromPropTypes,
} from '../lib/PropTypeValues';

import {
  gtagPushEventNewPage,
  getCianDealType,
  getCianObjectType,
  getExtraCategory,
  getParentId,
  getCustomUrlCian,
  getCustomUrlCianForApi2
} from '../classes/analytics/gtagUtils';
import GtagBuilder from '../classes/analytics/gtagEventBuilder';
import GtagBuilderV2 from '../classes/analytics/gtagEventBuilderV2';
import { getNewFormat } from '../utils';

class _ViewPage extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      prevPropertyId: null,
      nextPropertyId: null,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.load(id);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    if (nextProps.match.params.id !== match.params.id) {
      this.load(nextProps.match.params.id);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('search'));
  }

  setPagination(id) {
    const { properties } = this.props;
    if (properties.loaded) {
      const propertiesLength = properties.items.length;
      const currentIndex = findIndex(properties.items, (chr) => Number(chr.id) === Number(id));

      const prevPropertyId = currentIndex - 1 >= 0 ? properties.items[currentIndex - 1].id : null;
      const nextPropertyId =
        currentIndex + 1 < propertiesLength ? properties.items[currentIndex + 1].id : null;
      this.setState({
        prevPropertyId,
        nextPropertyId,
      });
    }
  }

  load(id) {
    const { lists, dictionaries, dispatch, route } = this.props;
    const type = route.path;
    if (type === 'garages') {
      Promise.all([dispatch(loadGarageObject(id, dictionaries))]).then(() => {
        dispatch(loadMicrodistrictRating());
        dispatch(loadSimilarObjects());
        this.handleSendViewPageGA('v2');
      });
    } else {
      Promise.all([dispatch(loadObject(id, type, lists))]).then(() => {
        this.setPagination(id);
        dispatch(loadMicrodistrictRating());
        dispatch(loadSimilarObjects());
        this.handleSendViewPageGA('v1');
      });
      dispatch(loadMortgage(id, type));
    }
    dispatch(setCurrentPageType('view'));
  }

  handleSendViewPageGA = (apiVersion = 'v1') => {
    const {
      object,
      user,
      selectedRegion,
    } = this.props;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const isDailyType = object.data && object.data.period == 2;
    const realtyType = isDailyType ? 'rentDaily' : object.data.realtyType;
    const data = {
      v1: {
        dealType: getCianDealType(getNewFormat(realtyType).dealType),
        dealTypeExtraCategory: getNewFormat(realtyType).dealType,
        objectType: getCianObjectType(getNewFormat(realtyType).realtyType, object.data.refType),
        realtyType: getNewFormat(realtyType).realtyType,
        objectTypeId: object.data.refType,
      },
      v2: {
        dealType: getCianDealType(object.data.dealType),
        dealTypeExtraCategory: object.data.dealType,
        objectType: getCianObjectType(object.data.realtyType, object.data.objectTypeId),
        realtyType: object.data.realtyType,
        objectTypeId: object.data.objectTypeId,
      }
    }
    const cianGtagEvent = GtagBuilderClass
      .setEvent('newpage')
      .setEventCategory('page')
      .setEventAction('newpage')
      .setEventLabel('open')
      .setUser(user)
      .setPage({
        pageType: 'Card',
        siteType: 'mobile',
        dealType: data[apiVersion].dealType,
        objectType: data[apiVersion].objectType,
        region: selectedRegion.name.replace(/\.$/, ''),
        offerID: object.data.id,
        parentId: getParentId(object.data),
        extra: {
          category: getExtraCategory(data[apiVersion].dealTypeExtraCategory, data[apiVersion].realtyType, data[apiVersion].objectTypeId)
        }
      })
      .setProducts([object.data])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleSendViewContactsGA = (apiVersion = 'v1') => {
    const {
      object,
      user,
      selectedRegion,
    } = this.props;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const isDailyType = object.data && object.data.period == 2;
    const realtyType = isDailyType ? 'rentDaily' : object.data.realtyType;
    const data = {
      v1: {
        dealType: getCianDealType(getNewFormat(object.data && realtyType).dealType),
        dealTypeExtraCategory: getNewFormat(object.data && realtyType).dealType,
        objectType: getCianObjectType(getNewFormat(object.data && realtyType).realtyType, object.data && object.data.refType),
        realtyType: getNewFormat(object.data && realtyType).realtyType,
        objectTypeId: object.data && object.data.refType,
      },
      v2: {
        dealType: getCianDealType(object.data && object.data.dealType),
        dealTypeExtraCategory: object.data && object.data.dealType,
        objectType: getCianObjectType(object.data && object.data.realtyType, object.data && object.data.objectTypeId),
        realtyType: object.data && object.data.realtyType,
        objectTypeId: object.data && object.data.objectTypeId,
      }
    }
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('Phones')
      .setEventAction('Open_card')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(object.data) : getCustomUrlCianForApi2(object.data))
      .setUser(user)
      .setPage({
        pageType: 'Card',
        siteType: 'mobile',
        dealType: data[apiVersion].dealType,
        objectType: data[apiVersion].objectType,
        region: selectedRegion.name.replace(/\.$/, ''),
        offerID: object.data.id,
        parentId: getParentId(object.data),
        extra: {
          category: getExtraCategory(data[apiVersion].dealTypeExtraCategory, data[apiVersion].realtyType, data[apiVersion].objectTypeId)
        }
      })
      .setProducts([object.data])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleEventAddFavoriteGA = (apiVersion = 'v1') => {
    const {
      object,
      user,
      selectedRegion,
    } = this.props;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const isDailyType = object.data && object.data.period == 2;
    const realtyType = isDailyType ? 'rentDaily' : object.data.realtyType;
    const data = {
      v1: {
        dealType: getCianDealType(getNewFormat(object.data && realtyType).dealType),
        dealTypeExtraCategory: getNewFormat(object.data && realtyType).dealType,
        objectType: getCianObjectType(getNewFormat(object.data && realtyType).realtyType, object.data && object.data.refType),
        realtyType: getNewFormat(object.data && realtyType).realtyType,
        objectTypeId: object.data && object.data.refType,
      },
      v2: {
        dealType: getCianDealType(object.data && object.data.dealType),
        dealTypeExtraCategory: object.data && object.data.dealType,
        objectType: getCianObjectType(object.data && object.data.realtyType, object.data && object.data.objectTypeId),
        realtyType: object.data && object.data.realtyType,
        objectTypeId: object.data && object.data.objectTypeId,
      }
    }
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('favorite')
      .setEventAction('add_from_card')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(object.data) : getCustomUrlCianForApi2(object.data))
      .setUser(user)
      .setPage({
        pageType: 'Card',
        siteType: 'mobile',
        dealType: data[apiVersion].dealType,
        objectType: data[apiVersion].objectType,
        region: selectedRegion.name.replace(/\.$/, ''),
        offerID: object.data.id,
        parentId: getParentId(object.data),
        extra: {
          category: getExtraCategory(data[apiVersion].dealTypeExtraCategory, data[apiVersion].realtyType, data[apiVersion].objectTypeId)
        }
      })
      .setProducts([object.data])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  handleEventRemoveFavoriteGA = (apiVersion = 'v1') => {
    const {
      object,
      user,
      selectedRegion,
    } = this.props;
    const GtagBuilderClass = apiVersion === 'v1' ? new GtagBuilder({}) : new GtagBuilderV2({});
    const isDailyType = object.data && object.data.period == 2;
    const realtyType = isDailyType ? 'rentDaily' : object.data.realtyType;
    const data = {
      v1: {
        dealType: getCianDealType(getNewFormat(object.data && realtyType).dealType),
        dealTypeExtraCategory: getNewFormat(object.data && realtyType).dealType,
        objectType: getCianObjectType(getNewFormat(object.data && realtyType).realtyType, object.data && object.data.refType),
        realtyType: getNewFormat(object.data && realtyType).realtyType,
        objectTypeId: object.data && object.data.refType,
      },
      v2: {
        dealType: getCianDealType(object.data && object.data.dealType),
        dealTypeExtraCategory: object.data && object.data.dealType,
        objectType: getCianObjectType(object.data && object.data.realtyType, object.data && object.data.objectTypeId),
        realtyType: object.data && object.data.realtyType,
        objectTypeId: object.data && object.data.objectTypeId,
      }
    }
    const cianGtagEvent = GtagBuilderClass
      .setEvent('oldevent')
      .setEventCategory('favorite')
      .setEventAction('remove_from_card')
      .setEventLabel(apiVersion === 'v1' ? getCustomUrlCian(object.data) : getCustomUrlCianForApi2(object.data))
      .setUser(user)
      .setPage({
        pageType: 'Card',
        siteType: 'mobile',
        dealType: data[apiVersion].dealType,
        objectType: data[apiVersion].objectType,
        region: selectedRegion.name.replace(/\.$/, ''),
        offerID: object.data.id,
        parentId: getParentId(object.data),
        extra: {
          category: getExtraCategory(data[apiVersion].dealTypeExtraCategory, data[apiVersion].realtyType, data[apiVersion].objectTypeId)
        }
      })
      .setProducts([object.data])
      .getEvent();
    gtagPushEventNewPage(cianGtagEvent);
  }

  render() {
    const {
      object,
      location,
      route,
      selectedRegion,
      mortgage,
      lists,
      microdistrictRating,
      similarObjects,
    } = this.props;
    const { prevPropertyId, nextPropertyId } = this.state;
    const type = route.path;
    return (
      <Loader loaded={object.loaded}>
        <PropertyView
          lists={lists}
          path={location.pathname}
          viewType={route.path}
          selectedRegion={selectedRegion}
          microdistrictRating={microdistrictRating}
          similarObjects={similarObjects}
          prevPropertyId={prevPropertyId}
          nextPropertyId={nextPropertyId}
          mortgage={mortgage}
          handleSendViewContactsGA={() => this.handleSendViewContactsGA(type === 'garages' ? 'v2' : 'v1')}
          eventAddFavorite={() => this.handleEventAddFavoriteGA(type === 'garages' ? 'v2' : 'v1')}
          eventRemoveFavorite={() => this.handleEventRemoveFavoriteGA(type === 'garages' ? 'v2' : 'v1')}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...object.data}
        />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    object: state.object,
    lists: state.lists,
    dictionaries: state.dictionaries,
    bids: state.bids,
    selectedRegion: state.selectedRegion,
    microdistrictRating: state.microdistrictRating,
    similarObjects: state.similarObjects,
    properties: state.properties,
    mortgage: state.object.mortgage,
    user: state.user,
  };
}

_ViewPage.defaultProps = {
  mortgage: null,
};

_ViewPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  properties: PropTypes.shape({
    loaded: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  lists: listsFromPropTypes.isRequired,
  dictionaries: dictionariesFromPropTypes.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  object: PropTypes.shape({
    data: PropTypes.objectOf(PropTypes.any),
    loaded: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  mortgage: PropTypes.objectOf(PropTypes.any),
  microdistrictRating: PropTypes.shape({
    payload: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }).isRequired,
  similarObjects: PropTypes.shape({
    payload: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }).isRequired,
  user: PropTypes.objectOf({
    token: PropTypes.string,
    profile: PropTypes.objectOf(PropTypes.any),
  }),
};

export default connect(mapStateToProps)(_ViewPage);
