import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { values, isEmpty } from 'lodash';
import serialize from 'form-serialize';
import BusinessFields from './BusinessFields';
import RentApartmentsFields from './RentApartmentsFields';
import DailyRentApartmentsFields from './DailyRentApartmentsFields';
import DailyRentLandsFields from './DailyRentLandsFields';
import RentLandsFields from './RentLandsFields';
import Sticky from '../Sticky';
import { showModal, setRentsInterval } from '../../actions';
import styles from './SearchForm.css';
import SubmitFormButton from '../SubmitFormButton';
import {
  selectedRegion as selectedRegionFromPropTypes,
  lists as listsFromPropTypes,
  selectedLocality as selectedLocalityFromPropTypes,
} from '../../lib/PropTypeValues';

class RentsForm extends React.Component {
  handleShowModal = (modalName, modalProps) => {
    const { dispatch } = this.props;
    dispatch(showModal(modalName, modalProps));
  };

  handleSubmit(e) {
    const { onSubmit } = this.props;
    e.preventDefault();
    const formdata = serialize(ReactDOM.findDOMNode(this.refs.form), { hash: true });

    onSubmit(formdata);
  }

  onClearSelectedValue = (name) => {
    const { updateFilter, filter } = this.props;
    const currentFilter = {
      ...filter,
    };
    delete currentFilter[name];
    updateFilter(currentFilter);
  };

  onChangeFilter = (delay = 0) => {
    const { updateFilter } = this.props;
    const formdata = serialize(ReactDOM.findDOMNode(this.refs.form), { hash: true });
    if (delay) {
      setTimeout(() => {
        if (!isEmpty(formdata)) {
          updateFilter(formdata);
        }
      }, delay);
    }
    if (!delay) {
      if (!isEmpty(formdata)) {
        updateFilter(formdata);
      }
    }
  };

  handleChangeInterval = (value) => {
    const { dispatch } = this.props;
    dispatch(setRentsInterval(value));
  };

  handleShowModal = (modalName, modalProps) => {
    const { dispatch } = this.props;
    dispatch(showModal(modalName, modalProps));
  };

  renderFields() {
    const {
      lists,
      region,
      locality,
      rubric,
      onSelectLocality,
      onSelectDistrict,
      filter,
      type,
      microdistricts,
      onSelectRegion,
      rubrics,
      selectedRentsInterval,
      intervals,
      isShowMls,
      districts,
    } = this.props;
    const formDistricts = values(districts);
    const passProps = {
      region,
      locality,
      districts: formDistricts,
      filter,
      type,
      ...lists,
      onSelectLocality,
      onSelectDistrict,
      onSelectRegion,
      microdistricts,
      onChangeFilter: this.onChangeFilter,
      handleShowModal: this.handleShowModal,
      rubrics,
      rubric,
      lists,
      intervals,
      selectedRentsInterval,
      handleChangeInterval: this.handleChangeInterval,
      isShowMls,
      onClearSelectedValue: this.onClearSelectedValue,
    };

    const routeToComponent = {
      longTerm: {
        rentApartments: RentApartmentsFields,
        rentLands: RentLandsFields,
        rentBusiness: BusinessFields,
      },
      daily: {
        rentApartments: DailyRentApartmentsFields,
        rentLands: DailyRentLandsFields,
        rentBusiness: BusinessFields,
      },
    };

    const props = { ...passProps, ref: 'fields', type: 'rents' };
    return React.createElement(
      routeToComponent[selectedRentsInterval.type][rubric.rentType],
      props
    );
  }

  render() {
    const { totalCount, rubric, isSelected } = this.props;
    return (
      <div>
        <form
          className={styles.form}
          onSubmit={this.handleSubmit.bind(this)}
          ref="form"
          action={rubric.route}
        >
          {this.renderFields()}

          {isSelected && (
            <Sticky>
              <SubmitFormButton totalCount={totalCount} />
            </Sticky>
          )}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    rentsFilter: state.rentsFilter,
    intervals: state.intervals,
  };
}

RentsForm.propTypes = {
  lists: listsFromPropTypes.isRequired,
  filter: PropTypes.objectOf(PropTypes.any),
  selectedRentsInterval: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  region: selectedRegionFromPropTypes.isRequired,
  rubric: PropTypes.shape({
    route: PropTypes.string,
    rentType: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func,
  updateFilter: PropTypes.func.isRequired,
  totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func.isRequired,
  locality: selectedLocalityFromPropTypes.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectLocality: PropTypes.func.isRequired,
  onSelectRegion: PropTypes.func.isRequired,
  type: PropTypes.string,
  microdistricts: PropTypes.arrayOf(PropTypes.object),
  rubrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  intervals: PropTypes.arrayOf(PropTypes.object).isRequired,
  districts: PropTypes.arrayOf(PropTypes.object),
  onSelectDistrict: PropTypes.func.isRequired,
  isShowMls: PropTypes.bool.isRequired,
};

RentsForm.defaultProps = {
  filter: {},
  microdistricts: [],
  districts: [],
  totalCount: 0,
  onSubmit: () => {},
  type: '',
};

export default connect(mapStateToProps)(RentsForm);
