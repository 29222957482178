import Cookies from 'cookies-js';
import { USER_LOGIN_SUCCESS, USER_LOGOUT, loadProfile } from '../actions';

let middlewareUserProfileLoading = false;

const userAuthCookie = (store) => (next) => (action) => {
  if (action.type === USER_LOGIN_SUCCESS) {
    const domain = document.location.hostname.split('.').slice(1).join('.');
    Cookies.set('msessionid', action.token, { expires: Infinity, domain: `.${domain}`, path: '/' });
  }
  if (action.type === USER_LOGOUT) {
    const domain = document.location.hostname.split('.').slice(1).join('.');
    Cookies.expire('msessionid', { domain: `.${domain}`, path: '/' });
  }

  const token = Cookies.get('msessionid');
  if (token && !store.getState().user.token && !middlewareUserProfileLoading) {
    middlewareUserProfileLoading = true;
    store.dispatch({
      type: USER_LOGIN_SUCCESS,
      token,
    });
    store.dispatch(loadProfile(token)).then(() => {
      middlewareUserProfileLoading = false;
    });
  }

  return next(action);
};

export default userAuthCookie;
