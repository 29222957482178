import PropTypes from 'prop-types';
import React from 'react';
import Adfox from '../Adfox';
import config from './config';
import cn from './Banner.css';

export default class Banner extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { domain, id, storage } = this.props;
    const adfoxConf = config.Adfox;
    const params = adfoxConf[domain] ? adfoxConf[domain].params : adfoxConf.default.params;
    return (
      <div className={cn.base}>
        <Adfox id={`${1484218589103820}_${id}`} params={params} storage={storage} />
      </div>
    );
  }
}

Banner.propTypes = {
  domain: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  storage: PropTypes.objectOf(PropTypes.any).isRequired,
};
