import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectedRegion as selectedRegionFromPropTypes } from '../lib/PropTypeValues';

class RelativeLinkFromDomain extends PureComponent {
  render() {
    const {
      selectedRegion: { domain },
      to,
      children,
      ...props
    } = this.props;
    return (
      <Link to={`/${domain}/${to}`} {...props}>
        {children}
      </Link>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRegion: state.selectedRegion,
  };
}

RelativeLinkFromDomain.defaultProps = {
  to: '',
};

RelativeLinkFromDomain.propTypes = {
  to: PropTypes.string,
  selectedRegion: selectedRegionFromPropTypes.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(RelativeLinkFromDomain);
