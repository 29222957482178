import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Infinite from 'react-infinite';
import Loader from 'react-loader';
import { Link } from 'react-router-dom';
import { formatPrice, toDotDMYhm } from '../../utils';
import { Panel } from '../ui';
import cn from './UserInvoices.css';
import { Card } from '../ui/material';
import { loadUserInvoicesList } from '../../actions';
import { DownloadIcon } from '../ui/Icons';
import { selectedRegion as selectedRegionFromPropTypes } from '../../lib/PropTypeValues';

class UserInvoices extends React.Component {
  state = {
    page: 1,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadUserInvoicesList());
  }

  onLoadMore = () => {
    const { userInvoicesList, dispatch } = this.props;
    const { page } = this.state;
    const meta = userInvoicesList.meta || {};
    if (meta.pageCount === page) {
      return false;
    }
    const nextPage = page + 1;
    this.setState({
      page: nextPage,
    });
    dispatch(
      loadUserInvoicesList({
        more: true,
        page: nextPage,
      })
    );
    return false;
  };

  render() {
    const { userInvoicesList, selectedRegion } = this.props;
    const { page } = this.state;
    const items = userInvoicesList.items || [];
    const meta = userInvoicesList.meta || {};
    const canLoadMore = meta.pageCount > page;
    const loadMore = meta.pageCount > page && meta.currentPage === page;
    const href = [`/${selectedRegion.domain}`, 'user/invoice'].join('/');

    return (
      <Loader loaded={userInvoicesList.loaded}>
        <Panel
          title="Счета на оплату"
          containerStyle={{ padding: '0' }}
          titleStyle={{ textAlign: 'center' }}
        >
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0, background: '#f9f9f9' }}>
            <Infinite
              ref={(n) => {
                this.list = n;
              }}
              elementHeight={90}
              isInfiniteLoading={!loadMore}
              infiniteLoadBeginEdgeOffset={150}
              containerHeight={window.innerHeight - 125}
              loadingSpinnerDelegate={
                canLoadMore ? (
                  <div style={{ textAlign: 'center', margin: 20 }}>Загрузка ...</div>
                ) : (
                  <div />
                )
              }
              onInfiniteLoad={this.onLoadMore}
            >
              {items.map((item) => (
                <li key={`${item.number}_${item.inserted}`} className={cn.item}>
                  <Card className={cn.card}>
                    <div className={cn.header}>
                      <div className={cn.number}>№ {item.number}</div>
                      <div className={cn.date}>{toDotDMYhm(item.inserted * 1000)}</div>
                    </div>
                    <div className={cn.params}>
                      <div>
                        <div className={cn.price}>{formatPrice(item.sum)}</div>
                        <div className={cn.contractor}>{item.contractor.companyName}</div>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <a
                          href={item.view.pdf}
                          target="_blank"
                          rel="noreferrer"
                          className={cn['btn-download']}
                        >
                          <DownloadIcon />
                        </a>
                      </div>
                    </div>
                  </Card>
                </li>
              ))}
            </Infinite>
          </ul>
          <Link to={href} className={cn.createInvoiceBtn}>
            Выписать счет
          </Link>
        </Panel>
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInvoicesList: state.userInvoicesList,
    user: state.user,
    selectedRegion: state.selectedRegion,
  };
}

UserInvoices.defaultProps = {
  userInvoicesList: {
    items: [],
    meta: {},
    loaded: false,
  },
};

UserInvoices.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userInvoicesList: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.objectOf(PropTypes.any),
    loaded: PropTypes.bool,
  }),
  selectedRegion: selectedRegionFromPropTypes.isRequired,
};

export default connect(mapStateToProps)(UserInvoices);
