import PropTypes from 'prop-types';
import React from 'react';
import styles from './PropertyItem.css';
import ym from '../../ReactYandexMetrika';
import { formatPrice, isLandDachaByArgs } from '../../../utils';
import { UpArrowIcon, DownArrowIcon } from '../../ui/Icons';
import BadgeLabel from '../../ui/BadgeLabel';
import RLink from '../../RLink';

const PropertyItemInfo = ({
  floor,
  floorTotal,
  refSquare,
  realtyType,
  refType,
  deltaPrice,
  refPriceRub,
  realtyTypeShortName,
  refStreet,
  isSpecial,
  isPromo,
  rcid,
  resComName,
  sqTotal,
  sqTiving,
  sqKitchen,
  areaTotal,
  districts,
  districtId,
}) => {
  const floorBuff = [floor || '-', floorTotal || '-'].join('/');
  const floorString = floor && floorTotal ? `Этаж ${floorBuff}` : '';
  const landArea = `Площадь ${(!!refSquare && refSquare) || '–'} сот.`;
  const houseArea = `Площадь ${
    (!!sqTotal && sqTotal.toFixed(1)) || (!!areaTotal && areaTotal.toFixed(1)) || '–'
  } /
                            ${(!!sqTiving && sqTiving.toFixed(1)) || '–'} /
                            ${(!!sqKitchen && sqKitchen.toFixed(1)) || '–'} м²`;
  const square = isLandDachaByArgs(realtyType, refType) ? landArea : houseArea;
  const arrow =
    deltaPrice > 0 ? (
      <UpArrowIcon color="#94C133" size="16" />
    ) : (
      <DownArrowIcon color="#FF3132" size="16" />
    );

  let hilight = '';
  if (isSpecial) {
    hilight = styles.special;
  }
  if (isPromo) {
    hilight = styles.promo;
  }
  const district = districts && districts[districtId] && `${districts[districtId].name} р-н`;

  return (
    <div className={`${styles.info} ${hilight}`}>
      <div className={styles.price}>
        {Number(refPriceRub) ? (
          <span>
            {formatPrice(refPriceRub)} {deltaPrice && arrow}
          </span>
        ) : (
          <span>Цена договорная {deltaPrice && arrow}</span>
        )}
      </div>
      <div className={styles.badgeContainer}>
        {!!isPromo && (
          <div className={styles.badge}>
            <BadgeLabel.Promo />
          </div>
        )}
        {!!isSpecial && (
          <div className={styles.badge}>
            <BadgeLabel.Premium />
          </div>
        )}
      </div>

      <div className={styles.address}>
        {[realtyTypeShortName, refStreet].filter((x) => x).join(', ')}
      </div>
      <div className={styles.area}>{district}</div>
      {!!rcid && (
        <div className={styles.complexLink}>
          <RLink
            to={`complex/${rcid}`}
            onClick={ym('reachGoal', 'residential-complex')}
            className={styles.complexLink}
          >
            {`ЖК "${resComName}"`}
          </RLink>
        </div>
      )}
      <div className={styles.params}>
        <div className={styles.square}>{square}</div>
        <div className={styles.devider}>{!!floor && !!floorTotal && '•'}</div>
        <div className={styles.floors}>{floorString}</div>
      </div>
    </div>
  );
};

PropertyItemInfo.defaultProps = {
  refSquare: null,
  deltaPrice: null,
  refPriceRub: null,
  isSpecial: null,
  isPromo: null,
  rcid: null,
  resComName: '',
  districts: null,
  sqTiving: null,
  sqKitchen: null,
  areaTotal: null,
  sqTotal: null,
  floorTotal: null,
  floor: null,
  refType: null,
};

PropertyItemInfo.propTypes = {
  floor: PropTypes.number,
  floorTotal: PropTypes.number,
  refSquare: PropTypes.number,
  realtyType: PropTypes.string.isRequired,
  refType: PropTypes.number,
  deltaPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refPriceRub: PropTypes.number,
  realtyTypeShortName: PropTypes.string.isRequired,
  refStreet: PropTypes.string.isRequired,
  isSpecial: PropTypes.number,
  isPromo: PropTypes.number,
  rcid: PropTypes.number,
  resComName: PropTypes.string,
  areaTotal: PropTypes.number,
  districts: PropTypes.objectOf(PropTypes.any),
  districtId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sqTotal: PropTypes.number,
  sqTiving: PropTypes.number,
  sqKitchen: PropTypes.number,
};

export default PropertyItemInfo;
