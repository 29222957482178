import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { RadioButton as MUIRadioButton } from '../ui/material';
import StylesRadioButton from './styles';

const RadioButton = (props) => {
  const { classes, ...other } = props;
  return (
    <MUIRadioButton
      className={classes.root}
      {...other}
      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      disableRipple
    />
  );
};

RadioButton.defaultProps = {
  classes: {},
};

RadioButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(StylesRadioButton)(RadioButton);
