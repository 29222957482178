import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { UserPayments } from '../../components/User';
import { setCurrentPageType } from '../../actions';
import { user as userFromPropTypes } from '../../lib/PropTypeValues';

class _PaymentsPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('transactions'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentPageType('empty'));
  }

  render() {
    const { user } = this.props;
    return (
      <Loader loaded={!user.loading}>
        <UserPayments />
      </Loader>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

_PaymentsPage.propTypes = {
  user: userFromPropTypes.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(_PaymentsPage);
