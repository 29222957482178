import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DialogContent } from '../ui/material';
import { DialogContentStyles, AutocompleteDialogContentStyles } from './DialogStyles';

function DialogContentWithStyles(props) {
  const { classes, children } = props;
  return (
    // eslint-disable-next-line no-restricted-globals
    <DialogContent className={classes.root} dividers={scroll === 'paper'} {...props}>
      {children}
    </DialogContent>
  );
}

DialogContentWithStyles.defaultProps = {
  classes: {},
};

DialogContentWithStyles.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

export const StyledDialogContent = withStyles(DialogContentStyles)(DialogContentWithStyles);
export const AutocompleteDialogContent = withStyles(AutocompleteDialogContentStyles)(
  DialogContentWithStyles
);
