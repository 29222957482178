import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from './UserLine.css';

class Line extends PureComponent {
  render() {
    const { name, children, value } = this.props;
    return (
      <div className={cn.root}>
        <div className={cn.name}>{name}</div>
        <div className={cn.value}>{children || value}</div>
      </div>
    );
  }
}

Line.defaultProps = {
  children: null,
  value: '',
};

Line.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Line;
