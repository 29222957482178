import PropTypes from 'prop-types';
import React from 'react';

/**
 * @class Placemark
 */
class Placemark extends React.Component {
  componentDidMount() {
    const { map, ymaps, geometry, properties, options } = this.props;
    const placemark = new ymaps.Placemark(geometry, properties, options);
    map.geoObjects.add(placemark);
  }

  render() {
    return null;
  }
}

Placemark.defaultProps = {
  properties: {},
  options: {},
  map: {},
  ymaps: {},
};

Placemark.propTypes = {
  geometry: PropTypes.arrayOf(PropTypes.string).isRequired,
  properties: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any),
  map: PropTypes.objectOf(PropTypes.any),
  ymaps: PropTypes.objectOf(PropTypes.any),
};

export default Placemark;
