import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { includes, without, isArray } from 'lodash';
import cx from 'classnames';
import styles from './styles.css';

export default class PropertyTypeSelect extends React.Component {
  constructor(...props) {
    super(...props);
    const { value } = this.props;

    const valueInit = !isArray(value) ? Object.keys(value) : value;
    this.state = {
      checked: valueInit.map(Number),
    };
  }

  handleItemClick(id) {
    const { onChangeFilter } = this.props;
    const { checked } = this.state;
    const checkedItem = this.isChecked(id) ? without(checked, id) : [...checked, id];

    this.setState(
      {
        checked: checkedItem,
      },
      () => {
        if (onChangeFilter) {
          onChangeFilter();
        }
      }
    );
  }

  isChecked(id) {
    const { checked } = this.state;
    return includes(checked, id);
  }

  renderItem(item) {
    const { name } = this.props;
    const className = cx(styles.item, {
      [styles.checked]: this.isChecked(item.id),
    });

    return (
      <div
        key={item.id}
        className={className}
        role="button"
        onClick={this.handleItemClick.bind(this, item.id)}
      >
        {item.displayName || item.shortName}
        {this.isChecked(item.id) ? (
          <input
            type="checkbox"
            style={{ display: 'none' }}
            name={`${name}[]`}
            value={item.id}
            checked
            readOnly
          />
        ) : (
          <input
            type="checkbox"
            style={{ display: 'none' }}
            readOnly
            checked={false}
            name={`${name}[${item.id}]`}
          />
        )}
      </div>
    );
  }

  render() {
    const { label, items } = this.props;
    return (
      <>
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.container}>{items.map((item) => this.renderItem(item))}</div>
      </>
    );
  }
}

PropertyTypeSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
};

PropertyTypeSelect.defaultProps = {
  value: [],
  items: [],
  label: '',
  onChangeFilter: null,
};
